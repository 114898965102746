import React, { useLayoutEffect, useState } from "react";
import clsx from "clsx";

import { LinkedInProfile } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import useDebounce from "common/hooks/useDebounce";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import { profileSearchOptions } from "../../../../../../constants";
import { useSearchParamsContext } from "../../SearchParamsContext";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import useMergeSearchOptions from "../useMergeSearchOptions";

export default function Followers({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  const isTabletOrDesktop = useTwBreakpoint("lg");
  const { searchParamKey } = profileSearchOptions[optionsKey];
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 300);
  const { searchParams } = useSearchParamsContext();
  const { addSearchParamValue, removeSearchParamValue } =
    useSearchParams(optionsKey);
  const { followers, suggestions } = useSearchOptions({
    followersKeywords: debouncedValue,
  });
  const { mergeOptions, allOptions } = useMergeSearchOptions<LinkedInProfile>();

  const selectedAccount = searchParams[searchParamKey] as LinkedInProfile;

  function onSelect(option: LinkedInProfile) {
    addSearchParamValue(searchParamKey, option);
  }

  useLayoutEffect(() => {
    if (followers || suggestions) {
      mergeOptions(
        selectedAccount ? [selectedAccount] : [],
        followers,
        suggestions[searchParamKey],
      );
    }
  }, [suggestions, followers]);

  return (
    <>
      <SearchInput
        variant={isTabletOrDesktop ? "sm" : "md"}
        value={inputValue}
        className="max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder="Search Accounts"
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />
      {allOptions?.length ? (
        <div className="flex max-h-56 flex-col gap-1 overflow-y-auto pb-1 scrollbar-thin max-lg:mt-2">
          {allOptions.map((option) => {
            const isSelected = option.urn === selectedAccount?.urn;
            return (
              <button
                type="button"
                key={option.urn}
                className={clsx(
                  "flex w-full items-center gap-2 px-4 py-2 transition-colors max-lg:rounded-xl",
                  isSelected ? "bg-purple-50" : "hover:bg-black-50 ",
                )}
                onClick={
                  isSelected
                    ? () => removeSearchParamValue(searchParamKey, option)
                    : () => onSelect(option)
                }
              >
                <ProfileImage src={option.picture_url} size="sm" />
                <div className="text-left">
                  <h5 className="text-button-14">{option.full_name}</h5>
                  <p className="line-clamp-1 text-black-400">
                    {option.occupation}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      ) : (
        <p className="mx-4 break-words py-8 text-center text-black-500">
          No results found for{" "}
          <span className="text-black-950">{debouncedValue}</span>
        </p>
      )}
    </>
  );
}
