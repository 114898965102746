import { produce } from "immer";
import { useQueryClient } from "@tanstack/react-query";

import { Workspace } from "common/types";

export default function useWorkspaceCacheUpdate() {
  const queryClient = useQueryClient();

  function updateCachedWorkspace(
    workspaceId: string,
    updateFunction: (workspace: Workspace) => void,
  ) {
    queryClient.setQueryData<Workspace[]>(["workspaces"], (prevWorkspaces) =>
      produce(prevWorkspaces, (draftWorkspaces) => {
        for (let i = 0; i < draftWorkspaces.length; i += 1) {
          if (draftWorkspaces[i].id === workspaceId) {
            updateFunction(draftWorkspaces[i]);
            return;
          }
        }
      }),
    );
  }

  return { updateCachedWorkspace };
}
