import { useQuery, useMutation } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";

import { Invoice } from "../types";

async function getInvoices(workspaceId: string): Promise<Invoice[]> {
  return (await get(`workspaces/${workspaceId}/subscriptions/invoices`))
    .invoices;
}
async function getInvoiceUrlRequest(invoiceId: string) {
  return (await get(`billing/invoices/${invoiceId}/download`)).link;
}

export default function useBilling() {
  const { id: workspaceId, subscription } = useSelectedWorkspaceContext();
  const { invoicesKeys } = getQueryKeys(workspaceId);

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery({
    queryKey: invoicesKeys.list,
    queryFn: () => getInvoices(workspaceId),
    enabled: !!subscription,
  });

  const { mutateAsync: getInvoiceUrl, isPending: isGettingInvoiceUrl } =
    useMutation({
      mutationFn: ({ invoiceId }: { invoiceId: string }) =>
        getInvoiceUrlRequest(invoiceId),
    });

  return { invoices, isLoadingInvoices, getInvoiceUrl, isGettingInvoiceUrl };
}
