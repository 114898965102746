import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { get, patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

interface AccountLimits {
  connect: number;
  message_request: number;
  inmail: number;
}

async function getAccountLimits(
  workspaceId: string,
  accountId: string,
): Promise<AccountLimits> {
  const { limit } = await get(
    `workspaces/${workspaceId}/accounts/${accountId}/limits`,
  );
  return limit;
}

async function updateAccountLimits(
  workspaceId: string,
  accountId: string,
  limits: AccountLimits,
): Promise<AccountLimits> {
  const { limit } = await patch(
    `workspaces/${workspaceId}/accounts/${accountId}/limits`,
    limits,
  );
  return limit;
}

export default function useAccountLimits(accountId: string) {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: accountLimits, isLoading: isLoadingAccountLimits } = useQuery({
    queryKey: ["accountLimits", accountId],
    queryFn: () => getAccountLimits(workspaceId, accountId),
    refetchOnMount: false,
  });

  const { mutateAsync: setAccountLimits, isPending: isSettingAccountLimits } =
    useMutation({
      mutationFn: (limits: AccountLimits) =>
        updateAccountLimits(workspaceId, accountId, limits),
      onMutate: (newLimits) => {
        queryClient.setQueryData(["accountLimits", accountId], newLimits);
      },
      onSuccess: () => {
        toast.success("Account limits updated successfully");
      },
      onError: () => {
        toast.error("Error updating account limits");
      },
    });

  return {
    accountLimits,
    isLoadingAccountLimits,
    setAccountLimits,
    isSettingAccountLimits,
  };
}
