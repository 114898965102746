import React, { useState } from "react";
import { UsersThree, CaretRight } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import { CampaignOutreachType, CampaignType } from "common/types";
import useCampaigns from "common/datahooks/useCampaigns";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";
import { CampaignSettingsSchema } from "common/schemas";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import useAppStore from "common/hooks/useAppStore";

import LinkedInAccountSelect from "./LinkedInAccountSelect";
import ItemContent from "./ItemContent";
import OutreachType from "./OutreachType";
// Campaign imports
import useCampaignMutations from "../../../../pages/campaigns/datahooks/useCampaignMutations";

interface CampaignSettingsProps {
  type: CampaignType;
  goBack: () => void;
  resetState: () => void;
}

export default function CampaignSettings({
  type,
  goBack,
  resetState,
}: CampaignSettingsProps) {
  const isListCampaign = type === "list";
  const isGroupOrEvent = type === "group" || type === "event";

  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );

  const { accounts } = useSelectedWorkspaceContext();
  const filteredAccounts = accounts.filter(
    (account) => account.state === "LoggedIn",
  );

  const { relativeNavigate } = useWorkspaceNavigate();
  const [selectedOutreachType, setSelectedOutreachType] =
    useState<CampaignOutreachType>("connect");
  const [selectedAccount, setSelectedAccount] = useState(filteredAccounts[0]);
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns");
  const { campaigns } = useCampaigns();
  const { createCampaign, isCreatingCampaign } = useCampaignMutations();

  function onSubmit({ name, size }: { name: string; size: number }) {
    createCampaign({
      name,
      type,
      audience_size: size,
      outreach_type: selectedOutreachType,
      account_id: selectedAccount.id,
    }).then((campaign) => {
      setIsCampaignModalOpen(false);
      resetState();
      relativeNavigate(
        `campaigns/${campaign.id}/accounts/${selectedAccount.id}`,
      );
    });
  }

  const defaultName = `${type.charAt(0).toUpperCase() + type.slice(1)} Campaign${campaigns ? ` #${campaigns.length + 1}` : ""}`;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ name: string; size: number }>({
    defaultValues: {
      name: defaultName,
      size: isListCampaign ? 1000 : 250,
    },
    resolver: valibotResolver(CampaignSettingsSchema),
  });

  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  return (
    <>
      <h3 className="mb-4 text-center text-headline-2xl-bold">
        Create a new <span className="capitalize">{type}</span> campaign
      </h3>

      <Label htmlFor="campaignName" size="sm" className="mb-1 mt-6">
        Campaign name
      </Label>
      <MaxLengthInput
        variant="lg"
        id="campaignName"
        placeholder="Campaign name"
        type="text"
        error={errors?.name?.message}
        characterLength={watch("name").length}
        maxLength={getSchemaMaxLength(CampaignSettingsSchema.entries.name)}
        {...register("name")}
      />
      <LinkedInAccountSelect
        accounts={filteredAccounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <OutreachType
        outreachType={selectedOutreachType}
        setOutreachType={setSelectedOutreachType}
        isGroupOrEvent={isGroupOrEvent}
        isListCampaign={isListCampaign}
      />

      {/* AudienceSize */}
      <RenderIf condition={!isListCampaign}>
        <section className="flex flex-col justify-between gap-6 py-6 max-md:mb-12 md:flex-row md:items-center">
          <ItemContent
            icon={UsersThree}
            infoText={t("audienceSize.infoText")}
            title={t("audienceSize.title")}
            description={t("audienceSize.description")}
          />
          <div className="flex flex-col gap-1 max-md:ml-16">
            <Label htmlFor="audienceSize" size="sm">
              Audience
            </Label>
            <Input
              variant={isTabletOrDesktop ? "md" : "lg"}
              className="max-w-32"
              id="audienceSize"
              name="audienceSize"
              error={errors?.size?.message}
              leftComponent={<UsersThree weight="fill" />}
              {...register("size", {
                valueAsNumber: true,
              })}
            />
          </div>
        </section>
      </RenderIf>

      <Footer>
        <Button
          onClick={goBack}
          size={isTabletOrDesktop ? "lg" : "md"}
          variant="secondary-black"
          disabled={isCreatingCampaign}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          rightIcon={<CaretRight />}
          size={isTabletOrDesktop ? "lg" : "md"}
          isLoading={isCreatingCampaign}
        >
          Create campaign
        </Button>
      </Footer>
    </>
  );
}
