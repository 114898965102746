import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";

import { AppSumoPromotion, Promotion, LTDPromotion } from "../types";

async function getPromotionsRequest(): Promise<Promotion[]> {
  return (await get(`customers/promotions`)).promotions;
}

export default function usePromotions() {
  const { data: promotions, isLoading: isLoadingPromotions } = useQuery({
    queryKey: ["promotions"],
    queryFn: () => getPromotionsRequest(),
  });

  const ltdPromotion = promotions?.find(
    ({ type }) => type === "ltd",
  ) as LTDPromotion;
  const appSumoPromotion = promotions?.find(
    ({ type }) => type === "appsumo",
  ) as AppSumoPromotion;

  return {
    ltdPromotion,
    appSumoPromotion,
    isLoadingPromotions,
  };
}
