import React from "react";
import { X } from "@phosphor-icons/react";

import { WorkExperience } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";

import SectionItem from "./SectionItem";
import { formatMonthAndYear, formatRange } from "../../utils";
import ExpandableText from "./ExpandableText";

import CompanyDefaultImage from "assets/images/company-fallback.png";

export default function CurrentExperience({
  currentExperience,
  isLoading,
}: {
  currentExperience: WorkExperience[];
  isLoading: boolean;
}) {
  let mainComponent = (
    <div className="flex h-52 flex-col items-center justify-center rounded-20 bg-black-100 max-lg:border max-lg:border-black-200 lg:bg-black-50">
      <div className="mb-4 flex items-center gap-x-2 rounded-xl bg-white px-2 py-1.5">
        <div className="flex size-8 items-center justify-center rounded-lg bg-black-300">
          <X className="size-6 text-white" />
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="h-1.5 w-20 rounded-full bg-black-300" />
          <div className="h-1.5 w-28 rounded-full bg-black-100" />
          <div className="h-1.5 w-16 rounded-full bg-black-100" />
        </div>
      </div>
      <span className="text-body-14-bold">
        This lead does not have any current roles
      </span>
    </div>
  );
  if (isLoading || currentExperience.length) {
    mainComponent = (
      <div className="flex flex-col px-2">
        {(isLoading ? new Array(1).fill({}) : currentExperience).map(
          (
            { start_date, description, location, job_title, company },
            index,
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <SectionItem key={index}>
              <div className="flex flex-col">
                <div className="flex items-center gap-x-2">
                  {isLoading ? (
                    <Skeleton className="size-10" />
                  ) : (
                    <ProfileImage
                      src={
                        company && company.logo_url
                          ? company.logo_url
                          : CompanyDefaultImage
                      }
                    />
                  )}
                  <div className="flex flex-col gap-y-px">
                    {isLoading ? (
                      <Skeleton className="h-4 w-40" />
                    ) : (
                      <span className="text-button-14">{job_title}</span>
                    )}
                    {isLoading ? (
                      <Skeleton className="h-4 w-60" />
                    ) : (
                      company && (
                        <span className="text-caption-12-regular text-black-500">
                          {company.name}
                        </span>
                      )
                    )}
                    <div className="flex gap-x-2">
                      {isLoading ? (
                        <Skeleton className="h-3 w-24" />
                      ) : (
                        <>
                          {location && (
                            <span className="text-caption-10-regular text-black-600">
                              {location.name}
                            </span>
                          )}
                          <span className="text-caption-10-regular text-black-400">
                            {formatRange(
                              formatMonthAndYear(start_date),
                              "Present",
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {description && (
                  <ExpandableText className="ml-12">
                    {description}
                  </ExpandableText>
                )}
              </div>
            </SectionItem>
          ),
        )}
      </div>
    );
  }
  return (
    <div className="mb-8 flex flex-col last:mb-0">
      <h2 className="mb-3.5 rounded-xl bg-purple-50 px-4 py-2 text-button-16 text-purple-600">
        Current position{!isLoading && currentExperience.length > 1 && "s"}
      </h2>
      {mainComponent}
    </div>
  );
}
