import React, { ImgHTMLAttributes, forwardRef, useState } from "react";
import { VariantProps, cva } from "class-variance-authority";
import * as Sentry from "@sentry/react";

import { cn } from "common/helpers/utils";
import Skeleton from "common/components/ui/Skeleton";

import FallbackProfileImage from "assets/images/fallback-profile-image.svg";

const profileImageVariants = cva("shrink-0 object-cover", {
  variants: {
    size: {
      "3xs": "size-4 rounded-md",
      "2xs": "size-5 rounded-lg",
      xs: "size-6 rounded-lg",
      sm: "size-8 rounded-xl",
      md: "size-10 rounded-xl",
      lg: "size-12 rounded-2xl",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export type ProfileImageProps = ImgHTMLAttributes<HTMLImageElement> &
  VariantProps<typeof profileImageVariants> & {
    src: string;
  };

const ProfileImage = forwardRef<HTMLImageElement, ProfileImageProps>(
  ({ src, size, className, ...rest }, ref) => {
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    let profileImage = FallbackProfileImage;
    if (!isError && src) {
      profileImage = src;
    }

    return (
      <>
        {!isLoaded && (
          <Skeleton className={profileImageVariants({ size, className })} />
        )}
        <img
          ref={ref}
          className={cn(
            profileImageVariants({
              className: [className, !isLoaded && "hidden", "data-hj-suppress"],
              size,
            }),
          )}
          alt=""
          src={profileImage}
          onError={() => {
            Sentry.captureMessage("Profile Image error");
            setIsError(true);
          }}
          onLoad={() => setIsLoaded(true)}
          {...rest}
        />
      </>
    );
  },
);

ProfileImage.displayName = "ProfileImage";

export default ProfileImage;
