import React, {
  HTMLAttributes,
  ReactNode,
  forwardRef,
  ForwardedRef,
} from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Slot, Slottable } from "@radix-ui/react-slot";

import { cn } from "common/helpers/utils";

const tagVariants = cva(
  "inline-flex shrink-0 items-center justify-center text-nowrap [&_svg]:size-4",
  {
    variants: {
      variant: {
        primary: "bg-purple-500 text-white",
        "primary-black": "bg-black-950 text-white",

        secondary: "bg-purple-100 text-purple-600",
        tertiary: "border border-black-200 bg-white text-black-950",
        quaternary: "bg-black-100 text-black-700",

        // Accent
        success: "bg-green-100 text-green-500",
        danger: "bg-red-100 text-red-500",
        info: "bg-blue-100 text-blue-500",

        disabled: "bg-black-200 text-black-400",

        magenta: "bg-magenta-50 text-magenta-500",
        orange: "bg-orange-100 text-orange-600",
        yellow: "bg-yellow-100 text-yellow-500",

        gradient: "bg-blackPurple text-white",
      },
      size: {
        md: "h-6 min-w-6 gap-1 rounded-md px-3 py-1 text-button-12",
        lg: "h-7 min-w-7 gap-1.5 rounded-lg px-3 py-1.5 text-button-12",
      },
      icon: {
        none: "",
        left: "[&_svg]:-ml-1.5",
        right: "[&_svg]:-mr-1.5",
        both: "px-1.5",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
      icon: "none",
    },
  },
);

type TagVariantType = VariantProps<typeof tagVariants>["variant"];

type TagProps = HTMLAttributes<HTMLSpanElement> &
  Omit<VariantProps<typeof tagVariants>, "icon"> & {
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    asChild?: boolean;
  };

function Tag(
  {
    className,
    variant,
    size,
    children,
    leftIcon = null,
    rightIcon = null,
    asChild = false,
    ...props
  }: TagProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  let icon: "none" | "left" | "right" | "both" = "none";
  const Comp = asChild ? Slot : "span";

  if (leftIcon && !rightIcon) {
    icon = "left";
  } else if (rightIcon && !leftIcon) {
    icon = "right";
  } else if (rightIcon && leftIcon) {
    icon = "both";
  }

  return (
    <Comp
      className={cn(tagVariants({ variant, size, icon, className }))}
      ref={ref}
      {...props}
    >
      {leftIcon}
      <Slottable>{children}</Slottable>
      {rightIcon}
    </Comp>
  );
}
Tag.displayName = "Tag";

const TagWithRef = forwardRef(Tag);

export { TagWithRef as Tag, tagVariants, type TagVariantType };
