import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useCustomer from "common/datahooks/useCustomer";
import { EmailSchema } from "common/schemas";
import { HTTPError } from "common/helpers/HTTP";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";

import SubmitButtons from "../SubmitButtons";

export default function UpdateEmail({ onClose }: { onClose: () => void }) {
  const {
    updateCustomer,
    isCustomerUpdating,
    customerInfo,
    updateCustomerError,
    resetUpdateCustomer,
  } = useCustomer();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: valibotResolver(EmailSchema),
    defaultValues: {
      email: customerInfo.email,
    },
  });

  async function onSubmit({ email }: { email: string }) {
    if (customerInfo.email !== email) {
      await updateCustomer({ email });
    }
    onClose();
  }

  let errorMessage: string;

  if (updateCustomerError) {
    if (
      updateCustomerError instanceof HTTPError &&
      updateCustomerError.type === "Conflict:"
    ) {
      errorMessage = "Email already exists";
    } else {
      errorMessage = "Error updating email, please try again later";
    }
  }

  return (
    <form
      className="flex w-full items-end gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full flex-col gap-2">
        <Label htmlFor="emailField" className="text-caption-12-bold" size="md">
          New email address
        </Label>

        <Input
          placeholder="Enter your new email address"
          variant="md"
          id="emailField"
          error={errorMessage || errors.email?.message}
          {...register("email", { onChange: resetUpdateCustomer })}
        />
      </div>

      <SubmitButtons isUpdating={isCustomerUpdating} onClose={onClose} />
    </form>
  );
}
