import { LeadsSearchParams, SelectedFacetParam } from "../../pages/leads/types";
import { MetricsParameters, ProfileSearchParams } from "../types";

export default function getQueryKeys(workspaceId: string) {
  const queryKeys = {
    leadsKeys: {
      base: [{ workspaceId }, "leads"],
      list: (searchParams?: LeadsSearchParams<SelectedFacetParam>) => {
        const base = [...queryKeys.leadsKeys.base, "list"];
        return searchParams ? [...base, searchParams] : base;
      },
      details: (leadId?: string) => {
        const base = [...queryKeys.leadsKeys.base, "details"];
        return leadId ? [...base, leadId] : base;
      },
      recent: (accountIds: string[], campaignId?: string, flowId?: string) => {
        const base = [{ workspaceId }, "recent", accountIds];
        if (campaignId) return [...base, campaignId];
        if (flowId) return [...base, flowId];
        return base;
      },
      conversationUrn: (leadId: string) => [
        ...queryKeys.leadsKeys.base,
        "conversationUrn",
        leadId,
      ],
    },
    campaignsKeys: {
      base: [{ workspaceId }, "campaigns"],
      list: () => [...queryKeys.campaignsKeys.base, "list"],
      details: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "details",
        campaignId,
      ],
      audience: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "audience",
        campaignId,
      ],
      targeting: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "targeting",
        campaignId,
      ],
      audienceStats: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "audienceStats",
        campaignId,
      ],
      audienceToken: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "audienceToken",
        campaignId,
      ],
      profilesPreview: (
        campaignId: string,
        searchParams: Partial<ProfileSearchParams>,
      ) => [
        ...queryKeys.campaignsKeys.base,
        "profilesPreview",
        campaignId,
        searchParams,
      ],
    },
    conversationsKeys: {
      base: [{ workspaceId }, "conversations"],
      list: (keywords?: string, filters?: string[]) => {
        const base = [...queryKeys.conversationsKeys.base, "list"];
        return [...base, ...(keywords ? [keywords] : []), ...(filters || [])];
      },
      messages: (conversationUrn: string) => [
        ...queryKeys.conversationsKeys.base,
        "messages",
        conversationUrn,
      ],
    },
    labelsKeys: {
      list: [{ workspaceId }, "labels"],
    },
    templatesKeys: {
      list: [{ workspaceId }, "templates"],
    },
    blacklistKeys: {
      list: [{ workspaceId }, "blacklist"],
    },
    profileSearchKeys: {
      list: (searchParams: Partial<ProfileSearchParams>) => [
        { workspaceId },
        "profileSearch",
        searchParams,
      ],
    },
    metricsKeys: {
      list: (
        parameters: MetricsParameters,
        accountIds: string[],
        campaignId?: string,
        flowId?: string,
      ) => {
        const base = [{ workspaceId }, "metrics", parameters, accountIds];
        if (campaignId) return [...base, campaignId];
        if (flowId) return [...base, flowId];
        return base;
      },
    },
    paymentSourceKeys: {
      list: ["paymentSources"],
      primary: [{ workspaceId }, "primaryPaymentSource"],
    },
    membersKeys: {
      list: [{ workspaceId }, "members"],
    },
    invoicesKeys: {
      list: [{ workspaceId }, "invoices"],
    },
  };

  return queryKeys;
}
