import { Lock } from "@phosphor-icons/react";
import React from "react";

import { Button } from "common/components/ui/Button";

import useCampaignMutations from "../../../datahooks/useCampaignMutations";

export default function PauseCampaign({ campaignId }: { campaignId: string }) {
  const { updateCampaign, isUpdatingCampaign } = useCampaignMutations();

  function pauseCampaign() {
    updateCampaign({
      campaignId,
      updates: { state: "PAUSED" },
    });
  }

  return (
    <div className="flex flex-col items-center justify-between gap-2 rounded-20 border border-black-200 px-3 py-4 md:flex-row md:gap-4 md:px-4">
      <div className="flex gap-4 md:items-center">
        <span className="inline-flex size-10 shrink-0 items-center justify-center rounded-2xl bg-black-900 md:size-14">
          <Lock size={24} className="fill-white" />
        </span>
        <div>
          <h5 className="text-button-16">Active campaign</h5>
          <p className="text-black-700">
            You cannot edit the campaign while it is active. Pause the campaign
            to make changes
          </p>
        </div>
      </div>
      <Button
        variant="tertiary-black"
        className="max-md:w-full"
        onClick={pauseCampaign}
        isLoading={isUpdatingCampaign}
      >
        Pause campaign
      </Button>
    </div>
  );
}
