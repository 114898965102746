import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
} from "react";
import { Drawer as DrawerPrimitive } from "vaul";

import { cn } from "common/helpers/utils";

type DrawerProps = ComponentPropsWithoutRef<typeof DrawerPrimitive.Root> & {
  onAfterClose?: () => void;
};

function Drawer({ onAfterClose = null, onOpenChange, ...props }: DrawerProps) {
  return (
    <DrawerPrimitive.Root
      onOpenChange={(open) => {
        if (onOpenChange) {
          onOpenChange(open);
        }

        if (!open && onAfterClose) {
          setTimeout(() => onAfterClose(), 300);
        }
      }}
      repositionInputs={false}
      {...props}
    />
  );
}
Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-overlay bg-black-950/60 backdrop-blur-sm",
      className,
    )}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DrawerPortal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        "fixed inset-x-0 bottom-0 z-dialog mt-24 flex h-auto max-h-dvh flex-col rounded-t-3xl overflow-hidden bg-white focus-visible:outline-none",
        className,
      )}
      {...props}
    >
      {/* Top Section */}
      <div className="absolute top-0 z-10 h-12 w-full bg-gradient-to-b from-white to-transparent" />
      <div className="absolute left-1/2 top-4 z-10 h-1 w-24 -translate-x-1/2 rounded-full bg-black-200" />

      {/* Content */}
      <div className="flex flex-col overflow-y-auto px-4 pb-6 pt-12 scrollbar-thin">
        {children}
      </div>
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = "DrawerContent";

function DrawerFooter({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "flex bg-white border-t justify-between border-t-black-200 p-4 shadow-2xl fixed inset-x-0 bottom-0 z-10",
        className,
      )}
      {...props}
    />
  );
}
DrawerFooter.displayName = "DrawerFooter";

export { Drawer, DrawerTrigger, DrawerClose, DrawerContent, DrawerFooter };
