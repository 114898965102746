import { ReactNode } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  renderWrapper: (children: ReactNode) => ReactNode;
  children: ReactNode;
}

export default function ConditionalWrapper({
  condition,
  renderWrapper,
  children,
}: ConditionalWrapperProps) {
  return condition ? renderWrapper(children) : children;
}
