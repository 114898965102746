import React from "react";
import { PlusCircle } from "@phosphor-icons/react";

import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import useAppStore from "common/hooks/useAppStore";

import NoCampaignsImage from "assets/images/empty-placeholders/no-campaigns.png";
import ReconnectAccountImage from "assets/images/empty-placeholders/reconnect-account.png";

export default function NoCampaignsPlaceholder() {
  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );

  const { accounts, role } = useSelectedWorkspaceContext();
  const { relativeNavigate } = useWorkspaceNavigate();
  const hasAccount = !!accounts.length;

  const isEveryAccountLoggedOut =
    hasAccount && accounts.every(({ state }) => state !== "LoggedIn");

  if (isEveryAccountLoggedOut) {
    return (
      <EmptyPlaceholder
        className="min-h-[400px] md:min-h-[540px]"
        title="Connect a LinkedIn Account"
        subtitle="You need to have at least one connected LinkedIn account to create a campaign"
        imageSrc={ReconnectAccountImage}
        actionComponent={
          <Button onClick={() => relativeNavigate("accounts")}>
            Go to accounts
          </Button>
        }
      />
    );
  }

  return (
    <EmptyPlaceholder
      className="min-h-[400px] md:min-h-[540px]"
      title={
        hasAccount
          ? "No campaigns found"
          : "Add a LinkedIn account and create your first campaign"
      }
      subtitle={
        hasAccount
          ? "This screen shows data from your outreach campaigns. Create a campaign and start growing your LinkedIn network"
          : "Start getting leads with Aimfox - Connect your LinkedIn account to build your first outreach campaign"
      }
      imageSrc={NoCampaignsImage}
      actionComponent={
        <Button
          data-intercom-target="create-campaign-button"
          onClick={() => setIsCampaignModalOpen(true)}
          leftIcon={<PlusCircle weight="fill" />}
          disabled={hasAccount && role === "member"}
        >
          {hasAccount ? "Create campaign" : "Get started"}
        </Button>
      }
    />
  );
}
