import { Lead } from "common/types";

// eslint-disable-next-line import/prefer-default-export
export const inboxVariables: {
  label: string;
  getValue: (profile: Lead) => string;
}[] = [
  {
    label: "First Name",
    getValue: (profile) => profile.full_name.split(" ")[0],
  },
  {
    label: "Last Name",
    getValue: (profile) => profile.full_name.split(" ")[1],
  },
  {
    label: "Occupation",
    getValue: (profile) => profile.occupation,
  },
  {
    label: "Location",
    getValue: (profile) => profile.location.name,
  },
];
