import { DotsThreeVertical, Envelope, User } from "@phosphor-icons/react";
import { Tag as TagIcon } from "@phosphor-icons/react/dist/csr/Tag";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import LeadLabels from "common/components/LeadLabels";
import { Lead } from "common/types";
import MessageLeadDialog from "common/components/MessageLeadDialog";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";

import NotesDialog from "./NotesDialog";

import AddNoteIcon from "assets/icons/note-add.svg?react";

export default function LeadMenu({ lead }: { lead: Lead }) {
  const [isMessagingDialogOpen, setIsMessagingDialogOpen] = useState(false);
  const [isLabelsDialogOpen, setIsLabelsDialogOpen] = useState(false);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
  const { relativeNavigate } = useWorkspaceNavigate();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild id="lead-menu-trigger">
          <Button
            variant="secondary-black"
            intent="iconOnly"
            className="ml-auto"
          >
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" id="lead-menu-content">
          <DropdownMenuItem onClick={() => setIsLabelsDialogOpen(true)}>
            <TagIcon size={20} />
            Edit tags
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to={lead.id.toString()} state={{ lead }}>
              <User size={20} />
              See profile
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsNotesDialogOpen(true)}>
            <AddNoteIcon className="size-5" />
            Add note
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={
              lead.lead_of.length > 1
                ? () => setIsMessagingDialogOpen(true)
                : () => relativeNavigate("inbox", { state: { lead } })
            }
          >
            <Envelope size={20} weight="fill" />
            Message lead
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <LeadLabels
        labels={lead.labels}
        profileId={lead.id}
        isOpen={isLabelsDialogOpen}
        onClose={() => setIsLabelsDialogOpen(false)}
      />
      <NotesDialog
        lead={lead}
        isOpen={isNotesDialogOpen}
        onClose={() => setIsNotesDialogOpen(false)}
      />
      <MessageLeadDialog
        lead={lead}
        isOpen={isMessagingDialogOpen}
        onClose={() => setIsMessagingDialogOpen(false)}
      />
    </>
  );
}
