import { createContext, useContext } from "react";
import { produce } from "immer";

import { Workspace } from "common/types";
import useAppStore from "common/hooks/useAppStore";

const SelectedWorkspaceContext = createContext<Workspace>(null);

export const SelectedWorkspaceProvider = SelectedWorkspaceContext.Provider;

export function useSelectedWorkspaceContext(): Workspace {
  const selectedWorkspace = useContext(SelectedWorkspaceContext);
  const { workspaceRole, subscriptionStatus, isWithoutSubscription } =
    useAppStore((state) => state.debugOptions);

  return produce(selectedWorkspace, (draftWorkspace) => {
    if (workspaceRole) {
      draftWorkspace.role = workspaceRole;
    }

    if (isWithoutSubscription) {
      draftWorkspace.subscription = null;
    } else if (selectedWorkspace.subscription && subscriptionStatus) {
      draftWorkspace.subscription.status = subscriptionStatus;
    }
  });
}
