import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { get, post } from "../helpers/HTTP";
import useConversations from "../../pages/inbox/datahooks/useConversations";

async function resetUnreadMessagesRequest(workspaceId: string) {
  await post(`workspaces/${workspaceId}/conversations/unread`);
}

async function getUnreadMessagesCount(workspaceId: string) {
  const { unread_count: unreadMessagesCount } = await get(
    `workspaces/${workspaceId}/conversations/unread`,
  );
  return { unreadMessagesCount };
}

export default function useUnreadMessagesCount({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const queryKey = ["unreadMessages"];
  const queryClient = useQueryClient();
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();

  const { refetchConversations } = useConversations();

  const { data } = useQuery<{
    unreadMessagesCount: number;
  }>({
    queryKey,
    queryFn: () => getUnreadMessagesCount(workspaceId),
    refetchInterval: 1000,
    enabled: !disableQuery && !!accounts.length,
    refetchOnMount: false,
  });

  const { mutate: resetUnreadMessages } = useMutation({
    mutationFn: () => resetUnreadMessagesRequest(workspaceId),
    onSuccess: () => {
      queryClient.setQueryData<{
        unreadMessagesCount: number;
        versionTag: string;
      }>(queryKey, (prevValue) => ({
        ...prevValue,
        unreadMessagesCount: 0,
      }));

      // Refetch conversations after unread POST is triggered
      refetchConversations();
    },
  });

  return {
    ...data,
    resetUnreadMessages,
  };
}
