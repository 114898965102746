import React from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { formatShortDate } from "common/helpers/utils";

export default function TrialBanner() {
  const { subscription } = useSelectedWorkspaceContext();

  return (
    <div className="w-fit rounded-lg bg-[url(/images/trial-banner-bg.jpg)] bg-cover bg-right px-3 py-1.5 text-caption-12-bold max-lg:mb-4">
      Free trial ends on {formatShortDate(subscription.next_billing * 1000)}
    </div>
  );
}
