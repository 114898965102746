import React from "react";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useNavStore from "common/hooks/useNavStore";
import RenderIf from "common/components/RenderIf";

interface StepperProps {
  selectedIndex: number;
  goToNextStep: () => void;
  isNextStepDisabled?: boolean;
  isNextButtonLoading?: boolean;
}

const STEPS = ["targeting", "audience", "flows", "schedule", "review"];

export default function Stepper({
  selectedIndex,
  goToNextStep,
  isNextStepDisabled = false,
  isNextButtonLoading = false,
}: StepperProps) {
  const isDesktop = useTwBreakpoint("lg");
  const isNavExpanded = useNavStore((state) => state.isNavExpanded);

  const progress = `${(selectedIndex / (STEPS.length - 1)) * 100}%`;

  const progressBarComponent = (
    <div className="relative flex w-full flex-1 lg:max-w-[650px]">
      {/* Progress */}
      <div className="absolute top-0.5 h-1 w-full overflow-hidden rounded-full bg-black-200 lg:left-[10%] lg:w-4/5">
        <div
          className="h-full rotate-180 bg-blackPurple"
          style={{ width: progress }}
        />
      </div>

      {/* Steps */}
      <div className="relative flex w-full justify-between">
        {STEPS.map((step, index) => (
          <div
            className="relative z-10 flex flex-col items-center lg:flex-1"
            key={step}
          >
            <span
              key={step}
              className={clsx(
                "size-2 rounded-full",
                index > selectedIndex && "bg-black-200",
                index === selectedIndex && "bg-black-950",
              )}
            />
            <span
              className={clsx(
                "mt-2 text-button-14 capitalize max-lg:hidden",
                index > selectedIndex && "text-black-400",
              )}
            >
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        "fixed inset-x-0 bottom-0 z-bottomNav flex flex-col items-center justify-between gap-4 border-t border-t-black-200 bg-white p-4 transition-[margin-left] duration-500 lg:flex-row lg:py-5",
        isNavExpanded ? "lg:ml-[266px]" : "lg:ml-[72px]",
      )}
    >
      <RenderIf condition={!isDesktop}>{progressBarComponent}</RenderIf>

      <div className="mx-auto flex w-full items-center justify-between px-4 lg:max-w-[1920px] lg:px-16">
        <Button
          variant="quaternary-black"
          size={isDesktop ? "lg" : "md"}
          leftIcon={<ArrowLeft />}
          className={selectedIndex <= 1 && "invisible"}
          asChild
        >
          <Link to={`../${STEPS[selectedIndex >= 1 ? selectedIndex - 1 : 0]}`}>
            Back
          </Link>
        </Button>

        {isDesktop ? (
          progressBarComponent
        ) : (
          <span className="flex-1 text-center text-button-14 capitalize">
            {STEPS[selectedIndex]}
          </span>
        )}

        <Button
          onClick={goToNextStep}
          disabled={isNextStepDisabled}
          isLoading={isNextButtonLoading}
          size={isDesktop ? "lg" : "md"}
          rightIcon={<ArrowRight />}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
