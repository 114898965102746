import React from "react";

import { MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";

import OverviewCard from "./OverviewCard";
import OverviewCardHeading from "./OverviewCardHeading";
import { CampaignFlow } from "../../../../types";

interface FlowsOverviewProps {
  flows: CampaignFlow[];
  metricsParams: MetricsParameters;
  goToFlowTab: (flowId: string) => void;
}

export default function FlowsOverview({
  flows,
  metricsParams,
  goToFlowTab,
}: FlowsOverviewProps) {
  return (
    <article className="flex flex-col gap-4 md:flex-row">
      {flows.map((flow) => (
        <section
          key={flow.id}
          className="flex flex-1 flex-col rounded-2xl border border-black-200 px-3 pb-2 pt-4 md:px-4"
        >
          <OverviewCardHeading flow={flow} />
          <OverviewCard flow={flow} metricsParams={metricsParams} />

          <Button
            variant="quaternary-purple"
            className="mt-2.5"
            onClick={() => goToFlowTab(flow.id)}
          >
            View stats
          </Button>
        </section>
      ))}
    </article>
  );
}
