import React, { useState } from "react";

import { CreatedTemplate, Template, TemplateType } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { DialogContent, Dialog } from "common/components/ui/Dialog";
import TemplateDetailsForm from "common/components/TemplateDetailsForm";

import TypeSelection from "./TypeSelection";

interface CreateTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (template: CreatedTemplate) => void;
  type?: TemplateType;
}

export default function CreateTemplate({
  type = null,
  isOpen,
  onClose,
  onCreated = null,
}: CreateTemplateProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [templateType, setTemplateType] = useState(type);

  const template: Template = {
    type: templateType,
    message: "",
    name: "",
    subject: "",
  };

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onAfterClose={() => setTemplateType(null)}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content>
        {templateType ? (
          <TemplateDetailsForm
            template={template}
            goBack={onClose}
            onCreated={onCreated}
          />
        ) : (
          <TypeSelection
            goToNextStep={(selectedType) => setTemplateType(selectedType)}
          />
        )}
      </Content>
    </Component>
  );
}
