import React, { useState } from "react";
import { DownloadSimple } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";

import useBilling from "../../datahooks/useBilling";

export default function DownloadInvoice({ invoiceId }: { invoiceId: string }) {
  const [invoiceUrl, setInvoiceUrl] = useState<string>();
  const { getInvoiceUrl, isGettingInvoiceUrl } = useBilling();
  const isTabletOrDesktop = useTwBreakpoint("md");

  function onClick() {
    getInvoiceUrl({ invoiceId }).then((url) => {
      setInvoiceUrl(url);
    });
  }

  return (
    <>
      <Button
        variant={isTabletOrDesktop ? "secondary-black" : "tertiary-black"}
        size={isTabletOrDesktop ? "md" : "sm"}
        intent={isTabletOrDesktop ? "iconOnly" : "default"}
        leftIcon={!isTabletOrDesktop && <DownloadSimple />}
        onClick={onClick}
        isLoading={isGettingInvoiceUrl}
      >
        {isTabletOrDesktop ? <DownloadSimple /> : "Download"}
      </Button>

      <Dialog
        open={!!invoiceUrl}
        onOpenChange={(open) => {
          if (!open) {
            setInvoiceUrl(undefined);
          }
        }}
      >
        <DialogContent>
          <h2 className="mb-2 text-center text-headline-2xl-bold">
            Your invoice is ready
          </h2>

          <p className="mb-8 text-center text-black-500">
            Click the button below to download your invoice
          </p>

          <Button
            onClick={() => {
              window.open(invoiceUrl);
              setInvoiceUrl(null);
            }}
          >
            Download invoice
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
