import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { del, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";

import useUpdateCampaign from "./useUpdateCampaign";
import { CampaignFlow } from "../types";

async function postSecondFlowRequest(
  workspaceId: string,
  campaignId: string,
  flow: CampaignFlow,
): Promise<CampaignFlow> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/flows`, flow)
  ).flow;
}

async function deleteFlowRequest(
  workspaceId: string,
  campaignId: string,
  flowId: string,
) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}/flows/${flowId}`);
}

export default function useCampaignFlows() {
  const { campaignId } = useCampaignContext();
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();

  const { mutateAsync: addSecondFlow, isPending: isAddingSecondFlow } =
    useMutation({
      mutationFn: ({ flow }: { flow: CampaignFlow }) =>
        postSecondFlowRequest(workspaceId, campaignId, flow),
      onSuccess: (flow) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.flows.push(flow);
        });
      },
    });

  const { mutateAsync: deleteCampaignFlow, isPending: isDeletingCampaignFlow } =
    useMutation({
      mutationFn: ({ flowId }: { flowId: string }) =>
        deleteFlowRequest(workspaceId, campaignId, flowId),
      onMutate: async ({ flowId }) => {
        await queryClient.cancelQueries({
          queryKey: campaignsKeys.details(campaignId),
        });

        const previousCampaign = queryClient.getQueryData(
          campaignsKeys.details(campaignId),
        );

        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.flows = draftCampaign.flows.filter(
            (flow) => flow.id !== flowId,
          );
        });

        return { previousCampaign };
      },
      onError: (_, __, { previousCampaign }) => {
        queryClient.setQueryData(
          campaignsKeys.details(campaignId),
          previousCampaign,
        );

        toast.error("Error deleting flow");
      },
    });

  return {
    addSecondFlow,
    isAddingSecondFlow,
    deleteCampaignFlow,
    isDeletingCampaignFlow,
  };
}
