import React, { useState } from "react";
import { DotsThreeVertical, PencilSimple, Trash } from "@phosphor-icons/react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button, ButtonVariantType } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";

import { CampaignFlow } from "../../../../types";
import FlowActionsDialog from "./FlowActionsDialog";

interface FlowActionsProps {
  flow: CampaignFlow;
  variant: ButtonVariantType;
  deleteFlow?: (flowId: string) => void;
}

export default function FlowActions({
  flow,
  variant,
  deleteFlow = null,
}: FlowActionsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [selectedActionType, setSelectedActionType] = useState<
    "delete" | "edit"
  >();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="ml-auto" asChild>
          <Button
            size={isTabletOrDesktop ? "md" : "lg"}
            intent="iconOnly"
            variant={variant}
          >
            <DotsThreeVertical weight="bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              setIsOpen(true);
              setSelectedActionType("edit");
            }}
          >
            <PencilSimple size={20} /> Edit name
          </DropdownMenuItem>
          <RenderIf condition={!!deleteFlow}>
            <DropdownMenuItem
              className="text-red-500"
              onClick={() => {
                setIsOpen(true);
                setSelectedActionType("delete");
              }}
            >
              <Trash size={20} /> Delete flow
            </DropdownMenuItem>
          </RenderIf>
        </DropdownMenuContent>
      </DropdownMenu>

      <FlowActionsDialog
        flow={flow}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={selectedActionType}
        deleteFlow={deleteFlow}
      />
    </>
  );
}
