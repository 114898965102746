import { produce } from "immer";

import { Keyword, KeywordModifiers, KeywordTypes } from "common/types";

import { useSearchParamsContext } from "../../SearchParamsContext";

export default function useKeywords() {
  const { searchParams, setSearchParams } = useSearchParamsContext();

  function addKeyword(keywordText: string, keywordType: KeywordTypes) {
    // make sure the keyword doesn't already exist for the same type
    if (
      searchParams.keywords.find(
        ({ type, text }) => text === keywordText && type === keywordType,
      )
    ) {
      return;
    }
    setSearchParams({
      keywords: [
        ...searchParams.keywords,
        { text: keywordText, type: keywordType, modifier: "optional" },
      ],
    });
  }
  function updateKeywordModifier(keyword: Keyword, modifier: KeywordModifiers) {
    setSearchParams({
      keywords: produce(searchParams.keywords, (draftState) => {
        for (let i = 0; i < draftState.length; i += 1) {
          if (
            draftState[i].text === keyword.text &&
            draftState[i].type === keyword.type
          ) {
            draftState[i].modifier = modifier;
            return;
          }
        }
      }),
    });
  }
  function removeKeyword(keyword: Keyword) {
    setSearchParams({
      keywords: searchParams.keywords.filter(
        ({ text, type }) => !(text === keyword.text && type === keyword.type),
      ),
    });
  }
  return { addKeyword, removeKeyword, updateKeywordModifier };
}
