import { useState } from "react";

import { LinkedInProfile } from "common/types";

import { AudienceProfile } from "../../../types";

export default function useAllResults(audience: AudienceProfile[]) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState<LinkedInProfile[]>(
    [],
  );

  function toggleSelection(profile: LinkedInProfile) {
    setSelectedProfiles((prevSelectedProfiles) => {
      if (prevSelectedProfiles.find(({ id }) => id === profile.id)) {
        return prevSelectedProfiles.filter(({ id }) => id !== profile.id);
      }
      return [...prevSelectedProfiles, profile];
    });
  }

  const filteredResults = audience.filter(({ full_name: fullName }) => {
    const isFullNameMatching = fullName
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    return isFullNameMatching;
  });

  function resetSelectedProfiles() {
    setSelectedProfiles([]);
  }

  return {
    toggleSelection,
    filteredResults,
    selectedProfiles,
    searchValue,
    setSearchValue,
    resetSelectedProfiles,
  };
}
