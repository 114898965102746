import React from "react";
import { ArrowsClockwise } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

export default function SyncingBadge() {
  return (
    <Tooltip>
      <TooltipTrigger className="rounded-full bg-purple-600 p-0.5">
        <ArrowsClockwise className="size-4 animate-spin text-white" />
      </TooltipTrigger>
      <TooltipContent align="start">
        Your leads are currently synchronising with Aimfox. This may take up to
        24 hours. This is a one time thing
      </TooltipContent>
    </Tooltip>
  );
}
