import React from "react";

import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";

import { LinkedinPost } from "../../../../types";

interface PostPreviewProps {
  post: LinkedinPost;
  isLoading: boolean;
}

export default function PostPreview({ post, isLoading }: PostPreviewProps) {
  return (
    <article className="flex-1 overflow-hidden rounded-2xl border border-black-200">
      {/* Post Author */}
      <div className="flex items-center gap-3.5 border-b border-b-black-200 p-4">
        {isLoading ? (
          <Skeleton className="size-12" />
        ) : (
          <ProfileImage size="lg" src={post.author.image.url} />
        )}
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <>
              <Skeleton className="h-[28px] w-40" />
              <Skeleton className="h-[19px] w-64" />
            </>
          ) : (
            <>
              <h5 className="text-headline-lg-bold">{post.author.name}</h5>
              <span className="line-clamp-1 text-black-500">
                {post.author.subtitle}
              </span>
            </>
          )}
        </div>
      </div>

      {/* Post Details Preview */}
      {isLoading ? (
        <>
          <Skeleton className="h-96 w-full rounded-none" />
          <div className="flex flex-col gap-1.5 p-4">
            <Skeleton className="h-[19px] w-full" />
            <Skeleton className="h-[19px] w-full" />
            <Skeleton className="h-[19px] w-8/12" />
          </div>
        </>
      ) : (
        <>
          {post.images && (
            <img src={post.images[0][0].url} alt="LinkedIn Post" />
          )}
          {post.text && (
            <div className="p-4">
              <p className="line-clamp-6 text-black-700">{post.text}</p>
            </div>
          )}
        </>
      )}
    </article>
  );
}
