import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import {
  CustomerInfo,
  QuestionnaireResponse,
  QuestionnaireResult,
} from "common/types";

import { get, post } from "../helpers/HTTP";

interface QuestionnaireSubmission {
  version: number;
  results: QuestionnaireResult[];
}

async function getQuestionnaire(): Promise<QuestionnaireResponse> {
  return (await get(`customers/questionnaire`)).questionnaire;
}

async function submitQuestionnaire(
  submission: QuestionnaireSubmission,
): Promise<void> {
  await post(`customers/questionnaire`, submission);
}

export default function useQuestionnaire() {
  const queryClient = useQueryClient();

  const {
    data: questionnaire,
    isLoading: isLoadingQuestionnaire,
    error: questionnaireError,
  } = useQuery({
    queryKey: ["questionnaire"],
    queryFn: () => getQuestionnaire(),
  });

  const {
    mutateAsync: submitQuestionnaireAnswers,
    isPending: isSubmittingQuestionnaire,
    error: submitQuestionnaireError,
  } = useMutation({
    mutationFn: submitQuestionnaire,
    onSuccess: () => {
      if (queryClient.getQueryData(["customerInfo"])) {
        queryClient.setQueryData(
          ["customerInfo"],
          (prevCustomerInfo: CustomerInfo) =>
            produce(prevCustomerInfo, (draftCustomerInfo) => {
              draftCustomerInfo.show_questionnaire = false;
            }),
        );
      }
    },
  });

  return {
    questionnaire,
    isLoadingQuestionnaire,
    questionnaireError,
    submitQuestionnaireAnswers,
    isSubmittingQuestionnaire,
    submitQuestionnaireError,
  };
}
