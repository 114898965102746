import { CampaignFlow } from "../types";
import useCampaignFlows from "../datahooks/useCampaignFlows";

export default function useFlowsActions(
  flows: CampaignFlow[],
  setSelectedFlowIndex: (selectedFlowIndex: number) => void,
) {
  const { addSecondFlow, deleteCampaignFlow } = useCampaignFlows();

  function addFlow() {
    const messageFlow: CampaignFlow = {
      id: null,
      name: "Message Flow B",
      type: "PRIMARY_MESSAGE",
      translate: false,
      source_language: null,
      is_manual_language: false,
      flow_message_templates: [],
      template: null,
      metrics: null,
    };

    const connectFlow: CampaignFlow = {
      ...messageFlow,
      type: "PRIMARY_CONNECT",
      name: "Connection Flow B",
    };

    const secondFlow =
      flows[0].type === "PRIMARY_CONNECT" ? connectFlow : messageFlow;

    addSecondFlow({ flow: secondFlow }).then(() => setSelectedFlowIndex(1));
  }

  function deleteFlow(flowId: string) {
    setSelectedFlowIndex(0);
    deleteCampaignFlow({ flowId }, { onError: () => setSelectedFlowIndex(1) });
  }

  function duplicateFlow() {
    const { name, ...rest } = flows[0];

    const flowName =
      rest.type === "PRIMARY_CONNECT" ? "Connection Flow B" : "Message Flow B";

    addSecondFlow({
      flow: {
        name: flowName,
        ...rest,
      },
    }).then(() => setSelectedFlowIndex(1));
  }

  return {
    addFlow,
    duplicateFlow,
    deleteFlow,
  };
}
