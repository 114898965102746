import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del, patch, HTTPError } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { AudienceProfile, CampaignUpdates, DetailedCampaign } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

type NewCampaign = Pick<
  DetailedCampaign,
  "name" | "type" | "audience_size" | "outreach_type"
> & {
  account_id: string;
};

async function createCampaignRequest(
  workspaceId: string,
  newCampaign: NewCampaign,
) {
  return (await post(`workspaces/${workspaceId}/campaigns`, newCampaign))
    .campaign;
}
async function updateCampaignRequest(
  workspaceId: string,
  campaignId: string,
  updates: CampaignUpdates,
) {
  await patch(`workspaces/${workspaceId}/campaigns/${campaignId}`, updates);
}
async function deleteCampaignRequest(workspaceId: string, campaignId: string) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}`);
}

async function updateAudienceSizeRequest(
  workspaceId: string,
  campaignId: string,
  size: number,
): Promise<string[]> {
  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/audience-size`,
      {
        size,
      },
    )
  ).removed;
}

export default function useCampaignMutations() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    updateCachedCampaign,
    updateCachedCampaigns,
    updateCachedCampaignInList,
  } = useUpdateCampaign();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: createCampaign, isPending: isCreatingCampaign } =
    useMutation({
      mutationFn: (newCampaign: NewCampaign) =>
        createCampaignRequest(workspaceId, newCampaign),
    });

  const { mutateAsync: updateCampaign, isPending: isUpdatingCampaign } =
    useMutation({
      mutationFn: ({
        campaignId,
        updates,
      }: {
        campaignId: string;
        updates: CampaignUpdates;
      }) => updateCampaignRequest(workspaceId, campaignId, updates),
      onSuccess: (_, { campaignId, updates }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => ({
          ...draftCampaign,
          ...updates,
        }));

        updateCachedCampaignInList(campaignId, updates);
      },
    });

  const { mutateAsync: deleteCampaign, isPending: isDeletingCampaign } =
    useMutation({
      mutationFn: ({ campaignId }: { campaignId: string }) =>
        deleteCampaignRequest(workspaceId, campaignId),
      onSuccess: (_, { campaignId }) => {
        updateCachedCampaigns((campaigns) =>
          campaigns.filter(({ id }) => id !== campaignId),
        );
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  const { mutateAsync: updateAudienceSize, isPending: isUpdatingAudienceSize } =
    useMutation({
      mutationFn: ({
        campaignId,
        size,
      }: {
        campaignId: string;
        size: number;
      }) => updateAudienceSizeRequest(workspaceId, campaignId, size),
      onSuccess: (removedTargets, { campaignId, size }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.audience_size = size;
        });

        queryClient.setQueryData(
          campaignsKeys.audience(campaignId),
          (prevAudience: AudienceProfile[]) => {
            const newTargets = prevAudience.filter(
              ({ urn }) => !removedTargets.includes(urn),
            );

            updateCachedCampaign(campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    createCampaign,
    isCreatingCampaign,
    updateCampaign,
    isUpdatingCampaign,
    deleteCampaign,
    isDeletingCampaign,
    updateAudienceSize,
    isUpdatingAudienceSize,
  };
}
