import {
  useNavigate,
  NavigateOptions,
  useParams,
  useLocation,
} from "react-router-dom";

import useWorkspaces from "common/datahooks/useWorkspaces";

import { workspaceRoutes } from "../../pages/routes";

export default function useWorkspaceNavigate() {
  const { workspaceId } = useParams();
  const { workspaces } = useWorkspaces();
  const originalNavigate = useNavigate();
  const location = useLocation();

  const { canGoBack } = location.state ?? {};

  // this function navigates relative to the selected workspaceId id
  // i.e: 'home' becomes workspaces/:workspaceId/home
  function relativeNavigate(to: string, options?: NavigateOptions) {
    if (!location.pathname.endsWith(to)) {
      originalNavigate(`/workspaces/${workspaceId}/${to}`, options);
    }
  }

  function goBackToWorkspace() {
    if (canGoBack) {
      originalNavigate(-1);
    } else {
      // TODO: Go back to last selected workspaces (stored in localStorage)
      originalNavigate(
        `/workspaces/${workspaces[0].id}/${workspaceRoutes[0].path}`,
      );
    }
  }

  function goToWorkspace(newWorkspaceId: string) {
    originalNavigate(
      `/workspaces/${newWorkspaceId}/${workspaceRoutes[0].path}`,
      {
        replace: true,
      },
    );
  }

  return { relativeNavigate, goBackToWorkspace, goToWorkspace };
}
