import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { CampaignFlow } from "../types";

const CampaignFlowsErrorContext = createContext<{
  invalidFlows: CampaignFlow[];
  setInvalidFlows: Dispatch<SetStateAction<CampaignFlow[]>>;
}>(null);

export const CampaignFlowsErrorProvider = CampaignFlowsErrorContext.Provider;

export function useCampaignFlowsErrorContext() {
  const context = useContext(CampaignFlowsErrorContext);

  if (!context) {
    throw new Error(
      "useCampaignFlowsErrorContext must be used within a CampaignFlowsErrorContext",
    );
  }

  return context;
}
