import React from "react";
import { LinkedinLogo } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import { WorkspaceAccount } from "common/types";

export default function AccountInfoCard({
  account,
}: {
  account: WorkspaceAccount;
}) {
  const {
    full_name: fullName,
    occupation,
    picture_url: pictureUrl,
    license,
    state,
    public_identifier: publicId,
  } = account;

  const isLoggedIn = state === "LoggedIn";

  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-white py-4 md:px-4">
      {/* User details */}
      <div className="flex flex-row items-center gap-4">
        <ProfileImage size="lg" src={pictureUrl} alt={`${fullName} profile`} />

        <div className="flex flex-col gap-0.5">
          <h3 className="text-headline-xl-bold">{fullName}</h3>

          <p className="line-clamp-1 text-body-14-regular text-black-500">
            {occupation}
          </p>
        </div>
      </div>

      <div className="h-px w-full bg-black-200" />

      {/* Seat & License Details */}
      <div className="flex flex-row justify-between">
        {/* Seat info */}
        <div className="flex flex-col gap-1">
          <span className="text-body-14-regular text-black-500">Seat</span>

          <span className="text-button-14">{license ? "Yes" : "No"}</span>
        </div>

        {/* Login Status */}
        <div className="flex flex-col gap-1">
          <span className="text-body-14-regular text-black-500">Status</span>

          <Tag variant={isLoggedIn ? "success" : "danger"}>
            {isLoggedIn ? "Connected" : "Not connected"}
          </Tag>
        </div>

        {/* Linkedin Redirect */}
        <Button size="lg" intent="iconOnly" asChild>
          <a
            target="_blank"
            href={`https://linkedin.com/in/${publicId}`}
            rel="noreferrer"
            aria-label="LinkedIn profile URL"
          >
            <LinkedinLogo className="cursor-pointer" weight="fill" />
          </a>
        </Button>
      </div>
    </div>
  );
}
