import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import MobileSelect from "./MobileSelect";
import { sequenceDelayOptions } from "../../../../constants";
import DelaySelect from "./DelaySelect";

interface Props {
  values: { days: number; hours: number };
  onChange: (values: { days: number; hours: number }) => void;
  messageIndex: number;
  isDisabled: boolean;
}

export default function MobileLayout({
  values,
  onChange,
  messageIndex,
  isDisabled,
}: Props) {
  const isTablet = useTwBreakpoint("md");
  const { days, hours } = values;
  const { dayOptions, hourOptions } = sequenceDelayOptions();

  const selectedDay = dayOptions.find(({ value }) => days === value)?.value;
  const selectedHour = hourOptions.find(({ value }) => hours === value)?.value;

  if (selectedDay === dayOptions[dayOptions.length - 1].value) {
    hourOptions.pop();
  }

  return (
    <div className="flex flex-col gap-2 rounded-2xl border border-black-200 p-2">
      <p className="text-center text-black-400">
        <span className="text-black-950">Message {messageIndex + 1}&nbsp;</span>
        delay after
        <span className="text-black-950">
          {messageIndex === 0 ? " connecting" : ` Message ${messageIndex}`}
        </span>
      </p>

      <div className="flex items-center gap-2">
        {isTablet ? (
          <DelaySelect
            options={dayOptions}
            selectedValue={dayOptions.find(({ value }) => days === value).value}
            onSelect={(value) => onChange({ hours, days: value })}
            isDisabled={isDisabled}
          />
        ) : (
          <MobileSelect
            value={selectedDay}
            isDisabled={isDisabled}
            onChange={(e) =>
              onChange({ days: Number(e.currentTarget.value), hours })
            }
            options={dayOptions}
          />
        )}

        {isTablet ? (
          <DelaySelect
            selectedValue={
              hourOptions.find(({ value }) => hours === value).value
            }
            isDisabled={isDisabled}
            onSelect={(value) => onChange({ days, hours: value })}
            options={hourOptions}
          />
        ) : (
          <MobileSelect
            value={selectedHour}
            isDisabled={isDisabled}
            onChange={(e) =>
              onChange({ days, hours: Number(e.currentTarget.value) })
            }
            options={hourOptions}
          />
        )}
      </div>
    </div>
  );
}
