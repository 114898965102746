import React from "react";
import { CaretDown, Minus, PlusCircle } from "@phosphor-icons/react";

import { ScheduleInterval } from "common/types";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";

type RangeOptions = {
  value: number;
  label: string;
}[];

interface RangeSelectProps {
  value: number;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  options: RangeOptions;
}

function RangeSelect({ value, onChange, options }: RangeSelectProps) {
  return (
    <div className="grid w-full items-center">
      <select
        value={value}
        onChange={onChange}
        className="col-start-1 row-start-1 h-10 w-full appearance-none rounded-lg bg-whiteGray px-3 text-button-14 text-black-700 outline-none focus-visible:ring-2 focus-visible:ring-purple-200"
      >
        {options.map(({ value: optionValue, label }) => (
          <option value={optionValue} key={optionValue}>
            {label}
          </option>
        ))}
      </select>
      <CaretDown size={20} className="col-start-1 row-start-1 mx-2 ml-auto" />
    </div>
  );
}

interface Props {
  ranges: ScheduleInterval[];
  onChange: (index: number, startOrEnd: "start" | "end", value: number) => void;
  addRange: () => void;
  removeRange: (index: number) => void;
  isSecondRangeDisabled: boolean;
  startOptions: RangeOptions;
  endOptions: RangeOptions;
}

export default function MobileLayout({
  ranges,
  onChange,
  addRange,
  removeRange,
  startOptions,
  endOptions,
  isSecondRangeDisabled,
}: Props) {
  return (
    <>
      <div className="flex flex-col gap-4">
        {ranges.map(({ start, end }, index) => {
          const filteredStartOptions = startOptions.filter(({ value }) =>
            index === 0 ? true : value > ranges[0].end,
          );
          const filteredEndOptions = endOptions.filter(
            ({ value }) => start !== null && value > start,
          );

          return (
            <div className="flex items-end gap-2" key={end}>
              <div className="flex flex-1 gap-2">
                {/* Start Options */}
                <div className="w-full">
                  <span className="mb-1 text-button-14 text-black-400">
                    From
                  </span>

                  <RangeSelect
                    value={start}
                    options={filteredStartOptions}
                    onChange={(e) =>
                      onChange(index, "start", Number(e.currentTarget.value))
                    }
                  />
                </div>

                {/* End Options */}
                <div className="w-full">
                  <span className="mb-1 text-button-14 text-black-400">to</span>
                  <RangeSelect
                    value={end}
                    options={filteredEndOptions}
                    onChange={(e) =>
                      onChange(index, "end", Number(e.currentTarget.value))
                    }
                  />
                </div>
              </div>

              <RenderIf condition={ranges.length > 1}>
                <Button
                  size="xs"
                  className="mb-2"
                  intent="iconOnly"
                  onClick={() => removeRange(index)}
                >
                  <Minus weight="bold" />
                </Button>
              </RenderIf>
            </div>
          );
        })}
      </div>

      <RenderIf condition={ranges.length === 1}>
        <Button
          onClick={addRange}
          className="mt-4"
          disabled={isSecondRangeDisabled}
          variant="tertiary-black"
          leftIcon={<PlusCircle weight="fill" />}
        >
          Add second time
        </Button>
      </RenderIf>
    </>
  );
}
