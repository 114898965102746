import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "./useUpdateCampaign";
import { AudienceProfile } from "../types";

async function getAudienceRequest(
  workspaceId: string,
  campaignId: string,
): Promise<AudienceProfile[]> {
  return (
    await get(`workspaces/${workspaceId}/campaigns/${campaignId}/audience`)
  ).audience;
}

async function removeProfileFromAudienceRequest(
  workspaceId: string,
  campaignId: string,
  targetUrns: string[],
): Promise<string[]> {
  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/audience-delete`,
      {
        target_urns: targetUrns,
      },
    )
  ).audience;
}

export default function useAudience() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId } = useCampaignContext();

  const { updateCachedCampaign } = useUpdateCampaign();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: audience, isLoading: isLoadingAudience } = useQuery({
    queryKey: campaignsKeys.audience(campaignId),
    queryFn: () => getAudienceRequest(workspaceId, campaignId),
  });

  const { mutateAsync: removeProfiles, isPending: isRemovingProfiles } =
    useMutation({
      mutationFn: ({ targetUrns }: { targetUrns: string[] }) =>
        removeProfileFromAudienceRequest(workspaceId, campaignId, targetUrns),
      onSuccess: (targetUrns) => {
        queryClient.setQueryData(
          campaignsKeys.audience(campaignId),
          (prevAudience: AudienceProfile[]) => {
            const newTargets = prevAudience.filter(
              ({ urn }) => !targetUrns.includes(urn),
            );

            updateCachedCampaign(campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    audience,
    isLoadingAudience,
    removeProfiles,
    isRemovingProfiles,
  };
}
