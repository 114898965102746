import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { DetailedLead, Lead } from "common/types";

async function getLead(
  workspaceId: string,
  profileId: string,
): Promise<DetailedLead> {
  const { lead } = await get(`workspaces/${workspaceId}/leads/${profileId}
`);
  return lead;
}
export default function useLead({
  profileId,
  placeholderLead,
}: {
  profileId?: string;
  placeholderLead?: Lead;
}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { leadsKeys } = getQueryKeys(workspaceId);

  const {
    data: lead,
    isFetching: isLoadingLead,
    refetch: refetchLead,
    error: leadError,
  } = useQuery({
    queryKey: leadsKeys.details(profileId),
    queryFn: () => getLead(workspaceId, profileId),
    enabled: !!profileId,
    placeholderData: placeholderLead
      ? {
          ...placeholderLead,
          volunteer_experience: [],
          education: [],
          languages: [],
          skills: [],
          certifications: [],
          work_experience: [],
          is_lead: true,
        }
      : undefined,
  });
  return {
    lead,
    isLoadingLead,
    refetchLead,
    leadError,
  };
}
