import React, { ReactNode } from "react";

import { typedObjectEntries } from "common/helpers/utils";

import SearchParamSelect from "./SearchParamSelect";
import Keywords from "../Keywords";
import { profileSearchOptions } from "../../../../../../constants";

export default function DesktopLayout({
  totalCountComponent,
}: {
  totalCountComponent: ReactNode;
}) {
  return (
    <>
      <Keywords />

      <span className="my-4 h-px w-full bg-black-200" />

      <article className="flex justify-between gap-16">
        <div className="mb-4 flex flex-wrap gap-3">
          {typedObjectEntries(profileSearchOptions).map(([key]) => (
            <SearchParamSelect key={key} optionsKey={key} />
          ))}
        </div>

        {totalCountComponent}
      </article>
    </>
  );
}
