import React, { useState } from "react";
import {
  DotsThreeVertical,
  Trash,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { toast } from "react-toastify";

import { Note as NoteType } from "common/types";
import { formatLastUpdateAt } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { NoteSchema, NoteSchemaType } from "common/schemas";
import useLeadNotes from "common/datahooks/useLeadNotes";
import RenderIf from "common/components/RenderIf";

import TextArea from "../ui/TextArea";

const maxVisibleCharacters = 200;
export default function Note({
  note,
  profileId,
  onDelete,
}: {
  profileId: string;
  note: NoteType;
  onDelete: () => void;
}) {
  const { id, text, edited_at: editedAt, created_at: createdAt } = note;
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    text.length < maxVisibleCharacters,
  );

  const { updateLeadNote, isUpdatingLeadNote } = useLeadNotes();
  const { register, handleSubmit, reset } = useForm<NoteSchemaType>({
    defaultValues: { text },
    resolver: valibotResolver(NoteSchema),
  });

  async function onSubmit({ text: newText }: NoteSchemaType) {
    if (newText.trim() !== text) {
      updateLeadNote({ profileId, noteId: id, newText })
        .then(() => {
          reset({ text: newText });
          setIsEditing(false);
        })
        .catch(() => {
          toast.error("Error saving note");
        });
    }
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handleSubmit(onSubmit)();
    }
  }

  if (isEditing) {
    return (
      <div className="flex flex-col gap-y-2">
        <TextArea
          autoFocus
          minRows={4}
          maxRows={6}
          placeholder="Write something..."
          onKeyDown={onKeyDown}
          {...register("text")}
        />

        <div className="flex justify-end gap-x-2">
          <Button
            variant="tertiary-black"
            disabled={isUpdatingLeadNote}
            onClick={() => setIsEditing(false)}
          >
            Discard
          </Button>

          <Button
            variant="secondary-purple"
            isLoading={isUpdatingLeadNote}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex items-center gap-x-2 rounded-2xl border border-purple-200 bg-white p-4">
        <div className="flex flex-col items-end gap-y-0.5 overflow-hidden break-all">
          {isExpanded ? text : `${text.slice(0, maxVisibleCharacters)}...`}

          <RenderIf condition={!isExpanded}>
            <button
              type="button"
              className="text-body-14-regular text-purple-600"
              onClick={() => setIsExpanded(true)}
            >
              {`Read more >`}
            </button>
          </RenderIf>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="ml-auto"
              intent="iconOnly"
              variant="quaternary-black"
              size="sm"
            >
              <DotsThreeVertical />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent side="left" className="z-aboveDialog">
            <DropdownMenuItem onClick={() => setIsEditing(true)}>
              <PencilSimpleLine size={20} className="mr-1" />
              Edit Note
            </DropdownMenuItem>

            <DropdownMenuItem onClick={onDelete}>
              <Trash size={20} className="mr-1 text-red-500" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <span className="ml-auto text-caption-10-regular text-black-500">
        Created {formatLastUpdateAt(createdAt)}{" "}
        <span>{editedAt && `(Edited ${formatLastUpdateAt(editedAt)})`}</span>
      </span>
    </div>
  );
}
