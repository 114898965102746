import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { PencilSimple } from "@phosphor-icons/react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";

import useCampaignFlow from "../../../datahooks/useCampaignFlow";
import { FlowNameSchema } from "../../../schemas";
import { CampaignFlow } from "../../../types";

interface ChangeFlowNameProps {
  flow: CampaignFlow;
  isDisabled: boolean;
}

export default function ChangeFlowName({
  flow,
  isDisabled,
}: ChangeFlowNameProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);

  const { updateFlow, isUpdatingFlow } = useCampaignFlow();

  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: flow.name,
    },
    resolver: valibotResolver(FlowNameSchema),
  });

  function onSave({ name }: { name: string }) {
    updateFlow({
      flowId: flow.id,
      updates: {
        name,
      },
    }).then(() => {
      reset({ name });
      setIsOpen(false);
    });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Trigger asChild>
        {isTabletOrDesktop ? (
          <Button
            variant="secondary-purple"
            className="ml-auto"
            disabled={isDisabled}
            leftIcon={<PencilSimple />}
          >
            Edit name
          </Button>
        ) : (
          <Button intent="iconOnly" variant="secondary-black">
            <PencilSimple />
          </Button>
        )}
      </Trigger>
      <Content>
        <h5 className="text-center text-headline-2xl-bold">Change Flow name</h5>
        <p className="mb-4 mt-2 text-center text-black-500">
          You can change flow&apos;s name here
        </p>
        <MaxLengthInput
          variant="lg"
          error={errors.name?.message}
          characterLength={watch("name").length}
          maxLength={getSchemaMaxLength(FlowNameSchema.entries.name)}
          {...register("name")}
        />
        <Button
          onClick={handleSubmit(onSave)}
          className="mb-3 mt-6"
          isLoading={isUpdatingFlow}
        >
          Save
        </Button>
        <Close asChild>
          <Button variant="quaternary-black" disabled={isUpdatingFlow}>
            Cancel
          </Button>
        </Close>
      </Content>
    </Component>
  );
}
