import React from "react";

import { ScheduleInterval } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

interface ScheduleItemProps {
  day: string;
  ranges: ScheduleInterval[];
  isDisabled: boolean;
  setRanges: (ranges: ScheduleInterval[]) => void;
  onApplyToAll: (ranges: ScheduleInterval[]) => void;
}

export default function ScheduleItem(props: ScheduleItemProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? DesktopLayout : MobileLayout;
  return <Component {...props} />;
}
