import React, { HTMLAttributes } from "react";

import { cn } from "common/helpers/utils";

export default function Skeleton({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-black-300", className)}
      {...props}
    />
  );
}
