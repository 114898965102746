import React from "react";

import { VolunteerExperience } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";

import SectionContainer from "./SectionContainer";
import { formatMonthAndYear, formatRange } from "../../utils";
import SectionItem from "./SectionItem";

import CompanyDefaultImage from "assets/images/company-fallback.png";

export default function Volunteering({
  volunteering,
}: {
  volunteering: VolunteerExperience[];
}) {
  return (
    <SectionContainer title="Volunteering">
      {volunteering.map(
        ({ start_date, end_date, company, role_name }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SectionItem key={index}>
            <div className="flex items-center gap-x-2">
              <ProfileImage
                src={
                  company && company.logo_url
                    ? company.logo_url
                    : CompanyDefaultImage
                }
              />
              <div className="flex flex-col">
                <span className="break-all text-button-14">{role_name}</span>
                <span className="break-all text-caption-12-regular text-black-500">
                  {company.name}
                </span>
              </div>
              {(start_date || end_date) && (
                <span className="ml-auto text-nowrap text-caption-12-regular">
                  {formatRange(
                    start_date && formatMonthAndYear(start_date),
                    end_date ? formatMonthAndYear(end_date) : "Present",
                  )}
                </span>
              )}
            </div>
          </SectionItem>
        ),
      )}
    </SectionContainer>
  );
}
