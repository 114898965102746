import React from "react";

import SelectionButton from "./SelectionButton";
import { CampaignFlow } from "../../../../types";
import useFlowsActions from "../../../../hooks/useFlowsActions";
import SplitTest from "./SplitTest";

interface FlowSelectionProps {
  flows: CampaignFlow[];
  selectedIndex: number;
  setSelectedIndex: (selectedFlowIndex: number) => void;
  targetCount: number;
}

export default function FlowSelection({
  flows,
  selectedIndex,
  setSelectedIndex,
  targetCount,
}: FlowSelectionProps) {
  const { addFlow, duplicateFlow, deleteFlow } = useFlowsActions(
    flows,
    setSelectedIndex,
  );

  return (
    <div className="my-4 grid grid-cols-2 gap-2 border-b border-b-black-200 pb-3 md:gap-3">
      {flows.length > 1 ? (
        flows.map((flow, index) => (
          <SelectionButton
            key={flow.id}
            flow={flow}
            targetCount={
              index === 0
                ? Math.ceil(targetCount / 2)
                : Math.floor(targetCount / 2)
            }
            isSelected={index === selectedIndex}
            onClick={() => setSelectedIndex(index)}
            deleteFlow={deleteFlow}
          />
        ))
      ) : (
        <>
          <SelectionButton
            flow={flows[0]}
            targetCount={targetCount}
            isSelected
          />
          <SplitTest duplicateFlow={duplicateFlow} addFlow={addFlow} />
        </>
      )}
    </div>
  );
}
