import React from "react";
import { PlusCircle, UsersThree } from "@phosphor-icons/react";
import clsx from "clsx";

import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { CampaignFlow } from "../../../../types";
import useValidateFlow from "../../../../hooks/useValidateFlow";
import FlowActions from "../FlowActions";

interface SelectionButtonProps {
  targetCount: number;
  flow: CampaignFlow;
  isSelected: boolean;
  onClick?: () => void;
  deleteFlow?: (flowId: string) => void;
}

export default function SelectionButton({
  targetCount,
  flow,
  isSelected,
  onClick = null,
  deleteFlow = null,
}: SelectionButtonProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { isFlowError } = useValidateFlow(flow.id);

  let tabStyles = "bg-black-100 transition-colors hover:bg-black-200";
  if (isFlowError) {
    tabStyles = "border border-red-500 bg-red-50 text-red-500";
  } else if (isSelected) {
    tabStyles = "bg-black-950 text-white";
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        "flex h-10 w-full items-center rounded-xl px-6 text-button-14 md:h-12 md:text-button-16",
        tabStyles,
      )}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <RenderIf condition={!flow}>
        <PlusCircle size={20} weight="fill" className="mr-2.5" />
      </RenderIf>

      <span className="line-clamp-1">
        {flow ? flow.name : "Create split test"}
      </span>

      <Tag
        leftIcon={isTabletOrDesktop && <UsersThree weight="fill" />}
        className="ml-3"
      >
        {targetCount}
      </Tag>

      <RenderIf condition={isTabletOrDesktop}>
        <FlowActions
          flow={flow}
          variant={isSelected ? "primary-black" : "quaternary-black"}
          deleteFlow={deleteFlow}
        />
      </RenderIf>
    </div>
  );
}
