import React from "react";

import { getProfileSubtitle } from "common/helpers/utils";

import { Conversation } from "../../../types";

interface ConversationInfoProps {
  conversation: Conversation;
  groupParticipantsString: string | null;
  fullName: string;
}

export default function ConversationInfo({
  conversation,
  groupParticipantsString,
  fullName,
}: ConversationInfoProps) {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <span className="line-clamp-1 max-w-[20ch] text-button-14 md:max-w-full">
        {groupParticipantsString || fullName}
      </span>

      <span className="line-clamp-1 max-w-[40ch] text-caption-12-regular text-black-600">
        {groupParticipantsString
          ? `${conversation.participants.length} members`
          : getProfileSubtitle(conversation.participants[0])}
      </span>
    </div>
  );
}
