import React from "react";

import { Button } from "common/components/ui/Button";
import usePromotionsMutations from "common/datahooks/usePromotionsMutations";

export default function ResetSeats({
  goBack,
  source,
}: {
  goBack: () => void;
  source: "appsumo" | "ltd";
}) {
  const { resetSeats, isResettingSeats } = usePromotionsMutations();

  function onReset() {
    resetSeats({ source }).then(goBack);
  }
  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Reset all seats and workspaces
      </h3>
      <p className="mb-8 text-center text-black-500 lg:mb-12">
        This action will reset and un-assign all of your lifetime seats and
        workspaces.{" "}
        <span className="text-body-14-bold text-black-950">
          Campaigns for accounts using these seats will stop.
        </span>
        &nbsp;After resetting, you can re-assign seats and workspaces however
        you wish
      </p>
      <div className="flex flex-col gap-4 md:flex-row-reverse">
        <Button
          variant="tertiary-danger"
          size="lg"
          className="flex-1"
          onClick={onReset}
          isLoading={isResettingSeats}
        >
          Reset
        </Button>
        <Button
          variant="secondary-black"
          size="lg"
          className="flex-1"
          onClick={goBack}
          disabled={isResettingSeats}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
