import React from "react";
import { LinkedinLogo } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";
import { LinkedInProfile } from "common/types";
import { Button } from "common/components/ui/Button";

export default function ProfileItem({
  profile,
  isLoading = false,
}: {
  profile: LinkedInProfile;
  isLoading?: boolean;
}) {
  return (
    <article className="flex items-center gap-3.5 rounded-20 border border-black-200 p-4">
      {isLoading ? (
        <Skeleton className="size-12" />
      ) : (
        <ProfileImage size="lg" src={profile.picture_url} />
      )}

      <div className="flex flex-1 flex-col gap-1">
        {isLoading ? (
          <>
            <Skeleton className="h-7 w-10/12" />
            <Skeleton className="h-5 w-full" />
          </>
        ) : (
          <>
            <h5 className="line-clamp-1 text-headline-lg-bold">
              {profile.full_name}
            </h5>
            <p className="line-clamp-1 text-black-500">{profile.occupation}</p>
          </>
        )}
      </div>
      {isLoading ? (
        <Skeleton className="size-10 rounded-full" />
      ) : (
        <Button intent="iconOnly" variant="quaternary-black" asChild>
          <a
            href={`https://linkedin.com/in/${profile.public_identifier}`}
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn Profile Url"
          >
            <LinkedinLogo weight="fill" />
          </a>
        </Button>
      )}
    </article>
  );
}
