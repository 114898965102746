import React from "react";

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "common/components/ui/Accordion";
import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";

import { profileSearchOptions } from "../../../../../../constants";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import GenericOptions from "../Options/GenericOptions";
import Languages from "../Options/Languages";
import Companies from "../Options/Companies";
import Followers from "../Options/Followers";

export default function SearchParamSelect({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  // this helps load suggestions earlier
  useSearchOptions();
  const { appliedSearchParamValues } = useSearchParams(optionsKey);
  const { icon: Icon, label } = profileSearchOptions[optionsKey];

  let content = <GenericOptions optionsKey={optionsKey} />;
  if (optionsKey === "followers") {
    content = <Followers optionsKey={optionsKey} />;
  } else if (optionsKey === "companies") {
    content = <Companies optionsKey={optionsKey} />;
  } else if (optionsKey === "languages") {
    content = <Languages optionsKey={optionsKey} />;
  }

  return (
    <AccordionItem value={optionsKey}>
      <AccordionTrigger>
        <Icon size={20} weight="fill" />
        <span>{label}</span>
        <RenderIf condition={!!appliedSearchParamValues.length}>
          <Tag variant="primary-black">{appliedSearchParamValues.length}</Tag>
        </RenderIf>
      </AccordionTrigger>
      <AccordionContent>{content}</AccordionContent>
    </AccordionItem>
  );
}
