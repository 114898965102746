import React from "react";
import clsx from "clsx";

import { getMonthName } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";

import DownloadInvoice from "./DownloadInvoice";
import { BILLING_STATUSES } from "../../constants";
import { Invoice } from "../../types";

export default function MobileList({ invoices }: { invoices: Invoice[] }) {
  return (
    <div className="flex flex-col gap-2.5">
      {invoices.map((invoice) => {
        const { date, total, status, id, line_items: lineItems } = invoice;
        const dateTimestamp = new Date(date * 1000);

        const infoItems = [
          { label: "Plan", value: lineItems[0].description },
          {
            value: (
              <Tag variant="success">{BILLING_STATUSES[status].label}</Tag>
            ),
          },
          {
            label: "Date",
            value: `${dateTimestamp.getDate()} ${getMonthName(dateTimestamp.getMonth())}`,
          },
          { label: "Amount", value: `$${total / 100}` },
        ];

        return (
          <div
            key={id}
            className="flex flex-col gap-2.5 rounded-2xl border border-black-200 p-2 pb-4"
          >
            <div className="flex flex-row items-center justify-between rounded-lg bg-black-100 p-2">
              <span className="text-caption-12-bold">
                {getMonthName(dateTimestamp.getMonth())}{" "}
                {dateTimestamp.getFullYear()}
              </span>

              <DownloadInvoice invoiceId={id} />
            </div>

            <div className="flex w-full flex-row flex-wrap gap-y-4 px-2">
              {infoItems.map((info, index) => {
                const { label, value } = info;

                return (
                  <div
                    key={info.label}
                    className={clsx([
                      "flex w-1/2 flex-col justify-center gap-1",
                      index % 2 === 1 && "items-end",
                      (index === 0 || index === 1) &&
                        "border-b border-black-200 pb-4",
                    ])}
                  >
                    <span className="text-caption-12-regular">{label}</span>
                    <span className="text-caption-12-bold">{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
