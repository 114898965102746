import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function RecentLeadsItemLoader({
  itemsCount = 1,
}: {
  itemsCount?: number;
}) {
  const getLoaderList = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, index) => index);

  return (
    <div className="my-8 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
      {getLoaderList(itemsCount).map((item) => (
        <div
          key={item}
          className="flex flex-row justify-between rounded-2xl border border-black-200 px-3 py-4"
        >
          <div className="flex flex-row items-center gap-2">
            <Skeleton className="size-12 rounded-xl" />

            <div className="flex flex-col gap-1">
              <Skeleton className="h-[20px] w-36" />
              <Skeleton className="h-[17px] w-24" />
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <Skeleton className="size-6 rounded-full" />
            <Skeleton className="size-6 rounded-full" />
          </div>
        </div>
      ))}
    </div>
  );
}
