import React from "react";

export default function UnreadCount({ unreadCount }: { unreadCount: number }) {
  if (!unreadCount) {
    return;
  }

  return (
    <span className="flex size-5 flex-col items-center justify-center rounded-full bg-purple-600 text-caption-10-bold text-white">
      {unreadCount > 9 ? "9+" : unreadCount}
    </span>
  );
}
