import React, { JSX, ReactNode } from "react";
import { clsx } from "clsx";

import useAppStore from "common/hooks/useAppStore";
import { NavItemType } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import TrialBanner from "common/layouts/WorkspaceLayout/TrialBanner";

import MobileHeader from "./MobileHeader";

interface MobileLayoutProps {
  navItems: NavItemType[];
  children: ReactNode;
  headerTitle: string;
  bannerComponent: JSX.Element;
}
export default function MobileLayout({
  navItems,
  children,
  headerTitle,
  bannerComponent,
}: MobileLayoutProps) {
  const { subscription } = useSelectedWorkspaceContext();
  const isPageHeightLimited = useAppStore((state) => state.isPageHeightLimited);

  return (
    <div
      className={clsx(
        "flex min-h-screen flex-col",
        isPageHeightLimited && "100vh",
      )}
    >
      <MobileHeader navItems={navItems} />
      <div className="flex grow flex-col p-4">
        {subscription && subscription.status === "in_trial" && <TrialBanner />}
        {bannerComponent}
        <h1 className="mb-4 text-headline-xl-bold">{headerTitle}</h1>
        {children}
      </div>
    </div>
  );
}
