import React from "react";

import { Invitation, Workspace } from "common/types";

import InvitationItem from "./InvitationItem";
import WorkspaceItem from "./WorkspaceItem";

interface WorkspacesListProps {
  workspaces: Workspace[];
  invitations: Invitation[];
}

export default function WorkspacesList({
  workspaces,
  invitations,
}: WorkspacesListProps) {
  return (
    <>
      {invitations.map((invitation) => (
        <InvitationItem invitation={invitation} key={invitation.token} />
      ))}
      {workspaces.map((workspace) => (
        <WorkspaceItem workspace={workspace} key={workspace.id} />
      ))}
    </>
  );
}
