import React from "react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";

interface RangeSelectProps {
  options: {
    value: number;
    label: string;
  }[];
  selectedValue: number;
  onSelect: (value: number) => void;
  isDisabled: boolean;
}

export default function RangeSelect({
  options,
  selectedValue,
  onSelect,
  isDisabled,
}: RangeSelectProps) {
  return (
    <Select
      value={selectedValue.toString()}
      onValueChange={(value) => onSelect(Number(value))}
    >
      <SelectTrigger className="w-24" disabled={isDisabled}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="max-h-80">
        {options.map(({ value, label }) => (
          <SelectItem value={value.toString()} key={value}>
            <SelectItemText>{label}</SelectItemText>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
