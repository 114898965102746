import React from "react";
import { UsersThree } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { formatNumber } from "common/helpers/utils";
import RenderIf from "common/components/RenderIf";
import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

export default function SearchParams({
  totalCount,
  isLoadingProfiles,
}: {
  totalCount: number;
  isLoadingProfiles: boolean;
}) {
  const isTabletOrDesktop = useTwBreakpoint("lg");

  const totalCountComponent = isLoadingProfiles ? (
    <Skeleton className="h-7 w-20 max-lg:self-end" />
  ) : (
    <RenderIf condition={!!totalCount}>
      <Tag
        className="max-lg:self-end"
        variant="info"
        size="lg"
        leftIcon={<UsersThree weight="fill" />}
      >
        {formatNumber(totalCount)}
      </Tag>
    </RenderIf>
  );

  const Component = isTabletOrDesktop ? DesktopLayout : MobileLayout;
  return <Component totalCountComponent={totalCountComponent} />;
}
