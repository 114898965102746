import { Trash } from "@phosphor-icons/react";
import React, { MouseEvent } from "react";

import { Tag } from "common/components/ui/Tag";
import { Label } from "common/types";
import useLeadLabels from "common/datahooks/useLeadLabels";

export default function AccountLabel({
  label,
  profileId,
  onDelete,
}: {
  label: Label;
  profileId: string;
  onDelete: () => void;
}) {
  const { addLeadLabel, isAddingLeadLabel } = useLeadLabels();

  const { name } = label;

  function onClick(e: MouseEvent<HTMLSpanElement>) {
    if (e.target === e.currentTarget) {
      addLeadLabel({ label, profileId });
    }
  }

  return (
    <Tag
      onClick={!isAddingLeadLabel && onClick}
      aria-disabled={isAddingLeadLabel}
      variant="quaternary"
      role="button"
      tabIndex={0}
      size="lg"
      rightIcon={
        <button aria-label="delete label" type="button" onClick={onDelete}>
          <Trash weight="bold" />
        </button>
      }
    >
      {name}
    </Tag>
  );
}
