import { HandWaving, Info } from "@phosphor-icons/react";
import React from "react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Conversation } from "../../types";

const iconContainerVariants = cva(
  "mr-1 rounded-md border p-px [&_svg]:size-4",
  {
    variants: {
      variant: {
        red: "border-red-200 bg-red-50 text-red-500",
        gray: "border-black-200 bg-black-50 ",
        purple: "border-purple-200 bg-purple-50 text-purple-500",
      },
    },
  },
);

function Notification({
  title,
  subtitle = null,
  variant,
}: {
  title: string;
  subtitle?: string;
  variant: "red" | "gray" | "purple";
}) {
  return (
    <div
      className={clsx(
        "mb-2 flex items-center gap-1 self-center rounded-xl border bg-white px-2 py-1 shadow-sm",
        variant === "red" ? "border-red-400" : "border-black-200",
      )}
    >
      <div className={iconContainerVariants({ variant })}>
        {variant === "purple" ? <HandWaving /> : <Info weight="fill" />}
      </div>
      <span className="text-caption-12-bold">{title}</span>
      {subtitle && (
        <span className="text-caption-12-regular text-black-600">
          {subtitle}
        </span>
      )}
    </div>
  );
}
export default function NotificationArea({
  conversation,
}: {
  conversation: Conversation;
}) {
  const {
    conversation_urn: conversationUrn,
    participants,
    last_message: lastMessage,
    owner,
  } = conversation;

  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === owner);

  if (!conversationUrn || !lastMessage) {
    return (
      <Notification
        title={`This is the very beginning of your direct message history with ${participants[0].full_name}. Say hi!`}
        variant="purple"
      />
    );
  }
  if (!participants[0].is_lead) {
    return (
      <Notification
        title="Cannot message this user."
        subtitle="You will be able to chat with this user once they respond to your message"
        variant="gray"
      />
    );
  }
  if (lastMessage && lastMessage.declined) {
    return (
      <Notification
        title="InMail Declined."
        subtitle="The recipient has declined your InMail, you cannot send them a message"
        variant="red"
      />
    );
  }
  if (account.state !== "LoggedIn") {
    return (
      <Notification
        title="Cannot message users."
        subtitle="You will be able to chat with users, once this account is reconnected"
        variant="gray"
      />
    );
  }
}
