import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import { ArrowsClockwise } from "@phosphor-icons/react";

import { Accordion } from "common/components/ui/Accordion";
import ProfileImage from "common/components/ui/ProfileImage";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import RenderIf from "common/components/RenderIf";

import { Facets, LeadsSearchParams, SelectedFacetParam } from "../../types";
import SearchFacet from "./SearchFacet";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import { leadsFacets } from "../../constants";

export default function SearchFilters({
  facets,
  accountsSync,
  isLoading,
  setSearchParams,
  searchParams,
}: {
  facets: Facets;
  accountsSync: Record<string, boolean>;
  isLoading: boolean;
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void;
}) {
  const { accounts } = useSelectedWorkspaceContext();
  const [accordionValue, setAccordionValue] = useState(
    // tests don't work because all the accordions are closed by default. This opens all the accordions in test environment.
    import.meta.env.MODE === "test"
      ? leadsFacets.map(({ label }) => label)
      : [leadsFacets[0].label],
  );
  const { toggleSearchParam, resetSearchParam } = useLeadsSearchParams(
    searchParams,
    setSearchParams,
  );

  useEffect(() => {
    setSearchParams(searchParams);
  }, [searchParams]);

  // we merge facets that have the same location name but different ids
  const mergedFacets = useMemo(
    () =>
      produce(facets, (draftState) => {
        if (draftState) {
          draftState.locations = draftState.locations.reduce(
            (accumulator, currentValue, index) => {
              const groupedFacet = currentValue;
              for (let i = 0; i < draftState.locations.length; i += 1) {
                if (
                  groupedFacet.name === draftState.locations[i].name &&
                  i !== index
                ) {
                  groupedFacet.target_count +=
                    draftState.locations[i].target_count;

                  if (typeof groupedFacet.id === "string") {
                    groupedFacet.id = [
                      groupedFacet.id,
                      draftState.locations[i].id as string,
                    ];
                  } else {
                    groupedFacet.id = [
                      ...(groupedFacet.id as string[]),
                      draftState.locations[i].id as string,
                    ];
                  }
                  draftState.locations.splice(i, 1);
                  i -= 1;
                }
              }
              accumulator.push(groupedFacet);
              return accumulator;
            },
            [],
          );
        }
      }),
    [facets],
  );

  function toggleAccordion(value: string) {
    if (accordionValue.includes(value)) {
      setAccordionValue(accordionValue.filter((item) => item !== value));
    } else {
      setAccordionValue([...accordionValue, value]);
    }
  }

  return (
    <Accordion type="multiple" value={accordionValue}>
      {leadsFacets.map(({ searchKey, facetKey, label }) => (
        <SearchFacet
          toggleAccordion={() => toggleAccordion(label)}
          key={searchKey}
          label={label}
          allFacets={mergedFacets ? mergedFacets[facetKey || searchKey] : []}
          selectedFacets={searchParams[searchKey]}
          toggleSearchParam={(param) => toggleSearchParam(searchKey, param)}
          resetFacet={() => resetSearchParam(searchKey)}
          isLoading={isLoading}
          renderFacet={(param) => {
            const account = accounts.find(
              ({ id }) => id.toString() === param.name,
            );

            if (searchKey === "lead_of") {
              return (
                <>
                  <ProfileImage
                    src={account.picture_url}
                    size="xs"
                    className="rounded-full"
                  />

                  <span className="text-start text-button-14">
                    {account.full_name}
                  </span>

                  {accountsSync && (
                    <RenderIf condition={accountsSync[account.id]}>
                      <div className="ml-1.5 rounded-full bg-purple-600 p-0.5">
                        <ArrowsClockwise className="size-2 animate-spin text-white" />
                      </div>
                    </RenderIf>
                  )}
                </>
              );
            }
            return (
              <span className="truncate text-start text-button-14">
                {param.name}
              </span>
            );
          }}
        />
      ))}
    </Accordion>
  );
}
