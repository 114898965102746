import React, {
  forwardRef,
  ReactNode,
  useRef,
  useImperativeHandle,
  ForwardedRef,
} from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";

const textAreaVariants = cva(
  [
    "relative rounded-2xl border border-purple-200 bg-white text-black-700 transition-all",
    "has-[textarea:disabled]:border-whiteGray has-[textarea:disabled]:bg-whiteGray has-[textarea:disabled]:text-black-300",
    "has-[textarea:hover]:bg-whiteGray has-[textarea:hover]:text-black-950",
    "has-[:focus-visible]:bg-whiteGray has-[:focus-visible]:text-black-950 has-[:focus-visible]:ring has-[:focus-visible]:ring-purple-200",
    "data-[error=true]:border-red-200 data-[error=true]:bg-red-50 data-[error=true]:text-red-500",
    "data-[error=true]:has-[:focus-visible]:ring-red-300 hover:data-[error=true]:bg-white",
  ],
  {
    variants: {
      variant: {
        sm: "text-caption-12 p-1.5",
        md: "text-caption-14 p-2",
        lg: "text-caption-16 p-3",
      },
    },
    defaultVariants: {
      variant: "md",
    },
  },
);

interface TextAreaProps
  extends TextareaAutosizeProps,
    VariantProps<typeof textAreaVariants> {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  bottomComponent?: ReactNode;
  error?: string | boolean;
}

function TextArea(
  {
    leftComponent = null,
    rightComponent = null,
    bottomComponent = null,
    error = null,
    variant,
    className,
    ...props
  }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  // Create a ref for the textarea
  const localRef = useRef<HTMLTextAreaElement>(null);

  // Sync the forwarded ref with the local ref
  useImperativeHandle(ref, () => localRef.current);

  // Handler to focus the textarea
  const handleClick = () => {
    localRef.current?.focus();
  };

  return (
    <>
      <div
        className={clsx(textAreaVariants({ variant }), className)}
        data-error={!!error || undefined}
        onClick={handleClick}
        role="presentation"
      >
        <div className="flex flex-row">
          {leftComponent}

          <TextareaAutosize
            className="w-full resize-none bg-transparent outline-none scrollbar-thin placeholder:text-black-400 disabled:placeholder:text-black-300"
            ref={localRef}
            {...props}
          />

          {rightComponent}
        </div>

        {bottomComponent}
      </div>

      {typeof error === "string" && (
        <span className="text-caption-12-regular text-red-500">{error}</span>
      )}
    </>
  );
}
TextArea.displayName = "TextArea";

export default forwardRef(TextArea);
