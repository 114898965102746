import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import { HTTPError } from "common/helpers/HTTP";

import usePromotionsMutations from "../../datahooks/usePromotionsMutations";

export default function AppSumoDialog({
  isOpen,
  code,
  onClose,
}: {
  isOpen: boolean;
  code: string;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const {
    createAppSumoPromotion,
    isCreatingAppSumoPromotion,
    createAppSumoPromotionError,
  } = usePromotionsMutations();

  useEffect(() => {
    if (code) {
      createAppSumoPromotion({ code });
    }
  }, [code]);

  let title = "AppSumo plan activated successfully!";
  let subtitle: ReactNode =
    "Welcome aboard Sumoling! You can assign your lifetime seats in the All Workspaces page";

  if (isCreatingAppSumoPromotion) {
    title = "Activating your AppSumo plan";
    subtitle =
      "We are activating your lifetime seats and preparing the dashboard. This may take a second";
  } else if (createAppSumoPromotionError) {
    const isLicenseAlreadyUsed =
      createAppSumoPromotionError instanceof HTTPError &&
      createAppSumoPromotionError.type === "LicenseAlreadyUsed";

    title = "Failed to activate AppSumo plan";
    subtitle = isLicenseAlreadyUsed ? (
      <>
        This AppSumo license is already redeemed for{" "}
        <span className="text-body-14-bold text-black-950">
          {createAppSumoPromotionError.serverMessage}
        </span>{" "}
        Aimfox account. Please contact support if you think this is a mistake
      </>
    ) : (
      "You may have already redeemed this plan. Please contact support if you think this is a mistake"
    );
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && !isCreatingAppSumoPromotion) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <h3 className="mb-2 text-center text-headline-2xl-bold">{title}</h3>
        <p className="mb-8 text-center text-black-500">{subtitle}</p>
        <Button
          size="lg"
          onClick={() => {
            if (createAppSumoPromotionError) {
              onClose();
            } else {
              navigate("/workspaces");
            }
          }}
          isLoading={isCreatingAppSumoPromotion}
        >
          {createAppSumoPromotionError ? "Close" : "Manage seats & workspaces"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
