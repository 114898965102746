import React from "react";
import { toast } from "react-toastify";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import useLeadNotes from "common/datahooks/useLeadNotes";

export default function DeleteNoteDialog({
  profileId,
  noteId,
  onClose,
}: {
  profileId: string;
  noteId: string;
  onClose: () => void;
}) {
  const { deleteLeadNote, isDeletingLeadNote } = useLeadNotes();

  function onDelete() {
    deleteLeadNote({ profileId, noteId }).catch(() => {
      toast.error("Error deleting Note");
    });
    onClose();
  }
  return (
    <Dialog
      open={!!noteId}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-96">
        <span className="mb-2 text-center text-headline-2xl-bold">
          Delete note?
        </span>
        <span className="mb-4 text-center text-body-14-regular text-black-500">
          Are you sure you want to delete this note?
        </span>
        <Button
          variant="tertiary-danger"
          className="mb-4"
          onClick={onDelete}
          isLoading={isDeletingLeadNote}
        >
          Yes
        </Button>
        <Button
          variant="quaternary-black"
          disabled={isDeletingLeadNote}
          onClick={onClose}
        >
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
}
