import React from "react";
import { X } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";

import AimfoxLogo from "assets/aimfoxLogos/aimfox-logo-text.svg?react";

export default function Header() {
  const isDesktop = useTwBreakpoint("lg");
  const { goBackToWorkspace } = useWorkspaceNavigate();

  return (
    <header className="flex h-20 items-center justify-between px-4 max-lg:shadow-sm lg:h-[88px] lg:border-b lg:border-black-200 lg:px-3">
      <AimfoxLogo />
      {isDesktop ? (
        <Button variant="tertiary-black" onClick={goBackToWorkspace}>
          Back to Workspace
        </Button>
      ) : (
        <Button
          intent="iconOnly"
          size="lg"
          variant="tertiary-black"
          onClick={goBackToWorkspace}
        >
          <X />
        </Button>
      )}
    </header>
  );
}
