import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function CommunicationItemLoader({
  itemsCount = 1,
}: {
  itemsCount?: number;
}) {
  const getLoaderList = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, index) => index);

  return (
    <div className="mb-4 flex flex-col gap-4">
      {getLoaderList(itemsCount).map((item) => (
        <div
          key={item}
          className="flex w-full flex-row items-center justify-between gap-4"
        >
          <div className="flex flex-col gap-1">
            <Skeleton className="h-[21px] w-[180px]" />
            <Skeleton className="h-[18px] w-[290px]" />
          </div>

          <Skeleton className="h-[20px] w-[36px] rounded-full" />
        </div>
      ))}
    </div>
  );
}
