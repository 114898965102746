import React, { LabelHTMLAttributes, forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const labelVariants = cva("text-black-700", {
  variants: {
    size: {
      sm: "text-caption-12-regular",
      md: "text-body-14-regular",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> &
  VariantProps<typeof labelVariants>;

const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, size, htmlFor, ...props }, ref) => (
    <label
      htmlFor={htmlFor}
      ref={ref}
      className={cn(labelVariants({ size, className }))}
      {...props}
    />
  ),
);

Label.displayName = "Label";

export default Label;
