import React from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";

import DownloadInvoice from "./DownloadInvoice";
import { BILLING_STATUSES } from "../../constants";
import { Invoice } from "../../types";
import { formatBillingDate } from "../../utils";

export default function DesktopTable({
  invoices,
  isLoading,
}: {
  invoices: Invoice[];
  isLoading: boolean;
}) {
  return (
    <Table variant="simple">
      <TableHeader>
        <tr>
          <TableHead>Date</TableHead>
          <TableHead>Plan</TableHead>
          <TableHead>Amount</TableHead>
          <TableHead>Status</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody>
        {(isLoading ? new Array<Invoice>(3).fill(null) : invoices).map(
          (invoice, index) => (
            <TableRow key={invoice?.id || index}>
              <TableCell className="max-w-14">
                {isLoading ? (
                  <Skeleton className="h-[18px] w-32 rounded-lg" />
                ) : (
                  <span className="text-caption-12-regular text-black-700">
                    {formatBillingDate(invoice.date * 1000)}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-44 rounded-lg" />
                ) : (
                  <span className="text-caption-12-bold text-black-700">
                    {invoice.line_items[0].description}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-10 rounded-lg" />
                ) : (
                  <span className="text-caption-12-bold text-black-700">
                    ${invoice.total / 100}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-16 rounded-lg" />
                ) : (
                  <Tag variant="success">
                    {BILLING_STATUSES[invoice.status].label}
                  </Tag>
                )}
              </TableCell>

              <TableCell className="text-end">
                <div className="flex items-center justify-end">
                  {isLoading ? (
                    <Skeleton className="size-[18px] rounded" />
                  ) : (
                    <DownloadInvoice invoiceId={invoice.id} />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ),
        )}
      </tbody>
    </Table>
  );
}
