import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
} from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cva, VariantProps } from "class-variance-authority";

import RenderIf from "common/components/RenderIf";
import { cn } from "common/helpers/utils";

// Slider variants using class-variance-authority
const sliderVariants = cva(
  "relative flex w-full touch-none select-none items-center",
  {
    variants: {
      size: {
        sm: "h-4",
        md: "h-6",
        lg: "h-8",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

// Type definition for Slider props
type SliderProps = ComponentPropsWithoutRef<typeof SliderPrimitive.Root> &
  VariantProps<typeof sliderVariants> & {
    showTooltip?: boolean;
  };

function Slider(
  { className, size = "md", showTooltip = false, ...props }: SliderProps,
  ref: ForwardedRef<ElementRef<typeof SliderPrimitive.Root>>,
) {
  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(sliderVariants({ size }), className)}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-black-300">
        <SliderPrimitive.Range className="absolute h-full bg-purple-500" />
      </SliderPrimitive.Track>

      <SliderPrimitive.Thumb className="group relative block size-4 cursor-pointer rounded-full border-2 border-purple-500 bg-purple-500 ring-offset-purple-500 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/20 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
        <RenderIf condition={showTooltip}>
          <span className="absolute -left-4 bottom-5 flex size-10 items-center justify-center rounded-xl bg-black-950 text-white opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
            {props.value || props.defaultValue}
          </span>
        </RenderIf>
      </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
  );
}

Slider.displayName = SliderPrimitive.Root.displayName;

export default forwardRef(Slider);
