import DOMPurify from "dompurify";

export function getHtmlString(text: string) {
  return `<span class="bg-purple-100 text-purple-600 rounded-md py-1 text-button-12 text-nowrap" contenteditable="false"><span class="opacity-0">{{</span><span>${text}</span><span class="opacity-0">}}</span></span>`;
}

const config = {
  ALLOWED_TAGS: ["span"],
  ALLOWED_ATTR: ["contenteditable", "class"],
};

export function getSanitizedValue(value: string) {
  return DOMPurify.sanitize(value, config);
}
