import React from "react";

import ProfileImage from "common/components/ui/ProfileImage";
import { RecentLead } from "common/types";

interface RecentLeadsItemProps {
  lead: RecentLead;
  showFlowOrigin: boolean;
  showCampaignOrigin: boolean;
  onLeadSelect: () => void;
}

export default function RecentLeadsItem({
  lead,
  showFlowOrigin,
  showCampaignOrigin,
  onLeadSelect,
}: RecentLeadsItemProps) {
  const { campaign_name: campaignName, target, flow_name: flowName } = lead;
  const { picture_url: pictureUrl, full_name: fullName, occupation } = target;

  return (
    <button
      className="flex flex-row justify-between gap-4 rounded-2xl border border-black-200 px-3 py-4 transition-shadow hover:shadow-md"
      onClick={() => onLeadSelect()}
      type="button"
    >
      <div className="flex flex-row items-center gap-2">
        <ProfileImage src={pictureUrl} size="lg" />

        <div className="flex flex-col items-start">
          <span className="line-clamp-1 text-start text-button-16">{fullName}</span>

          <p className="line-clamp-1 break-all text-start text-caption-12-regular text-black-500 md:max-w-[30ch]">
            {showFlowOrigin || showCampaignOrigin ? (
              <>
                Origin:{" "}
                <span className="font-semibold">
                  {showFlowOrigin ? flowName : campaignName}
                </span>
              </>
            ) : (
              occupation
            )}
          </p>
        </div>
      </div>
    </button>
  );
}
