import React, { ComponentProps, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";

import ErrorPlaceholderImage from "assets/images/empty-placeholders/error.png";

interface EmptyPlaceholderProps extends ComponentProps<"div"> {
  errorMessage: string;
  onRetry: () => void;
}

const ErrorPlaceholder = forwardRef<HTMLDivElement, EmptyPlaceholderProps>(
  ({ errorMessage, onRetry, className, ...props }, ref) => {
    const { t } = useTranslation("common");

    return (
      <div
        ref={ref}
        className={cn(
          "max-w-72 md:max-w-96 flex flex-col mx-auto grow justify-center items-center",
          className,
        )}
        {...props}
      >
        <img src={ErrorPlaceholderImage} alt="Error" width={364} height={254} />
        <h5 className="mb-2 text-center text-headline-lg-regular">
          {errorMessage}
        </h5>
        <p className="text-center text-black-500">
          {t("errorPlaceholderSubtitle")}
        </p>
        <Button className="mt-6 w-full" onClick={onRetry}>
          Reload
        </Button>
      </div>
    );
  },
);
ErrorPlaceholder.displayName = "ErrorPlaceholder";

export default ErrorPlaceholder;
