import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { post, del, patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Label } from "common/types";

import getQueryKeys from "./getQueryKeys";
import useUpdateLeads from "./useUpdateLeads";

async function addLabel(
  workspaceId: string,
  label: Partial<Label>,
): Promise<Label> {
  return (await post(`workspaces/${workspaceId}/labels`, label)).label;
}
async function deleteLabel(workspaceId: string, labelId: string) {
  await del(`workspaces/${workspaceId}/labels/${labelId}`);
}
async function updateLabel(
  workspaceId: string,
  labelId: string,
  updatedLabel: Label,
) {
  await patch(`workspaces/${workspaceId}/labels/${labelId}`, updatedLabel);
}

export default function useAccountLabels() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const updateLeads = useUpdateLeads();

  const { labelsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: addAccountLabel, isPending: isAddingAccountLabel } =
    useMutation({
      mutationFn: (label: Partial<Label>) => addLabel(workspaceId, label),
      onSuccess: (label) => {
        queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) => [
          ...prevLabels,
          label,
        ]);
      },
    });

  const { mutateAsync: deleteAccountLabel, isPending: isDeletingAccountLabel } =
    useMutation({
      mutationFn: ({ labelId }: { labelId: string }) =>
        deleteLabel(workspaceId, labelId),
      onSuccess: (_, { labelId }) => {
        queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) =>
          prevLabels.filter(({ id }) => id !== labelId),
        );
        updateLeads((draftLead) => {
          draftLead.labels = draftLead.labels.filter(
            ({ id }) => id !== labelId,
          );
        });
      },
    });

  const { mutateAsync: updateAccountLabel, isPending: isUpdatingAccountLabel } =
    useMutation({
      mutationFn: ({ labelId, label }: { labelId: string; label: Label }) =>
        updateLabel(workspaceId, labelId, label),
      onSuccess: (_, { labelId, label }) => {
        queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) =>
          produce(prevLabels, (draftState) => {
            for (let i = 0; i < draftState.length; i += 1) {
              if (draftState[i].id === labelId) {
                draftState[i] = { ...draftState[i], ...label };
              }
            }
          }),
        );
        updateLeads((draftLead) => {
          for (let i = 0; i < draftLead.labels.length; i += 1) {
            if (draftLead.labels[i].id === labelId) {
              draftLead.labels[i] = label;
            }
          }
        });
      },
    });
  return {
    addAccountLabel,
    isAddingAccountLabel,
    deleteAccountLabel,
    isDeletingAccountLabel,
    updateAccountLabel,
    isUpdatingAccountLabel,
  };
}
