import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
} from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

interface DialogProps
  extends ComponentPropsWithoutRef<typeof DialogPrimitive.Root> {
  onAfterClose?: () => void;
}

function Dialog({ onAfterClose = null, onOpenChange, ...props }: DialogProps) {
  return (
    <DialogPrimitive.Root
      onOpenChange={(open) => {
        if (onOpenChange) {
          onOpenChange(open);
        }

        if (!open && onAfterClose) {
          setTimeout(() => onAfterClose(), 300);
        }
      }}
      {...props}
    />
  );
}
Dialog.displayName = DialogPrimitive.Root.displayName;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    onClick={(e) => e.stopPropagation()}
    className={cn(
      "fixed inset-0 z-overlay bg-black-950/60 backdrop-blur-sm",
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={cn(
        "fixed flex flex-col left-1/2 top-1/2 z-dialog -translate-x-1/2 -translate-y-1/2 w-10/12 md:w-[540px] bg-white px-4 pb-6 pt-10 shadow-lg rounded-2xl duration-200",
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
        className,
      )}
      {...props}
    >
      <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-black-400 focus:ring-offset-2 disabled:pointer-events-none">
        <X size={16} />
        <span className="sr-only">Close</span>
      </DialogClose>
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

function DialogFooter({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "flex justify-between bg-white p-4 -mb-6 -mx-4 rounded-b-3xl border-t border-t-black-200",
        className,
      )}
      {...props}
    />
  );
}
DialogFooter.displayName = "DialogFooter";

export { Dialog, DialogTrigger, DialogContent, DialogClose, DialogFooter };
