import React from "react";
import { DotOutline } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { WorkExperience } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";

import SectionContainer from "./SectionContainer";
import { formatMonthAndYear, formatRange } from "../../utils";
import SectionItem from "./SectionItem";
import ExpandableText from "./ExpandableText";

import CompanyDefaultImage from "assets/images/company-fallback.png";

function WorkExperienceItem({
  workExperience,
  isLoading,
}: {
  workExperience: WorkExperience;
  isLoading: boolean;
}) {
  const {
    company,
    start_date: startDate,
    end_date: endDate,
    description,
    location,
    job_title: jobTitle,
  } = workExperience;
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-2">
        {isLoading ? (
          <Skeleton className="size-10" />
        ) : (
          <ProfileImage
            src={
              company && company.logo_url
                ? company.logo_url
                : CompanyDefaultImage
            }
          />
        )}
        <div
          className={`grid grow items-center justify-between gap-y-0.5 [grid-template-areas:'title''dateLocation''company'] lg:[grid-template-areas:'title_dateLocation''company_dateLocation']`}
        >
          {isLoading ? (
            <Skeleton className="h-4 w-40 [grid-area:title]" />
          ) : (
            <span className="break-all text-button-14 [grid-area:title]">
              {jobTitle}
            </span>
          )}
          {isLoading ? (
            <Skeleton className="h-3 w-60 [grid-area:company]" />
          ) : (
            company && (
              <span className="text-caption-12-regular text-black-500 [grid-area:company]">
                {company.name}
              </span>
            )
          )}
          <div className="flex flex-col [grid-area:dateLocation] lg:items-end lg:gap-y-1">
            {isLoading ? (
              <Skeleton className="h-3 w-24" />
            ) : (
              startDate && (
                <span className="text-nowrap text-caption-12-regular">
                  {formatRange(
                    formatMonthAndYear(startDate),
                    endDate ? formatMonthAndYear(endDate) : "Present",
                  )}
                </span>
              )
            )}
            {isLoading ? (
              <Skeleton className="h-3 w-44" />
            ) : (
              location && (
                <span className="text-nowrap text-caption-12-regular">
                  {location.name}
                </span>
              )
            )}
          </div>
        </div>
      </div>
      {description && (
        <ExpandableText className="ml-12">{description}</ExpandableText>
      )}
    </div>
  );
}
const noCompanyKey = "nocompany";
export default function Experience({
  workExperience,
  isLoading,
}: {
  workExperience: WorkExperience[];
  isLoading: boolean;
}) {
  const groupedWorkExperience = workExperience
    ? workExperience.reduce<Record<string, WorkExperience[]>>(
        (accumulator, currentValue) => {
          const key = currentValue.company
            ? currentValue.company.name
            : noCompanyKey;
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(currentValue);
          return accumulator;
        },
        {},
      )
    : {};

  return (
    <SectionContainer title="Experience">
      {isLoading
        ? new Array(5).fill({}).map((experience, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SectionItem key={index}>
              <WorkExperienceItem
                workExperience={experience}
                isLoading={isLoading}
              />
            </SectionItem>
          ))
        : Object.entries(groupedWorkExperience).map(
            ([companyName, experiences]) => {
              if (experiences.length > 1 && companyName !== noCompanyKey) {
                const firstJobStart =
                  experiences[experiences.length - 1].start_date;
                const lastJobEnd = experiences[0].end_date;

                return (
                  <SectionItem key={companyName}>
                    <div className="mb-2.5 flex items-center">
                      <ProfileImage
                        className="mr-2"
                        src={
                          experiences[0].company &&
                          experiences[0].company.logo_url
                            ? experiences[0].company.logo_url
                            : CompanyDefaultImage
                        }
                      />
                      <span className="text-button-14">{companyName}</span>
                      <span className="ml-auto text-caption-12-regular">
                        {formatRange(
                          formatMonthAndYear(firstJobStart),
                          lastJobEnd
                            ? formatMonthAndYear(lastJobEnd)
                            : "Present",
                        )}
                      </span>
                    </div>
                    {experiences.map(
                      (
                        {
                          job_title,
                          start_date,
                          end_date,
                          description,
                          location,
                          employment_type,
                        },
                        index,
                      ) => (
                        <div
                          className="flex items-stretch gap-x-2"
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={index}
                        >
                          <div className="-mb-4 flex w-10 shrink-0 flex-col items-center pt-4">
                            <DotOutline
                              className="text-black-400"
                              size={16}
                              weight="fill"
                            />
                            {index !== experiences.length - 1 && (
                              <div className="h-full w-0.5 rounded-full bg-black-200" />
                            )}
                          </div>
                          <div
                            className={clsx(
                              "mr-8 mt-4 flex grow flex-col",
                              index !== experiences.length - 1 && "mb-4",
                            )}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex flex-col">
                                <span className="text-button-12">
                                  {job_title}
                                </span>
                                {employment_type && (
                                  <span className="text-caption-10-regular text-black-500">
                                    {employment_type.name}
                                  </span>
                                )}
                              </div>
                              <div className="flex flex-col items-end gap-y-1">
                                <span className="text-caption-10-regular text-black-500">
                                  {formatRange(
                                    formatMonthAndYear(start_date),
                                    end_date
                                      ? formatMonthAndYear(end_date)
                                      : "Present",
                                  )}
                                </span>
                                {location && (
                                  <span className="text-caption-10-regular text-black-500">
                                    {location.name}
                                  </span>
                                )}
                              </div>
                            </div>
                            {description && (
                              <ExpandableText>{description}</ExpandableText>
                            )}
                          </div>
                        </div>
                      ),
                    )}
                  </SectionItem>
                );
              }
              return (
                <SectionItem key={companyName}>
                  <WorkExperienceItem
                    workExperience={experiences[0]}
                    isLoading={false}
                  />
                </SectionItem>
              );
            },
          )}
    </SectionContainer>
  );
}
