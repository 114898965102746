import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { Pricing } from "common/types";

async function getPricingRequest(): Promise<Pricing[]> {
  return (await get("billing/pricing")).pricing;
}

export default function usePricing() {
  const { data: pricing } = useQuery({
    queryKey: ["pricing"],
    queryFn: getPricingRequest,
    refetchOnMount: false,
  });

  return { pricing };
}
