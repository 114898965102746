import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function HeaderLoader() {
  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl bg-white p-4 shadow-sm md:shadow-transparent">
      <div className="flex w-full flex-row justify-between gap-2">
        <div className="flex flex-row gap-2">
          <Skeleton className="size-10 rounded-xl" />

          <div className="flex flex-col justify-center gap-0.5">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-36" />
          </div>
        </div>

        <div className="flex gap-2">
          <Skeleton className="size-10 rounded-full xl:w-36" />
          <Skeleton className="size-10 rounded-full" />
        </div>
      </div>

      <div className="flex flex-row gap-2">
        <Skeleton className="h-6 w-24" />
        <Skeleton className="h-6 w-24" />
        <Skeleton className="h-6 w-24" />
      </div>
    </div>
  );
}
