import { InferOutput, number, object, pipe, integer, minValue } from "valibot";

export const AssignSeatsSchema = object({
  seats: pipe(
    number("Seats to assign must be a number"),
    minValue(1, "Seats to assign must be greater than zero"),
    integer(),
  ),
});
export type AssignSeatsSchemaType = InferOutput<typeof AssignSeatsSchema>;
