import React, { useRef, useState } from "react";
import { clsx } from "clsx";

import SearchInput from "common/components/SearchInput";
import useDebounce from "common/hooks/useDebounce";
import useProfileSearch from "common/datahooks/useProfileSearch";
import ProfileImage from "common/components/ui/ProfileImage";
import { LinkedInProfile } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useBlacklist from "../../datahooks/useBlacklist";

export default function AccountsSearch({
  selectedAccounts,
  setSelectedAccounts,
}: {
  selectedAccounts: LinkedInProfile[];
  setSelectedAccounts: (accounts: LinkedInProfile[]) => void;
}) {
  const inputRef = useRef<HTMLInputElement>();
  const { blacklist } = useBlacklist();
  const { accounts } = useSelectedWorkspaceContext();
  const [keywords, setKeywords] = useState("");
  const debouncedKeywords = useDebounce(keywords, 600);
  const { profiles } = useProfileSearch({
    searchParams: debouncedKeywords && {
      keywords: [
        { text: debouncedKeywords, type: "general", modifier: "optional" },
      ],
    },
  });

  function addAccount(account: LinkedInProfile) {
    setSelectedAccounts([...selectedAccounts, account]);
    setKeywords("");
    inputRef.current.focus();
  }

  return (
    <div className="relative mb-4 lg:mb-2 lg:mr-auto">
      <SearchInput
        ref={inputRef}
        className="w-full lg:w-80"
        placeholder="Search accounts"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        onClear={() => setKeywords("")}
      />
      <div
        className={clsx(
          "absolute top-full mt-1 flex h-60 w-full flex-col gap-y-1 overflow-auto rounded-2xl bg-white py-2 shadow-md transition-opacity",
          profiles && keywords
            ? "opacity-100"
            : "pointer-events-none opacity-0",
        )}
      >
        {profiles && profiles.length ? (
          profiles
            .filter(
              (profile) =>
                !blacklist.find(({ id }) => profile.id === id) &&
                !selectedAccounts.find(({ id }) => profile.id === id) &&
                !accounts.find(({ id }) => profile.id === id),
            )
            .map((account) => (
              <button
                className="flex gap-x-2 px-4 py-2 hover:bg-black-50"
                type="button"
                key={account.id}
                onClick={() => addAccount(account)}
              >
                <ProfileImage src={account.picture_url} />
                <div className="flex flex-col gap-y-0.5">
                  <span className="line-clamp-1 text-left text-button-14">
                    {account.full_name}
                  </span>
                  <span className="line-clamp-1 text-left text-caption-12-regular text-black-400">
                    {account.occupation}
                  </span>
                </div>
              </button>
            ))
        ) : (
          <span className="m-auto text-button-14">No results found</span>
        )}
      </div>
    </div>
  );
}
