import React, { useState } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import { WorkExperience } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { getMonthName } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";

import ExpandableText from "../../../pages/leads/components/LeadDetails/ExpandableText";

function formatRange(from: string, to: string) {
  return [from, to].filter((value) => !!value).join(" - ");
}
function formatMonthAndYear(data: { month: number; year: number }) {
  const { month, year } = data;
  const monthName = getMonthName(month - 1);
  if (monthName) {
    return `${monthName} ${year}`;
  }
  return year.toString();
}
export default function CurrentExperience({
  currentExperience,
}: {
  currentExperience: WorkExperience[];
}) {
  const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(0);

  let content = (
    <span className="my-14 text-center text-body-14-bold">
      This lead does not have any current roles
    </span>
  );

  if (currentExperience.length) {
    const {
      company,
      end_date: endDate,
      start_date: startDate,
      description,
      location,
      job_title: jobTitle,
    } = currentExperience[selectedExperienceIndex];
    content = (
      <>
        <div className="flex items-center gap-x-2">
          {company && <ProfileImage src={company.logo_url} size="md" />}
          <div className="flex flex-col gap-y-px">
            <span className="text-button-12">{jobTitle}</span>
            {company && (
              <span className="text-caption-12-regular text-black-500">
                {company.name}
              </span>
            )}
            <div className="flex gap-x-2">
              {location && (
                <span className="text-caption-10-regular text-black-600">
                  {location.name}
                </span>
              )}
              <span className="text-caption-10-regular text-black-400">
                {formatRange(
                  formatMonthAndYear(startDate),
                  endDate ? formatMonthAndYear(endDate) : "Present",
                )}
              </span>
            </div>
          </div>
        </div>
        {description && <ExpandableText>{description}</ExpandableText>}
      </>
    );
  }

  return (
    <div className="flex flex-col gap-y-2.5 rounded-20 border border-purple-200 bg-white p-4">
      <div className="flex justify-between">
        <span className="mb-1 text-button-14 text-purple-500">
          Current position{currentExperience.length > 1 && "s"}
        </span>
        {currentExperience.length > 1 && (
          <div className="flex items-center gap-x-2">
            <Button
              disabled={selectedExperienceIndex === 0}
              variant="secondary-black"
              size="sm"
              intent="iconOnly"
              onClick={() =>
                setSelectedExperienceIndex(selectedExperienceIndex - 1)
              }
            >
              <CaretLeft />
            </Button>
            <span className="text-button-14">
              {selectedExperienceIndex + 1}/{currentExperience.length}
            </span>
            <Button
              disabled={
                selectedExperienceIndex === currentExperience.length - 1
              }
              variant="secondary-black"
              size="sm"
              intent="iconOnly"
              onClick={() =>
                setSelectedExperienceIndex(selectedExperienceIndex + 1)
              }
            >
              <CaretRight />
            </Button>
          </div>
        )}
      </div>
      {content}
    </div>
  );
}
