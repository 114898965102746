import React from "react";
import { Skull } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

import SubscriptionStatus from "./SubscriptionStatus";
import MiscOptions from "./MiscOptions";
import WorkspaceRole from "./WorkspaceRole";

export default function DebugPopup() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="support"
        >
          <Skull weight="fill" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col p-4" align="end">
        <MiscOptions />
        <SubscriptionStatus />
        <WorkspaceRole />
      </PopoverContent>
    </Popover>
  );
}
