import React from "react";

import WorkspaceDetails from "./WorkspaceDetails";
import WorkspacePlan from "./WorkspacePlan";

export default function Overview() {
  return (
    <div className="flex flex-col gap-4">
      <WorkspaceDetails />
      <WorkspacePlan />
    </div>
  );
}
