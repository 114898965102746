import { InvitationStatus } from "common/types";

import { InvoiceStatus } from "./types";

export const BILLING_STATUSES: Record<
  InvoiceStatus,
  { label: string; bgClassname: string }
> = {
  paid: { label: "Paid", bgClassname: "bg-green-400" },
  posted: { label: "Posted", bgClassname: "bg-black-400" },
  pending: { label: "Invite sent", bgClassname: "bg-black-400" },
  not_paid: { label: "Not paid", bgClassname: "bg-red-500" },
  payment_due: { label: "Payment due", bgClassname: "bg-yellow-400" },
  voided: { label: "Voided", bgClassname: "bg-black-400" },
} as const;

export const INVITATION_STATUSES: Record<
  InvitationStatus,
  {
    label: string;
  }
> = {
  pending: { label: "Invite sent" },
  accepted: { label: "Accepted" },
  rejected: { label: "Declined" },
} as const;
