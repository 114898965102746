import React from "react";
import clsx from "clsx";

import { ScheduleInterval } from "common/types";
import Switch from "common/components/ui/Switch";

import EditScheduleInterval from "./EditScheduleInterval";
import { getFormattedInterval } from "../../../utils";

interface Props {
  day: string;
  ranges: ScheduleInterval[];
  isDisabled: boolean;
  setRanges: (ranges: ScheduleInterval[]) => void;
  onApplyToAll: (ranges: ScheduleInterval[]) => void;
}

export default function MobileLayout(props: Props) {
  const { day, ranges, isDisabled, setRanges } = props;
  const isEnabled = !!ranges.length;

  return (
    <div className="flex items-center border-b border-black-200 py-4 last:border-0">
      <h6
        className={clsx(
          "w-20 text-body-14-bold capitalize",
          !isEnabled && "text-black-400 ",
        )}
      >
        {day}
      </h6>

      <div className="flex flex-1 flex-col items-center justify-center text-body-14-bold">
        {ranges.length ? (
          ranges.map((range) => {
            const { startValue, endValue } = getFormattedInterval(range);

            return (
              <span key={range.end} className="text-black-600">
                {startValue} - {endValue}
              </span>
            );
          })
        ) : (
          <span className="text-black-400">Not active</span>
        )}
      </div>

      <div className="ml-auto flex items-center gap-2">
        <Switch
          checked={isEnabled}
          disabled={isDisabled}
          onCheckedChange={() =>
            setRanges(ranges.length ? [] : [{ start: 0, end: 2 }])
          }
        />

        <div className="h-8 w-px bg-black-200" />

        <EditScheduleInterval {...props} />
      </div>
    </div>
  );
}
