import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { QuestionnaireCustomInputSchema } from "common/schemas";
import useQuestionnaire from "common/datahooks/useQuestionnaire";
import { QuestionnaireResult } from "common/types";
import Loader from "common/components/Loader";

import QuestionDisplay from "./QuestionDisplay";
import ProgressBar from "./ProgressBar";
import NavigationButtons from "./NavigationButtons";

export default function Questionnaire() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const {
    questionnaire,
    isLoadingQuestionnaire,
    submitQuestionnaireAnswers,
    isSubmittingQuestionnaire,
  } = useQuestionnaire();

  const {
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = useForm<{ customInput: string }>({
    resolver: valibotResolver(QuestionnaireCustomInputSchema),
    mode: "onTouched",
  });

  const initialResponses: QuestionnaireResult[] =
    questionnaire?.questions.map((q) => ({
      id: q.id,
      question: q.title,
      answers: [""],
    })) ?? [];

  const [responses, setResponses] =
    useState<QuestionnaireResult[]>(initialResponses);

  if (isLoadingQuestionnaire || !questionnaire) {
    return <Loader />;
  }

  const { questions } = questionnaire;

  const currentResponse = responses[currentQuestionIndex];
  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (answer: string, isCustomAnswer: boolean) => {
    setResponses((prevResponses) => {
      const newResponses = [...prevResponses];

      if (currentQuestion.type === "single") {
        newResponses[currentQuestionIndex] = {
          ...newResponses[currentQuestionIndex],
          answers: [answer],
          additional_info: isCustomAnswer ? "" : undefined,
        };
      } else {
        const currentAnswers =
          newResponses[currentQuestionIndex]?.answers || [];
        const updatedAnswers = currentAnswers.includes(answer)
          ? currentAnswers.filter((a) => a !== answer)
          : [...currentAnswers, answer];

        const hasCustomAnswer = updatedAnswers.some(
          (a) =>
            currentQuestion.answers.find((qa) => qa.title === a).isCustomAnswer,
        );

        newResponses[currentQuestionIndex] = {
          ...newResponses[currentQuestionIndex],
          answers: updatedAnswers,
          additional_info: hasCustomAnswer
            ? newResponses[currentQuestionIndex]?.additional_info || ""
            : undefined,
        };
      }

      return newResponses;
    });

    if (isCustomAnswer) {
      setValue("customInput", "");
    } else {
      clearErrors("customInput");
    }
  };

  const handleCustomInputChange = (value: string) => {
    setValue("customInput", value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setResponses((prevResponses) => {
      const newResponses = [...prevResponses];
      newResponses[currentQuestionIndex].additional_info = value;
      return newResponses;
    });
  };

  const handleNext = () => {
    const selectedAnswers = currentResponse.answers;

    const hasCustomAnswer = selectedAnswers.some((answer) => {
      const selectedAnswer = currentQuestion.answers.find(
        (a) => a.title === answer,
      );
      return selectedAnswer?.isCustomAnswer;
    });

    if (hasCustomAnswer) {
      const isValid = trigger("customInput");
      const additionalInfo = currentResponse.additional_info?.trim();

      if (!isValid || !additionalInfo) {
        return;
      }
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      clearErrors("customInput");
      setValue("customInput", "", { shouldDirty: false, shouldTouch: false });
    } else {
      const finalResponses = responses.map((response, index) => ({
        id: questions[index].id,
        question: response.question,
        answers: response.answers,
        additional_info: response.additional_info?.trim(),
      }));

      submitQuestionnaireAnswers({
        version: questionnaire.version,
        results: finalResponses,
      });
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const isNextDisabled = () => {
    const selectedAnswers = responses[currentQuestionIndex]?.answers;

    if (!selectedAnswers || selectedAnswers.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <form
      className="relative mb-auto flex w-full max-w-[620px] flex-col items-center justify-center gap-8 md:mb-0 md:h-[300px] md:justify-end"
      onSubmit={(e) => {
        e.preventDefault();
        handleNext();
      }}
    >
      <QuestionDisplay
        question={currentQuestion}
        currentResponse={currentResponse}
        handleAnswer={handleAnswer}
        handleCustomInputChange={handleCustomInputChange}
        errorMessage={errors.customInput}
      />

      <div className="fixed bottom-0 left-0 flex w-full flex-col gap-4 p-4 md:relative md:gap-8 md:p-0">
        <ProgressBar progress={currentQuestion.progress} />

        <NavigationButtons
          currentQuestionIndex={currentQuestionIndex}
          totalQuestions={questions.length}
          isSubmitting={isSubmittingQuestionnaire}
          isNextDisabled={isNextDisabled()}
          handleBack={handleBack}
        />
      </div>
    </form>
  );
}
