import React from "react";

import Slider from "common/components/ui/Slider";
import { Input } from "common/components/ui/Input";
import Skeleton from "common/components/ui/Skeleton";

const MIN_VALUE = 50;
const MAX_VALUE = 200;
const STEP = 10;

interface DynamicSliderProps {
  label: string;
  value: number;
  isLoading: boolean;
  setValue: (value: number) => void;
}

export default function DynamicSlider({
  label,
  value,
  isLoading,
  setValue,
}: DynamicSliderProps) {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-button-12 text-black-400">{label}</span>
      <div className="flex flex-row items-center gap-2">
        <span className="text-button-12">{MIN_VALUE}</span>

        {isLoading ? (
          <Skeleton className="h-2 w-full" />
        ) : (
          <Slider
            className="w-full"
            showTooltip
            value={[value]}
            onValueChange={(val) => {
              const newValue = Math.max(val[0], MIN_VALUE);
              setValue(newValue);
            }}
            max={MAX_VALUE}
            min={MIN_VALUE}
            step={STEP}
          />
        )}

        <span className="text-button-12">{MAX_VALUE}</span>

        {isLoading ? (
          <Skeleton className="ml-1.5 h-10 w-16 shrink-0" />
        ) : (
          <Input
            id={label.toLowerCase().replace(" ", "-")}
            className="ml-1.5 max-w-14 text-center"
            value={value}
            type="text"
            onChange={({ target: { value: inputValue } }) => {
              const numericValue = Number(inputValue);

              if (!Number.isNaN(numericValue)) {
                setValue(numericValue);
              }
            }}
            onBlur={({ target: { value: inputValue } }) => {
              const numericValue = Number(inputValue);

              if (
                !Number.isNaN(numericValue) &&
                numericValue >= MIN_VALUE &&
                numericValue <= MAX_VALUE
              ) {
                setValue(numericValue);
              } else {
                // Determine which value to set based on proximity
                const isCloserToMax =
                  Math.abs(numericValue - MAX_VALUE) <
                  Math.abs(numericValue - MIN_VALUE);
                setValue(isCloserToMax ? MAX_VALUE : MIN_VALUE);
              }
            }}
          />
        )}
      </div>

      <div className="h-px w-full bg-black-200" />
    </div>
  );
}
