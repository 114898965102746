import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import PaymentMethod, { type PaymentMethodProps } from "./PaymentMethod";

interface PaymentMethodModalProps extends PaymentMethodProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function PaymentMethodModal({
  isOpen,
  setIsOpen,
  onBack,
  paymentSource,
}: PaymentMethodModalProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content>
        <PaymentMethod paymentSource={paymentSource} onBack={onBack} />
      </Content>
    </Component>
  );
}
