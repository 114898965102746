import React, { useState } from "react";
import {
  Envelope,
  User,
  XLogo,
  LinkedinLogo,
  Plus,
  CaretDown,
  CaretUp,
} from "@phosphor-icons/react";
import { clsx } from "clsx";

import ProfileImage from "common/components/ui/ProfileImage";
import { DetailedLead, Lead } from "common/types";
import { Button } from "common/components/ui/Button";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import LeadLabels from "common/components/LeadLabels";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import MessageLeadDialog from "common/components/MessageLeadDialog";
import LeadSource from "common/components/LeadPreview/LeadSource";

import RenderIf from "../RenderIf";
import { ConversationParticipant } from "../../../pages/inbox/types";

const maxLabels = 6;
export default function LeadHeading({
  lead,
  isPreview,
  isLoading,
  canSendMessage,
}: {
  lead: ConversationParticipant | DetailedLead | Lead;
  isPreview: boolean;
  isLoading: boolean;
  canSendMessage: boolean;
}) {
  const { relativeNavigate } = useWorkspaceNavigate();
  const [isLabelsOpen, setIsLabelsOpen] = useState(false);
  const [isMessagingDialogOpen, setIsMessagingDialogOpen] = useState(false);
  const [isLabelsExpanded, setIsLabelsExpanded] = useState(false);

  const isLead = lead && (!("is_lead" in lead) || lead.is_lead);

  const handleSeeFullProfile = () => {
    relativeNavigate(`leads/${lead.id}`, { state: { lead } });
  };

  return (
    <div className="flex flex-col gap-4 rounded-20 bg-white p-4">
      <div className="flex">
        {isLoading ? (
          <Skeleton className="mr-2 size-12 rounded-2xl" />
        ) : (
          <ProfileImage size="lg" className="mr-2" src={lead.picture_url} />
        )}
        <div className="flex flex-col gap-y-0.5">
          {isLoading ? (
            <Skeleton className="h-5 w-24" />
          ) : (
            <span className="text-button-16">{lead.full_name}</span>
          )}
          {isLoading ? (
            <Skeleton className="h-4 w-40" />
          ) : (
            <span className="line-clamp-2 text-caption-12-regular text-black-600">
              {lead.occupation}
            </span>
          )}

          {/*
           This condition is requested by backend, so we need to check if it's an empty array,
           because they are having issues with the API and it's returning undefined or empty array
          */}
          <RenderIf condition={!!lead.origins.length}>
            <div className="flex shrink-0 items-center gap-x-1 gap-y-0.5">
              {isLoading ? (
                <Skeleton className="h-3 w-36" />
              ) : (
                <LeadSource origins={lead.origins} />
              )}
            </div>
          </RenderIf>
        </div>
      </div>
      <div className="flex gap-2 border-b border-b-black-200 pb-3">
        {isLoading ? (
          <Skeleton className="h-6 w-36 rounded-full" />
        ) : (
          <LinkedInAccountsTag accountIds={lead.lead_of} />
        )}
      </div>

      {lead && isLead && (
        <>
          <LeadLabels
            labels={lead.labels}
            profileId={lead.id}
            isOpen={isLabelsOpen}
            onClose={() => setIsLabelsOpen(false)}
          />
          <MessageLeadDialog
            lead={lead}
            isOpen={isMessagingDialogOpen}
            onClose={() => setIsMessagingDialogOpen(false)}
          />
          <div className="flex flex-wrap items-center gap-2">
            {lead.labels.length ? (
              <>
                {(isLabelsExpanded
                  ? lead.labels
                  : lead.labels.slice(0, maxLabels)
                ).map(({ id: labelId, name, color }) => (
                  <Tag key={labelId} variant={color}>
                    {name}
                  </Tag>
                ))}
                {lead.labels.length > maxLabels && (
                  <Tag
                    variant="quaternary"
                    rightIcon={isLabelsExpanded ? <CaretUp /> : <CaretDown />}
                    asChild
                  >
                    <button
                      type="button"
                      onClick={() => setIsLabelsExpanded(!isLabelsExpanded)}
                    >
                      {isLabelsExpanded
                        ? "Hide"
                        : `+ ${lead.labels.length - maxLabels} more`}
                    </button>
                  </Tag>
                )}
                <Button
                  variant="tertiary-black"
                  size="xs"
                  intent="iconOnly"
                  className="ml-auto"
                  onClick={() => setIsLabelsOpen(true)}
                >
                  <Plus />
                </Button>
              </>
            ) : (
              <Tag size="lg" variant="tertiary" asChild leftIcon={<Plus />}>
                <button type="button" onClick={() => setIsLabelsOpen(true)}>
                  Add tag
                </button>
              </Tag>
            )}
          </div>
        </>
      )}
      <RenderIf condition={isLead}>
        <div className="flex justify-between gap-2">
          <div className="flex grow flex-col gap-2 lg:flex-row">
            {isPreview &&
              (isLoading ? (
                <Skeleton
                  className={clsx(
                    "h-10 min-w-52 rounded-full",
                    isPreview && "flex-1",
                  )}
                />
              ) : (
                <Button
                  variant="secondary-purple"
                  className="flex-1"
                  leftIcon={<User />}
                  onClick={handleSeeFullProfile}
                >
                  See full profile
                </Button>
              ))}

            <RenderIf condition={canSendMessage}>
              {isLoading ? (
                <Skeleton
                  className={clsx(
                    "h-10  min-w-52 rounded-full",
                    isPreview && "flex-1",
                  )}
                />
              ) : (
                <Button
                  variant="primary-black"
                  className={clsx(isPreview && "flex-1")}
                  leftIcon={<Envelope />}
                  onClick={
                    lead.lead_of.length > 1
                      ? () => setIsMessagingDialogOpen(true)
                      : () => relativeNavigate("inbox", { state: { lead } })
                  }
                >
                  Send message
                </Button>
              )}
            </RenderIf>
          </div>
          <div className={clsx("flex gap-x-2", isPreview && "hidden")}>
            {isLoading ? (
              <Skeleton className="size-10 rounded-full" />
            ) : (
              <Button variant="quaternary-black" intent="iconOnly" asChild>
                <a
                  href={`https://linkedin.com/in/${lead.public_identifier}`}
                  target="_blank"
                  aria-label="go to linkedin page"
                  rel="noreferrer"
                >
                  <LinkedinLogo weight="fill" />
                </a>
              </Button>
            )}
            {lead && lead.twitter_handles.length ? (
              <Button asChild variant="quaternary-black" intent="iconOnly">
                <a
                  href={`https://twitter.com/${lead.twitter_handles[0]}`}
                  aria-label="go to twitter page"
                  target="_blank"
                  rel="noreferrer"
                >
                  <XLogo />
                </a>
              </Button>
            ) : null}
          </div>
        </div>
      </RenderIf>
    </div>
  );
}
