import React, { useState } from "react";
import { EnvelopeSimple } from "@phosphor-icons/react";

import useCustomer from "common/datahooks/useCustomer";

import ProfileSettingsItem from "../ProfileSettingsItem";
import UpdateEmail from "./UpdateEmail";

export default function Email() {
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const { customerInfo } = useCustomer();

  return (
    <ProfileSettingsItem
      label="Email"
      isEditing={isEditingEmail}
      onEditClick={() => {
        setIsEditingEmail(true);
      }}
    >
      {!isEditingEmail ? (
        <div className="flex flex-row items-center gap-2 text-black-400">
          <EnvelopeSimple size={20} />

          <span className="max-w-[256px] overflow-hidden truncate text-body-14-bold ">
            {customerInfo.email}
          </span>
        </div>
      ) : (
        <UpdateEmail onClose={() => setIsEditingEmail(false)} />
      )}
    </ProfileSettingsItem>
  );
}
