import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import useThisWasMeToken from "common/datahooks/useThisWasMeToken";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useWorkspaceCacheUpdate from "common/datahooks/useWorkspaceCacheUpdate";
import { WorkspaceAccount } from "common/types";

const wsBaseUrl = import.meta.env.VITE_WS_URL;

export default function useThisWasMeChallenge(
  email: string,
  onSuccess: () => void,
  onAppChallengeFail: () => void,
) {
  const [socket, setSocket] = useState<WebSocket>(null);
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { thisWasMeToken } = useThisWasMeToken(email);
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const websocketUrl = `${wsBaseUrl}workspaces/${workspaceId}/accounts/challenge/${email}/status?authorization=${thisWasMeToken}`;

  function connect() {
    const websocket = new WebSocket(websocketUrl);

    websocket.onmessage = (event) => {
      const {
        challenge_status: challengeStatus,
        status,
        error,
        profile,
      } = JSON.parse(event.data);

      if (challengeStatus === "SOLVED") {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          const { accounts } = draftWorkspace;

          // TODO: (id.toString()) Temporary fix, until backend returns ID as a string instead of number

          // Check if the account has already been connected (Reconnect case)
          const account = accounts.find(
            ({ id }) => id === profile.id.toString(),
          );

          if (account) {
            account.state = "LoggedIn";
          } else {
            const newAccount: WorkspaceAccount = {
              ...profile,
              id: profile.id.toString(),
              state: "LoggedIn",
              license: null,
            };

            accounts.push(newAccount);
          }
        });
        toast.success(`Account added - ${profile.full_name}`);

        onSuccess();
      }

      if (status === "fail") {
        Sentry.setExtra("error", error);
        Sentry.setExtra("data_json", JSON.stringify(event.data));
        Sentry.captureMessage("This was me challenge - WebSocket Error");
        onAppChallengeFail();
      }
    };

    setSocket(websocket);
  }

  useEffect(() => {
    if (thisWasMeToken) {
      connect();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [thisWasMeToken]);
}
