import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";

import { TimezoneOption, timezones } from "common/constants/timezones";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import SearchInput from "common/components/SearchInput";

interface TimezoneSelectProps {
  selectedTimezone: TimezoneOption;
  onSelect: (newTimezone: TimezoneOption) => void;
  isDisabled: boolean;
}

export default function TimezoneSelect({
  selectedTimezone,
  onSelect,
  isDisabled,
}: TimezoneSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const lowercasedInput = inputValue.toLowerCase();
  const filteredOptions = timezones.filter(({ label }) =>
    label.toLowerCase().includes(lowercasedInput),
  );

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className="group ml-auto flex h-10 w-full items-center justify-between rounded-xl bg-whiteGray px-3 text-button-14 data-[state=open]:text-black-950 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50 md:w-[380px]"
        disabled={isDisabled}
      >
        <span className="line-clamp-1 text-left">{selectedTimezone.label}</span>
        <CaretDown
          size={20}
          className="ml-2 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-[--radix-popper-anchor-width] pb-2"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <SearchInput
          type="text"
          variant="sm"
          placeholder="Search timezones"
          value={inputValue}
          className="p-2"
          onChange={(e) => setInputValue(e.target.value)}
          onClear={() => setInputValue("")}
        />

        {filteredOptions.length ? (
          <div className="h-40 overflow-y-auto scrollbar-thin md:h-56">
            {filteredOptions.map((timezone) => (
              <button
                key={timezone.label}
                onClick={() => {
                  onSelect(timezone);
                  setIsOpen(false);
                }}
                type="button"
                className="w-full px-4 py-2 text-left text-button-14 transition-colors hover:bg-black-50"
              >
                {timezone.label}
              </button>
            ))}
          </div>
        ) : (
          <p className="mx-4 break-words py-8 text-center text-black-500">
            No results for <span className="text-black-950">{inputValue}</span>
          </p>
        )}
      </PopoverContent>
    </Popover>
  );
}
