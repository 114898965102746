import React from "react";
import { PencilSimple } from "@phosphor-icons/react";
import clsx from "clsx";

import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";
import useInboxDraftStore from "common/hooks/useInboxDraftStore";

import { Conversation } from "../../types";
import UnreadCount from "./UnreadCount";

interface MessageDetailsProps {
  conversation: Conversation;
  isMine: boolean;
}

export default function MessageDetails({
  conversation,
  isMine,
}: MessageDetailsProps) {
  const {
    last_message: lastMessage,
    unread_count: unreadCount,
    participants,
    conversation_urn: conversationUrn,
    owner: accountId,
  } = conversation;

  const {
    deleted_at: deletedAt,
    attachments,
    type,
    sender,
    body,
  } = lastMessage;

  const draftKey = `${conversationUrn}:${accountId}`;

  const { drafts } = useInboxDraftStore();
  const draftMessage = drafts[draftKey];
  const isGroupConversation = participants.length > 1;

  let message = isMine ? (
    <>
      <span className="text-body-14-bold">You: </span>
      {body}
    </>
  ) : (
    body
  );

  if (deletedAt) {
    message = "This message has been deleted";
  }

  if (attachments) {
    message = attachments[0].name;
  }

  if (draftMessage) {
    return (
      <div className="flex flex-row items-center gap-1 text-black-400">
        <PencilSimple />

        <span className="line-clamp-1 break-all text-start">
          Draft: {draftMessage}
        </span>
      </div>
    );
  }

  if (type === "INMAIL") {
    return (
      <div className="flex flex-row items-center gap-2">
        <Tag variant="secondary">InMail</Tag>

        <span
          className={clsx([
            "line-clamp-1 text-start",
            unreadCount && "max-w-96 text-body-14-bold",
          ])}
        >
          {message}
        </span>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-row justify-between">
      <div className="flex flex-row items-center gap-1 text-start leading-normal">
        {/* Render group name if applicable */}
        <RenderIf condition={isGroupConversation && !isMine}>
          <span className="text-nowrap text-body-14-bold">{sender.full_name}:</span>
        </RenderIf>

        {/* Message content */}
        <span
          className={clsx([
            "line-clamp-1 w-full",
            unreadCount ? "max-w-[40ch] text-body-14-bold" : "max-w-[45ch]",
            deletedAt && "pr-1 text-body-14-regular italic text-black-400",
          ])}
        >
          {message}
        </span>
      </div>

      {/* Unread count indicator */}
      <UnreadCount unreadCount={unreadCount} />
    </div>
  );
}
