import React from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";
import { UserRole } from "common/types";
import RestrictedComponent from "common/components/RestrictedComponent";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import Members from "./Members";
import Blacklist from "./Blacklist";
import Overview from "./Overview";
import Invoices from "./Invoices";

interface WorkspaceTabs {
  id: string;
  label: string;
  component: JSX.Element;
  hiddenForRoles: UserRole[];
}

export default function WorkspaceSettings() {
  const { role } = useSelectedWorkspaceContext();

  // Define the list of tabs
  const tabs: WorkspaceTabs[] = [
    {
      id: "overview",
      label: "Overview",
      component: <Overview />,
      hiddenForRoles: [],
    },
    {
      id: "invoices",
      label: "Invoices",
      component: <Invoices />,
      hiddenForRoles: ["member"],
    },
    {
      id: "members",
      label: "Members",
      component: <Members />,
      hiddenForRoles: [],
    },
    {
      id: "blacklist",
      label: "Blacklist",
      component: <Blacklist />,
      hiddenForRoles: [],
    },
  ];

  // Find the first category that does not include the user's role in hiddenForRoles array
  const defaultCategory = tabs.find(
    (tab) => !tab.hiddenForRoles.includes(role),
  );

  // Initialize active tab based on queryParams
  const [activeTab, setActiveTab] = useQueryParam(
    "activeTab",
    withDefault(StringParam, defaultCategory.id),
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );

  return (
    <Tabs
      className="mt-5 flex grow flex-col lg:mt-0"
      defaultValue={activeTab}
      onValueChange={(selectedTab) => setActiveTab(selectedTab)}
    >
      <TabsList
        className="mb-4 justify-start pb-4 md:gap-2"
        size="md"
        variant="default"
      >
        {tabs.map(({ label, id, hiddenForRoles }) => (
          <RestrictedComponent
            disabledForRoles={[]}
            key={id}
            hiddenForRoles={hiddenForRoles}
          >
            <TabsTrigger value={id}>{label}</TabsTrigger>
          </RestrictedComponent>
        ))}
      </TabsList>

      {tabs.map(({ label, id, component, hiddenForRoles }) => (
        <RestrictedComponent
          disabledForRoles={[]}
          key={label}
          hiddenForRoles={hiddenForRoles}
        >
          <TabsContent
            value={id}
            className="flex flex-col data-[state=active]:grow"
          >
            {component}
          </TabsContent>
        </RestrictedComponent>
      ))}
    </Tabs>
  );
}
