import React, { useState } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import Skeleton from "common/components/ui/Skeleton";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { cardIcons, paymentMethods } from "common/constants";
import { PaymentMethod } from "common/components/PaymentMethod";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function SelectedPaymentMethod() {
  const { subscription } = useSelectedWorkspaceContext();
  const { primaryPaymentSource, isLoadingPrimaryPaymentSource } =
    usePrimaryPaymentSource();
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  let paymentMethodComponent;
  if (isLoadingPrimaryPaymentSource) {
    paymentMethodComponent = (
      <div className="flex w-full flex-row items-center gap-10 md:w-auto">
        <div className="flex flex-1 flex-col">
          <Skeleton className="h-4 w-[120px]" />
          <Skeleton className="mt-1 h-4 w-[165px]" />
        </div>

        <Skeleton className="size-12 rounded-full" />
      </div>
    );
  } else if (primaryPaymentSource) {
    const Icon =
      primaryPaymentSource.type === "card"
        ? cardIcons[primaryPaymentSource.card.brand]
        : paymentMethods[primaryPaymentSource.type].icon;

    paymentMethodComponent = (
      <div className="flex w-full flex-row items-center justify-between gap-4 md:w-auto md:justify-normal">
        <div className="flex flex-col">
          <span className="text-black-600">Payment method</span>

          <div className="flex flex-row items-center gap-2">
            <Icon />

            <span className="align-center flex flex-row text-nowrap text-body-16-bold">
              {primaryPaymentSource.type === "card"
                ? `Card ending with ${primaryPaymentSource.card.last4}`
                : primaryPaymentSource.paypal.email}
            </span>
          </div>
        </div>

        <Button
          onClick={() => setIsDialogOpen(true)}
          variant="secondary-black"
          intent="iconOnly"
          size="lg"
        >
          <PencilSimpleLine />
        </Button>
      </div>
    );
  } else {
    paymentMethodComponent = (
      <div className="flex w-full flex-row items-center justify-between gap-10 md:w-fit md:justify-normal">
        <div className="flex flex-col">
          <span className="text-black-600">Payment method</span>

          <span className="text-nowrap text-body-16-bold">
            No credit card added
          </span>
        </div>

        <Button
          variant="secondary-black"
          size="lg"
          intent="iconOnly"
          onClick={() => setIsDialogOpen(true)}
          disabled={!subscription}
        >
          <PencilSimpleLine size={20} />
        </Button>
      </div>
    );
  }

  return (
    <>
      {/* Payment Method */}
      {paymentMethodComponent}

      <Component open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Content>
          <PaymentMethod
            paymentSource={primaryPaymentSource}
            onBack={() => setIsDialogOpen(false)}
          />
        </Content>
      </Component>
    </>
  );
}
