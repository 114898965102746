import React, { useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import { UserRole } from "common/types";

import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import RoleOption from "./RoleOption";

interface ChangeRoleDialogProps {
  isOpen: boolean;
  currentRole: UserRole;
  memberId: string;
  onOpenChange: (isOpen: boolean) => void;
}

export default function ChangeRoleDialog({
  isOpen,
  currentRole,
  memberId,
  onOpenChange,
}: ChangeRoleDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [selectedRole, setSelectedRole] = useState(currentRole);

  const { changeMemberRole, isChangingRole } = useWorkspaceMembers({
    disableQuery: true,
  });

  const Container = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  const handleSave = () => {
    changeMemberRole({ memberId, role: selectedRole }).then(() => {
      onOpenChange(false);
    });
  };

  return (
    <Container open={isOpen} onOpenChange={onOpenChange}>
      <Content>
        <h3 className="mb-4 text-center text-headline-2xl-bold">
          Change this user&apos;s role
        </h3>

        <div className="flex flex-col gap-4 py-4">
          <RoleOption
            option="admin"
            selectedRole={selectedRole}
            onRoleChange={setSelectedRole}
            label="Admin"
            description="Admins have full access to Campaigns, Leads, Inbox and Templates. They can manage seats, members and workspace settings, except billing"
          />

          <RoleOption
            option="member"
            selectedRole={selectedRole}
            onRoleChange={setSelectedRole}
            label="Member"
            description="Members have view-only access to campaigns and full access to Inbox and Leads. They cannot manage seats, members, or other workspace settings"
          />
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <Close asChild>
            <Button
              size="lg"
              disabled={isChangingRole}
              variant="secondary-black"
            >
              Cancel
            </Button>
          </Close>

          <Button size="lg" isLoading={isChangingRole} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Content>
    </Container>
  );
}
