import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { get } from "../helpers/HTTP";

interface EstimateRenewal {
  nextBillingAt: number;
  amountDue: number;
}

async function getEstimateRenewal(
  workspaceId: string,
): Promise<EstimateRenewal> {
  const { estimate } =
    await get(`workspaces/${workspaceId}/subscriptions/estimates/renew
`);
  const {
    subscription_estimate: subscriptionEstimate,
    invoice_estimate: invoiceEstimate,
  } = estimate;

  return {
    nextBillingAt: subscriptionEstimate.next_billing_at,
    amountDue: invoiceEstimate.amount_due,
  };
}

export default function useEstimateRenewal() {
  const { id: workspaceId, subscription } = useSelectedWorkspaceContext();

  const { data: estimateRenewal, isLoading: isLoadingEstimateRenewal } =
    useQuery({
      queryKey: ["estimateRenewal", workspaceId],
      queryFn: () => getEstimateRenewal(workspaceId),
      refetchOnMount: false,
      enabled:
        !!subscription &&
        subscription.status !== "cancelled" &&
        subscription.status !== "non_renewing",
    });

  return {
    estimateRenewal,
    isLoadingEstimateRenewal,
  };
}
