import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { get } from "../helpers/HTTP";
import { CreatedTemplate } from "../types";
import getQueryKeys from "./getQueryKeys";

async function getTemplates(workspaceId: string) {
  return (await get(`workspaces/${workspaceId}/templates`)).templates;
}

export default function useTemplates() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { templatesKeys } = getQueryKeys(workspaceId);

  const {
    isLoading: isLoadingAllTemplates,
    data: allTemplates,
    error: templatesError,
    refetch: refetchTemplates,
  } = useQuery<CreatedTemplate[]>({
    queryKey: templatesKeys.list,
    queryFn: () => getTemplates(workspaceId),
  });

  return {
    allTemplates,
    isLoadingAllTemplates,
    templatesError,
    refetchTemplates,
  };
}
