import { create } from "zustand";
import { persist } from "zustand/middleware";

interface NavStore {
  isNavExpanded: boolean;
  setIsNavExpanded: (isNavExpanded: boolean) => void;
}

export default create<NavStore>()(
  persist(
    (set) => ({
      isNavExpanded: false,
      setIsNavExpanded: (isNavExpanded) => set({ isNavExpanded }),
    }),
    {
      name: "isNavExpanded",
    },
  ),
);
