import { get } from "./HTTP";

export async function redirectToAuth(useSyncer: boolean) {
  const host = `${window.location.protocol}//${window.location.host}`;

  let loginUrl = `oauth/login?url=${encodeURIComponent(host)}`;

  if (useSyncer) {
    const data = await window.syncer.get();
    loginUrl += `&data=${encodeURIComponent(JSON.stringify(data))}`;
  }

  const urlParams = new URLSearchParams(window.location.search);

  const inviteToken = urlParams.get("invite_token");
  if (inviteToken) {
    loginUrl += `&invite_token=${inviteToken}`;
  }

  const appSumoCode = urlParams.get("code");
  if (appSumoCode) {
    loginUrl += `&code=${appSumoCode}&signup=true`;
  }

  const { redirect_url: redirectUrl } = await get(loginUrl);

  if (appSumoCode) {
    window.location.href = redirectUrl.replace("/auth?", "/registrations?");
  } else {
    window.location.href = redirectUrl;
  }
}

export async function logout() {
  await get("oauth/logout").then(() => {
    localStorage.removeItem("auth");
    redirectToAuth(false);
  });
  return Promise.resolve();
}
