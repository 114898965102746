import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";

interface MessageDelayProps {
  // if this prop provided, the component is in edit mode
  onDelayChange?: (newDelay: number) => void;
  delay: number;
  messageIndex: number;
  isDisabled?: boolean;
}

export default function MessageDelay({
  onDelayChange = null,
  delay,
  messageIndex,
  isDisabled = false,
}: MessageDelayProps) {
  const isDesktop = useTwBreakpoint("lg");
  const { canEditFlow } = useCampaignFlowContext();

  const days = Math.floor(delay / 24);
  const hours = delay - days * 24;

  function onChange(newValues: { days: number; hours: number }) {
    let newDelay = 0;
    newDelay += newValues.days * 24;
    newDelay += newValues.hours;
    onDelayChange(newDelay);
  }

  const isSelectDisabled = isDisabled || !canEditFlow;

  const Component = isDesktop ? DesktopLayout : MobileLayout;
  return (
    <Component
      isEditing={!!onDelayChange}
      values={{ days, hours }}
      onChange={onChange}
      messageIndex={messageIndex}
      isDisabled={isSelectDisabled}
    />
  );
}
