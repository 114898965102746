import React, { ComponentPropsWithoutRef, forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const avatarVariants = cva(
  "flex shrink-0 items-center justify-center rounded-full text-white",
  {
    variants: {
      size: {
        xs: "size-6 text-caption-10-semibold",
        sm: "size-8 text-caption-12-bold",
        md: "size-10 text-body-14-bold",
        lg: "size-12 text-body-16-bold",
      },
      variant: {
        primary: "bg-purple-500 text-white",
        secondary: "bg-purple-100 text-purple-600",
        quaternary: "bg-black-400 text-white",
      },
    },
    defaultVariants: {
      size: "md",
      variant: "primary",
    },
  },
);

type AvatarProps = ComponentPropsWithoutRef<"span"> &
  VariantProps<typeof avatarVariants>;

const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
  ({ size, className, variant, ...props }, ref) => (
    <span
      ref={ref}
      className={cn(
        avatarVariants({ size, variant, className }),
        "leading-none",
      )}
      {...props}
    />
  ),
);
Avatar.displayName = "Avatar";
export default Avatar;
