import React from "react";

import useAppStore from "common/hooks/useAppStore";
import { typedObjectEntries } from "common/helpers/utils";
import { userRoles } from "common/constants";
import Label from "common/components/ui/Label";
import RadioButton from "common/components/ui/RadioButton";

export default function WorkspaceRole() {
  const workspaceRole = useAppStore(
    (state) => state.debugOptions.workspaceRole,
  );
  return (
    <>
      <span className="my-2 text-caption-12-regular text-black-400">
        Workspace Role
      </span>
      <div className="flex flex-wrap gap-2">
        <div className="flex gap-x-2">
          <RadioButton
            id="defaultRole"
            onChange={() =>
              useAppStore.getState().setDebugOptions({ workspaceRole: null })
            }
            name="workspaceRole"
            checked={!workspaceRole}
          />
          <Label htmlFor="defaultRole">Default</Label>
        </div>
        {typedObjectEntries(userRoles).map(([key, { label }]) => (
          <div className="flex gap-x-2" key={key}>
            <RadioButton
              id={key}
              onChange={() =>
                useAppStore.getState().setDebugOptions({ workspaceRole: key })
              }
              name="workspaceRole"
              checked={workspaceRole === key}
            />
            <Label htmlFor={key}>{label}</Label>
          </div>
        ))}
      </div>
    </>
  );
}
