import React, { Fragment, useState } from "react";
import clsx from "clsx";

import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import { CampaignType } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { LinkedInLogin } from "common/components/LinkedInLogin";
import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import CampaignTypes from "./CampaignTypes";
import CampaignSettings from "./CampaignSettings";

import ConnectAccountImage from "assets/images/empty-placeholders/connect-account.png";

interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateCampaignModal({
  isOpen,
  onClose,
}: CreateCampaignModalProps) {
  const { accounts, role } = useSelectedWorkspaceContext();
  const isTabletOrDesktop = useTwBreakpoint("md");

  const initialStepIndex = accounts.length ? 2 : 0;
  const initialType: CampaignType = "search";

  const [stepIndex, setStepIndex] = useState(initialStepIndex);
  const [campaignType, setCampaignType] = useState<CampaignType>(initialType);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  function resetState() {
    setStepIndex(initialStepIndex);
    setCampaignType(initialType);
  }

  const steps = [
    <Fragment key={0}>
      <img
        src={ConnectAccountImage}
        width={364}
        height={254}
        alt=""
        className="mx-auto mb-4"
      />
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Connect a LinkedIn Account to create a campaign
      </h3>
      <p className="mb-8 text-center text-body-14-regular text-black-500">
        Campaigns are run from individual LinkedIn accounts. Add a LinkedIn
        account to create a campaign
      </p>
      <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          className="flex-1"
          size="lg"
          variant="primary-black"
          onClick={() => setStepIndex(1)}
        >
          Add account
        </Button>
        <Close asChild>
          <Button className="flex-1" size="lg" variant="secondary-black">
            Cancel
          </Button>
        </Close>
      </div>
    </Fragment>,
    <LinkedInLogin
      key={1}
      goBack={() => setStepIndex(0)}
      onSuccess={() => {
        if (role === "member") {
          onClose();
        } else {
          setStepIndex(2);
        }
      }}
    />,
    <CampaignTypes
      key={2}
      type={campaignType}
      setType={setCampaignType}
      goToNextStep={() => setStepIndex(3)}
    />,
    <CampaignSettings
      key={3}
      type={campaignType}
      goBack={() => setStepIndex(2)}
      resetState={resetState}
    />,
  ];

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
      onAfterClose={resetState}
    >
      <Content
        className={clsx("transition-none", stepIndex > 1 && "md:w-[650px]")}
      >
        {steps[stepIndex]}
      </Content>
    </Component>
  );
}
