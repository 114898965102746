import React from "react";
import clsx from "clsx";

import RenderIf from "common/components/RenderIf";
import Skeleton from "common/components/ui/Skeleton";

import useUrlPreview from "../../../datahooks/useUrlPreview";
import { MessageLink } from "../../../types";

export default function LinkPreview({
  link,
  isMine,
}: {
  link: MessageLink;
  isMine: boolean;
}) {
  const { preview, isLoadingPreview } = useUrlPreview(link);

  function getContent() {
    if (isLoadingPreview) {
      return (
        <div className="flex flex-col overflow-hidden rounded-2xl border border-black-200 bg-white">
          <Skeleton className="h-52 w-96 rounded-none" />

          <div className="flex flex-col gap-2 p-3">
            <Skeleton className="h-3 w-full" />
            <Skeleton className="h-3 w-10/12" />
            <Skeleton className="h-3 w-8/12" />
          </div>
        </div>
      );
    }

    const { title, description, images, url: redirectUrl } = preview;

    function redirectLinkUrl(url: string): void {
      window.open(url, "_blank");
    }

    return (
      <button
        type="button"
        className="overflow-hidden rounded-2xl border border-black-200 bg-black-50"
        onClick={() => redirectLinkUrl(redirectUrl)}
      >
        <img
          src={images[0]}
          className="h-52 w-full border-b border-b-black-200 object-cover"
          alt={`${title} preview`}
        />

        <div className="flex flex-col gap-0.5 p-4 text-start">
          <span className="text-body-14-bold">{title}</span>

          <RenderIf condition={!!description}>
            <p className="line-clamp-5 text-caption-12-regular">
              {description}
            </p>
          </RenderIf>
        </div>
      </button>
    );
  }

  if (!preview && !isLoadingPreview) {
    return null;
  }

  return (
    <div
      className={clsx([
        "mt-2 max-w-72 md:max-w-96",
        isMine ? "ml-auto" : "mr-auto",
      ])}
    >
      {getContent()}
    </div>
  );
}
