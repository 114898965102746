import React, { useState } from "react";
import { ArrowCounterClockwise } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useWorkspaces from "common/datahooks/useWorkspaces";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import Alert from "common/components/ui/Alert";
import { DialogClose } from "common/components/ui/Dialog";
import { DrawerClose } from "common/components/ui/Drawer";
import { Promotion } from "common/types";
import usePromotionsMutations from "common/datahooks/usePromotionsMutations";

import { AssignSeatsSchema, AssignSeatsSchemaType } from "../../schema";
import WorkspaceSelect from "./WorkspaceSelect";

export default function AssignSeats({
  promotion,
  onSuccess,
  onReset,
}: {
  promotion: Promotion;
  onSuccess: () => void;
  onReset: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>();
  const { workspaces } = useWorkspaces();
  const { addLifetimeLicenses, isAddingLifetimeLicenses } =
    usePromotionsMutations();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      seats: 1,
    },
    resolver: valibotResolver(AssignSeatsSchema),
  });

  const {
    unassigned_license_count: unassignedLicenseCount,
    license_count: licenseCount,
    type,
  } = promotion;

  function onAssign(data: AssignSeatsSchemaType) {
    if (data.seats > unassignedLicenseCount) {
      setError("seats", { message: "You don't have that many licenses" });
    } else {
      addLifetimeLicenses({
        count: data.seats,
        source: promotion.type,
        workspaceId: selectedWorkspaceId,
      }).then(onSuccess);
    }
  }

  const selectedWorkspace = workspaces.find(
    ({ id }) => id === selectedWorkspaceId,
  );
  const canAssign =
    !!unassignedLicenseCount &&
    (type !== "appsumo" || promotion.unassigned_license_count > 0);

  const assignedSeatsCount = selectedWorkspace
    ? selectedWorkspace.licenses.filter(
        ({ source }) => source === promotion.type,
      ).length
    : 0;

  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <>
      <h3 className="text-center text-headline-2xl-bold md:mt-4">
        Manage your lifetime seats
      </h3>
      {type === "appsumo" && (
        <p className="mt-2 text-center text-black-500">
          Workspaces remaining: {promotion.unassigned_workspaces}
        </p>
      )}
      <Button
        className="my-6 ml-auto lg:mb-4 lg:mt-8"
        variant="tertiary-black"
        leftIcon={<ArrowCounterClockwise />}
        onClick={onReset}
      >
        Reset seats and workspaces
      </Button>
      <Label className="mb-1" htmlFor="workspace-select">
        Workspace
      </Label>
      <WorkspaceSelect
        selectedWorkspaceId={selectedWorkspaceId}
        setSelectedWorkspaceId={setSelectedWorkspaceId}
      />
      <div className="mb-6 flex justify-between py-4 lg:mb-4">
        <span className="text-black-700">Seats assigned to this workspace</span>
        <span className="text-body-14-bold text-black-700">
          {assignedSeatsCount}
        </span>
      </div>
      <Label className="mb-1" htmlFor="seats-count">
        New seats to assign
      </Label>
      <Input
        id="seats-count"
        type="number"
        min={1}
        {...register("seats", { valueAsNumber: true })}
        error={errors.seats?.message}
      />
      <div className="flex justify-between py-4 pb-6 lg:pb-4">
        <span className="text-black-700">Total seats remaining</span>
        <span className="text-body-14-bold text-black-700">
          {unassignedLicenseCount}/{licenseCount}
        </span>
      </div>
      <Alert
        variant="warning"
        title={canAssign ? "Assign carefully" : "No seats left"}
        description={
          canAssign
            ? "Assigning seats is a one-way street. If you want to re-allocate seats, you will have to reset and start from scratch"
            : "You have used up all of your lifetime seats. You can upgrade your package if your promotion is ongoing, or re-assign seats by resetting"
        }
      />

      <div className="mt-10 flex flex-col gap-2 md:mt-8 md:flex-row-reverse md:gap-4">
        <Button
          size="lg"
          className="flex-1"
          disabled={!canAssign || !selectedWorkspaceId}
          onClick={handleSubmit(onAssign)}
          isLoading={isAddingLifetimeLicenses}
        >
          Assign
        </Button>
        <Close asChild>
          <Button
            size="lg"
            className="flex-1"
            variant="secondary-black"
            disabled={isAddingLifetimeLicenses}
          >
            Cancel
          </Button>
        </Close>
      </div>
    </>
  );
}
