import React, { ComponentProps, ForwardedRef, forwardRef } from "react";
import { CircleNotch } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

interface LoaderProps extends ComponentProps<"div"> {
  iconSize?: number;
}

function Loader(
  { iconSize = 80, className, ...props }: LoaderProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cn("flex grow items-center justify-center", className)}
      {...props}
    >
      <CircleNotch size={iconSize} className="animate-spin fill-black-300" />
    </div>
  );
}
Loader.displayName = "Loader";

export default forwardRef(Loader);
