import React from "react";
import { Warning } from "@phosphor-icons/react";
import clsx from "clsx";

import Skeleton from "common/components/ui/Skeleton";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";

interface PreviewContentProps {
  message: string;
  subject: string;
  isLoading: boolean;
  onTryAgain?: () => void;
}

const defaultMessage = "Your message will show up here...";
const defaultSubject = "Your subject will show up here...";

export default function PreviewContent({
  message,
  subject,
  isLoading,
  onTryAgain = null,
}: PreviewContentProps) {
  if (onTryAgain) {
    return (
      <>
        <div className="flex items-center gap-2">
          <Warning size={20} weight="fill" className="fill-red-400" />
          <p className="text-button-16 text-red-500">Error loading preview</p>
        </div>
        <Button onClick={onTryAgain} size="sm" className="mt-6 w-fit">
          Click here to try again
        </Button>
      </>
    );
  }

  return isLoading ? (
    <div className="ml-auto flex w-full flex-col gap-1">
      <RenderIf condition={!!subject}>
        <Skeleton className="mb-1 h-[19px] w-5/12" />
      </RenderIf>
      <Skeleton className="h-[19px] w-full" />
      <Skeleton className="h-[19px] w-full" />
      <Skeleton className="h-[19px] w-8/12" />
    </div>
  ) : (
    <div
      className={clsx(
        "flex flex-col gap-2 transition-colors",
        !message && "text-black-400",
      )}
    >
      {subject && (
        <span className="text-body-14-bold">{subject || defaultSubject}</span>
      )}
      <p className="whitespace-pre-wrap break-words">
        {message || defaultMessage}
      </p>
    </div>
  );
}
