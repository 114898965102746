import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { VariantProps, cva } from "class-variance-authority";
import { Check } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

import RenderIf from "../../RenderIf";

const switchVariants = cva(
  [
    "relative inline-flex shrink-0 cursor-pointer overflow-hidden rounded-full p-0.5 transition-colors",
    "data-[state=unchecked]:bg-black-300",
    "disabled:cursor-not-allowed disabled:opacity-30",
    "focus-visible:outline-none",
  ],
  {
    variants: {
      size: {
        sm: "h-4 w-7",
        md: "h-5 w-9",
      },
      color: {
        green: "text-green-500 data-[state=checked]:bg-green-500",
        purple: "text-purple-600 data-[state=checked]:bg-purple-600",
      },
    },
    defaultVariants: {
      size: "md",
      color: "green",
    },
  },
);

type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
  VariantProps<typeof switchVariants>;

const Switch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, size = "md", color, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(switchVariants({ size, color, className }))}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn([
        "peer pointer-events-none rounded-full bg-white shadow-md transition-transform data-[state=checked]:translate-x-full",
        size === "md" ? "size-4" : "size-3",
      ])}
    />
    <RenderIf condition={size === "md"}>
      <Check
        size={12}
        className="absolute right-5 top-1 opacity-0 transition-all peer-data-[state=checked]:right-1 peer-data-[state=checked]:opacity-100"
      />
    </RenderIf>
  </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export default Switch;
