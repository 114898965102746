export default function register() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js", {
        type: "module",
      })
      .catch((error) => {
        console.error(`Service Worker registration failed with ${error}`);
      });
  }
}
