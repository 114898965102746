import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { original } from "immer";

import { post, patch, del } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Note } from "common/types";

import useUpdateLead from "./useUpdateLead";

async function addNote(workspaceId: string, profileId: string, text: string) {
  const { note: newNote } = await post(
    `workspaces/${workspaceId}/leads/${profileId}/notes`,
    {
      text: text.trim(),
    },
  );
  return { profileId, note: newNote };
}
async function updateNote(
  workspaceId: string,
  profileId: string,
  noteId: string,
  text: string,
) {
  await patch(`workspaces/${workspaceId}/leads/${profileId}/notes/${noteId}`, {
    text,
  });
  return { profileId, noteId, text: text.trim() };
}
async function deleteNote(
  workspaceId: string,
  profileId: string,
  noteId: string,
) {
  await del(`workspaces/${workspaceId}/leads/${profileId}/notes/${noteId}`);
  return { profileId, noteId };
}

export default function useLeadNotes() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const updateLead = useUpdateLead();

  const { mutateAsync: addLeadNote, isPending: isAddingLeadNote } = useMutation(
    {
      mutationFn: ({ text, profileId }: { text: string; profileId: string }) =>
        addNote(workspaceId, profileId, text),
      onSuccess: async ({
        profileId,
        note,
      }: {
        profileId: string;
        note: Note;
      }) => {
        await updateLead(profileId, (lead) => {
          lead.notes.unshift(note);
        });
        toast.success("Note added");
      },
    },
  );
  const { mutateAsync: updateLeadNote, isPending: isUpdatingLeadNote } =
    useMutation({
      mutationFn: ({
        profileId,
        newText,
        noteId,
      }: {
        profileId: string;
        newText: string;
        noteId: string;
      }) => updateNote(workspaceId, profileId, noteId, newText),
      onMutate: async ({ profileId, newText, noteId }) => {
        let originalText;
        let originalEditedAt;
        await updateLead(profileId, (draftLead) => {
          for (let j = 0; j < draftLead.notes.length; j += 1) {
            if (draftLead.notes[j].id === noteId) {
              const { text, edited_at: editedAt } = original(
                draftLead.notes[j],
              );
              originalText = text;
              originalEditedAt = editedAt;

              draftLead.notes[j].text = newText;
              draftLead.notes[j].edited_at = Date.now();
              return;
            }
          }
        });
        return { originalText, originalEditedAt };
      },
      onError: async (
        error,
        { profileId, noteId },
        { originalText, originalEditedAt },
      ) => {
        await updateLead(profileId, (draftLead) => {
          for (let j = 0; j < draftLead.notes.length; j += 1) {
            if (draftLead.notes[j].id === noteId) {
              draftLead.notes[j].text = originalText;
              draftLead.notes[j].edited_at = originalEditedAt;
              return;
            }
          }
        });
        toast.error("Error updating Note");
      },
    });
  const { mutateAsync: deleteLeadNote, isPending: isDeletingLeadNote } =
    useMutation({
      mutationFn: ({
        profileId,
        noteId,
      }: {
        profileId: string;
        noteId: string;
      }) => deleteNote(workspaceId, profileId, noteId),
      onMutate: async ({ profileId, noteId }) => {
        let previousNotes: Note[];
        await updateLead(profileId, (draftLead) => {
          previousNotes = original(draftLead.notes);
          draftLead.notes = draftLead.notes.filter(({ id }) => id !== noteId);
        });
        return { previousNotes };
      },
      onError: async (error, { profileId }, { previousNotes }) => {
        await updateLead(profileId, (draftLead) => {
          draftLead.notes = previousNotes;
        });
        toast.error("Error deleting Note");
      },
    });
  return {
    addLeadNote,
    isAddingLeadNote,
    updateLeadNote,
    isUpdatingLeadNote,
    deleteLeadNote,
    isDeletingLeadNote,
  };
}
