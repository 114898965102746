import { ReactNode } from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { UserRole } from "common/types";

export default function RestrictedComponent({
  disabledForRoles,
  hiddenForRoles,
  placeholderComponent,
  children,
}: {
  disabledForRoles: UserRole[];
  hiddenForRoles: UserRole[];
  placeholderComponent?: ReactNode;
  children: ReactNode | ((isDisabled: boolean) => ReactNode);
}) {
  const { role } = useSelectedWorkspaceContext();

  if (hiddenForRoles.includes(role)) {
    return placeholderComponent;
  }
  const isDisabled = disabledForRoles.includes(role);
  return typeof children === "function" ? children(isDisabled) : children;
}
