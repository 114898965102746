import useUnreadMessagesCount from "common/datahooks/useUnreadMessagesCount";
import { NavItemType } from "common/types";

import { getBaseNavItems } from "../../pages/routes";

export default function useNavItems() {
  const { unreadMessagesCount } = useUnreadMessagesCount();
  const navItems: NavItemType[] = [];

  getBaseNavItems().forEach((baseNavItem) => {
    const navItem: NavItemType = baseNavItem;

    if (navItem.path === "inbox") {
      navItem.notifications = unreadMessagesCount;
    }
    navItems.push(navItem);
  });

  return navItems;
}
