import React from "react";
import clsx from "clsx";
import { CircleNotch } from "@phosphor-icons/react";

import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput/SearchInput";

import { Conversation } from "../types";
import ConversationsList from "./ConversationsList/ConversationsList";
import ConversationContainer from "./ConversationContainer/ConversationContainer";
import FilterConversations from "./FilterConversations";

import SendMessageImage from "assets/images/empty-placeholders/send-a-message.png";

interface DesktopInboxProps {
  activeConversation: Conversation;
  setActiveConversationUrn: (
    conversationUrn: string,
    accountId: string,
  ) => void;
  conversations: Conversation[];
  loadMoreConversations: () => void;
  keywordsInputValue: string;
  setKeywordsInputValue: (keywords: string) => void;
  keywords: string;
  clearKeywords: () => void;
  isLoadingConversations: boolean;
  hasMoreConversations: boolean;
  selectedAccountIds: string[];
  setSelectedAccountIds: (accountId: string[]) => void;
}

export default function DesktopInbox({
  activeConversation,
  setActiveConversationUrn,
  conversations,
  loadMoreConversations,
  keywordsInputValue,
  setKeywordsInputValue,
  keywords,
  clearKeywords,
  isLoadingConversations,
  hasMoreConversations,
  selectedAccountIds,
  setSelectedAccountIds,
}: DesktopInboxProps) {
  return (
    <section className="relative flex grow items-stretch md:h-[calc(100vh-8.125rem)]">
      <div className="mr-4 flex w-full max-w-md flex-col">
        <div className="mb-4 flex flex-row items-center justify-between gap-2">
          <SearchInput
            placeholder="Search"
            className="w-full"
            variant="lg"
            value={keywordsInputValue}
            onChange={(e) => setKeywordsInputValue(e.target.value)}
            onClear={() => setKeywordsInputValue("")}
            rightComponent={
              keywords &&
              isLoadingConversations && (
                <CircleNotch className="animate-spin text-black-500" />
              )
            }
          />

          <FilterConversations
            selectedAccountIds={selectedAccountIds}
            onApplyFilters={setSelectedAccountIds}
          />
        </div>

        <div
          id="thread-scroll-container"
          className="flex h-full flex-col overflow-y-auto scrollbar-thin [mask-image:_linear-gradient(to_bottom,_black_128px,_black_calc(100%-100px),transparent_100%)]"
        >
          <ConversationsList
            loadMoreConversations={loadMoreConversations}
            isLoadingConversations={isLoadingConversations}
            conversations={conversations}
            activeConversation={activeConversation}
            setActiveConversationUrn={setActiveConversationUrn}
            hasMoreConversations={hasMoreConversations}
            keywords={keywordsInputValue}
            clearKeywords={clearKeywords}
            selectedFilters={selectedAccountIds}
            clearFilters={() => setSelectedAccountIds([])}
          />
        </div>
      </div>

      <div
        className={clsx([
          "relative flex w-full flex-col overflow-hidden rounded-20",
          activeConversation && !isLoadingConversations
            ? "bg-black-50"
            : "border border-dashed border-black-300 bg-white",
        ])}
      >
        {activeConversation ? (
          <ConversationContainer
            activeConversation={activeConversation}
            isLoadingConversations={isLoadingConversations}
          />
        ) : (
          <EmptyPlaceholder
            imageSrc={SendMessageImage}
            title="Select a conversation"
            subtitle="Chat with your leads directly through Aimfox"
          />
        )}
      </div>
    </section>
  );
}
