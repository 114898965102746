import React from "react";
import { X } from "@phosphor-icons/react";

import RenderIf from "common/components/RenderIf";
import { Tag, tagVariants } from "common/components/ui/Tag";
import { GenericSearchParam, SearchParamWithImage } from "common/types";

export default function MobileOptions({
  options,
  appliedOptions,
  removeSearchParam,
  addSearchParam,
}: {
  options: GenericSearchParam[];
  appliedOptions: (GenericSearchParam | SearchParamWithImage)[];
  removeSearchParam: (
    option: GenericSearchParam | SearchParamWithImage,
  ) => void;
  addSearchParam: (option: GenericSearchParam | SearchParamWithImage) => void;
}) {
  return (
    <>
      {/* Applied Filters */}
      <RenderIf condition={!!appliedOptions.length}>
        <div className="mt-4 flex flex-wrap gap-1">
          {appliedOptions.map((option) => (
            <Tag
              key={option.urn}
              variant="primary-black"
              size="lg"
              rightIcon={
                <button
                  type="button"
                  aria-label="Remove filter"
                  onClick={() => removeSearchParam(option)}
                >
                  <X />
                </button>
              }
            >
              {option.name}
            </Tag>
          ))}
        </div>
      </RenderIf>

      {/* Suggestions */}
      <RenderIf condition={!!options.length}>
        <div className="mt-4 flex flex-wrap gap-1">
          {options.map((option) => (
            <button
              key={option.urn}
              type="button"
              className={tagVariants({ variant: "tertiary", size: "lg" })}
              onClick={() => addSearchParam(option)}
            >
              {option.name}
            </button>
          ))}
        </div>
      </RenderIf>
    </>
  );
}
