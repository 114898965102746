import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LinkedInProfile, WorkspaceAccount } from "common/types";
import useAppStore from "common/hooks/useAppStore";

import useWorkspaceCacheUpdate from "./useWorkspaceCacheUpdate";

async function loginRequest(
  workspaceId: string,
  loginData: { email: string; password: string; proxyLocation: string },
): Promise<
  | {
      loginResult: "PASS";
      profile: LinkedInProfile;
    }
  | {
      loginResult: "CHALLENGE";
      challengeType:
        | "EmailPinChallenge"
        | "SMSPinChallenge"
        | "AuthenticatorAppChallenge"
        | "CaptchaChallenge"
        | "AppChallenge";
    }
> {
  const { email, password, proxyLocation } = loginData;

  const {
    login_result: loginResult,
    challenge_type: challengeType,
    profile,
  } = await post(`workspaces/${workspaceId}/accounts/login`, {
    email,
    password,
    proxy_config: {
      "location.countryISO": {
        $eq: proxyLocation,
      },
      "provider.name": {
        $eq: "nord",
      },
    },
    debug: useAppStore.getState().debugOptions.shouldDebugLogin,
  });

  return { loginResult, challengeType, profile };
}
async function resolveChallengeRequest(
  workspaceId: string,
  email: string,
  pin: string,
): Promise<{
  loginResult: "PASS";
  profile: LinkedInProfile;
}> {
  const { login_result: loginResult, profile } = await post(
    `workspaces/${workspaceId}/accounts/challenge/${email}/resolve`,
    { pin },
  );
  return { loginResult, profile };
}
async function resendChallengeRequest(workspaceId: string, email: string) {
  await post(`workspaces/${workspaceId}/accounts/challenge/${email}/resend`);
}
async function deleteAccountRequest(workspaceId: string, accountId: string) {
  await del(`workspaces/${workspaceId}/accounts/${accountId}`);
}

export default function useWorkspaceAccountMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const { mutateAsync: login, isPending: isLoggingIn } = useMutation({
    mutationFn: ({
      loginData,
    }: {
      loginData: { email: string; password: string; proxyLocation: string };
    }) => loginRequest(workspaceId, loginData),
    onSuccess: (response) => {
      if (response.loginResult === "PASS") {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          const { accounts } = draftWorkspace;

          // TODO: (id.toString()) Temporary fix, until backend returns ID as a string instead of number

          // Check if the account has already been connected (Reconnect case)
          const account = accounts.find(
            ({ id }) => id === response.profile.id.toString(),
          );

          if (account) {
            account.state = "LoggedIn";
          } else {
            const newAccount: WorkspaceAccount = {
              ...response.profile,
              id: response.profile.id.toString(),
              state: "LoggedIn",
              license: null,
            };

            accounts.push(newAccount);
          }
        });

        toast.success(`Account added - ${response.profile.full_name}`);
      }
    },
  });
  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    useMutation({
      mutationFn: ({ accountId }: { accountId: string }) =>
        deleteAccountRequest(workspaceId, accountId),
      onSuccess: (_, { accountId }) => {
        updateCachedWorkspace(workspaceId, (draftWorkspace) => {
          draftWorkspace.accounts = draftWorkspace.accounts.filter(
            ({ id }) => id !== accountId,
          );
        });
      },
    });
  const { mutateAsync: resolveChallenge, isPending: isResolvingChallenge } =
    useMutation({
      mutationFn: ({ email, pin }: { email: string; pin: string }) =>
        resolveChallengeRequest(workspaceId, email, pin),
      onSuccess: ({ loginResult, profile }) => {
        if (loginResult === "PASS") {
          updateCachedWorkspace(workspaceId, (draftWorkspace) => {
            const { accounts } = draftWorkspace;

            // TODO: (id.toString()) Temporary fix, until backend returns ID as a string instead of number

            // Check if the account has already been connected (Reconnect case)
            const account = accounts.find(
              ({ id }) => id === profile.id.toString(),
            );

            if (account) {
              account.state = "LoggedIn";
            } else {
              const newAccount: WorkspaceAccount = {
                ...profile,
                id: profile.id.toString(),
                state: "LoggedIn",
                license: null,
              };

              accounts.push(newAccount);
            }
          });

          toast.success(`Account added - ${profile.full_name}`);
        }
      },
    });

  const { mutateAsync: resendChallenge, isPending: isResendingChallenge } =
    useMutation({
      mutationFn: ({ email }: { email: string }) =>
        resendChallengeRequest(workspaceId, email),
    });

  return {
    login,
    isLoggingIn,
    deleteAccount,
    isDeletingAccount,
    resolveChallenge,
    isResolvingChallenge,
    resendChallenge,
    isResendingChallenge,
  };
}
