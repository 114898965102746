import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function getTargetsToken(
  workspaceId: string,
  campaignId: string,
): Promise<string> {
  return (
    await get(`workspaces/${workspaceId}/campaigns/${campaignId}/audience-token`)
  ).token;
}

export default function useAudienceToken() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId } = useCampaignContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: audienceToken } = useQuery({
    queryKey: campaignsKeys.audienceToken(campaignId),
    queryFn: () => getTargetsToken(workspaceId, campaignId),
  });

  return { audienceToken };
}
