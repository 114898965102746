import React from "react";
import { Link } from "react-router-dom";
import { Envelope, PencilSimple } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import RenderIf from "common/components/RenderIf";

import { CampaignFlow } from "../../../types";
import { flowTypeOptions } from "../../../constants";
import { getFlowTitle } from "../../../utils";

interface FlowsPreviewProps {
  flows: CampaignFlow[];
}

export default function FlowsPreview({ flows }: FlowsPreviewProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const flowsTagsComponent = (
    <div className="flex flex-wrap gap-3 max-md:mt-3">
      {flows.map((flow) => {
        const { tagVariant } = flowTypeOptions[flow.type];

        return (
          <Tag
            key={flow.id}
            variant={tagVariant}
            leftIcon={flow.type === "INMAIL_OPTIMIZATION" && <Envelope />}
          >
            {getFlowTitle(flow)}
          </Tag>
        );
      })}
    </div>
  );

  return (
    <section className="mb-4 flex flex-col rounded-2xl border border-black-200 px-3 py-4 md:flex-row md:items-center md:px-4">
      <ConditionalWrapper
        condition={!isTabletOrDesktop}
        renderWrapper={(children) => <div className="flex">{children}</div>}
      >
        <div className="flex-1 md:mr-4">
          <h3 className="text-button-16">
            Campaign Flow{flows.length > 1 ? "s" : ""}
          </h3>
          <p className="text-caption-12-regular text-black-500">
            How the campaign will engage with your targets
          </p>
        </div>

        <RenderIf condition={isTabletOrDesktop}>{flowsTagsComponent}</RenderIf>

        <div className="mx-4 h-10 w-px bg-black-200" />

        <Button intent="iconOnly" variant="secondary-black" asChild>
          <Link to="../flows">
            <PencilSimple />
          </Link>
        </Button>
      </ConditionalWrapper>

      <RenderIf condition={!isTabletOrDesktop}>{flowsTagsComponent}</RenderIf>
    </section>
  );
}
