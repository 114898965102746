import React from "react";
import {
  ArrowUpRight,
  Chat,
  ThumbsUp,
  UsersThree,
} from "@phosphor-icons/react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";
import Switch from "common/components/ui/Switch";
import { typedObjectEntries } from "common/helpers/utils";

import { PostInteractions, PostStatus } from "../../../../types";

interface PostStatsDetails {
  postStatus: PostStatus;
  interactions: PostInteractions;
  isLoading: boolean;
  updatePostStatus: (status: keyof PostStatus, checked: boolean) => void;
}

interface PostDetailsInfo {
  icon: React.FunctionComponent;
  label: string;
  description: string;
  amount: number;
  isChecked: boolean;
}

export default function PostStats({
  postStatus,
  interactions,
  isLoading,
  updatePostStatus,
}: PostStatsDetails) {
  const { isLiked, isCommented, isShared } = postStatus;
  const { likes, shares, comments } = interactions;

  const postStatuses: Record<keyof PostStatus, PostDetailsInfo> = {
    isLiked: {
      icon: ThumbsUp,
      label: "Likes",
      description: "People that liked the post",
      amount: likes,
      isChecked: isLiked,
    },
    isCommented: {
      icon: Chat,
      label: "Comments",
      description: "People that commented the post",
      amount: comments,
      isChecked: isCommented,
    },
    isShared: {
      icon: ArrowUpRight,
      label: "Reposts",
      description: "People that reposted the post",
      amount: shares,
      isChecked: isShared,
    },
  };

  const totalAmount = typedObjectEntries(postStatuses)
    .map(([, post]) => post)
    .reduce((total, { amount, isChecked }) => {
      if (isChecked) {
        return total + amount;
      }
      return total;
    }, 0);

  return (
    <article className="rounded-2xl border border-black-200 p-4">
      <h5 className="text-headline-lg-bold">Post stats</h5>
      <p className="-mx-4 border-b border-b-black-200 px-4 pb-4 text-black-500">
        Select at least one
      </p>
      {typedObjectEntries(postStatuses).map(
        ([key, { icon: Icon, label, description, amount, isChecked }]) => (
          <div
            key={key}
            className={clsx(
              "flex items-center gap-3 py-3",
              key !== "isShared" && "border-b border-b-black-200",
            )}
          >
            <Button key={label} intent="labelIcon" variant="secondary-black">
              <Icon />
            </Button>
            <div className="flex-1">
              <h6 className="mb-0.5 text-button-16">{label}</h6>
              <p className="text-caption-12-regular text-black-500">
                {description}
              </p>
            </div>

            <div
              className={clsx(
                "flex items-center transition-colors",
                isChecked ? "text-black-950" : "text-black-400",
              )}
            >
              <UsersThree size={20} weight="fill" className="mr-1" />
              {isLoading ? (
                <Skeleton className="size-6" />
              ) : (
                <span className="text-button-14">{amount}</span>
              )}
              <Switch
                className="ml-3"
                checked={isChecked}
                disabled={isLoading}
                onCheckedChange={(checked) => updatePostStatus(key, checked)}
              />
            </div>
          </div>
        ),
      )}
      <div className="-mx-4 flex items-center justify-between border-t border-t-black-200 px-4 pt-4">
        <div>
          <h5 className="text-headline-lg-bold">Total people</h5>
          <p className="text-black-500">Select at least one</p>
        </div>
        <div className="flex items-center gap-1">
          <UsersThree size={20} weight="fill" />
          {isLoading ? (
            <Skeleton className="size-6" />
          ) : (
            <span className="text-button-14">{totalAmount}</span>
          )}
        </div>
      </div>
    </article>
  );
}
