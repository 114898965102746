import React from "react";

import { Certification } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";

import SectionContainer from "./SectionContainer";
import { formatMonthAndYear, formatRange } from "../../utils";
import SectionItem from "./SectionItem";

import CertificationsFallback from "assets/images/certifications-fallback.png";

export default function Certifications({
  certification,
}: {
  certification: Certification[];
}) {
  return (
    <SectionContainer title="Licenses & certifications">
      {certification.map(({ name, id, issued, authority, expires }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SectionItem key={index}>
          <div className="flex items-start gap-x-2">
            <ProfileImage
              src={
                authority && authority.logo_url
                  ? authority.logo_url
                  : CertificationsFallback
              }
            />
            <div className="flex flex-col items-start gap-y-0.5">
              <span className="text-button-14">{name}</span>
              {authority && (
                <span className="text-caption-12-regular">
                  {authority.name}
                </span>
              )}
              {id && (
                <span className="text-caption-12-regular text-black-400">
                  Credential ID: {id}
                </span>
              )}
            </div>
            {(issued || expires) && (
              <span className="ml-auto text-caption-12-regular">
                {formatRange(
                  `Issued ${formatMonthAndYear(issued)}`,
                  expires
                    ? `Expires ${formatMonthAndYear(expires)}`
                    : "No Expiration Date",
                )}
              </span>
            )}
          </div>
        </SectionItem>
      ))}
    </SectionContainer>
  );
}
