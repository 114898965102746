import React, {
  ButtonHTMLAttributes,
  ReactNode,
  forwardRef,
  ForwardedRef,
} from "react";
import { Slot, SlotProps, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { CircleNotch } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

const defaultButtonClasses = [
  "transition-colors duration-300 ease-in-out cursor-pointer",
  "focus-visible:outline-none",
  "disabled:cursor-not-allowed disabled:bg-black-200 disabled:text-black-400",
];

const buttonVariants = cva(
  "inline-flex shrink-0 items-center justify-center text-nowrap rounded-full",
  {
    variants: {
      variant: {
        // Primary Button
        "primary-black": "bg-black-950 text-white hover:bg-black-700",
        "primary-purple": "bg-purple-500 text-white hover:bg-purple-400",
        "primary-orange": "bg-orange-600 text-white hover:bg-orange-400",

        // Secondary Button
        "secondary-black": "bg-black-100 text-black-950 hover:bg-black-200",
        "secondary-purple": "bg-purple-100 text-purple-600 hover:bg-purple-200",
        "secondary-orange": "bg-orange-100 text-orange-600 hover:bg-orange-200",

        // Tertiary Button
        "tertiary-black":
          "border border-black-200 bg-white text-black-950 hover:bg-black-50 disabled:border-none",
        "tertiary-purple":
          "border border-purple-200 bg-white text-purple-600 hover:bg-purple-50 disabled:border-none",
        "tertiary-orange":
          "border border-orange-200 bg-white text-orange-600 hover:bg-orange-50 disabled:border-none",
        "tertiary-danger":
          "border border-red-200 bg-white text-red-500 hover:bg-red-50 disabled:border-none",

        // Quaternary Button
        "quaternary-black": "text-black-950 hover:bg-black-100",
        "quaternary-purple": "text-purple-600 hover:bg-purple-100",
        "quaternary-orange": "text-orange-600 hover:bg-orange-100",
        "quaternary-danger": "text-red-500 hover:bg-red-200",

        // Gradient Button (AI)
        gradient:
          "text-white transition-opacity enabled:bg-blackPurple enabled:hover:opacity-80",
      },
      size: {
        // XS Variant is used for Icon Buttons only
        xs: "size-6 [&_svg]:size-4",
        sm: "h-8 min-w-8 gap-1 px-4 py-2 text-button-12 [&_svg]:size-4",
        md: "h-10 min-w-10 gap-2 px-6 py-2.5 text-button-14 [&_svg]:size-5",
        lg: "h-12 min-w-12 gap-2 px-6 py-3 text-button-16 [&_svg]:size-6",
      },
      intent: {
        default: defaultButtonClasses,
        iconOnly: [...defaultButtonClasses, "p-0"],
        labelIcon: "pointer-events-none p-0",
      },
    },
    defaultVariants: {
      variant: "primary-black",
      size: "md",
      intent: "default",
    },
  },
);

type IconPosition = "left" | "right" | "both";
const iconStyleOptions: Record<
  IconPosition,
  { sm: string; md: string; lg: string }
> = {
  both: {
    sm: "px-2",
    md: "px-3",
    lg: "px-3",
  },
  left: {
    sm: "[&_svg]:-ml-2",
    md: "[&_svg]:-ml-3",
    lg: "[&_svg]:-ml-3",
  },
  right: {
    sm: "[&_svg]:-mr-2",
    md: "[&_svg]:-mr-3",
    lg: "[&_svg]:-mr-3",
  },
};

type ButtonVariantType = VariantProps<typeof buttonVariants>["variant"];

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
  };

function Button(
  {
    className,
    variant,
    size = "md",
    intent,
    children,
    isLoading = false,
    leftIcon = null,
    rightIcon = null,
    asChild = false,
    disabled,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const loadingComponent = <CircleNotch className="animate-spin" />;

  let Comp:
    | React.ForwardRefExoticComponent<
        SlotProps & React.RefAttributes<HTMLElement>
      >
    | "button"
    | "span" = "button";

  if (intent === "labelIcon") {
    Comp = "span";
  } else if (asChild) {
    Comp = Slot;
  }

  let iconPosition: IconPosition;
  if (leftIcon && !rightIcon) {
    iconPosition = "left";
  } else if (rightIcon && !leftIcon) {
    iconPosition = "right";
  } else if (rightIcon && leftIcon) {
    iconPosition = "both";
  }

  const iconStyle =
    iconPosition && size !== "xs" ? iconStyleOptions[iconPosition][size] : "";

  return (
    <Comp
      className={cn(
        buttonVariants({
          variant,
          size,
          intent,
          className,
        }),
        iconStyle,
      )}
      ref={ref}
      {...props}
      disabled={isLoading || disabled}
    >
      {isLoading && leftIcon ? loadingComponent : leftIcon}

      {intent === "iconOnly" && isLoading ? (
        loadingComponent
      ) : (
        <Slottable>{children}</Slottable>
      )}

      {isLoading &&
      (rightIcon || (!rightIcon && !leftIcon && intent !== "iconOnly"))
        ? loadingComponent
        : rightIcon}
    </Comp>
  );
}

Button.displayName = "Button";

const ButtonWithRef = forwardRef(Button);

export { ButtonWithRef as Button, type ButtonVariantType };
