import React, { useState } from "react";

import ProfileSettingsItem from "../ProfileSettingsItem";
import UpdatePassword from "./UpdatePassword";

export default function Password() {
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  return (
    <ProfileSettingsItem
      label="Password"
      isEditing={isEditingPassword}
      onEditClick={() => {
        setIsEditingPassword(true);
      }}
    >
      {!isEditingPassword ? (
        <span className="text-body-14-bold">●●●●●●●●●●●</span>
      ) : (
        <UpdatePassword onClose={() => setIsEditingPassword(false)} />
      )}
    </ProfileSettingsItem>
  );
}
