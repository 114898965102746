import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LinkedInProfile } from "common/types";

async function getBlacklist(workspaceId: string) {
  return (await get(`workspaces/${workspaceId}/blacklist`)).targets;
}
export default function useBlacklist() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { blacklistKeys } = getQueryKeys(workspaceId);

  const {
    isLoading: isLoadingBlacklist,
    data: blacklist,
    error: blacklistError,
    refetch: refetchBlacklist,
  } = useQuery<LinkedInProfile[]>({
    queryKey: blacklistKeys.list,
    queryFn: () => getBlacklist(workspaceId),
  });

  return {
    blacklist,
    refetchBlacklist,
    isLoadingBlacklist,
    blacklistError,
  };
}
