import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Bell, CaretUpDown } from "@phosphor-icons/react";
import clsx from "clsx";

import { NavItemType } from "common/types";
import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import WorkspaceImage from "common/components/WorkspaceImage";
import { userRoles } from "common/constants";

import MobileNav from "./MobileNav";

function MobileHeader({ navItems }: { navItems: NavItemType[] }) {
  const { id: workspaceId, name, role } = useSelectedWorkspaceContext();
  const { label, textColor } = userRoles[role];

  return (
    <div className="sticky top-0 z-nav flex h-20 w-full items-center justify-between gap-3 bg-white p-4 shadow-sm">
      <Link
        to="/workspaces"
        className="flex flex-1 items-center gap-x-2 rounded-2xl border border-black-200 px-3 py-2 transition-colors"
        state={{ canGoBack: true }}
      >
        <WorkspaceImage id={workspaceId} />
        <div className="flex flex-col">
          <span className="line-clamp-1 break-all text-left text-button-16">
            {name}
          </span>
          <span
            className={clsx(
              "text-left text-button-12 text-black-600",
              textColor,
            )}
          >
            {label}
          </span>
        </div>
        <CaretUpDown size={20} className="ml-auto" />
      </Link>

      <Button intent="iconOnly" variant="quaternary-black" size="lg">
        <NavLink to="notifications">
          <Bell weight="fill" />
        </NavLink>
      </Button>
      <MobileNav navItems={navItems} />
    </div>
  );
}

export default MobileHeader;
