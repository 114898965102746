import React from "react";
import clsx from "clsx";

interface ProgressBarProps {
  progress: Progress;
}

interface Progress {
  step: number;
  totalSteps: number;
}

export default function ProgressBar({ progress }: ProgressBarProps) {
  const { step, totalSteps } = progress;

  return (
    <div className="flex w-full grow flex-row items-center justify-center gap-2 md:gap-4">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={`progress-question-${index + 1}`}
          className={clsx(
            "h-2 w-full rounded-full",
            index < step ? "bg-purple-500" : "bg-black-200",
          )}
        />
      ))}
    </div>
  );
}
