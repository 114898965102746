import React, { ComponentProps } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { cn } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";

interface PaginationProps extends ComponentProps<"div"> {
  pagesCount: number;
  setPage: (page: number) => void;
  selectedIndex: number;
}

export default function Pagination({
  className,
  pagesCount,
  setPage,
  selectedIndex,
  ...props
}: PaginationProps) {
  if (pagesCount <= 1) {
    return null;
  }

  const isEllipsisPagination = pagesCount > 3;
  const pageNumbers = [];
  let isPageNumberOutOfRange = false;

  for (let i = 0; i < pagesCount; i += 1) {
    const isFirstPage = i === 0;
    const isLastPage = i === pagesCount - 1;
    const isCurrentPageWithinOnePageNumber = Math.abs(i - selectedIndex) <= 1;

    if (isFirstPage || isLastPage || isCurrentPageWithinOnePageNumber) {
      isPageNumberOutOfRange = false;
      const isActive = i === selectedIndex;
      pageNumbers.push(
        <Button
          variant={isActive ? "primary-black" : "quaternary-black"}
          className="select-none rounded-xl p-0 transition-none"
          key={i}
          onClick={() => setPage(i)}
        >
          {i + 1}
        </Button>,
      );
    } else if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      pageNumbers.push(
        <span key={`ellipsis-${i}`} className="select-none text-black-500">
          ...
        </span>,
      );
    }
  }

  return (
    <div
      aria-label="pagination"
      className={cn(
        "mx-auto flex items-center justify-center",
        isEllipsisPagination && "w-80",
        className,
      )}
      {...props}
    >
      <Button
        aria-label="Go to previous page"
        intent="iconOnly"
        className={clsx(
          "rounded-xl",
          isEllipsisPagination ? "mr-auto" : "mr-6",
        )}
        variant="tertiary-black"
        onClick={() => setPage(selectedIndex - 1)}
        disabled={selectedIndex === 0}
      >
        <CaretLeft />
      </Button>
      {pageNumbers}
      <Button
        aria-label="Go to next page"
        intent="iconOnly"
        className={clsx(
          "rounded-xl",
          isEllipsisPagination ? "ml-auto" : "ml-6",
        )}
        variant="tertiary-black"
        disabled={selectedIndex === pagesCount - 1}
        onClick={() => setPage(selectedIndex + 1)}
      >
        <CaretRight />
      </Button>
    </div>
  );
}
