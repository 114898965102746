import React, { useState } from "react";

import { Label } from "common/types";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useAccountLabels from "common/datahooks/useAccountLabels";
import AccountLabel from "common/components/LeadLabels/AccountLabel";
import AddLabel from "common/components/LeadLabels/AddLabel";
import AppliedLabel from "common/components/LeadLabels/AppliedLabel";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import DeleteLabelPrompt from "common/components/LeadLabels/DeleteLabelPrompt";

interface LeadNotesProps {
  labels: Label[];
  profileId: string;
  isOpen: boolean;
  onClose: () => void;
}

function LeadLabels({ labels, profileId, isOpen, onClose }: LeadNotesProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [deletingLabelId, setDeletingLabelId] = useState(null);
  const { accountLabels } = useAccountLabels();

  const filteredAccountLabels = accountLabels
    ? accountLabels.filter(
        (accountLabel) => !labels.some((label) => accountLabel.id === label.id),
      )
    : [];

  const content = deletingLabelId ? (
    <DeleteLabelPrompt
      labelId={deletingLabelId}
      onBack={() => setDeletingLabelId(null)}
    />
  ) : (
    <>
      <h2 className="mb-4 text-headline-2xl-bold lg:mb-6">Tags</h2>
      <span className="mb-2 text-button-14">Applied</span>
      <div className="mb-6 flex max-h-48 flex-wrap gap-1 overflow-y-auto lg:mb-8">
        {labels.length ? (
          labels.map((label) => (
            <AppliedLabel key={label.id} profileId={profileId} label={label} />
          ))
        ) : (
          <Tag size="lg" variant="tertiary">
            No tags added
          </Tag>
        )}
      </div>
      <span className="mb-2 text-button-12 text-black-400">
        Already created:
      </span>
      <div className="mb-4 flex max-h-48 flex-wrap gap-1 overflow-y-auto border-b border-b-black-200 pb-4 lg:mb-6">
        {filteredAccountLabels.length ? (
          filteredAccountLabels.map((label) => (
            <AccountLabel
              key={label.id}
              label={label}
              profileId={profileId}
              onDelete={() => setDeletingLabelId(label.id)}
            />
          ))
        ) : (
          <Tag size="lg" variant="tertiary">
            No tags
          </Tag>
        )}
      </div>
      <AddLabel profileId={profileId} />
    </>
  );
  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose();
        }
      }}
      onAfterClose={() => setDeletingLabelId(null)}
    >
      <Content>{content}</Content>
    </Component>
  );
}
LeadLabels.displayName = "LeadLabels";

export default LeadLabels;
