import React from "react";
import { produce } from "immer";

import { ScheduleInterval } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

interface RangesSelectProps {
  ranges: ScheduleInterval[];
  isDisabled: boolean;
  setRanges: (value: ScheduleInterval[]) => void;
}

export default function RangesSelect(props: RangesSelectProps) {
  const { ranges, isDisabled, setRanges } = props;
  const isTabletOrDesktop = useTwBreakpoint("md");

  const startOptions: { value: number; label: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    const label = `${i > 9 ? i : `0${i}`}:00`;
    startOptions.push({ value: i, label });
  }
  const endOptions = [...startOptions.slice(1), { value: 24, label: "23:59" }];

  function onChange(index: number, startOrEnd: "start" | "end", value: number) {
    let newRanges = produce(ranges, (draftState) => {
      draftState[index][startOrEnd] = value;
      // readjust ranges to be valid after selection change
      if (draftState[index].start >= draftState[index].end) {
        draftState[index].end = draftState[index].start + 1;
      }
      // make sure 2nd range is valid as per first range
      if (draftState[1]) {
        if (draftState[1].start <= draftState[0].end) {
          draftState[1].start = draftState[0].end + 1;
          draftState[1].end = draftState[0].end + 2;
        }
      }
    });
    if (newRanges[0].end >= 23) {
      newRanges = [newRanges[0]];
    }
    setRanges(newRanges);
  }
  function addRange() {
    setRanges([
      ranges[0],
      {
        start: ranges[0].end + 1,
        end: ranges[0].end + 2,
      },
    ]);
  }
  function removeRange(index: number) {
    const newRanges = produce(ranges, (draft) => {
      draft.splice(index, 1);
    });
    setRanges(newRanges);
  }

  const isSecondRangeDisabled = ranges[0].end >= 23;

  const Component = isTabletOrDesktop ? DesktopLayout : MobileLayout;
  return (
    <Component
      startOptions={startOptions}
      endOptions={endOptions}
      isDisabled={isDisabled}
      isSecondRangeDisabled={isSecondRangeDisabled}
      removeRange={removeRange}
      onChange={onChange}
      addRange={addRange}
      {...props}
    />
  );
}
