import React from "react";

import useNotifications from "common/datahooks/useNotifications";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import NotificationItem from "./NotificationItem";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/notifications.png";

export default function Notifications() {
  const { notifications } = useNotifications();

  return notifications.length ? (
    <div className="flex flex-col gap-2 md:gap-2.5">
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
    </div>
  ) : (
    <EmptyPlaceholder
      title="Nothing to report"
      subtitle="This area will light up with new notifications once there's activity in your workspaces"
      imageSrc={EmptyPlaceholderImage}
    />
  );
}
