import React from "react";
import clsx from "clsx";

import { formatChatTimestamp } from "../../utils";

interface TimestampDisplayProps {
  createdAt: number;
  unreadCount: number;
}

export default function TimestampDisplay({
  createdAt,
  unreadCount,
}: TimestampDisplayProps) {
  return (
    <span
      className={clsx([
        unreadCount ? "text-caption-10-bold" : "text-caption-10-regular",
      ])}
    >
      {formatChatTimestamp(createdAt)}
    </span>
  );
}
