import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { OTPInput, SlotProps } from "input-otp";

import { HTTPError } from "common/helpers/HTTP";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";

import useWorkspaceAccountMutations from "../../datahooks/useWorkspaceAccountMutations";

const pinContainerClassname =
  "flex rounded-xl border border-black-400 h-14 flex-1";

function PinBox({ char, hasFakeCaret }: SlotProps) {
  return (
    <div className="relative flex flex-1 items-center justify-center border-r border-r-black-400 text-button-16 text-black-400 last:border-r-0">
      {!char && !hasFakeCaret ? "-" : char}
      <RenderIf condition={hasFakeCaret}>
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="h-4 w-px animate-caret-blink bg-black-400" />
        </div>
      </RenderIf>
    </div>
  );
}
export default function EnterPin({
  email,
  onSuccess,
}: {
  onSuccess: () => void;
  email: string;
}) {
  const [cooldownTimer, setCooldownTimer] = useState(0);
  const [pin, setPin] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    resolveChallenge,
    isResolvingChallenge,
    resendChallenge,
    isResendingChallenge,
  } = useWorkspaceAccountMutations();

  useEffect(() => {
    if (cooldownTimer > 0) {
      setTimeout(() => setCooldownTimer((prevState) => prevState - 1), 1000);
    }
  }, [cooldownTimer]);

  function sendPin() {
    resolveChallenge({
      email,
      pin,
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setErrorMessage(
          error instanceof HTTPError && error.serverMessage
            ? error.serverMessage
            : "Unexpected error, please try again later.",
        );
        Sentry.setExtra("error", error);
        Sentry.setExtra("error_json", JSON.stringify(error));
        Sentry.captureMessage("Pin Challenge Error");
      });
  }

  function resendPin() {
    resendChallenge({ email }).then(() => {
      setCooldownTimer(5);
    });
  }

  const isDisabled = cooldownTimer !== 0 || isResendingChallenge;

  return (
    <form className="flex flex-col" onSubmit={sendPin}>
      <OTPInput
        autoFocus
        value={pin}
        onChange={(value) => setPin(value)}
        maxLength={6}
        render={({ slots }) => (
          <div className="mt-12 flex items-center gap-x-4">
            <div className={pinContainerClassname}>
              {slots.slice(0, 3).map((props, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PinBox key={index} {...props} />
              ))}
            </div>
            <div className="size-2 shrink-0 rounded-full bg-black-400" />
            <div className={pinContainerClassname}>
              {slots.slice(3).map((props, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PinBox key={index} {...props} />
              ))}
            </div>
          </div>
        )}
        onComplete={sendPin}
      />
      {errorMessage && (
        <span className="mt-1 text-center text-caption-12-regular text-red-500">
          {errorMessage}
        </span>
      )}
      <span className="my-12 text-center text-button-14 text-black-400">
        Didn&apos;t receive the code?{" "}
        <button
          type="button"
          className={
            isDisabled ? "text-black-400" : "text-purple-600 underline"
          }
          onClick={resendPin}
          disabled={isDisabled}
        >
          {cooldownTimer === 0 ? "Resend" : `Resend in ${cooldownTimer} sec...`}
        </button>
      </span>
      <Button
        variant="primary-black"
        isLoading={isResolvingChallenge}
        type="submit"
      >
        Connect Account
      </Button>
    </form>
  );
}
