import React, { ComponentPropsWithoutRef, ReactNode, forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";

import { Button } from "common/components/ui/Button";
import { cn } from "common/helpers/utils";

const switcherVariants = cva(
  "flex w-fit gap-1.5 rounded-full bg-black-100 p-1",
  {
    variants: {
      size: {
        sm: "h-10",
        md: "h-12",
        lg: "h-14",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

type Option = {
  value: string;
  label: string;
  icon?: ReactNode;
};

type SwitcherProps = ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof switcherVariants> & {
    options: Option[];
    selectedOption: string;
    onOptionSelect: (newValue: string) => void;
  };

const Switcher = forwardRef<HTMLDivElement, SwitcherProps>(
  ({ className, size, options, selectedOption, onOptionSelect }, ref) => (
    <div ref={ref} className={cn(switcherVariants({ size, className }))}>
      {options.map(({ label, value, icon }) => {
        const isItemSelected = selectedOption === value;

        return (
          <Button
            key={value}
            leftIcon={icon}
            size={size}
            variant={isItemSelected ? "primary-orange" : "quaternary-black"}
            onClick={() => onOptionSelect(value)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  ),
);
Switcher.displayName = "Switcher";

export default Switcher;
