import React from "react";
import { Link } from "react-router-dom";
import { PencilSimple } from "@phosphor-icons/react";
import clsx from "clsx";

import { typedObjectEntries } from "common/helpers/utils";
import { Schedule } from "common/types";
import { Button } from "common/components/ui/Button";

import { getFormattedInterval } from "../../../utils";

interface ScheduleReviewProps {
  schedule: Schedule;
}

export default function ScheduleReview({ schedule }: ScheduleReviewProps) {
  return (
    <section className="rounded-2xl border border-black-200 px-3 py-4 md:col-span-2 md:px-4">
      <div className="-mx-3 flex justify-between gap-4 border-b border-black-200 px-3 pb-4 max-md:items-center md:-mx-4 md:px-4">
        <div>
          <h5 className="mb-0.5 text-button-16">Sending Schedule</h5>
          <p className="text-caption-12-regular text-black-500">
            The time your audience should receive deliveries from this campaign
          </p>
        </div>

        <div className="ml-auto h-10 w-px bg-black-200 md:hidden" />

        <Button intent="iconOnly" variant="secondary-black" asChild>
          <Link to="../schedule">
            <PencilSimple />
          </Link>
        </Button>
      </div>

      {typedObjectEntries(schedule).map(([day, { intervals }]) => (
        <div
          key={day}
          className={clsx(
            "flex items-center border-b border-black-200 py-3 last:border-b-0",
            !intervals.length && "text-black-400",
          )}
        >
          <h6 className="capitalize">{day}</h6>

          <div className="ml-auto flex flex-col items-end">
            {intervals.length
              ? intervals.map((interval) => {
                  const { startValue, endValue } =
                    getFormattedInterval(interval);

                  return (
                    <span key={interval.end} className="text-body-14-bold">
                      {startValue} - {endValue}
                    </span>
                  );
                })
              : "Not active"}
          </div>
        </div>
      ))}
    </section>
  );
}
