import React, { useState } from "react";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";

import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import Pagination from "common/components/ui/Pagination";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onRowClick?: (rowValue: TData) => void;
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  onRowClick = null,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { getState, getHeaderGroups, getRowModel, getPageCount, setPageIndex } =
    useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: 7,
        },
      },
      state: {
        sorting,
      },
    });

  return (
    <>
      <Table>
        <TableHeader>
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </tr>
          ))}
        </TableHeader>
        <tbody>
          {getRowModel().rows.map((row) => (
            <TableRow
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
              className={clsx({
                "hover:shadow-none hover:cursor-auto": !onRowClick,
              })}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>

      <Pagination
        className="my-4"
        setPage={setPageIndex}
        pagesCount={getPageCount()}
        selectedIndex={getState().pagination.pageIndex}
      />
    </>
  );
}
