import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Invitation } from "common/types";
import { Button } from "common/components/ui/Button";
import useInvitations from "common/datahooks/useInvitations";

import InvitationImage from "assets/images/invitation.png";

interface WorkspaceInvitationProps {
  invitation: Invitation | undefined;
  onDecline: () => void;
}

export default function WorkspaceInvitation({
  invitation,
  onDecline,
}: WorkspaceInvitationProps) {
  const navigate = useNavigate();
  const isAcceptRef = useRef<boolean>();
  const { respondToInvitation, isRespondingToInvitation } = useInvitations({
    disableQuery: true,
  });

  function acceptInvite() {
    isAcceptRef.current = true;
    respondToInvitation({ accept: true, memberToken: invitation.token }).then(
      ({ id }) => {
        navigate(`/workspaces/${id}`, {
          replace: true,
        });
      },
    );
  }

  function declineInvite() {
    isAcceptRef.current = false;
    respondToInvitation({ accept: false, memberToken: invitation.token }).then(
      () => onDecline(),
    );
  }

  return (
    <section className="mx-auto flex max-w-[520px] grow flex-col justify-center">
      <img
        width={364}
        height={254}
        src={InvitationImage}
        alt="Aimfox Workspace Invitation"
        className="mx-auto mb-6"
      />

      <h2 className="mb-2 text-center text-headline-2xl-bold">
        Join{" "}
        <span className="text-purple-600">{invitation.workspace_name}</span> on
        Aimfox
      </h2>

      <p className="text-center text-black-500">
        You were invited to join the Aimfox workspace{" "}
        <span className="text-body-14-bold text-black-950">
          {invitation.workspace_name}
        </span>{" "}
        as an{" "}
        <span className="text-body-14-bold capitalize text-black-950">
          {invitation.role}
        </span>
        . Join now to start collaborating!
      </p>

      <div className="mt-12 flex flex-col-reverse gap-4 md:flex-row">
        <Button
          variant="secondary-black"
          className="flex-1"
          size="lg"
          onClick={declineInvite}
          isLoading={isAcceptRef.current === false && isRespondingToInvitation}
          disabled={isAcceptRef.current === true && isRespondingToInvitation}
        >
          Decline
        </Button>
        <Button
          variant="primary-black"
          className="flex-1"
          size="lg"
          onClick={acceptInvite}
          isLoading={isAcceptRef.current === true && isRespondingToInvitation}
          disabled={isAcceptRef.current === false && isRespondingToInvitation}
        >
          Accept
        </Button>
      </div>
    </section>
  );
}
