import React, { forwardRef, HTMLAttributes, useEffect, useRef } from "react";
import clsx from "clsx";

import { getHtmlString } from "./helpers";

interface TextWithLabelsProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  isEditable: boolean;
  isDisabled: boolean;
}

const TextWithLabels = forwardRef<HTMLDivElement, TextWithLabelsProps>(
  ({ text, isEditable, isDisabled, ...rest }, forwardedRef) => {
    const contentEditableRef = useRef<HTMLDivElement>();

    useEffect(() => {
      const regex = /\{\{(?:FIRST_NAME|LAST_NAME|OCCUPATION|LOCATION|ID)}}/g;
      contentEditableRef.current.innerHTML = text.replace(regex, (match) =>
        getHtmlString(match.slice(2, -2)),
      );
    }, []);

    const contentEditableProps = {
      contentEditable: true,
      "data-placeholder": "Enter message text here...",
      role: "textbox",
      spellCheck: false,
      autoCorrect: "off",
      autoCapitalize: "off",
      "aria-multiline": true,
    };

    return (
      <div
        className={clsx(
          "group flex-1 whitespace-pre-wrap break-words text-button-14 text-black-700 outline-none",
          "[&[contenteditable=true]:empty::before]:text-black-400 [&[contenteditable=true]:empty::before]:content-[attr(data-placeholder)]",
          isDisabled && "text-black-400",
          isEditable && "cursor-text",
        )}
        ref={(_ref) => {
          contentEditableRef.current = _ref;
          if (forwardedRef) {
            if (typeof forwardedRef === "function") {
              forwardedRef(_ref);
            } else {
              forwardedRef.current = _ref;
            }
          }
        }}
        {...(isEditable && !isDisabled && contentEditableProps)}
        {...rest}
      />
    );
  },
);
TextWithLabels.displayName = "TextWithLabels";

export default TextWithLabels;
