import React from "react";
import { CaretRight, GearSix } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { userRoles } from "common/constants";
import { Workspace } from "common/types";
import { Button } from "common/components/ui/Button";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import WorkspaceImage from "common/components/WorkspaceImage";

interface WorkspaceItemProps {
  workspace: Workspace;
  isSelected: boolean;
}

export default function WorkspaceItem({
  workspace: { id, name, role },
  isSelected,
}: WorkspaceItemProps) {
  const { goToWorkspace } = useWorkspaceNavigate();
  const { label, textColor } = userRoles[role];

  return (
    <button
      type="button"
      key={id}
      className="group flex items-center gap-2 rounded-2xl border border-black-200 p-3 hover:bg-whiteGray"
      aria-label={`Select ${name}'s workspace`}
      onClick={(e) => {
        if (
          !e.nativeEvent
            .composedPath()
            .some((element) => (element as Element).tagName === "A")
        ) {
          goToWorkspace(id);
        }
      }}
    >
      <WorkspaceImage id={id} />

      <div className="flex flex-col items-start gap-y-0.5">
        <span className="line-clamp-1 break-all text-left text-body-14-bold">
          {name}
        </span>
        <span className={clsx(textColor, "text-button-12")}>{label}</span>
      </div>

      {isSelected ? (
        <Button variant="quaternary-black" intent="iconOnly" asChild>
          <Link to={`/workspaces/${id}/settings`} className="ml-auto">
            <GearSix weight="fill" />
          </Link>
        </Button>
      ) : (
        <Button
          variant="quaternary-black"
          intent="labelIcon"
          className="ml-auto opacity-0 transition-opacity group-hover:opacity-100"
        >
          <CaretRight />
        </Button>
      )}
    </button>
  );
}
