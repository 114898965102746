import React from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  formatPeriodUnitLabel,
  formatPrice,
  formatShortDate,
} from "common/helpers/utils";
import useEstimateUpdate from "common/datahooks/useEstimateUpdate";
import { PricingPeriodUnit } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import useDebounce from "common/hooks/useDebounce";
import useEstimateCreation from "common/datahooks/useEstimateCreation";

interface NewPriceProps {
  licenseCount: number;
  selectedPeriodUnit: PricingPeriodUnit;
}

export default function NewPrice({
  licenseCount,
  selectedPeriodUnit,
}: NewPriceProps) {
  const { subscription, licenses } = useSelectedWorkspaceContext();
  const hasLifetimeLicense = licenses.some(({ lifetime }) => lifetime);
  const debouncedLicenseCount = useDebounce(licenseCount);
  const { estimateUpdate, isLoadingEstimateUpdate } = useEstimateUpdate({
    selectedPeriodUnit,
    quantity: debouncedLicenseCount,
    prorate: false,
  });
  const { estimateCreation, isLoadingEstimateCreation } = useEstimateCreation({
    selectedPeriodUnit,
    quantity: debouncedLicenseCount,
  });

  const isLoading = isLoadingEstimateCreation || isLoadingEstimateUpdate;
  const isSubscriptionCancelled = subscription?.status === "cancelled";
  const isSubscriptionNonRenewing = subscription?.status === "non_renewing";

  let nextBillingAt;
  let amountDue;
  let priceTitle = "New price";
  if (subscription) {
    if (isSubscriptionCancelled) {
      priceTitle = "Price";
    }

    if (isSubscriptionNonRenewing) {
      nextBillingAt = estimateCreation?.nextBillingAt;
      amountDue = estimateCreation?.amountDue;
    } else {
      nextBillingAt = estimateUpdate?.nextBillingAt;
      amountDue = estimateUpdate?.amountDue;
    }
  } else {
    if (selectedPeriodUnit === "month" && !hasLifetimeLicense) {
      priceTitle = "Price after trial";
    }

    nextBillingAt = estimateCreation?.nextBillingAt;
    amountDue = estimateCreation?.amountDue;
  }

  let startingDateComponent = null;
  if (!isSubscriptionCancelled) {
    startingDateComponent = isLoading ? (
      <Skeleton className="h-[17px] w-40" />
    ) : (
      <span className="text-caption-12-bold text-purple-600">
        Starting from {formatShortDate(nextBillingAt * 1000)}
      </span>
    );
  } else if (!subscription && selectedPeriodUnit === "year") {
    startingDateComponent = (
      <span className="text-caption-12-bold text-purple-600">
        Starting immediately
      </span>
    );
  }

  return (
    <div className="flex items-center justify-between px-2 py-1">
      <div className="flex flex-col gap-y-0.5">
        <span className="text-button-14 text-black-600">{priceTitle}</span>
        {startingDateComponent}
      </div>
      {isLoading ? (
        <Skeleton className="h-6 w-14" />
      ) : (
        <span className="text-body-16-bold">
          {formatPrice(amountDue / 100)}/
          {formatPeriodUnitLabel(selectedPeriodUnit)}
        </span>
      )}
    </div>
  );
}
