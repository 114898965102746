import React from "react";
import { VirtuosoGrid } from "react-virtuoso";

import { LinkedInProfile } from "common/types";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import { AudienceProfile } from "../../../../types";
import TargetProfile from "./AudienceProfile";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

interface AudiencePreviewProps {
  profiles: AudienceProfile[];
  selectedProfiles: LinkedInProfile[];
  toggleSelection: (profile: LinkedInProfile) => void;
  searchValue: string;
}

export default function AudiencePreview({
  profiles,
  selectedProfiles,
  toggleSelection,
  searchValue,
}: AudiencePreviewProps) {
  if (!profiles.length)
    return (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle={`No results found for "${searchValue}"`}
      />
    );

  return (
    <VirtuosoGrid
      totalCount={profiles.length}
      useWindowScroll
      listClassName="grid md:grid-cols-2 lg:grid-cols-3 gap-3"
      /* eslint-disable-next-line react/no-unstable-nested-components */
      itemContent={(index) => {
        const profile = profiles[index];

        const isSelected = !!selectedProfiles.find(
          ({ id }) => profile.id === id,
        );

        return (
          <TargetProfile
            profile={profile}
            isSelected={isSelected}
            toggleSelection={toggleSelection}
          />
        );
      }}
    />
  );
}
