import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";

import Email from "./Email/Email";
import Password from "./Password/Password";
import Communication from "./Communication/Communication";
import UpdateName from "./UpdateName/UpdateName";
import ProfileInfo from "./ProfileInfo";

export default function ProfileSettings() {
  const isDesktop = useTwBreakpoint("lg");

  return (
    <>
      <RenderIf condition={isDesktop}>
        <h1 className="mb-6 text-headline-2xl-bold">Profile settings</h1>
      </RenderIf>

      <ProfileInfo />

      <RenderIf condition={!isDesktop}>
        <h1 className="mt-4 text-headline-lg-bold">Profile settings</h1>
      </RenderIf>

      <div className="mx-auto w-full max-w-[1000px]">
        <UpdateName />
        <Email />
        <Password />
        <Communication />
      </div>
    </>
  );
}
