import React from "react";

import { Lead } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Avatar from "common/components/ui/Avatar";
import RenderIf from "common/components/RenderIf";

export default function GroupConversationAvatar({
  participants,
}: {
  participants: Lead[];
}) {
  const membersCount = participants.length;

  return (
    <div className="flex flex-row -space-x-4 rtl:space-x-reverse">
      {participants.slice(0, 2).map((participant) => {
        const { id, picture_url: pictureUrl } = participant;

        return (
          <ProfileImage
            key={id}
            size="sm"
            className="ring-2 ring-white"
            src={pictureUrl}
          />
        );
      })}

      <RenderIf condition={membersCount - 2 !== 0}>
        <Avatar
          variant="secondary"
          className="rounded-xl ring-2 ring-white"
          size="sm"
        >
          <span className="text-caption-12-bold">+{membersCount - 2}</span>
        </Avatar>
      </RenderIf>
    </div>
  );
}
