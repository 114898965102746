import React from "react";
import { X } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Sheet,
  SheetContent,
  SheetClose,
} from "common/components/ui/Sheet/Sheet";
import LeadNotes from "common/components/LeadNotes";
import CurrentExperience from "common/components/LeadPreview/CurrentExperience";
import About from "common/components/LeadPreview/About";
import { Tag } from "common/components/ui/Tag";
import LeadHeading from "common/components/LeadPreview/LeadHeading";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Lead } from "common/types";

import { ConversationParticipant } from "../../../pages/inbox/types";

interface LeadPreviewProps {
  lead: Lead | ConversationParticipant;
  isOpen: boolean;
  onClose: () => void;
}

function LeadPreview({ lead, isOpen, onClose }: LeadPreviewProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const content = lead && (
    <>
      <LeadHeading
        lead={lead}
        isPreview
        canSendMessage={!("is_lead" in lead)}
        isLoading={false}
      />
      <About lead={lead} isLoading={false} />
      <CurrentExperience currentExperience={lead.current_experience} />
      {(!("is_lead" in lead) || lead.is_lead) && (
        <div className="rounded-20 bg-white p-4">
          <div className="mb-2 mr-auto flex items-center gap-x-1 text-button-14">
            Notes
            {!!lead.notes.length && (
              <Tag variant="yellow">{lead.notes.length}</Tag>
            )}
          </div>
          <LeadNotes notes={lead.notes} profileId={lead.id} />
        </div>
      )}
    </>
  );

  const Component = isTabletOrDesktop ? Sheet : Drawer;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      {isTabletOrDesktop ? (
        <SheetContent className="flex w-[568px] flex-col gap-y-2 overflow-y-auto rounded-tl-[32px] bg-black-50 p-6 scrollbar-thin">
          <SheetClose asChild>
            <Button
              className="mb-2 ml-auto"
              variant="quaternary-black"
              intent="iconOnly"
            >
              <X />
            </Button>
          </SheetClose>
          {content}
        </SheetContent>
      ) : (
        <DrawerContent className="bg-black-100">
          <div className="flex flex-col gap-y-2 bg-black-100">{content}</div>
        </DrawerContent>
      )}
    </Component>
  );
}
LeadPreview.displayName = "LeadPreview";

export default LeadPreview;
