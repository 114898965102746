import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "common/components/ui/Button";

import AimfoxLogo from "assets/aimfoxLogos/aimfox-logo-text.svg?react";

export default function CrashPage({
  resetError,
  eventId,
}: {
  resetError: () => void;
  eventId: string;
}) {
  const location = useLocation();
  const errorLocationRef = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocationRef.current) {
      resetError();
    }
  }, [location.pathname]);

  return (
    <div className="mx-auto flex h-dvh max-w-[1000px] grow flex-col items-center justify-center px-4 lg:px-32">
      <AimfoxLogo className="mb-8" />

      <p className="mb-4 text-center text-headline-xl-bold">
        Something went wrong, please try again later
      </p>

      <span className="my-4 text-center  text-body-16-regular text-black-500">
        Event ID:{" "}
        <span className="text-body-16-bold text-black-950">{eventId}</span>
      </span>

      <Button size="lg" onClick={resetError}>
        Reload Page
      </Button>
    </div>
  );
}
