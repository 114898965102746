import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import useConversationMessages from "../../datahooks/useConversationMessages";
import Message from "./Message/Message";
import { Conversation, ConversationMessage } from "../../types";
import { formatConversationMessagesDate } from "../../utils";

interface MessageThreadProps {
  conversation: Conversation;
  isLoadingConversations: boolean;
  setEditMessage: (message: ConversationMessage) => void;
}

export default function MessageThread({
  conversation,
  isLoadingConversations,
  setEditMessage,
}: MessageThreadProps) {
  const {
    owner: accountId,
    conversation_urn: conversationUrn,
    unread_count: unreadCount,
    last_message: lastMessage,
    participants,
  } = conversation;

  const { messages, loadNextMessages, isLoadingMessages, hasMoreMessages } =
    useConversationMessages({
      accountId,
      conversationUrn,
      unreadCount,
      lastMessageCreatedAt: lastMessage?.created_at,
    });

  let messagesComponents = [];

  if (isLoadingMessages || isLoadingConversations) {
    messagesComponents = new Array(6).fill({}).map((message, index) => (
      <Message
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        message={message}
        setEditMessage={setEditMessage}
        participants={participants}
        accountId={accountId}
        isLoading
      />
    ));
  } else if (messages) {
    for (let i = 0; i < messages.length; i += 1) {
      const message = messages[i];
      const date = new Date(message.created_at);

      messagesComponents.push(
        <Message
          key={message.urn}
          message={message}
          setEditMessage={setEditMessage}
          participants={participants}
          accountId={accountId}
          isLoading={false}
        />,
      );
      const nextDate = new Date(messages[i + 1]?.created_at);

      if (
        !nextDate ||
        date.getDate() !== nextDate.getDate() ||
        date.getMonth() !== nextDate.getMonth()
      ) {
        const formattedDate = formatConversationMessagesDate(date);

        messagesComponents.push(
          <div
            className="my-4 flex flex-row items-center justify-center gap-4"
            key={formattedDate}
          >
            <div className="h-px w-full bg-black-200" />

            <span className="text-nowrap rounded-full bg-purple-50 px-4 py-1 text-caption-12-bold text-purple-500 shadow-sm">
              {formattedDate}
            </span>

            <div className="h-px w-full bg-black-200" />
          </div>,
        );
      }
    }
  }

  return (
    <div
      id="thread-scroll-container"
      className="relative flex flex-1 flex-col-reverse overflow-y-auto scrollbar-thin lg:mt-28 lg:px-4 lg:py-2"
    >
      <InfiniteScroll
        className="flex flex-col-reverse overflow-hidden"
        inverse
        next={loadNextMessages}
        hasMore={hasMoreMessages}
        dataLength={messagesComponents.length}
        loader={
          <>
            {new Array(6).fill({}).map((message, index) => (
              <Message
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                message={message}
                setEditMessage={setEditMessage}
                participants={participants}
                accountId={accountId}
                isLoading
              />
            ))}
          </>
        }
        scrollableTarget="thread-scroll-container"
      >
        {messagesComponents}
      </InfiniteScroll>
    </div>
  );
}
