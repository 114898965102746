import React, { Dispatch, SetStateAction } from "react";
import { produce } from "immer";
import { useTranslation } from "react-i18next";
import {
  ClockCountdown,
  GlobeHemisphereWest,
  Info,
} from "@phosphor-icons/react";

import { typedObjectEntries } from "common/helpers/utils";
import { TimezoneOption } from "common/constants/timezones";
import { ScheduleInterval, Schedule as ScheduleType } from "common/types";
import { Button } from "common/components/ui/Button";
import {
  TooltipContent,
  TooltipTrigger,
  Tooltip,
} from "common/components/ui/Tooltip";

import ScheduleItem from "./ScheduleItem";
import TimezoneSelect from "./TimezoneSelect";

interface ScheduleProps {
  schedule: ScheduleType;
  timezone: TimezoneOption;
  setSchedule: Dispatch<SetStateAction<ScheduleType>>;
  setSelectedTimezone: Dispatch<SetStateAction<TimezoneOption>>;
  isDisabled?: boolean;
}

export default function Schedule({
  schedule,
  timezone,
  setSchedule,
  setSelectedTimezone,
  isDisabled = false,
}: ScheduleProps) {
  const { t } = useTranslation("campaigns");

  function setRanges(day: keyof ScheduleType, ranges: ScheduleInterval[]) {
    setSchedule((prevSchedule) => {
      const newSchedule = produce(prevSchedule, (draftState) => {
        if (!ranges.length) {
          draftState[day].intervals = [];
        } else {
          draftState[day].intervals = ranges;
        }
      });
      if (Object.values(newSchedule).some(({ intervals }) => !!intervals)) {
        return newSchedule;
      }
      return prevSchedule;
    });
  }

  function applyToActiveDays(ranges: ScheduleInterval[]) {
    typedObjectEntries(schedule)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, { intervals }]) => intervals.length)
      .forEach(([key]) => {
        setRanges(key, ranges);
      });
  }

  return (
    <article className="rounded-2xl border border-black-200 px-3 py-4 md:px-4">
      {/* Timezone */}
      <div className="mb-4 flex flex-col items-center gap-3 border-b border-black-200 pb-4 md:flex-row">
        <div className="flex w-full gap-3 lg:items-center">
          <Button intent="labelIcon" variant="secondary-black">
            <GlobeHemisphereWest />
          </Button>

          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-button-16"> {t("timezoneTitle")}</h3>
              <Tooltip>
                <TooltipTrigger>
                  <Info weight="fill" size={20} className="fill-black-300" />
                </TooltipTrigger>
                <TooltipContent>{t("timezoneTooltip")}</TooltipContent>
              </Tooltip>
            </div>

            <p className="text-caption-12-regular text-black-500">
              {t("timezoneDescription")}
            </p>
          </div>
        </div>

        <TimezoneSelect
          selectedTimezone={timezone}
          onSelect={(newTimezone) => setSelectedTimezone(newTimezone)}
          isDisabled={isDisabled}
        />
      </div>

      {/* Working hours */}
      <div className="flex items-center gap-3">
        <Button intent="labelIcon" variant="secondary-black">
          <ClockCountdown />
        </Button>
        <div>
          <h3 className="text-button-16"> {t("workingHoursTitle")}</h3>
          <p className="text-caption-12-regular text-black-500">
            {t("workingHoursDescription")}
          </p>
        </div>
      </div>

      {typedObjectEntries(schedule).map(([day, { intervals }]) => (
        <ScheduleItem
          isDisabled={isDisabled}
          key={day}
          day={day}
          ranges={intervals}
          setRanges={(ranges: ScheduleInterval[]) => {
            setRanges(day, ranges);
          }}
          onApplyToAll={applyToActiveDays}
        />
      ))}
    </article>
  );
}
