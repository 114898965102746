import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";

import useCampaignFlow from "../../../../datahooks/useCampaignFlow";
import { FlowNameSchema } from "../../../../schemas";
import { CampaignFlow } from "../../../../types";

interface FlowActionsDialogProps {
  flow: CampaignFlow;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: "delete" | "edit";
  deleteFlow?: (flowId: string) => void;
}

export default function FlowActionsDialog({
  flow,
  isOpen,
  setIsOpen,
  type,
  deleteFlow = null,
}: FlowActionsDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { updateFlow, isUpdatingFlow } = useCampaignFlow();

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: flow.name,
    },
    resolver: valibotResolver(FlowNameSchema),
  });

  function onSave({ name }: { name: string }) {
    updateFlow({
      flowId: flow.id,
      updates: {
        name,
      },
    }).then(() => {
      reset({ name });
      setIsOpen(false);
    });
  }

  function onConfirm() {
    if (type === "edit") {
      handleSubmit(onSave)();
    } else {
      deleteFlow(flow.id);
      setIsOpen(false);
    }
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content>
        <h3 className="mb-2 text-center text-headline-2xl-bold">
          {type === "edit" ? "Change Flow name" : `Delete ${flow.name}`}
        </h3>
        <p className="text-center text-black-400">
          {type === "edit"
            ? "You can change flow's name here"
            : `Are you sure you want to delete ${flow.name}?`}
        </p>
        <RenderIf condition={type === "edit"}>
          <MaxLengthInput
            variant="lg"
            className="mt-4"
            error={errors.name?.message}
            characterLength={watch("name").length}
            maxLength={getSchemaMaxLength(FlowNameSchema.entries.name)}
            {...register("name")}
          />
        </RenderIf>
        <Button
          size="lg"
          isLoading={isUpdatingFlow}
          variant={type === "edit" ? "primary-black" : "tertiary-danger"}
          className="mb-3 mt-6"
          onClick={onConfirm}
        >
          {type === "edit" ? "Save" : "Yes"}
        </Button>
        <Button
          size="lg"
          variant="tertiary-black"
          onClick={() => {
            setIsOpen(false);
            reset();
          }}
        >
          {type === "edit" ? "Cancel" : "No"}
        </Button>
      </Content>
    </Component>
  );
}
