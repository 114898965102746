import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const tabVariants = cva(
  [
    "inline-flex items-center justify-center whitespace-nowrap transition-colors",
    "data-[state=inactive]:text-black-400",
    "disabled:cursor-not-allowed",
  ],
  {
    variants: {
      variant: {
        default: [
          "data-[state=active]:bg-black-100 data-[state=active]:text-black-950",
          "data-[state=inactive]:hover:text-black-950",
          "hover:bg-black-200",
        ],
        purple: [
          "data-[state=active]:bg-purple-100 data-[state=active]:text-purple-600",
          "data-[state=inactive]:hover:text-purple-600",
          "hover:bg-purple-200",
        ],
      },
      size: {
        xs: "h-6 gap-1.5 rounded-md px-3 text-caption-10-semibold [&_svg]:size-4",
        sm: "h-8 gap-1.5 rounded-lg px-4 text-button-12 [&_svg]:size-4",
        md: "h-10 gap-2 rounded-xl px-4 text-button-14 [&_svg]:size-5",
        lg: "h-12 gap-2.5 rounded-xl px-6 text-button-16 [&_svg]:size-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  },
);

const TabVariantContext = createContext<VariantProps<typeof tabVariants>>({
  size: "md",
});

const Tabs = TabsPrimitive.Root;

const TabsContent = TabsPrimitive.Content;

type TabsListProps = ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
  VariantProps<typeof tabVariants>;

const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  TabsListProps
>(({ className, size, variant, ...props }, ref) => {
  const variants = useMemo(() => ({ size, variant }), []);

  return (
    <TabVariantContext.Provider value={variants}>
      <TabsPrimitive.List
        ref={ref}
        className={cn(
          "flex items-center justify-center border-b border-b-black-200 overflow-x-auto scrollbar-thin",
          className,
        )}
        {...props}
      />
    </TabVariantContext.Provider>
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { size, variant } = useContext(TabVariantContext);

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(tabVariants({ size, variant, className }))}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
