import React, { useState } from "react";

import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Lead } from "common/types";

import useRecentLeads from "../../datahooks/useRecentLeads";
import RecentLeadsItem from "./RecentLeadsItem";
import RecentLeadsItemLoader from "./RecentLeadsItemLoader";
import LeadPreview from "../LeadPreview";

import ProfilesNotFoundImage from "assets/images/empty-placeholders/no-profiles.png";

interface RecentLeadsProps {
  flowId?: string;
  campaignId?: string;
  showFlowOrigin?: boolean;
  showCampaignOrigin?: boolean;
  accountIds: string[];
}

export default function RecentLeads({
  flowId = null,
  campaignId = null,
  showFlowOrigin = false,
  showCampaignOrigin = false,
  accountIds,
}: RecentLeadsProps) {
  const {
    recentLeads,
    isLoadingRecentLeads,
    recentLeadsError,
    refetchRecentLeads,
  } = useRecentLeads({ flowId, campaignId, accountIds });

  const [selectedLead, setSelectedLead] = useState<Lead>(null);
  const [isLeadPreviewOpen, setIsLeadPreviewOpen] = useState(false);

  function getContent() {
    if (isLoadingRecentLeads) {
      return <RecentLeadsItemLoader itemsCount={6} />;
    }

    if (recentLeadsError) {
      return (
        <ErrorPlaceholder
          className="my-8"
          errorMessage="Could not display recent Leads"
          onRetry={refetchRecentLeads}
        />
      );
    }

    if (recentLeads && recentLeads.length) {
      return (
        <div className="mt-6 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
          {recentLeads.map((lead) => {
            const { target, target_id: targetId } = lead;

            return (
              <RecentLeadsItem
                key={targetId}
                lead={lead}
                showFlowOrigin={showFlowOrigin}
                showCampaignOrigin={showCampaignOrigin}
                onLeadSelect={() => {
                  setSelectedLead(target);
                  setIsLeadPreviewOpen(true);
                }}
              />
            );
          })}

          <LeadPreview
            lead={selectedLead}
            isOpen={isLeadPreviewOpen && !!selectedLead}
            onClose={() => {
              setIsLeadPreviewOpen(false);
            }}
          />
        </div>
      );
    }
    let emptySubtitle =
      "You do not have any recent leads at this time. Make sure you have at least one active ";

    if (flowId) {
      emptySubtitle += "flow";
    } else if (campaignId) {
      emptySubtitle += "campaign";
    } else {
      emptySubtitle += "Aimfox campaign";
    }

    return (
      <EmptyPlaceholder
        className="my-8"
        imageSrc={ProfilesNotFoundImage}
        title="No Recent Leads"
        subtitle={emptySubtitle}
      />
    );
  }

  let subtitleSuffix = "Aimfox";

  if (flowId) {
    subtitleSuffix = "this flow";
  } else if (campaignId) {
    subtitleSuffix = "this campaign";
  }

  return (
    <section className="my-8 flex flex-col gap-4">
      <div className="flex flex-col">
        <h3 className="text-headline-xl-bold">Recent Leads</h3>
        <span className="text-black-500">
          The latest leads you have gained through {subtitleSuffix}
        </span>
      </div>

      {getContent()}
    </section>
  );
}
