import React from "react";
import { useTranslation } from "react-i18next";
import { Translate } from "@phosphor-icons/react";

import Switch from "common/components/ui/Switch";
import { Button } from "common/components/ui/Button";

interface AutoTranslateProps {
  translate: boolean;
  isDisabled: boolean;
  setTranslate: (isTranslateEnabled: boolean) => void;
}

export default function AutoTranslate({
  translate,
  isDisabled,
  setTranslate,
}: AutoTranslateProps) {
  const { t } = useTranslation("campaigns", { keyPrefix: "campaignCreation" });

  return (
    <section className="flex items-center gap-3 rounded-xl bg-black-50 py-2 max-md:items-center max-md:px-2 md:pr-2">
      <div className="flex flex-1 items-center gap-2 md:gap-3">
        <Button variant="quaternary-black" intent="labelIcon">
          <Translate weight="fill" />
        </Button>
        <div>
          <h4 className="mb-0.5 md:text-button-16">
            {t("automaticTranslationTitle")}
          </h4>
          <p className="hidden text-caption-12-regular text-black-500 md:block">
            {t("automaticTranslationSubtitle")}
          </p>
        </div>
      </div>

      <Switch
        checked={translate}
        onCheckedChange={setTranslate}
        disabled={isDisabled}
      />
    </section>
  );
}
