import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FlowsPreview from "./FlowsPreview";
import Schedule from "../../Schedule";
import Stepper from "../Stepper";
import useCampaignSchedule from "../../../datahooks/useCampaignSchedule";
import {
  CampaignFlow,
  CampaignSchedule as CampaignScheduleType,
} from "../../../types";
import { formattedTimezone, isValidSchedule } from "../../../utils";

interface ScheduleProps {
  flows: CampaignFlow[];
  campaignSchedule: CampaignScheduleType;
}

export default function CampaignSchedule({
  flows,
  campaignSchedule,
}: ScheduleProps) {
  const { t } = useTranslation("campaigns");
  const { timezone: initialTimezone, ...initialSchedule } = campaignSchedule;
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(initialSchedule);
  const [selectedTimezone, setSelectedTimezone] = useState(
    formattedTimezone(initialTimezone),
  );

  const { setCampaignSchedule, isSettingCampaignSchedule } =
    useCampaignSchedule();

  function goToCampaignReview() {
    const { label, offset } = selectedTimezone;
    let timezone = null;
    if (offset) {
      timezone = {
        name: label,
        offset,
      };
    }

    setCampaignSchedule({
      schedule: {
        ...schedule,
        timezone,
      },
    }).then(() => navigate("../review"));
  }

  return (
    <>
      <FlowsPreview flows={flows} />

      <h2 className="mb-1 text-headline-xl-bold">{t("scheduleTitle")}</h2>
      <p className="mb-4 text-caption-12-regular text-black-500">
        {t("scheduleDescription")}
      </p>

      <Schedule
        schedule={schedule}
        timezone={selectedTimezone}
        setSchedule={setSchedule}
        setSelectedTimezone={setSelectedTimezone}
      />

      <Stepper
        selectedIndex={3}
        goToNextStep={goToCampaignReview}
        isNextStepDisabled={!isValidSchedule(schedule)}
        isNextButtonLoading={isSettingCampaignSchedule}
      />
    </>
  );
}
