import React from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import ProfileImage from "common/components/ui/ProfileImage";
import Checkbox from "common/components/ui/Checkbox";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

interface AccountsToCancelProps {
  goBack: () => void;
  goToNextStep: () => void;
  accountsToCancel: string[];
  setAccountsToCancel: (accounts: string[]) => void;
  seatsToCancel: number;
}

export default function AccountsToCancel({
  goBack,
  goToNextStep,
  accountsToCancel,
  setAccountsToCancel,
  seatsToCancel,
}: AccountsToCancelProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  const { accounts } = useSelectedWorkspaceContext();
  const accountsWithLicense = accounts.filter((account) => account.license);

  const isLimitReached = accountsToCancel.length === seatsToCancel;

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">Remove seats </h3>
      <p className="mb-4 text-center text-black-500">
        You will not have enough outreach seats for your currently active
        accounts. Choose which accounts will have their seats removed
      </p>

      <p className="mb-4 text-black-500">
        Selected:{" "}
        <span className="text-body-14-bold text-black-950">
          {accountsToCancel.length}/{seatsToCancel}
        </span>
      </p>

      <section className="flex flex-col gap-y-4 overflow-y-auto scrollbar-thin md:max-h-96">
        {accountsWithLicense.map(
          ({
            id,
            full_name: fullName,
            picture_url: pictureUrl,
            occupation,
          }) => {
            const isSelected = !!accountsToCancel.find(
              (accountId) => accountId === id,
            );

            function handleClick() {
              setAccountsToCancel(
                isSelected
                  ? accountsToCancel.filter((accountId) => accountId !== id)
                  : [...accountsToCancel, id],
              );
            }

            return (
              <button
                type="button"
                key={id}
                className="group flex items-center gap-x-2 rounded-2xl border border-black-200 bg-white p-3 disabled:cursor-not-allowed disabled:bg-black-200"
                onClick={handleClick}
                disabled={isLimitReached && !isSelected}
              >
                <ProfileImage src={pictureUrl} size="lg" />
                <div className="flex flex-1 flex-col gap-y-1">
                  <h5 className="line-clamp-1 text-left text-body-16-bold group-disabled:text-black-400">
                    {fullName}
                  </h5>
                  <span className="line-clamp-1  text-left text-caption-12-regular text-black-500 group-disabled:text-black-400">
                    {occupation}
                  </span>
                </div>
                <Checkbox checked={isSelected} isStyleOnly />
              </button>
            );
          },
        )}
      </section>

      <Footer className="md:-mx-16 md:mt-4">
        <Button size="lg" variant="secondary-black" onClick={goBack}>
          Back
        </Button>
        <Button size="lg" onClick={goToNextStep} disabled={!isLimitReached}>
          Next
        </Button>
      </Footer>
    </>
  );
}
