import { useReducer, useMemo } from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LicensesStep, PaymentSource, PricingPeriodUnit } from "common/types";
import { isLifetimeDeal } from "common/helpers/utils";

interface LicensesState {
  currentStep: LicensesStep;
  selectedPeriodUnit: PricingPeriodUnit;
  licenseCount: number;
  accountsToCancel: string[];
  paymentSource: PaymentSource;
}

type LicensesAction =
  | { type: "NEXT_STEP"; payload: LicensesStep }
  | { type: "SET_LICENSE_COUNT"; payload: number }
  | { type: "SET_PERIOD_UNIT"; payload: PricingPeriodUnit }
  | { type: "SET_ACCOUNTS_TO_CANCEL"; payload: string[] }
  | { type: "SET_PAYMENT_SOURCE"; payload: PaymentSource }
  | { type: "RESET"; payload: LicensesState };

function licensesReducer(
  state: LicensesState,
  action: LicensesAction,
): LicensesState {
  const { type, payload } = action;

  switch (type) {
    case "NEXT_STEP":
      return { ...state, currentStep: payload };
    case "SET_LICENSE_COUNT":
      return { ...state, accountsToCancel: [], licenseCount: payload };
    case "SET_PERIOD_UNIT":
      return { ...state, selectedPeriodUnit: payload };
    case "SET_ACCOUNTS_TO_CANCEL":
      return { ...state, accountsToCancel: payload };
    case "SET_PAYMENT_SOURCE":
      return { ...state, paymentSource: payload };
    case "RESET":
      return payload; // Reset to initial state
    default:
      return state;
  }
}

export default function useLicensesState(accountId: string | null) {
  const {
    license_count: workspaceLicenseCount,
    licenses,
    subscription,
  } = useSelectedWorkspaceContext();

  const initialLicenseCount = useMemo(() => {
    if (!subscription && !isLifetimeDeal(licenses)) return 1;
    return accountId ? workspaceLicenseCount + 1 : workspaceLicenseCount;
  }, [workspaceLicenseCount, accountId, subscription, licenses]);

  const initialPlan: PricingPeriodUnit = useMemo(() => {
    if (subscription) {
      return subscription.item_price.period_unit;
    }

    return "month";
  }, [subscription]);

  const initialStep = useMemo(() => {
    if (!subscription && !isLifetimeDeal(licenses)) {
      return "freeTrial";
    }
    return accountId ? "seatPurchase" : "manageSeats";
  }, [subscription, accountId, licenses]);

  const initialState: LicensesState = {
    currentStep: initialStep,
    selectedPeriodUnit: initialPlan,
    licenseCount: initialLicenseCount,
    accountsToCancel: [],
    paymentSource: null,
  };

  const [state, dispatch] = useReducer(licensesReducer, initialState);

  const assignedLicenseCount = licenses.filter(
    (license) => license.account_id,
  ).length;
  const shouldRemoveAccounts = state.licenseCount < assignedLicenseCount;

  function resetState() {
    dispatch({ type: "RESET", payload: initialState });
  }

  return {
    state,
    dispatch,
    resetState,
    assignedLicenseCount,
    shouldRemoveAccounts,
  };
}
