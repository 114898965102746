import React, { useState } from "react";
import { CaretDown, CaretUp, Plus } from "@phosphor-icons/react";

import LeadLabels from "common/components/LeadLabels";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";

import { Conversation } from "../../../types";

interface HeaderTagsProps {
  conversation: Conversation;
}

const maxLabels = 10;
export default function HeaderTags({ conversation }: HeaderTagsProps) {
  const [isLeadsPreviewOpen, setIsLeadsPreviewOpen] = useState(false);
  const [isLabelsExpanded, setIsLabelsExpanded] = useState(false);

  const {
    participants: [firstParticipant],
  } = conversation;

  const { labels, id, is_lead: isLead } = firstParticipant;

  return (
    <>
      {isLead && (
        <div className="flex flex-wrap items-center gap-2">
          {labels.length ? (
            <>
              {(isLabelsExpanded ? labels : labels.slice(0, maxLabels)).map(
                ({ id: labelId, name, color }) => (
                  <Tag key={labelId} variant={color}>
                    {name}
                  </Tag>
                ),
              )}
              {labels.length > maxLabels && (
                <Tag
                  variant="quaternary"
                  rightIcon={isLabelsExpanded ? <CaretUp /> : <CaretDown />}
                  asChild
                >
                  <button
                    type="button"
                    onClick={() => setIsLabelsExpanded(!isLabelsExpanded)}
                  >
                    {isLabelsExpanded
                      ? "Hide"
                      : `+ ${labels.length - maxLabels} more`}
                  </button>
                </Tag>
              )}

              <Button
                variant="quaternary-black"
                size="sm"
                intent="iconOnly"
                onClick={() => setIsLeadsPreviewOpen(true)}
              >
                <Plus />
              </Button>
            </>
          ) : (
            <Tag size="lg" variant="tertiary" asChild leftIcon={<Plus />}>
              <button type="button" onClick={() => setIsLeadsPreviewOpen(true)}>
                Add tag
              </button>
            </Tag>
          )}
        </div>
      )}

      {/* Modal for creating tags */}
      <LeadLabels
        labels={labels}
        profileId={id}
        isOpen={isLeadsPreviewOpen}
        onClose={() => setIsLeadsPreviewOpen(false)}
      />
    </>
  );
}
