import { FacetParam, LeadsSearchParams, SelectedFacetParam } from "../types";
import { defaultLeadsSearchParams } from "../constants";

export default function useLeadsSearchParams(
  searchParams: LeadsSearchParams<SelectedFacetParam>,
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void,
) {
  function toggleSearchParam(
    key: keyof Omit<LeadsSearchParams<never>, "keywords">,
    value: SelectedFacetParam,
  ) {
    if (
      searchParams[key].some(({ id }) =>
        Array.isArray(value.id) && Array.isArray(id)
          ? value.id.every((string) => id.includes(string))
          : value.id === id,
      )
    ) {
      setSearchParams({
        [key]: (searchParams[key] as FacetParam[]).filter((item) =>
          Array.isArray(value.id) && Array.isArray(item.id)
            ? !value.id.every((string) => item.id.includes(string))
            : value.id !== item.id,
        ),
      });
    } else {
      setSearchParams({
        [key]: [
          ...(searchParams[key] as FacetParam[]),
          { id: value.id, name: value.name },
        ],
      });
    }
  }
  function resetSearchParam(param: keyof LeadsSearchParams<never>) {
    setSearchParams({
      [param]: defaultLeadsSearchParams[param],
    });
  }
  function setSearchParam(key: string, value: string) {
    setSearchParams({ [key]: value });
  }
  function resetAllParams() {
    setSearchParams({
      ...defaultLeadsSearchParams,
      keywords: searchParams.keywords,
    });
  }
  return {
    setSearchParam,
    toggleSearchParam,
    resetSearchParam,
    resetAllParams,
  };
}
