import React, { JSX } from "react";
import { clsx } from "clsx";

export default function BadgeWrapper({
  children,
  badgeClassName,
}: {
  children: JSX.Element;
  badgeClassName: string;
}) {
  return (
    <div className="relative">
      {children}
      <div
        className={clsx(
          "absolute rounded-full border bg-red-500",
          badgeClassName,
        )}
      />
    </div>
  );
}
