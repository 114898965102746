import React, { Suspense } from "react";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import { DayPicker } from "react-day-picker";

import "react-day-picker/style.css";
import { Button } from "common/components/ui/Button";
import useCustomer from "common/datahooks/useCustomer";
import { formatDate } from "common/helpers/utils";

import type { DateRange } from "react-day-picker";

export default function DatePicker({
  selectedRange,
  setSelectedRange,
}: {
  selectedRange: { from: number; to: number };
  setSelectedRange: (range: { from: number; to: number }) => void;
}) {
  const { customerInfo } = useCustomer();

  const selectedDates = {
    from: new Date(selectedRange.from),
    to: new Date(selectedRange.to),
  };

  function onSelect(newSelection: DateRange) {
    if (newSelection) {
      setSelectedRange({
        from:
          newSelection.from.getTime() -
          newSelection.from.getTimezoneOffset() * 60 * 1000,
        to:
          newSelection.to.getTime() -
          newSelection.to.getTimezoneOffset() * 60 * 1000,
      });
    }
  }

  const minDate = new Date(customerInfo.created_at * 1000);
  const maxDate = new Date();
  return (
    <Suspense fallback={<div>Loading</div>}>
      <div className="flex flex-col rounded-2xl border border-black-200">
        <div className="border-b border-b-black-200 px-4 py-3 text-caption-12-regular">
          {`${formatDate(selectedDates.from.getTime())} - ${formatDate(selectedDates.to.getTime())}`}
        </div>
        <DayPicker
          mode="range"
          showOutsideDays
          selected={selectedDates}
          onSelect={onSelect}
          required
          classNames={{
            month_grid: "border-separate border-spacing-y-0.5",
            selected: "!bg-black-950 text-white",
            day: "text-caption-10-regular h-6 w-8 [&:not([data-selected])]:rounded-lg hover:[&:not([data-selected],[data-today])]:bg-black-100",
            day_button: "w-full h-full",
            today: "bg-purple-100",
            range_start: "rounded-l-lg",
            range_end: "rounded-r-lg",
            range_middle: "rounded-none",
            root: "px-4 py-3",
            weekday: "text-caption-10-regular text-black-400",
            cell: "bg-red-200",
            outside: "text-black-400",
            nav: "justify-between w-full flex mb-4 absolute",
            month_caption: "text-button-12 mb-3 h-6 flex justify-center",
            disabled: "text-black-400 pointer-events-none",
          }}
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            Button: (props) => (
              <Button
                size="xs"
                variant="quaternary-black"
                intent="iconOnly"
                {...props}
              />
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            Chevron: ({
              orientation = null,
            }: {
              orientation?: "right" | "left" | "up" | "down";
            }) => (orientation === "right" ? <CaretRight /> : <CaretLeft />),
          }}
          startMonth={minDate}
          endMonth={maxDate}
          disabled={(date) =>
            date.getTime() < minDate.getTime() ||
            date.getTime() > maxDate.getTime()
          }
        />
      </div>
    </Suspense>
  );
}
