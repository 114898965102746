import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { get, patch } from "../helpers/HTTP";
import { Notification } from "../types";

async function getNotifications(): Promise<Notification[]> {
  return (await get(`notifications`)).notifications;
}
async function openNotificationRequest(notificationId: string) {
  await patch(`notifications/${notificationId}`);
  return notificationId;
}

export default function useNotifications() {
  const queryKey = ["notifications"];
  const queryClient = useQueryClient();
  const { data: notifications = [] } = useQuery({
    queryKey,
    queryFn: getNotifications,
  });

  const { mutateAsync: openNotification } = useMutation({
    mutationFn: ({ notificationId }: { notificationId: string }) =>
      openNotificationRequest(notificationId),
    onSuccess: (notificationId) => {
      queryClient.setQueryData(
        queryKey,
        (prevNotifications: { id: string; seen: boolean }[]) => {
          const index = prevNotifications.findIndex(
            ({ id }) => id === notificationId,
          );
          const clonedNotifications = [...prevNotifications];
          clonedNotifications[index] = {
            ...clonedNotifications[index],
            seen: true,
          };
          return clonedNotifications;
        },
      );
    },
  });

  const unseenCount = notifications.reduce(
    (accumualtor: number, currentValue) => {
      if (!currentValue.seen) {
        return accumualtor + 1;
      }
      return accumualtor;
    },
    0,
  );

  return {
    notifications,
    unseenCount,
    openNotification,
  };
}
