import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import clsx from "clsx";

import { templateTypes } from "common/constants";
import { FlowInmailTemplateSchema } from "common/schemas";
import { getSchemaMaxLength } from "common/helpers/utils";
import { TemplateType } from "common/types";
import { VariableEditor } from "common/components/VariableEditor";

interface TemplateEditProps {
  form: UseFormReturn<{ message: string; subject?: string }>;
  templateType: TemplateType;
  isDisabled: boolean;
}
export default function TemplateEdit({
  form,
  templateType,
  isDisabled,
}: TemplateEditProps) {
  const subjectLength = form.watch("subject")?.length;
  const subjectMaxLength = getSchemaMaxLength(
    FlowInmailTemplateSchema.entries.subject,
  );

  return (
    <>
      {templateType === "INMAIL_TEMPLATE" && (
        <div className="mb-3 flex items-center justify-between gap-4 border-b border-b-black-200 pb-3 text-black-700">
          <input
            type="text"
            placeholder="Subject"
            className="flex-1 bg-transparent text-button-14 outline-none"
            {...form.register("subject")}
          />
          <span
            className={clsx(
              "text-caption-12-regular",
              subjectLength > subjectMaxLength
                ? "text-red-500"
                : "text-black-400",
            )}
          >
            {subjectLength}/{subjectMaxLength}
          </span>
        </div>
      )}

      <Controller
        control={form.control}
        render={({ field: { value, onBlur, onChange, ref } }) => (
          <VariableEditor
            ref={ref}
            value={value}
            onChange={onChange}
            maxLength={getSchemaMaxLength(
              templateTypes[templateType].validationSchema.entries.message,
            )}
            onBlur={onBlur}
            isDisabled={isDisabled}
          />
        )}
        name="message"
      />
    </>
  );
}
