import clsx from "clsx";
import React from "react";

import { flowTypeOptions } from "../constants";
import { CampaignFlowType } from "../types";

export default function FlowTypeLabel({ type }: { type: CampaignFlowType }) {
  const { icon: Icon, classNames } = flowTypeOptions[type];

  return (
    <span
      className={clsx(
        "inline-flex size-12 items-center justify-center rounded-full",
        classNames,
      )}
    >
      <Icon size={24} />
    </span>
  );
}
