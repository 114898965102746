import { createContext, useContext } from "react";

import { CampaignFlow } from "../types";

const CampaignFlowContext = createContext<{
  flow: CampaignFlow;
  isFlowError?: boolean;
  canEditFlow?: boolean;
  resetFlowError?: () => void;
}>(null);

export const CampaignFlowProvider = CampaignFlowContext.Provider;

export function useCampaignFlowContext() {
  const context = useContext(CampaignFlowContext);
  if (!context) {
    throw new Error(
      "useCampaignFlowContext must be used within a CampaignFlowProvider",
    );
  }
  return context;
}
