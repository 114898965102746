import React, { useLayoutEffect, useReducer } from "react";
import { ArrowCounterClockwise } from "@phosphor-icons/react";
import clsx from "clsx";

import { ProfileSearchParams } from "common/types";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";

import { Targeting } from "../../../../types";
import { SearchParamsProvider } from "./SearchParamsContext";
import PreviewResults from "./PreviewResults";
import SearchParams from "./SearchParams";
import TargetingInfo from "./TargetingInfo";
import useTargeting from "../../../../datahooks/useTargeting";
import Stepper from "../../Stepper";
import useProfilesPreview from "../../../../datahooks/useProfilesPreview";

const defaultSearchParams: ProfileSearchParams = {
  keywords: [],
  locations: [],
  industries: [],
  current_companies: [],
  past_companies: [],
  followers_of: null,
  schools: [],
  languages: [],
  skills: [],
};

export default function SearchPreview({ targeting }: { targeting: Targeting }) {
  const [searchParams, setSearchParams] = useReducer(
    (
      prevState: ProfileSearchParams,
      newState: Partial<ProfileSearchParams>,
    ) => ({
      ...prevState,
      ...newState,
    }),
    { ...defaultSearchParams, ...targeting },
  );

  const { setTargeting, isSettingTargeting } = useTargeting();

  const hasParams = Object.values(searchParams).some((param) =>
    Array.isArray(param) ? param.length : param,
  );

  const { profiles, isLoadingProfiles, totalCount, isLimitExceeded } =
    useProfilesPreview({
      searchParams,
    });

  useLayoutEffect(() => {
    // this is used to reset search params when campaign targeting is reset
    setSearchParams({ ...defaultSearchParams, ...targeting });
  }, [targeting]);

  return (
    <>
      <SearchParamsProvider value={{ searchParams, setSearchParams }}>
        <div className={clsx("mb-4", hasParams && "flex items-center")}>
          <ConditionalWrapper
            condition={hasParams}
            renderWrapper={(children) => (
              <div className="inline-flex flex-col">{children}</div>
            )}
          >
            <h2 className="text-headline-xl-bold">Targeting Setup</h2>
            <p className="text-black-500">
              Find targets for this campaign by adding relevant keywords and
              filters. You can add up to 6 keywords and unlimited filters
            </p>
          </ConditionalWrapper>
          <RenderIf condition={hasParams}>
            <Button
              className="ml-auto"
              size="sm"
              variant="secondary-black"
              leftIcon={<ArrowCounterClockwise />}
              onClick={() => setSearchParams(defaultSearchParams)}
            >
              Reset all
            </Button>
          </RenderIf>
        </div>

        <SearchParams
          totalCount={totalCount}
          isLoadingProfiles={isLoadingProfiles}
        />

        <PreviewResults profiles={profiles} isLoading={isLoadingProfiles} />
      </SearchParamsProvider>

      <RenderIf condition={!!profiles?.length}>
        <TargetingInfo isLimitExceeded={isLimitExceeded} />
      </RenderIf>

      <Stepper
        selectedIndex={0}
        isNextStepDisabled={!profiles?.length}
        isNextButtonLoading={isSettingTargeting}
        goToNextStep={() => setTargeting({ campaignTargeting: searchParams })}
      />
    </>
  );
}
