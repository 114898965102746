import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function usePageTitle() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  // the current path without the workspaceId
  const strippedPathname = location.pathname.replace(
    `/workspaces/${workspaceId}/`,
    "",
  );
  const currentPathComponents = strippedPathname.split("/");

  const translationNamespace = currentPathComponents[0];
  let translationPathComponents = [currentPathComponents[1]];

  const hasNestedTitle = i18n.exists(
    [...translationPathComponents, "title"].join("."),
    {
      ns: translationNamespace,
    },
  );
  if (!hasNestedTitle) {
    translationPathComponents = [];
  }

  return t([...translationPathComponents, "title"].join("."), {
    ns: translationNamespace,
  });
}
