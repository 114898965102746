import React, { useEffect, useRef, useState } from "react";
import { CreditCard } from "@phosphor-icons/react";

import useGTM from "common/hooks/useGTM";
import { HTTPError } from "common/helpers/HTTP";
import useBraintreeToken from "common/datahooks/useBraintreeToken";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { Button } from "common/components/ui/Button";
import usePaymentSources from "common/datahooks/usePaymentSources";
import { PaymentSource } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import CreditCardInput from "./CreditCardInput";
import PaypalCheckout from "./PaypalCheckout";

interface AddPaymentMethodProps {
  onBack: () => void;
  onNext?: (newPaymentSource: PaymentSource) => void;
}

export default function AddPaymentMethod({
  onBack,
  onNext = null,
}: AddPaymentMethodProps) {
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [braintreeClient, setBraintreeClient] = useState<BraintreeClient>(null);
  const [isParsingCard, setIsParsingCard] = useState(false);
  const { subscription } = useSelectedWorkspaceContext();
  const { setPrimaryPaymentSource, isSettingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  const getTokenFunctionRef = useRef<() => Promise<string>>();
  const {
    addPaymentSource,
    isAddingPaymentSource,
    addPaymentSourceError,
    resetAddPaymentSourceError,
  } = usePaymentSources();

  const { braintreeToken } = useBraintreeToken();
  const { sendEvent } = useGTM();

  useEffect(() => {
    if (braintreeToken) {
      braintree.client
        .create({
          authorization: braintreeToken,
        })
        .then((clientInstance) => {
          setBraintreeClient(clientInstance);
        });
    }
  }, [braintreeToken]);

  useEffect(() => {
    if (window.Chargebee) {
      window.Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEBEE_KEY,
      });
    }
  }, []);

  function onAdd(token: string, source: "card" | "paypal" | "apple-pay") {
    addPaymentSource({ token, source })
      .then((paymentSource) => {
        sendEvent("add_payment_source_success", ({ email }) => ({
          email,
          payment_method: source,
        }));

        if (subscription) {
          setPrimaryPaymentSource({ paymentSource }).then(() => onBack());
          return;
        }

        if (onNext) {
          onNext(paymentSource);
        }
      })
      .catch(() => {
        sendEvent("add_payment_source_fail", ({ email }) => ({
          email,
          payment_method: source,
        }));
      });
  }

  async function onCreditCardSave() {
    let token;
    try {
      resetAddPaymentSourceError();
      setIsParsingCard(true);
      token = await getTokenFunctionRef.current();
    } finally {
      setIsParsingCard(false);
    }
    onAdd(token, "card");
  }

  const isLoading =
    isAddingPaymentSource || isParsingCard || isSettingPrimaryPaymentSource;

  let content = (
    <div className="flex flex-col gap-4">
      <button
        className="flex w-full items-center justify-center gap-2 rounded-2xl bg-purple-50 py-4 text-body-14-bold"
        onClick={() => setIsAddingCard(true)}
        type="button"
      >
        <CreditCard weight="fill" size={20} />
        Credit Card
      </button>

      {braintreeClient && (
        <PaypalCheckout
          onTokenReceived={(token) => onAdd(token, "paypal")}
          braintreeClient={braintreeClient}
        />
      )}
    </div>
  );
  if (isAddingCard) {
    content = (
      <>
        <CreditCardInput getTokenFunctionRef={getTokenFunctionRef} />

        {addPaymentSourceError && (
          <span className="mt-3 text-center text-red-500">
            {(addPaymentSourceError instanceof HTTPError &&
              addPaymentSourceError.serverMessage) ||
              "Failed to add card. Please try again or contact support."}
          </span>
        )}
      </>
    );
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Add payment method
      </h3>

      <p className="mb-8 text-center text-black-500">
        Add a preferred payment method for your Aimfox subscription. You can
        change this at any time
      </p>

      {content}

      <div className="mt-10 flex justify-between gap-x-4">
        <Button
          onClick={isAddingCard ? () => setIsAddingCard(false) : onBack}
          variant="secondary-black"
          disabled={isLoading}
          size="lg"
        >
          Back
        </Button>

        {isAddingCard && (
          <Button isLoading={isLoading} onClick={onCreditCardSave} size="lg">
            Add card
          </Button>
        )}
      </div>
    </>
  );
}
