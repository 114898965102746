import React from "react";
import { PlusCircle } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import useAppStore from "common/hooks/useAppStore";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/no-profiles.png";

export default function NoLeadsPlaceholder() {
  const { accounts, role } = useSelectedWorkspaceContext();
  const hasAccount = !!accounts.length;

  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );

  return (
    <EmptyPlaceholder
      title={
        hasAccount ? "No leads found" : "Add a LinkedIn account to use LeadsCRM"
      }
      subtitle={
        hasAccount
          ? "Leads are your LinkedIn connections and accounts you can chat with. Whenever you get new lead from campaigns, they will show up here"
          : "LeadsCRM will automatically sync with your LinkedIn account, and update every time you get a new connection or conversation"
      }
      imageSrc={EmptyPlaceholderImage}
      actionComponent={
        <Button
          onClick={() => setIsCampaignModalOpen(true)}
          leftIcon={<PlusCircle weight="fill" />}
          disabled={hasAccount && role === "member"}
        >
          {hasAccount ? "Create campaign" : "Connect account"}
        </Button>
      }
    />
  );
}
