import React, { ReactNode } from "react";
import clsx from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { LicensesStep } from "common/types";

import useLicensesState from "./useLicensesState";
import ManageSeats from "./ManageSeats";
import Summary from "./Summary";
import Success from "./Success";
import AccountsToCancel from "./AccountsToCancel";
import SeatPurchase from "./SeatPurchase";
import FreeTrial from "./FreeTrial";

interface LicensesProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  accountId?: string;
}

export default function Licenses({
  isOpen,
  setIsOpen,
  accountId = null,
}: LicensesProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const {
    dispatch,
    assignedLicenseCount,
    shouldRemoveAccounts,
    resetState,
    state,
  } = useLicensesState(accountId);

  const {
    licenseCount,
    selectedPeriodUnit,
    accountsToCancel,
    currentStep,
    paymentSource,
  } = state;

  const steps: Record<LicensesStep, ReactNode> = {
    freeTrial: (
      <FreeTrial
        onNext={() =>
          dispatch({
            type: "NEXT_STEP",
            payload: accountId ? "summary" : "manageSeats",
          })
        }
      />
    ),
    seatPurchase: (
      <SeatPurchase
        onNext={() => dispatch({ type: "NEXT_STEP", payload: "summary" })}
      />
    ),
    manageSeats: (
      <ManageSeats
        selectedPeriodUnit={selectedPeriodUnit}
        setSelectedPeriodUnit={(periodUnit) =>
          dispatch({ type: "SET_PERIOD_UNIT", payload: periodUnit })
        }
        licenseCount={licenseCount}
        setLicenseCount={(newCount) => {
          dispatch({ type: "SET_LICENSE_COUNT", payload: newCount });
        }}
        goToNextStep={() =>
          dispatch({
            type: "NEXT_STEP",
            payload: shouldRemoveAccounts ? "accountsToCancel" : "summary",
          })
        }
      />
    ),
    accountsToCancel: (
      <AccountsToCancel
        goBack={() => dispatch({ type: "NEXT_STEP", payload: "manageSeats" })}
        goToNextStep={() => dispatch({ type: "NEXT_STEP", payload: "summary" })}
        accountsToCancel={accountsToCancel}
        setAccountsToCancel={(accounts) =>
          dispatch({ type: "SET_ACCOUNTS_TO_CANCEL", payload: accounts })
        }
        seatsToCancel={assignedLicenseCount - licenseCount}
      />
    ),
    summary: (
      <Summary
        licenseCount={licenseCount}
        selectedPeriodUnit={selectedPeriodUnit}
        goBack={() =>
          dispatch({
            type: "NEXT_STEP",
            payload: shouldRemoveAccounts ? "accountsToCancel" : "manageSeats",
          })
        }
        goToFreeTrialSuccess={() =>
          dispatch({ type: "NEXT_STEP", payload: "freeTrialSuccess" })
        }
        goToUpdateSubscriptionSuccess={() =>
          dispatch({ type: "NEXT_STEP", payload: "updateSubscriptionSuccess" })
        }
        accountsToCancel={accountsToCancel}
        accountId={accountId}
        paymentSource={paymentSource}
        setPaymentSource={(newPaymentSource) =>
          dispatch({
            type: "SET_PAYMENT_SOURCE",
            payload: newPaymentSource,
          })
        }
      />
    ),
    freeTrialSuccess: <Success isFreeTrial />,
    updateSubscriptionSuccess: <Success />,
  };

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen} onAfterClose={resetState}>
      <Content
        className={clsx(
          "transition-none",
          currentStep === "freeTrial"
            ? "lg:w-[940px]"
            : "max-md:pb-20 md:px-16",
        )}
      >
        {steps[currentStep]}
      </Content>
    </Component>
  );
}
