import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import clsx from "clsx";

import useGTM from "common/hooks/useGTM";
import useInvitations from "common/datahooks/useInvitations";
import { CreateWorkspace } from "common/components/CreateWorkspace";
import WorkspaceInvitation from "common/components/WorkspaceInvitation";
import Skeleton from "common/components/ui/Skeleton";
import useCustomer from "common/datahooks/useCustomer";

import useInvitation from "../../../common/datahooks/useInvitation";
import Header from "./Header";
import Questionnaire from "./Questionnaire";

export default function Onboarding() {
  const { sendEvent } = useGTM();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { customerInfo } = useCustomer();

  /* We'll need both hooks here because `useInvitations` will only get the invitations for current account,
  but with `useInvitation`(singular) we can get any invitation based on the `inviteToken` (not necessarily tied to the account) */
  const { invitation, isLoadingInvitation } = useInvitation(
    searchParams.get("invite_token"),
  );

  const { invitations, isLoadingInvitations } = useInvitations();
  const [onboardingId, setOnboardingId] = useState<
    "createWorkspace" | "invitation"
  >();

  useEffect(() => {
    sendEvent("virtualPageView", () => ({
      pageUrl: window.location.href,
      pagePath: location.pathname,
    }));
  }, []);

  useEffect(() => {
    if (!isLoadingInvitations && !isLoadingInvitation) {
      if (invitation || (invitations && invitations.length)) {
        setOnboardingId("invitation");
      } else {
        setOnboardingId("createWorkspace");
      }
    }
  }, [isLoadingInvitation, isLoadingInvitations]);

  let mainComponent = (
    <>
      <Skeleton className="mx-auto mb-6 aspect-[364/254] size-full max-w-[364px]" />
      <Skeleton className="mx-auto mb-2 h-8 w-8/12" />

      <div className="flex flex-col items-center gap-1">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-8/12" />
      </div>
    </>
  );

  if (customerInfo.show_questionnaire) {
    mainComponent = <Questionnaire />;
  } else if (onboardingId === "createWorkspace") {
    mainComponent = <CreateWorkspace />;
  } else if (onboardingId === "invitation") {
    mainComponent = (
      <WorkspaceInvitation
        invitation={invitation || (invitations && invitations[0])}
        onDecline={() => setOnboardingId("createWorkspace")}
      />
    );
  }

  const isCreateWorkspace =
    onboardingId === "createWorkspace" && !customerInfo.show_questionnaire;

  return (
    <>
      <Header
        onboardingId={onboardingId}
        showQuestionnaire={customerInfo.show_questionnaire}
        setOnboardingId={setOnboardingId}
      />

      <main
        className={clsx([
          "mx-auto flex min-h-[calc(100dvh_-_80px)] grow flex-col items-center justify-center px-4 py-6 md:py-12 lg:min-h-[calc(100dvh_-_88px)]",
          isCreateWorkspace && "max-w-[520px]",
        ])}
      >
        {mainComponent}
      </main>
    </>
  );
}
