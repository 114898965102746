import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { typedObjectEntries } from "common/helpers/utils";

import { get } from "../helpers/HTTP";
import { CampaignMetrics, MetricsParameters } from "../types";
import getQueryKeys from "./getQueryKeys";

async function getMetricsRequest(
  workspaceId: string,
  parameters: MetricsParameters,
  accountIds: string[],
  flowId?: string,
  campaignId?: string,
) {
  const searchParams = new URLSearchParams();
  if (parameters.bucketSize) {
    searchParams.append("bucket", parameters.bucketSize);
  }
  if (parameters.timestamps.from) {
    searchParams.append(
      "from",
      Math.floor(parameters.timestamps.from).toString(),
    );
  }
  if (parameters.timestamps.to) {
    searchParams.append("to", Math.floor(parameters.timestamps.to).toString());
  }
  if (campaignId) {
    searchParams.append("campaign_id", campaignId);
  }
  if (flowId) {
    searchParams.append("flow_id", flowId);
  }
  if (accountIds.length) {
    searchParams.append("account_ids", JSON.stringify(accountIds));
  }
  return (
    await get(
      `workspaces/${workspaceId}/analytics/interactions?${searchParams.toString()}`,
    )
  ).buckets;
}
export default function useMetrics({
  campaignId,
  flowId,
  parameters,
  accountIds,
}: {
  campaignId?: string;
  flowId?: string;
  parameters: MetricsParameters;
  accountIds: string[];
}) {
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();

  const { metricsKeys } = getQueryKeys(workspaceId);

  const {
    isLoading: isLoadingMetrics,
    data: metrics,
    error: metricsError,
    refetch: refetchMetrics,
  } = useQuery<(CampaignMetrics & { timestamp: number })[]>({
    queryKey: metricsKeys.list(parameters, accountIds, campaignId, flowId),
    queryFn: () =>
      getMetricsRequest(
        workspaceId,
        parameters,
        accountIds,
        flowId,
        campaignId,
      ),
    refetchInterval: 4000,
    enabled: !!accounts.length,
  });

  // the summed metrics for the provided time period
  let metricsSum: CampaignMetrics = {
    accepted_connections: 0,
    replies: 0,
    sent_connections: 0,
    sent_inmails: 0,
    sent_messages: 0,
    views: 0,
    message_requests: 0,
  };

  if (metrics) {
    metricsSum = metrics.reduce((accumulator, bucket) => {
      typedObjectEntries(bucket).forEach(([key, value]) => {
        if (key !== "timestamp") {
          accumulator[key] += value;
        }
      });

      return accumulator;
    }, metricsSum);
  }

  return {
    metrics,
    metricsSum,
    isLoadingMetrics,
    metricsError,
    refetchMetrics,
  };
}
