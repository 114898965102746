import React from "react";

import useEstimateCreation from "common/datahooks/useEstimateCreation";
import useDebounce from "common/hooks/useDebounce";
import Skeleton from "common/components/ui/Skeleton";
import { formatShortDate } from "common/helpers/utils";
import { PricingPeriodUnit } from "common/types";

import Banner from "./Banner";

interface FreeTrialBannerProps {
  licenseCount: number;
  selectedPeriodUnit: PricingPeriodUnit;
}

export default function FreeTrialBanner({
  licenseCount,
  selectedPeriodUnit,
}: FreeTrialBannerProps) {
  const debouncedLicenseCount = useDebounce(licenseCount);
  const { estimateCreation, isLoadingEstimateCreation } = useEstimateCreation({
    selectedPeriodUnit,
    quantity: debouncedLicenseCount,
  });

  const { nextBillingAt } = estimateCreation ?? {};

  return (
    <Banner
      variant="gradient"
      textNode={
        <>
          You are starting a free trial and will not be charged until the trial
          expires. Your subscription will automatically upgrade to a paid plan
          on{" "}
          {isLoadingEstimateCreation ? (
            <Skeleton className="inline-flex h-4 w-16 bg-purple-300 align-text-bottom" />
          ) : (
            formatShortDate(nextBillingAt * 1000)
          )}
          . Cancel anytime
        </>
      }
    />
  );
}
