import React, { useState } from "react";
import { Play } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import { Button } from "common/components/ui/Button";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";
import Licenses from "common/components/Licenses";
import CampaignStateTag from "common/components/CampaignStateTag";

import { DetailedCampaign } from "../../types";
import {
  CampaignActionsDialog,
  CampaignActionsDropdown,
} from "../CampaignActions";
import { campaignOutreachOptions } from "../../constants";

export default function CampaignInfo({
  campaign,
}: {
  campaign: DetailedCampaign;
}) {
  const { type, name, state, outreach_type: outreachType, owner } = campaign;

  const isLicenseAvailable = useValidateAccountLicense(owner);
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const {
    icon: SubtypeIcon,
    tagVariant: subtypeTagVariant,
    label: subtypeLabel,
  } = campaignOutreachOptions[outreachType];

  const accountTag = <LinkedInAccountsTag accountIds={[owner]} />;
  const stateTag = <CampaignStateTag state={state} owner={owner} />;
  const subtypeTag = (
    <Tag variant={subtypeTagVariant} leftIcon={<SubtypeIcon />}>
      {subtypeLabel}
    </Tag>
  );
  const startCampaignButton = (
    <RenderIf condition={state === "PAUSED" || state === "CREATED"}>
      <Button
        variant="primary-purple"
        leftIcon={<Play weight="fill" />}
        className="max-md:mb-4"
        onClick={() => setIsOpen(true)}
      >
        Start campaign
      </Button>
    </RenderIf>
  );
  const campaignTypeIcon = <CampaignTypeIcon type={type} size="lg" />;
  const actionsDropdown = (
    <CampaignActionsDropdown campaign={campaign} isDetailedView />
  );

  let mainContent;
  if (isTabletOrDesktop) {
    mainContent = (
      <>
        {campaignTypeIcon}
        <h2 className="break-words text-button-16 md:mx-3">{name}</h2>
        {subtypeTag}
        <div className="flex flex-1 items-center justify-end gap-x-4">
          <div className="flex items-center gap-x-1">
            <span className="text-caption-12-regular text-black-500">
              Run by:
            </span>
            {accountTag}
          </div>
          {stateTag}
          <span className="h-10 w-px bg-black-200" />
          {startCampaignButton}
          {actionsDropdown}
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <div className="flex w-full items-center">
          {campaignTypeIcon}
          <div className="ml-3 w-[calc(100%_-_133px)]">
            <h2 className="break-words text-button-16 md:mx-3">{name}</h2>
            <div className="mt-1 flex items-center gap-2">
              {subtypeTag} {stateTag}
            </div>
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-4">
            <span className="h-10 w-px bg-black-200" />
            {actionsDropdown}
          </div>
        </div>
        <span className="my-2 h-px w-full bg-black-200" />
        {accountTag}
      </>
    );
  }

  return (
    <>
      <section className="mb-4 flex flex-col items-start rounded-2xl border border-black-200 px-3 py-4 md:flex-row md:items-center md:px-4">
        {mainContent}
      </section>
      <RenderIf condition={!isTabletOrDesktop}>{startCampaignButton}</RenderIf>
      {isLicenseAvailable ? (
        <CampaignActionsDialog
          campaign={campaign}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type="start"
        />
      ) : (
        <Licenses isOpen={isOpen} setIsOpen={setIsOpen} accountId={owner} />
      )}
    </>
  );
}
