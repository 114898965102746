import { useQuery, keepPreviousData } from "@tanstack/react-query";

import { get, HTTPError } from "common/helpers/HTTP";
import { Invitation } from "common/types";

async function getInvitation(inviteToken: string): Promise<Invitation> {
  try {
    return (await get(`invites/${inviteToken}`)).invite;
  } catch (err) {
    if (err instanceof HTTPError && err.code === 404) {
      return null;
    }
    throw err;
  }
}

export default function useInvitation(inviteToken: string) {
  const {
    data: invitation,
    isLoading: isLoadingInvitation,
    error: invitationError,
    refetch: refetchInvitation,
  } = useQuery({
    queryKey: ["invitations", inviteToken],
    queryFn: () => getInvitation(inviteToken),
    placeholderData: keepPreviousData,
    enabled: !!inviteToken,
  });

  return {
    invitation,
    isLoadingInvitation,
    invitationError,
    refetchInvitation,
  };
}
