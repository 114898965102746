import { useCampaignFlowsErrorContext } from "../context/CampaignFlowsErrorContext";

export default function useValidateFlow(flowId: string) {
  const { invalidFlows, setInvalidFlows } = useCampaignFlowsErrorContext();

  const isFlowError = !!invalidFlows.find(
    (invalidFlow) => invalidFlow.id === flowId,
  );

  function resetFlowError() {
    setInvalidFlows(
      invalidFlows.filter((invalidFLow) => invalidFLow.id !== flowId),
    );
  }

  return { isFlowError, resetFlowError };
}
