import useFlowMessages from "../datahooks/useFlowMessages";
import { FlowMessageTemplate } from "../types";

export default function useMessageTemplateActions(
  messageTemplates: FlowMessageTemplate[],
) {
  const {
    addFlowMessage,
    deleteLastFlowMessage,
    updateFlowMessage,
    addPlaceholderMessage,
    removePlaceholderMessage,
  } = useFlowMessages();

  function addMessageTemplate(flowTemplate: FlowMessageTemplate) {
    addFlowMessage({
      flowMessage: flowTemplate,
    });
  }

  function setMessageTemplate(
    index: number,
    flowTemplate: FlowMessageTemplate,
  ) {
    updateFlowMessage({
      index,
      updates: flowTemplate,
    });
  }

  function setMessageDelay(index: number, delay: number) {
    updateFlowMessage({
      index,
      updates: {
        delay,
      },
    });
  }

  function removeLastMessage() {
    deleteLastFlowMessage();
  }

  function toggleMessageTemplateAi(index: number) {
    updateFlowMessage({
      index,
      updates: {
        ai: !messageTemplates[index].ai,
      },
    });
  }

  return {
    addMessageTemplate,
    setMessageTemplate,
    setMessageDelay,
    removeLastMessage,
    toggleMessageTemplateAi,
    addPlaceholderMessage,
    removePlaceholderMessage,
  };
}
