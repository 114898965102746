import usePricing from "common/datahooks/usePricing";
import { PricingPeriodUnit } from "common/types";

export default function usePeriodUnitPricing(periodUnit: PricingPeriodUnit) {
  const { pricing } = usePricing();

  const license = pricing.find(
    ({ period_unit, item_type }) =>
      period_unit === periodUnit && item_type === "addon",
  );

  const plan = pricing.find(
    ({ period_unit, item_type }) =>
      period_unit === periodUnit && item_type === "plan",
  );

  return { plan, license };
}
