import React from "react";
import {
  LinkSimple,
  Chat,
  Envelope,
  MegaphoneSimple,
  CheckCircle,
  XCircle,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import Switcher from "common/components/ui/Switcher";
import { CampaignOutreachType } from "common/types";

import ItemContent from "./ItemContent";

const baseMethodOptions = [
  {
    value: "connect",
    label: "Connect",
    icon: <LinkSimple />,
  },
  {
    value: "inmail",
    label: "InMail",
    icon: <Envelope />,
  },
  {
    value: "message",
    label: "Message",
    icon: <Chat />,
  },
] as const;

interface OutreachTypeProps {
  isGroupOrEvent: boolean;
  isListCampaign: boolean;
  outreachType: CampaignOutreachType;
  setOutreachType: (newType: CampaignOutreachType) => void;
}

export default function OutreachType({
  isGroupOrEvent,
  isListCampaign,
  outreachType,
  setOutreachType,
}: OutreachTypeProps) {
  const outreachTypeOptions = baseMethodOptions.filter((option) =>
    isGroupOrEvent ? true : option.value !== "message",
  );

  const { t } = useTranslation("campaigns", {
    keyPrefix: "outreachType",
  });

  return (
    <section
      className={clsx(
        "flex flex-col justify-between gap-4 py-6 md:flex-row md:items-center md:gap-6",
        !isListCampaign ? "border-b border-b-black-200" : "max-md:mb-12",
      )}
    >
      <ItemContent
        icon={MegaphoneSimple}
        title={t("title")}
        description={t("description")}
        infoText={t("infoText")}
      />
      <div className="flex flex-col gap-2 max-md:ml-16 md:items-center">
        <Switcher
          size="sm"
          options={outreachTypeOptions}
          selectedOption={outreachType}
          onOptionSelect={(option) =>
            setOutreachType(option as CampaignOutreachType)
          }
        />
        <div className="flex items-center gap-2">
          {outreachTypeOptions.map(({ label, value }) => {
            const isMethodEnabled =
              (outreachType === "inmail" && value === "inmail") ||
              (outreachType === "connect" && value !== "message") ||
              (outreachType === "message" && value !== "connect");

            return (
              <div key={value} className="flex items-center gap-x-0.5">
                {isMethodEnabled ? (
                  <CheckCircle
                    className="fill-green-500"
                    size={16}
                    weight="fill"
                  />
                ) : (
                  <XCircle className="fill-black-400" size={16} weight="fill" />
                )}

                <span
                  className={clsx(
                    "text-caption-10-regular transition-colors",
                    !isMethodEnabled && "text-black-400",
                  )}
                >
                  {label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
