import React from "react";

export default function SectionItem({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <div className="py-2 lg:p-3">{children}</div>
      <div className="my-2 h-px bg-black-200 last:hidden" />
    </>
  );
}
