import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { get, post } from "common/helpers/HTTP";
import { Invitation, Workspace } from "common/types";

async function getInvitationsRequest(): Promise<Invitation[]> {
  return (await get("invites")).invites;
}

async function handleInvite(
  memberToken: string,
  accept: boolean,
): Promise<Workspace> {
  return (
    await post(`invites/${memberToken}`, {
      accept,
    })
  ).workspace;
}

export default function useInvitations({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const queryClient = useQueryClient();
  const queryKey = ["invitations"];

  const { data: invitations, isPending: isLoadingInvitations } = useQuery({
    queryKey,
    queryFn: getInvitationsRequest,
    enabled: !disableQuery,
  });

  const {
    mutateAsync: respondToInvitation,
    isPending: isRespondingToInvitation,
  } = useMutation({
    mutationFn: ({
      memberToken,
      accept,
    }: {
      memberToken: string;
      accept: boolean;
    }) => handleInvite(memberToken, accept),
    onSuccess: (_, { accept, memberToken }) => {
      queryClient.setQueryData<Invitation[]>(queryKey, (prevInvitations) =>
        prevInvitations.filter(({ token }) => token !== memberToken),
      );

      // TODO: We should update cached workspaces, once the backend is implemented (currently "accounts" array is missing from the response)
      if (accept) {
        queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      }
    },
  });

  return {
    invitations,
    isLoadingInvitations,
    respondToInvitation,
    isRespondingToInvitation,
  };
}
