import React from "react";
import { useTranslation } from "react-i18next";

import { typedObjectKeys } from "common/helpers/utils";
import { CreatedTemplate, TemplateType } from "common/types";
import { templateTypes } from "common/constants";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
} from "common/components/ui/Select";

interface TemplateTypeFilterProps {
  selectedFilter: TemplateType;
  setFilter: (filter: TemplateType) => void;
  templates: CreatedTemplate[];
}

export default function TemplateTypeFilter({
  selectedFilter,
  setFilter,
  templates,
}: TemplateTypeFilterProps) {
  const { t } = useTranslation("enums");

  return (
    <Select value={selectedFilter} onValueChange={setFilter}>
      <SelectTrigger className="w-40" variant="simple">
        {selectedFilter ? t(selectedFilter) : "Filter by"}
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={null}>
          <SelectItemText>
            All
            <span className="text-black-500">
              &nbsp;({templates ? templates.length : 0})
            </span>
          </SelectItemText>
        </SelectItem>
        {typedObjectKeys(templateTypes).map((key) => (
          <SelectItem value={key} key={key}>
            <SelectItemText>{t(key)}</SelectItemText>
            <span className="text-black-500">
              &nbsp;(
              {templates
                ? templates.filter(({ type }) => type === key).length
                : 0}
              )
            </span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
