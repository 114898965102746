import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import Hotjar from "@hotjar/browser";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useCustomer from "common/datahooks/useCustomer";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";

export default function useWorkspaceChangeUpdates() {
  const {
    id: workspaceId,
    accounts,
    subscription,
  } = useSelectedWorkspaceContext();
  const { customerInfo } = useCustomer();
  const { primaryPaymentSource } = usePrimaryPaymentSource();

  const { update } = useIntercom();

  useEffect(() => {
    let paymentMethod = "No payment method";
    if (primaryPaymentSource) {
      paymentMethod =
        primaryPaymentSource.type === "card"
          ? `Card ending in ${primaryPaymentSource.card.last4}`
          : `Paypal email ${primaryPaymentSource.paypal.email}`;
    }

    const loggedOutAccountsCount = accounts.filter(
      (account) => account.state !== "LoggedIn",
    ).length;

    const loggedInAccountsCount = accounts.filter(
      (account) => account.state === "LoggedIn",
    ).length;

    update({
      customAttributes: {
        "Workspace ID": workspaceId,
        "Payment Method": paymentMethod,
        "LoggedOut accounts count": loggedOutAccountsCount,
        "LoggedIn accounts count": loggedInAccountsCount,
        ...(subscription && {
          "Subscription ID": subscription.id,
          "Subscription Status": subscription.status,
          "Next Billing Date": new Date(
            subscription.next_billing * 1000,
          ).toISOString(),
        }),
      },
    });
    Sentry.setTag("workspace id", workspaceId);
    Hotjar.identify(customerInfo.id, {
      "Workspace ID": workspaceId,
    });
  }, [workspaceId, primaryPaymentSource, accounts, subscription]);
}
