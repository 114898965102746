import React, { JSX } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import RenderIf from "common/components/RenderIf";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import Alert from "common/components/ui/Alert";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";

import CampaignOverview from "./CampaignOverview";
import CampaignAudience from "./CampaignAudience";
import CampaignSchedule from "./CampaignSchedule";
import { DetailedCampaign } from "../../types";
import { getFlowTitle, getSortedAndFilteredFlows } from "../../utils";
import TargetingPreview from "../TargetingPreview";
import FlowDetails from "./FlowDetails";

interface CampaignNavItem {
  label: string;
  path: string;
  component: JSX.Element;
}

interface CampaignDetailsProps {
  campaign: DetailedCampaign;
}

export default function CampaignDetails({ campaign }: CampaignDetailsProps) {
  const [activeTab, setActiveTab] = useQueryParam(
    "activeTab",
    withDefault(StringParam, "overview"),
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );

  const { subscription } = useSelectedWorkspaceContext();

  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    campaign.flows,
    campaign.inmail_optimization,
  );

  const flowNavMenus: CampaignNavItem[] = [];

  function goToFlowTab(flowId: string) {
    setActiveTab(`flows/${flowId}`);
  }

  sortedAndFilteredFlows.forEach((flow) => {
    flowNavMenus.push({
      label: getFlowTitle(flow),
      path: `flows/${flow.id}`,
      component: <FlowDetails flow={flow} key={flow.id} campaign={campaign} />,
    });
  });

  const submenus = [
    {
      label: "Overview",
      path: "overview",
      component: (
        <CampaignOverview
          flows={sortedAndFilteredFlows}
          campaign={campaign}
          goToFlowTab={goToFlowTab}
        />
      ),
    },
    {
      label: "Audience",
      path: "audience",
      component: <CampaignAudience />,
    },
    ...flowNavMenus,
    {
      label: "Schedule",
      path: "schedule",
      component: <CampaignSchedule campaignSchedule={campaign.schedule} />,
    },
  ];

  return (
    <>
      <RenderIf
        condition={
          subscription?.status === "cancelled" && campaign.state === "ACTIVE"
        }
      >
        <Alert description="Campaigns will not perform any interactions while you do not have a LinkedIn Outreach subscription." />
      </RenderIf>
      <TargetingPreview campaignType={campaign.type} />
      <Tabs value={activeTab}>
        <TabsList className="mb-4 justify-start gap-x-1 pb-4 max-md:-mx-4 max-md:px-4">
          {submenus.map(({ label, path }) => (
            <TabsTrigger
              key={path}
              value={path}
              onClick={() => setActiveTab(path)}
            >
              {label}
            </TabsTrigger>
          ))}
        </TabsList>

        {submenus.map(({ component, path }) => (
          <TabsContent key={path} value={path}>
            {component}
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}
