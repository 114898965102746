import React from "react";
import { clsx } from "clsx";

export default function ExpandCollapseIcon({
  isExpanded,
}: {
  isExpanded: boolean;
}) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-5"
    >
      <path
        className="stroke-black-600 group-hover:stroke-white"
        d="M10.5557 30L10.5557 2.77778"
        stroke="#030712"
        strokeWidth="2"
      />
      <path
        className={clsx(
          "origin-center stroke-black-600 transition-transform duration-500 group-hover:stroke-white  ",
          isExpanded ? "translate-x-2 -scale-x-100 scale-y-100" : "",
        )}
        d="M17.5557 20.6667L22.2224 16L17.5557 11.3333"
        stroke="#030712"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        className="stroke-black-600 group-hover:stroke-white"
        x="1"
        y="-1"
        width="26"
        height="26"
        rx="3.66667"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 2 28)"
        stroke="#030712"
        strokeWidth="2"
      />
    </svg>
  );
}
