import { useEffect, useState } from "react";

export default function useBannerVisibility(bannerName: string) {
  const [isVisible, setIsVisible] = useState(
    !document.cookie.includes(bannerName),
  );

  useEffect(() => {
    if (!isVisible) {
      const cookieAge = 60 * 60 * 24;
      document.cookie = `${bannerName}=true; max-age=${cookieAge}; path=/;`;
    }
  }, [isVisible]);

  return { isVisible, setIsVisible };
}
