import React, { useState } from "react";
import { CalendarDots, CaretDown, X } from "@phosphor-icons/react";
import clsx from "clsx";

import { MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import DatePicker from "common/components/DatePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { formatDate } from "common/helpers/utils";

import { statsTimeframeOptions } from "../../pages/campaigns/constants";

export default function TimeframeSelector({
  selectedParams,
  setSelectedParams,
}: {
  selectedParams: MetricsParameters;
  setSelectedParams: (selectedTimeframe: MetricsParameters) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);

  const selectedTimeframeOption = statsTimeframeOptions.find(
    ({ parameters }) => parameters === selectedParams,
  );

  return (
    <div className="ml-auto flex shrink-0 items-center gap-x-3">
      {isCustomDate && (
        <Tag
          size="lg"
          variant="quaternary"
          rightIcon={
            <button
              type="button"
              onClick={() => {
                setSelectedParams(statsTimeframeOptions[1].parameters);
                setIsCustomDate(false);
              }}
              aria-label="reset custom date"
            >
              <X />
            </button>
          }
        >
          {`${formatDate(selectedParams.timestamps.from)} - ${formatDate(selectedParams.timestamps.to)}`}
        </Tag>
      )}
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="secondary-black"
            leftIcon={<CalendarDots />}
            rightIcon={
              <CaretDown
                className={clsx(isOpen && "rotate-180", "transition-transform")}
              />
            }
            className={isOpen && "bg-black-200"}
          >
            {isCustomDate ? "Custom" : selectedTimeframeOption.label}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="flex w-auto min-w-[200px] flex-col gap-y-1"
        >
          {isCustomDate ? (
            <DatePicker
              selectedRange={selectedParams.timestamps}
              setSelectedRange={(range) =>
                setSelectedParams({
                  timestamps: {
                    from: range.from,
                    to: range.to,
                  },
                  bucketSize: "1 day",
                })
              }
            />
          ) : (
            <>
              <button
                type="button"
                onClick={() => setIsCustomDate(true)}
                className="flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50"
              >
                Custom
              </button>
              {statsTimeframeOptions.map(({ label, parameters }) => {
                const isSelected = parameters === selectedParams;
                return (
                  <button
                    key={label}
                    type="button"
                    onClick={() => {
                      setSelectedParams(parameters);
                      setIsOpen(false);
                    }}
                    className={clsx(
                      isSelected && "bg-black-50",
                      "flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50",
                    )}
                  >
                    {label}
                  </button>
                );
              })}
            </>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}
