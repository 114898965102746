import React, { JSX } from "react";
import { clsx } from "clsx";
import { CaretRight, FunnelSimple } from "@phosphor-icons/react";

import {
  Drawer,
  DrawerTrigger,
  DrawerContent,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";

export default function MobileFilters({
  filtersComponent,
  resetAllParams,
}: {
  filtersComponent: JSX.Element;
  resetAllParams: () => void;
}) {
  return (
    <Drawer>
      <DrawerTrigger className="mb-3 flex items-center gap-x-2 rounded-20 border border-black-200 p-4">
        <Button variant="secondary-black" intent="labelIcon">
          <FunnelSimple />
        </Button>
        <span className="text-headline-lg-bold">Filters</span>
        <Button
          variant="quaternary-black"
          intent="labelIcon"
          className="ml-auto"
        >
          <CaretRight />
        </Button>
      </DrawerTrigger>

      <DrawerContent>
        <div className="mb-4 flex items-center justify-between">
          <span className="text-headline-xl-bold">Filters</span>
          <Button
            variant="quaternary-danger"
            size="lg"
            className={clsx(!resetAllParams && "invisible")}
            onClick={resetAllParams}
          >
            Clear all
          </Button>
        </div>
        {filtersComponent}
      </DrawerContent>
    </Drawer>
  );
}
