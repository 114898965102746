import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function MessageFieldLoader() {
  return (
    <div className="w-full bg-black-50 md:p-3">
      <div className="rounded-2xl bg-white p-2">
        <div className="flex flex-row justify-between gap-4">
          <Skeleton className="h-10 w-64" />
          <Skeleton className="h-8 w-20 rounded-full" />
        </div>

        <div className="my-2 h-px bg-black-200" />

        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-1">
            <Skeleton className="hidden h-6 w-20 md:block" />
            <Skeleton className="hidden h-6 w-20 md:block" />
            <Skeleton className="hidden h-6 w-20 md:block" />
            <Skeleton className="block size-6 rounded-full md:hidden" />
          </div>

          <div className="flex flex-row gap-1">
            <Skeleton className="size-6 rounded-full" />
            <Skeleton className="size-6 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
