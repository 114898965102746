import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useCampaignMutations from "../../datahooks/useCampaignMutations";
import PrimaryFlows from "./PrimaryFlows";
import InMailOptimization from "./InMailOptimization";
import { CampaignFlow, DetailedCampaign, AudienceStats } from "../../types";
import { getFlowsByType, validateFlows } from "../../utils";
import {
  CampaignFlowsErrorProvider,
  useCampaignFlowsErrorContext,
} from "../../context/CampaignFlowsErrorContext";
import Stepper from "./Stepper";

interface CampaignFlowsProps {
  campaign: DetailedCampaign;
  audienceStats: AudienceStats;
  isLoadingAudienceStats: boolean;
}

function CampaignFlowsContent({
  campaign,
  audienceStats,
  isLoadingAudienceStats,
}: CampaignFlowsProps) {
  const {
    outreach_type: outreachType,
    id,
    inmail_optimization: isInMailOptimizationEnabled,
    flows,
    target_count: targetCount,
  } = campaign;

  const navigate = useNavigate();
  const { updateCampaign } = useCampaignMutations();
  const { setInvalidFlows } = useCampaignFlowsErrorContext();

  const { primaryFlows, inmailFlow } = getFlowsByType(flows);

  let inmailFlowWithTemplate = inmailFlow;
  if (inmailFlow && !inmailFlow.template) {
    inmailFlowWithTemplate = {
      ...inmailFlow,
      template: {
        ai: false,
        edited: false,
        message: "",
        original_id: null,
        subject: "",
        type: "INMAIL_TEMPLATE",
      },
    };
  }

  function goToNextStep() {
    const { isFlowListValid, flowsWithError } = validateFlows(
      flows,
      isInMailOptimizationEnabled,
    );

    setInvalidFlows(flowsWithError);

    if (isFlowListValid) {
      navigate("../schedule");
    } else {
      toast.error("All campaign flows must be valid");
    }
  }

  function toggleInmailOptimizations() {
    updateCampaign({
      campaignId: id,
      updates: {
        inmail_optimization: !isInMailOptimizationEnabled,
      },
    });
  }

  return (
    <>
      {/* Flows */}
      <>
        {outreachType !== "inmail" ? (
          <PrimaryFlows
            flows={primaryFlows}
            type={outreachType}
            targetCount={targetCount}
          />
        ) : null}

        <InMailOptimization
          flow={inmailFlowWithTemplate}
          canToggle={outreachType !== "inmail"}
          isEnabled={isInMailOptimizationEnabled}
          isLoadingTargetsCount={isLoadingAudienceStats}
          targetCount={audienceStats ? audienceStats.inmailOptimization : 0}
          toggleOptimization={toggleInmailOptimizations}
        />
      </>

      {/* Stepper */}
      <Stepper selectedIndex={2} goToNextStep={goToNextStep} />
    </>
  );
}

export default function CampaignFlows({
  campaign,
  audienceStats,
  isLoadingAudienceStats,
}: CampaignFlowsProps) {
  const [invalidFlows, setInvalidFlows] = useState<CampaignFlow[]>([]);

  return (
    <CampaignFlowsErrorProvider value={{ invalidFlows, setInvalidFlows }}>
      <CampaignFlowsContent
        campaign={campaign}
        audienceStats={audienceStats}
        isLoadingAudienceStats={isLoadingAudienceStats}
      />
    </CampaignFlowsErrorProvider>
  );
}
