import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import LeadHeading from "common/components/LeadPreview/LeadHeading";
import About from "common/components/LeadPreview/About";
import LeadNotes from "common/components/LeadNotes";
import { Tag } from "common/components/ui/Tag";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import useAppStore from "common/hooks/useAppStore";

import Experience from "./Experience";
import useLead from "../../datahooks/useLead";
import Education from "./Education";
import Volunteering from "./Volunteering";
import Certifications from "./Certifications";
import Skills from "./Skills";
import Languages from "./Languages";
import CurrentExperience from "./CurrentExperience";

export default function LeadDetails() {
  const { profileId } = useParams();
  const { state } = useLocation();
  const { lead, refetchLead, isLoadingLead, leadError } = useLead({
    profileId,
    placeholderLead: state && state.lead,
  });

  useEffect(() => {
    if (lead) {
      const originalHeaderTitle = useAppStore.getState().headerContent;
      useAppStore
        .getState()
        .setHeaderContent({ title: `${lead.full_name}'s Profile` });
      return () => {
        useAppStore.getState().setHeaderContent(originalHeaderTitle);
      };
    }
  }, [lead]);

  if (leadError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Leads Data"
        onRetry={refetchLead}
      />
    );
  }
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-[428px_auto]">
      <div className="flex flex-col">
        <div className="-mx-4 mb-2 flex flex-col gap-4 rounded-3xl bg-black-50 p-2 lg:mx-0">
          <LeadHeading
            lead={lead}
            isPreview={false}
            isLoading={!lead && isLoadingLead}
            canSendMessage
          />
          <About lead={lead} isLoading={!lead && isLoadingLead} />
        </div>
        {lead && (
          <div className="row-span-2 flex h-fit flex-col rounded-3xl bg-black-50 p-4">
            <div className="mb-3 flex items-center gap-x-1">
              <h2 className="text-button-16">Notes</h2>
              {!!lead.notes.length && (
                <Tag variant="yellow">{lead.notes.length}</Tag>
              )}
            </div>
            <LeadNotes notes={lead.notes} profileId={lead.id} />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <CurrentExperience
          currentExperience={lead && lead.current_experience}
          isLoading={!lead && isLoadingLead}
        />
        <Experience
          workExperience={lead && lead.work_experience}
          isLoading={isLoadingLead}
        />
        <Education
          education={lead && lead.education}
          isLoading={isLoadingLead}
        />
        {lead && (
          <>
            {!!lead.volunteer_experience.length && (
              <Volunteering volunteering={lead.volunteer_experience} />
            )}
            {!!lead.certifications.length && (
              <Certifications certification={lead.certifications} />
            )}
            {!!lead.skills.length && <Skills skills={lead.skills} />}
            {!!lead.languages.length && (
              <Languages languages={lead.languages} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
