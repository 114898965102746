import React from "react";

import Skeleton from "common/components/ui/Skeleton";
import useEstimateRenewal from "common/datahooks/useEstimateRenewal";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { formatPeriodUnitLabel, formatPrice } from "common/helpers/utils";

export default function CurrentLicense() {
  const { license_count: workspaceLicenseCount, subscription } =
    useSelectedWorkspaceContext();
  const { estimateRenewal, isLoadingEstimateRenewal } = useEstimateRenewal();

  return (
    <>
      <div className="flex items-center justify-between px-2 py-1">
        <span className="text-button-14 text-black-600">
          Current no. of seats
        </span>
        <span className="text-body-16-regular text-black-500">
          {workspaceLicenseCount}
        </span>
      </div>

      <div className="flex items-center justify-between px-2 py-1">
        <span className="text-button-14 text-black-600">Current price</span>
        {isLoadingEstimateRenewal ? (
          <Skeleton className="h-6 w-14" />
        ) : (
          <span className="text-body-16-regular text-black-500">
            {formatPrice(estimateRenewal.amountDue / 100)}/
            {formatPeriodUnitLabel(subscription.item_price.period_unit)}
          </span>
        )}
      </div>

      <span className="h-px w-full bg-black-200" />
    </>
  );
}
