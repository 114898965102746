import React from "react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";

interface NavigationButtonsProps {
  currentQuestionIndex: number;
  totalQuestions: number;
  handleBack: () => void;
  isNextDisabled: boolean;
  isSubmitting: boolean;
}

export default function NavigationButtons({
  currentQuestionIndex,
  totalQuestions,
  handleBack,
  isNextDisabled,
  isSubmitting,
}: NavigationButtonsProps) {
  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;

  return (
    <div
      className={clsx([
        "flex w-full items-center gap-4",
        currentQuestionIndex !== 0 ? "justify-between" : "justify-end",
      ])}
    >
      {currentQuestionIndex !== 0 && (
        <Button type="button" variant="secondary-black" onClick={handleBack}>
          Back
        </Button>
      )}

      <Button type="submit" disabled={isNextDisabled} isLoading={isSubmitting}>
        {isLastQuestion ? "Finish" : "Next"}
      </Button>
    </div>
  );
}
