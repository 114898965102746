import { useMemo } from "react";

import useCustomer from "common/datahooks/useCustomer";

export default function useGTM() {
  const { customerInfo } = useCustomer();

  const hashedEmail = useMemo(() => {
    if (customerInfo) {
      return customerInfo.email
        .split("")
        .map((ch) => ch.charCodeAt(0).toString(16))
        .join("");
    }
  }, [customerInfo]);

  function sendEvent(
    event: string,
    getData?: (userInfo: { email: string }) => Record<string, unknown>,
  ) {
    window.dataLayer = window.dataLayer || [];
    let payload: { event: string; user_id: string } = {
      event,
      user_id: hashedEmail,
    };
    if (getData) {
      payload = {
        ...payload,
        ...getData({ email: customerInfo.email }),
      };
    }
    window.dataLayer.push(payload);
  }
  return { sendEvent };
}
