import React, { Dispatch, SetStateAction, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCampaignContext } from "common/helpers/CampaignContext";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useCampaignMutations from "../../../datahooks/useCampaignMutations";

interface CampaignStartProps {
  isModalOpened: boolean;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;
}

export default function CampaignStart({
  isModalOpened,
  setIsModalOpened,
}: CampaignStartProps) {
  const navigate = useNavigate();
  const isStartingNowRef = useRef(true);

  const isTabletOrDesktop = useTwBreakpoint("md");
  const { campaignId, accountId } = useCampaignContext();
  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === accountId);

  const { updateCampaign, isUpdatingCampaign } = useCampaignMutations();
  const { assignLicense, isAssigningLicense } = useLicenseMutations();

  function startCampaign(startNow: boolean = true) {
    updateCampaign({
      campaignId,
      updates: {
        state: startNow ? "ACTIVE" : "CREATED",
      },
    }).then(() => navigate(`../../details`));
  }
  const isStartingNow = isStartingNowRef.current;

  function saveAsDraft() {
    isStartingNowRef.current = false;
    startCampaign(false);
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  return (
    <Component open={isModalOpened} onOpenChange={setIsModalOpened}>
      <Content>
        {account.license ? (
          <>
            <h3 className="mb-2 text-center text-headline-2xl-bold">
              Do you want to start this campaign?
            </h3>
            <p className="mb-10 text-center text-black-500">
              You can choose to start this campaign immediately, or leave it as
              a draft and start it later, if you want to make some last minute
              changes
            </p>
            <div className="flex gap-x-4">
              <Button
                variant="secondary-black"
                className="flex-1"
                size="lg"
                onClick={saveAsDraft}
                disabled={isStartingNow && isUpdatingCampaign}
                isLoading={!isStartingNow && isUpdatingCampaign}
              >
                Leave as draft
              </Button>

              <Button
                size="lg"
                className="flex-1"
                onClick={() => startCampaign()}
                disabled={!isStartingNow && isUpdatingCampaign}
                isLoading={isStartingNow && isUpdatingCampaign}
              >
                Start campaign
              </Button>
            </div>
          </>
        ) : (
          <>
            <h3 className="mb-2 text-center text-headline-2xl-bold">
              Assign seat to {account.full_name}?
            </h3>
            <p className="mb-12 text-center text-body-14-regular text-black-500">
              You need to assign a seat to {account.full_name} in order to start
              this campaign
            </p>
            <div className="flex flex-col-reverse gap-2 md:flex-row md:gap-4">
              <Button
                variant="secondary-black"
                className="flex-1"
                size="lg"
                onClick={saveAsDraft}
                disabled={isAssigningLicense}
                isLoading={isUpdatingCampaign}
              >
                Save as draft
              </Button>
              <Button
                isLoading={isAssigningLicense}
                disabled={isUpdatingCampaign}
                onClick={() => assignLicense({ accountId })}
                variant="gradient"
                size="lg"
                className="flex-1"
              >
                Assign
              </Button>
            </div>
          </>
        )}
      </Content>
    </Component>
  );
}
