import React, { useState } from "react";

import { WorkspaceAccount } from "common/types";
import { LinkedInLogin } from "common/components/LinkedInLogin";

import ModifyLicense from "../components/ModifyLicense";
import DeleteAccount from "../components/DeleteAccount";
import RemoveCancellation from "../components/RemoveCancellation";

export default function useWorkspaceAccountActions({
  account,
  onClose,
  goBack,
}: {
  account: WorkspaceAccount;
  onClose: () => void;
  goBack: () => void;
}) {
  const [action, setAction] = useState<
    "license" | "delete" | "reconnect" | "reactivate" | "settings"
  >(null);

  let actionComponent;
  if (action === "license") {
    actionComponent = (
      <ModifyLicense
        type={account.license ? "unassign" : "assign"}
        accountFullname={account.full_name}
        accountId={account.id}
        goBack={goBack}
        onSuccess={onClose}
      />
    );
  } else if (action === "delete") {
    actionComponent = (
      <DeleteAccount
        accountFullname={account.full_name}
        accountId={account.id}
        goBack={goBack}
        onSuccess={onClose}
      />
    );
  } else if (action === "reconnect") {
    actionComponent = (
      <LinkedInLogin goBack={goBack} account={account} onSuccess={onClose} />
    );
  } else if (action === "reactivate") {
    actionComponent = (
      <RemoveCancellation
        accountId={account.id}
        onSuccess={onClose}
        goBack={goBack}
      />
    );
  }

  return {
    action,
    setAction,
    actionComponent,
  };
}
