import React, { ComponentProps, forwardRef } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "common/helpers/utils";
import { CampaignType } from "common/types";
import { campaignTypes } from "common/constants";

const campaignTypeIconVariants = cva(
  "inline-flex shrink-0 items-center justify-center border border-black-200 bg-white",
  {
    variants: {
      size: {
        xs: "size-6 rounded-lg [&_svg]:size-3",
        sm: "size-8 rounded-xl [&_svg]:size-4",
        md: "size-10 rounded-2xl [&_svg]:size-5",
        lg: "size-12 rounded-2xl [&_svg]:size-6",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

type CampaignTypeIconProps = Omit<ComponentProps<"span">, "children"> &
  VariantProps<typeof campaignTypeIconVariants> & {
    type: CampaignType;
  };

const CampaignTypeIcon = forwardRef<HTMLSpanElement, CampaignTypeIconProps>(
  ({ className, size, type, ...props }, ref) => {
    const { icon: Icon } = campaignTypes[type];

    return (
      <span
        ref={ref}
        className={cn(
          campaignTypeIconVariants({
            size,
            className,
          }),
        )}
        {...props}
      >
        <Icon weight="duotone" />
      </span>
    );
  },
);
CampaignTypeIcon.displayName = "CampaignTypeIcon";

export default CampaignTypeIcon;
