import { create } from "zustand";
import { JSX } from "react";

import { SubscriptionStatus, UserRole } from "common/types";

interface HeaderContent {
  title?: string;
  component?: JSX.Element;
}
interface DebugOptions {
  shouldDebugLogin: boolean;
  isPaymentMethodHidden: boolean;
  isWithoutSubscription: boolean;
  subscriptionStatus: SubscriptionStatus;
  workspaceRole: UserRole;
}
interface AppState {
  // this limits page height to 100vh
  isPageHeightLimited: boolean;
  setIsPageHeightLimited: (isPageHeightLimited: boolean) => void;

  isCampaignModalOpen: boolean;
  setIsCampaignModalOpen: (isCampaignModalOpen: boolean) => void;

  headerContent: HeaderContent;
  setHeaderContent: (headerContent: HeaderContent) => void;

  debugOptions: DebugOptions;
  setDebugOptions: (debugOptions: Partial<DebugOptions>) => void;
}
export default create<AppState>((set, get) => ({
  isCampaignModalOpen: false,
  setIsCampaignModalOpen: (isCampaignModalOpen) => set({ isCampaignModalOpen }),

  isPageHeightLimited: false,
  setIsPageHeightLimited: (isPageHeightLimited) => set({ isPageHeightLimited }),

  headerContent: null,
  setHeaderContent: (headerContent) => set({ headerContent }),

  debugOptions: {
    shouldDebugLogin: false,
    isWithoutSubscription: false,
    isPaymentMethodHidden: false,
    subscriptionStatus: null,
    workspaceRole: null,
  },
  setDebugOptions: (newDebugOptions) =>
    set({ debugOptions: { ...get().debugOptions, ...newDebugOptions } }),
}));
