import { getMonthName } from "common/helpers/utils";

function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) return "th"; // covers 11th, 12th, 13th, etc.

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// eslint-disable-next-line import/prefer-default-export
export function formatBillingDate(dateTimestamp: number): string {
  const date = new Date(dateTimestamp);
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();

  return `${month} ${day}${daySuffix}, ${year}`;
}
