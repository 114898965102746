import { create } from "zustand";
import { persist } from "zustand/middleware";

interface DraftState {
  drafts: Record<string, string>;
  setDraft: (conversationId: string, message: string) => void;
  removeDraft: (conversationId: string) => void;
}

export default create<DraftState>()(
  persist(
    (set) => ({
      drafts: {},
      setDraft: (conversationId, message) => {
        if (!conversationId) {
          return;
        }
        set((state) => ({
          drafts: { ...state.drafts, [conversationId]: message },
        }));
      },
      removeDraft: (conversationId) =>
        set((state) => {
          const { [conversationId]: removed, ...rest } = state.drafts;
          return { drafts: rest };
        }),
    }),
    { name: "draftInbox" },
  ),
);
