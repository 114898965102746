import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { get, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  LinkedInProfile,
  ProfileSearchParams,
  GenericSearchParam,
  SearchParamWithImage,
} from "common/types";

async function getIndustries(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/industries?keyword=${keywords}`,
    )
  ).industries;
}
async function getCompanies(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/companies?keyword=${keywords}`,
    )
  ).companies;
}
async function getLocations(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/locations?keyword=${keywords}`,
    )
  ).locations;
}
async function getFollowers(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/followers?keyword=${keywords}`,
    )
  ).profiles;
}
async function getSchools(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/schools?keyword=${keywords}`,
    )
  ).schools;
}
async function getSkills(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/skills?keyword=${keywords}`,
    )
  ).skills;
}
async function getLanguages(
  workspaceId: string,
  campaignId: string,
  keywords: string,
) {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/languages?keyword=${keywords}`,
    )
  ).languages;
}
async function getSuggestions(
  workspaceId: string,
  campaignId: string,
  searchParams: Partial<ProfileSearchParams> = {},
) {
  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/search/suggestions`,
      searchParams,
    )
  ).suggestions;
}
export default function useSearchOptions({
  searchParams,
  companiesKeywords,
  locationsKeywords,
  followersKeywords,
  schoolsKeywords,
  industriesKeywords,
  skillsKeywords,
  languagesKeywords,
}: {
  searchParams?: Partial<ProfileSearchParams>;
  companiesKeywords?: string;
  locationsKeywords?: string;
  followersKeywords?: string;
  schoolsKeywords?: string;
  industriesKeywords?: string;
  skillsKeywords?: string;
  languagesKeywords?: string;
} = {}) {
  const { campaignId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: industries, isLoading: isLoadingIndustries } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [workspaceId, campaignId, "industries", industriesKeywords],
    queryFn: () => getIndustries(workspaceId, campaignId, industriesKeywords),
    enabled: !!industriesKeywords,
  });

  const { data: companies, isLoading: isLoadingCompanies } = useQuery<
    SearchParamWithImage[]
  >({
    queryKey: [workspaceId, campaignId, "companies", companiesKeywords],
    queryFn: () => getCompanies(workspaceId, campaignId, companiesKeywords),
    enabled: !!companiesKeywords,
  });

  const { data: locations, isLoading: isLoadingLocations } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [workspaceId, campaignId, "locations", locationsKeywords],
    queryFn: () => getLocations(workspaceId, campaignId, locationsKeywords),
    enabled: !!locationsKeywords,
  });

  const { data: schools, isLoading: isLoadingSchools } = useQuery<
    SearchParamWithImage[]
  >({
    queryKey: [workspaceId, campaignId, "schools", schoolsKeywords],
    queryFn: () => getSchools(workspaceId, campaignId, schoolsKeywords),
    enabled: !!schoolsKeywords,
  });

  const { data: followers, isLoading: isLoadingFollowers } = useQuery<
    LinkedInProfile[]
  >({
    queryKey: [workspaceId, campaignId, "followers", followersKeywords],
    queryFn: () => getFollowers(workspaceId, campaignId, followersKeywords),
    enabled: !!followersKeywords,
  });

  const { data: skills, isLoading: isLoadingSkills } = useQuery({
    queryKey: [workspaceId, campaignId, "skills", skillsKeywords],
    queryFn: () => getSkills(workspaceId, campaignId, skillsKeywords),
    enabled: !!skillsKeywords,
  });

  const { data: languages, isLoading: isLoadingLanguages } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [workspaceId, campaignId, "languages", languagesKeywords],
    queryFn: () => getLanguages(workspaceId, campaignId, languagesKeywords),
    enabled: !!languagesKeywords,
  });

  const { data: suggestions, isLoading: isLoadingSuggestions } = useQuery({
    queryKey: [workspaceId, campaignId, "suggestions"],
    queryFn: () => getSuggestions(workspaceId, campaignId, searchParams),
    staleTime: Infinity,
  });

  return {
    industries,
    isLoadingIndustries,
    companies,
    isLoadingCompanies,
    locations,
    isLoadingLocations,
    followers,
    isLoadingFollowers,
    schools,
    isLoadingSchools,
    skills,
    isLoadingSkills,
    languages,
    isLoadingLanguages,
    suggestions,
    isLoadingSuggestions,
    isLoading:
      isLoadingIndustries ||
      isLoadingCompanies ||
      isLoadingLocations ||
      isLoadingFollowers ||
      isLoadingSchools ||
      isLoadingSkills ||
      isLoadingLanguages ||
      isLoadingSuggestions,
  };
}
