import { Info } from "@phosphor-icons/react";
import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";

const bannerVariants = cva("flex gap-x-2 rounded-2xl px-4 py-3 shadow-sm", {
  variants: {
    variant: {
      warning: "border border-yellow-400 bg-white",
      gradient: "bg-[url(/images/trial-banner-bg.jpg)] bg-cover bg-right",
      info: "border border-black-200 bg-white",
    },
  },
});

interface BannerProps extends Required<VariantProps<typeof bannerVariants>> {
  textNode: ReactNode;
}

export default function Banner({ variant, textNode }: BannerProps) {
  return (
    <article className={bannerVariants({ variant })}>
      <Info weight="fill" size={16} />
      <p className="text-caption-12-regular">{textNode}</p>
    </article>
  );
}
