import { useQuery } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Lead } from "common/types";

import { Facets, LeadsSearchParams, SelectedFacetParam } from "../types";
import { parseSearchParams } from "../utils";

async function searchLeads(
  workspaceId: string,
  searchParams: LeadsSearchParams<SelectedFacetParam>,
  order: "asc" | "desc",
  pageSize: number,
  page: number,
): Promise<{
  totalLeads: number;
  hits: Lead[];
  facets: Facets;
  sync: boolean;
  accountsSync: Record<string, boolean>;
}> {
  const queryParams = new URLSearchParams();
  queryParams.set("start", (page * pageSize).toString());
  queryParams.set("count", pageSize.toString());

  const {
    leads: {
      total_leads: totalLeads,
      hits,
      facets,
      sync,
      accounts_sync: accountsSync,
    },
  } = await post(
    `workspaces/${workspaceId}/leads-search?${queryParams.toString()}`,
    { ...parseSearchParams(searchParams), order },
  );

  return { totalLeads, hits, facets, sync, accountsSync };
}
export default function useLeads(options: {
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  order: "asc" | "desc";
  page?: number;
  pageSize: number;
}) {
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();

  const { searchParams, page, pageSize, order } = options;
  const { leadsKeys } = getQueryKeys(workspaceId);

  const {
    data: leads,
    isFetching: isFetchingLeads,
    error: leadsError,
    refetch: refetchLeads,
  } = useQuery({
    queryKey: [...leadsKeys.list(searchParams), `page: ${page}`, order],
    queryFn: () =>
      searchLeads(workspaceId, searchParams, order, pageSize, page),
    enabled: !!accounts.length,
    placeholderData: (previousData) =>
      previousData
        ? {
            facets: previousData.facets,
            totalLeads: previousData.totalLeads,
            hits: null,
            sync: false,
            accountsSync: {},
          }
        : null,
    gcTime: 0,
  });

  return {
    leads: leads?.hits,
    totalLeads: leads?.totalLeads,
    facets: leads?.facets,
    isSyncing: leads?.sync,
    accountsSync: leads?.accountsSync,
    isFetchingLeads,
    refetchLeads,
    leadsError,
  };
}
