import React from "react";
import { CaretDown } from "@phosphor-icons/react";

interface MobileSelectProps {
  value: number;
  isDisabled: boolean;
  options: { value: number; label: string }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

export default function MobileSelect({
  value,
  isDisabled,
  options,
  onChange,
}: MobileSelectProps) {
  return (
    <div className="grid w-full items-center">
      <select
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        className="col-start-1 row-start-1 h-12 w-full appearance-none rounded-lg bg-whiteGray p-2 text-button-16 text-black-400 outline-none transition-colors focus-visible:text-black-950 focus-visible:ring-2 focus-visible:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50"
      >
        {options.map(({ value: optionValue, label }) => (
          <option value={optionValue} key={optionValue}>
            {label}
          </option>
        ))}
      </select>
      <CaretDown size={20} className="col-start-1 row-start-1 mx-2 ml-auto" />
    </div>
  );
}
