const languages = [
  {
    code: "af",
    name: "Afrikaans",
  },
  {
    code: "sq",
    name: "Albanian",
  },
  {
    code: "am",
    name: "Amharic",
  },
  {
    code: "ar",
    name: "Arabic",
  },
  {
    code: "hy",
    name: "Armenian",
  },
  {
    code: "as",
    name: "Assamese",
  },
  {
    code: "ay",
    name: "Aymara",
  },
  {
    code: "az",
    name: "Azerbaijani",
  },
  {
    code: "bm",
    name: "Bambara",
  },
  {
    code: "eu",
    name: "Basque",
  },
  {
    code: "be",
    name: "Belarusian",
  },
  {
    code: "bn",
    name: "Bengali",
  },
  {
    code: "bho",
    name: "Bhojpuri",
  },
  {
    code: "bs",
    name: "Bosnian",
  },
  {
    code: "bg",
    name: "Bulgarian",
  },
  {
    code: "ca",
    name: "Catalan",
  },
  {
    code: "ceb",
    name: "Cebuano",
  },
  {
    code: "ny",
    name: "Chichewa",
  },
  {
    code: "zh",
    name: "Chinese (Simplified)",
  },
  {
    code: "zh-TW",
    name: "Chinese (Traditional)",
  },
  {
    code: "co",
    name: "Corsican",
  },
  {
    code: "hr",
    name: "Croatian",
  },
  {
    code: "cs",
    name: "Czech",
  },
  {
    code: "da",
    name: "Danish",
  },
  {
    code: "dv",
    name: "Divehi",
  },
  {
    code: "doi",
    name: "Dogri",
  },
  {
    code: "nl",
    name: "Dutch",
  },
  {
    code: "en",
    name: "English",
  },
  {
    code: "eo",
    name: "Esperanto",
  },
  {
    code: "et",
    name: "Estonian",
  },
  {
    code: "ee",
    name: "Ewe",
  },
  {
    code: "tl",
    name: "Filipino",
  },
  {
    code: "fi",
    name: "Finnish",
  },
  {
    code: "fr",
    name: "French",
  },
  {
    code: "fy",
    name: "Frisian",
  },
  {
    code: "gl",
    name: "Galician",
  },
  {
    code: "lg",
    name: "Ganda",
  },
  {
    code: "ka",
    name: "Georgian",
  },
  {
    code: "de",
    name: "German",
  },
  {
    code: "el",
    name: "Greek",
  },
  {
    code: "gn",
    name: "Guarani",
  },
  {
    code: "gu",
    name: "Gujarati",
  },
  {
    code: "ht",
    name: "Haitian Creole",
  },
  {
    code: "ha",
    name: "Hausa",
  },
  {
    code: "haw",
    name: "Hawaiian",
  },
  {
    code: "iw",
    name: "Hebrew",
  },
  {
    code: "hi",
    name: "Hindi",
  },
  {
    code: "hmn",
    name: "Hmong",
  },
  {
    code: "hu",
    name: "Hungarian",
  },
  {
    code: "is",
    name: "Icelandic",
  },
  {
    code: "ig",
    name: "Igbo",
  },
  {
    code: "ilo",
    name: "Iloko",
  },
  {
    code: "id",
    name: "Indonesian",
  },
  {
    code: "ga",
    name: "Irish Gaelic",
  },
  {
    code: "it",
    name: "Italian",
  },
  {
    code: "ja",
    name: "Japanese",
  },
  {
    code: "jw",
    name: "Javanese",
  },
  {
    code: "kn",
    name: "Kannada",
  },
  {
    code: "kk",
    name: "Kazakh",
  },
  {
    code: "km",
    name: "Khmer",
  },
  {
    code: "rw",
    name: "Kinyarwanda",
  },
  {
    code: "gom",
    name: "Konkani",
  },
  {
    code: "ko",
    name: "Korean",
  },
  {
    code: "kri",
    name: "Krio",
  },
  {
    code: "ku",
    name: "Kurdish (Kurmanji)",
  },
  {
    code: "ckb",
    name: "Kurdish (Sorani)",
  },
  {
    code: "ky",
    name: "Kyrgyz",
  },
  {
    code: "lo",
    name: "Lao",
  },
  {
    code: "la",
    name: "Latin",
  },
  {
    code: "lv",
    name: "Latvian",
  },
  {
    code: "ln",
    name: "Lingala",
  },
  {
    code: "lt",
    name: "Lithuanian",
  },
  {
    code: "lb",
    name: "Luxembourgish",
  },
  {
    code: "mk",
    name: "Macedonian",
  },
  {
    code: "mai",
    name: "Maithili",
  },
  {
    code: "mg",
    name: "Malagasy",
  },
  {
    code: "ms",
    name: "Malay",
  },
  {
    code: "ml",
    name: "Malayalam",
  },
  {
    code: "mt",
    name: "Maltese",
  },
  {
    code: "mi",
    name: "Maori",
  },
  {
    code: "mr",
    name: "Marathi",
  },
  {
    code: "mni-Mtei",
    name: "Meiteilon (Manipuri)",
  },
  {
    code: "lus",
    name: "Mizo",
  },
  {
    code: "mn",
    name: "Mongolian",
  },
  {
    code: "my",
    name: "Myanmar (Burmese)",
  },
  {
    code: "ne",
    name: "Nepali",
  },
  {
    code: "nso",
    name: "Northern Sotho",
  },
  {
    code: "no",
    name: "Norwegian",
  },
  {
    code: "or",
    name: "Odia (Oriya)",
  },
  {
    code: "om",
    name: "Oromo",
  },
  {
    code: "ps",
    name: "Pashto",
  },
  {
    code: "fa",
    name: "Persian",
  },
  {
    code: "pl",
    name: "Polish",
  },
  {
    code: "pt",
    name: "Portuguese",
  },
  {
    code: "pa",
    name: "Punjabi",
  },
  {
    code: "qu",
    name: "Quechua",
  },
  {
    code: "ro",
    name: "Romanian",
  },
  {
    code: "ru",
    name: "Russian",
  },
  {
    code: "sm",
    name: "Samoan",
  },
  {
    code: "sa",
    name: "Sanskrit",
  },
  {
    code: "gd",
    name: "Scots Gaelic",
  },
  {
    code: "sr",
    name: "Serbian",
  },
  {
    code: "st",
    name: "Sesotho",
  },
  {
    code: "sn",
    name: "Shona",
  },
  {
    code: "sd",
    name: "Sindhi",
  },
  {
    code: "si",
    name: "Sinhala",
  },
  {
    code: "sk",
    name: "Slovak",
  },
  {
    code: "sl",
    name: "Slovenian",
  },
  {
    code: "so",
    name: "Somali",
  },
  {
    code: "es",
    name: "Spanish",
  },
  {
    code: "su",
    name: "Sundanese",
  },
  {
    code: "sw",
    name: "Swahili",
  },
  {
    code: "sv",
    name: "Swedish",
  },
  {
    code: "tg",
    name: "Tajik",
  },
  {
    code: "ta",
    name: "Tamil",
  },
  {
    code: "tt",
    name: "Tatar",
  },
  {
    code: "te",
    name: "Telugu",
  },
  {
    code: "th",
    name: "Thai",
  },
  {
    code: "ti",
    name: "Tigrinya",
  },
  {
    code: "ts",
    name: "Tsonga",
  },
  {
    code: "tr",
    name: "Turkish",
  },
  {
    code: "tk",
    name: "Turkmen",
  },
  {
    code: "ak",
    name: "Twi",
  },
  {
    code: "uk",
    name: "Ukrainian",
  },
  {
    code: "ur",
    name: "Urdu",
  },
  {
    code: "ug",
    name: "Uyghur",
  },
  {
    code: "uz",
    name: "Uzbek",
  },
  {
    code: "vi",
    name: "Vietnamese",
  },
  {
    code: "cy",
    name: "Welsh",
  },
  {
    code: "xh",
    name: "Xhosa",
  },
  {
    code: "yi",
    name: "Yiddish",
  },
  {
    code: "yo",
    name: "Yoruba",
  },
  {
    code: "zu",
    name: "Zulu",
  },
  {
    code: "he",
    name: "Hebrew",
  },
  {
    code: "jv",
    name: "Javanese",
  },
  {
    code: "zh-CN",
    name: "Chinese (Simplified)",
  },
];

export default languages;
