import React from "react";
import clsx from "clsx";

import RadioButton from "common/components/ui/RadioButton";
import { UserRole } from "common/types";

interface RoleOptionProps {
  option: UserRole;
  selectedRole: string;
  onRoleChange: (role: UserRole) => void;
  label: string;
  description: string;
}

export default function RoleOption({
  option,
  selectedRole,
  onRoleChange,
  label,
  description,
}: RoleOptionProps) {
  return (
    <button
      type="button"
      aria-label={option}
      className={clsx([
        "grid cursor-pointer grid-flow-col gap-4 rounded-2xl p-3",
        selectedRole === option ? "bg-black-50" : "bg-white",
      ])}
      onClick={() => onRoleChange(option)}
    >
      <RadioButton
        id={option}
        className="mt-1"
        checked={selectedRole === option}
        isStyleOnly
      />

      <div className="flex flex-col text-start">
        <span className="text-body-16-bold">{label}</span>
        <span className="text-caption-12-regular text-black-700">
          {description}
        </span>
      </div>
    </button>
  );
}
