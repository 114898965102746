import React from "react";
import { X } from "@phosphor-icons/react";

import { Label } from "common/types";
import { Tag } from "common/components/ui/Tag";
import useLeadLabels from "common/datahooks/useLeadLabels";

export default function AppliedLabel({
  label,
  profileId,
}: {
  profileId: string;
  label: Label;
}) {
  const { removeLeadLabel, isRemovingLeadLabel } = useLeadLabels();

  const { name, color } = label;

  function onRemove() {
    removeLeadLabel({ label, profileId });
  }

  return (
    <Tag
      size="lg"
      variant={color}
      rightIcon={
        <button
          aria-label="remove label"
          type="button"
          disabled={isRemovingLeadLabel}
          onClick={onRemove}
        >
          <X />
        </button>
      }
    >
      {name}
    </Tag>
  );
}
