import React from "react";
import { DownloadSimple, Files } from "@phosphor-icons/react";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";

import { MessageAttachment } from "../../../types";

export default function Attachment({
  attachment,
}: {
  attachment: MessageAttachment;
}) {
  const { name, url, media_type: mediaType, byte_size: byteSize } = attachment;

  const bytesToMegabytes = (bytes: number) => (bytes / 1048576).toFixed(2);

  function downloadAttachment(fileUrl: string): void {
    window.open(fileUrl, "_blank");
  }

  if (mediaType.startsWith("image")) {
    return (
      <Dialog>
        <DialogTrigger>
          <img
            className="w-[257px] rounded-2xl object-cover"
            src={url}
            alt={name}
          />
        </DialogTrigger>

        <DialogContent className="w-fit bg-transparent p-2 shadow-transparent">
          <a
            className="flex"
            href={url}
            target="_blank"
            rel="noreferrer"
            aria-label={`${name} linked image`}
          >
            <img className="max-h-[1000px]" src={url} alt={name} />
          </a>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <button
      type="button"
      className="group flex cursor-pointer flex-row items-center gap-3 rounded-20 border border-black-100 bg-whiteGray p-2 transition-colors ease-in-out hover:bg-white"
      aria-label={`Download ${name}`}
      onClick={() => downloadAttachment(url)}
    >
      <div className="rounded-2xl border border-black-200 bg-white p-4 transition-colors ease-in-out group-hover:bg-purple-50">
        <Files size={24} className="group-hover:hidden" />

        <DownloadSimple
          size={24}
          className="hidden text-purple-500 group-hover:block"
        />
      </div>

      <div className="flex w-fit flex-col gap-0.5 text-start">
        <span className="line-clamp-1 max-w-[25ch] text-body-14-bold">
          {name}
        </span>

        <span className="text-caption-12-regular text-black-500">
          {bytesToMegabytes(byteSize)} MB
        </span>
      </div>
    </button>
  );
}
