import { minidenticon } from "minidenticons";
import React, { ComponentProps, useMemo } from "react";

import ProfileImage from "common/components/ui/ProfileImage";

const saturation = 75;
const lightness = 50;

interface WorkspaceImageProps
  extends Omit<ComponentProps<typeof ProfileImage>, "src"> {
  id: string;
}

export default function WorkspaceImage({ id, ...props }: WorkspaceImageProps) {
  const svgURI = useMemo(
    () =>
      `data:image/svg+xml;utf8,${encodeURIComponent(
        minidenticon(id, saturation, lightness),
      )}`,
    [id],
  );

  return <ProfileImage src={svgURI} {...props} />;
}
