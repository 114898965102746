import React, { ReactNode, JSX } from "react";
import { clsx } from "clsx";

import useAppStore from "common/hooks/useAppStore";
import useNavStore from "common/hooks/useNavStore";
import { NavItemType } from "common/types";

import DesktopHeader from "./DesktopHeader";
import DesktopNav from "./DesktopNav";

interface DesktopLayoutProps {
  navItems: NavItemType[];
  children: ReactNode;
  headerTitle: string;
  bannerComponent: JSX.Element;
}

export default function DesktopLayout({
  navItems,
  children,
  headerTitle,
  bannerComponent,
}: DesktopLayoutProps) {
  const { isNavExpanded, setIsNavExpanded } = useNavStore();
  const isPageHeightLimited = useAppStore((state) => state.isPageHeightLimited);

  return (
    <>
      <DesktopNav
        isExpanded={isNavExpanded}
        setIsExpanded={setIsNavExpanded}
        navItems={navItems}
      />
      <main
        className={clsx(
          "flex min-h-screen w-full flex-col transition-[padding] duration-500",
          isNavExpanded ? "pl-[266px]" : "pl-[72px]",
        )}
      >
        <DesktopHeader headerTitle={headerTitle} />
        <div
          className={clsx(
            "mx-auto flex w-full max-w-[1920px] grow flex-col overflow-x-hidden p-6",
            isPageHeightLimited && "max-h-screen",
          )}
        >
          {bannerComponent}
          {children}
        </div>
      </main>
    </>
  );
}
