import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { clsx } from "clsx";

import useGTM from "common/hooks/useGTM";
import { LoginSchema, LoginSchemaType } from "common/schemas";
import { WorkspaceAccount } from "common/types";

import Login from "./Login";
import ThisWasMeChallenge from "./ThisWasMeChallenge";
import EnterPin from "./EnterPin";

export interface LinkedInLoginProps {
  goBack: () => void;
  onSuccess: () => void;
  account?: WorkspaceAccount;
}

export default function LinkedInLogin({
  goBack,
  onSuccess,
  account = null,
}: LinkedInLoginProps) {
  const { sendEvent } = useGTM();
  const [currentStep, setCurrentStep] = useState<"login" | "pin" | "thiswasme">(
    "login",
  );
  const formReturn = useForm<LoginSchemaType>({
    defaultValues: {
      email: account?.email ?? "",
      password: "",
      proxyLocation: "",
    },
    resolver: valibotResolver(LoginSchema),
  });

  function onAppChallengeFail() {
    setCurrentStep("login");
    formReturn.setError("password", {
      message: "Error resolving app challenge",
    });
  }
  function onLoginSuccess() {
    sendEvent("connect_linkedin_account_success");
    onSuccess();
  }

  const steps = {
    login: {
      title: account ? "Connect your LinkedIn account" : "Add account",
      subtitle: account
        ? `Enter your LinkedIn${account.email ? "" : " email and"} password to securely connect to Aimfox`
        : "",
      mainComponent: (
        <Login
          account={account}
          onSuccess={onLoginSuccess}
          formReturn={formReturn}
          setStep={setCurrentStep}
          goBack={goBack}
        />
      ),
    },
    pin: {
      title: "Enter your PIN",
      subtitle:
        "You received a pin from LinkedIn via SMS, email or authenticator app. Please enter it below to connect your LinkedIn account",
      mainComponent: (
        <EnterPin
          email={formReturn.getValues("email")}
          onSuccess={onLoginSuccess}
        />
      ),
    },
    thiswasme: {
      title: "Confirm your login",
      subtitle:
        "LinkedIn wants to make sure this is an authorized login to your account. Please go to your LinkedIn app and confirm this connection",
      mainComponent: (
        <ThisWasMeChallenge
          email={formReturn.getValues("email")}
          onSuccess={onLoginSuccess}
          onAppChallengeFail={onAppChallengeFail}
        />
      ),
    },
  };
  const { title, subtitle, mainComponent } = steps[currentStep];

  return (
    <>
      <h2
        className={clsx(
          "text-center text-headline-2xl-bold",
          account ? "mb-2" : "mb-4",
        )}
      >
        {title}
      </h2>

      {subtitle && (
        <p className="text-center text-body-14-regular text-black-500">
          {subtitle}
        </p>
      )}

      {mainComponent}
    </>
  );
}
