import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useAppStore from "common/hooks/useAppStore";
import {
  SelectedWorkspaceProvider,
  useSelectedWorkspaceContext,
} from "common/helpers/SelectedWorkspaceContext";
import useWorkspaceChangeUpdates from "common/hooks/useWorkspaceChangeUpdates";
import useNavItems from "common/hooks/useNavItems";
import useGTM from "common/hooks/useGTM";
import usePageTitle from "common/hooks/usePageTitle";
import ToastContainer from "common/components/ui/ToastContainer";
import useWorkspaces from "common/datahooks/useWorkspaces";
import AppSumoDialog from "common/layouts/WorkspaceLayout/AppSumoDialog";
import CreateCampaignModal from "common/components/CreateCampaignModal";

import WebPushPrompt from "./WebPushPrompt";
import Layout from "./Layout";
import Banners from "./Banners";
import { workspaceRoutes } from "../../../pages/routes";

function Content() {
  useWorkspaceChangeUpdates();
  const { sendEvent } = useGTM();
  const location = useLocation();
  const selectedWorkspace = useSelectedWorkspaceContext();
  const navItems = useNavItems();
  const headerContent = useAppStore((state) => state.headerContent);
  const pageTitle = usePageTitle();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAppSumoDialogOpen, setIsAppSumoDialogOpen] = useState(
    !!searchParams.get("code"),
  );
  const inviteToken = searchParams.get("invite_token");
  const isCampaignModalOpen = useAppStore((state) => state.isCampaignModalOpen);
  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });

    const pagePath = selectedWorkspace
      ? location.pathname.replace(`/workspaces/${selectedWorkspace.id}`, "")
      : location.pathname;

    if (pagePath) {
      sendEvent("virtualPageView", () => ({
        pageUrl: window.location.href,
        pagePath,
      }));
    }
  }, [location]);

  // Check for "invite_token" in the query params
  if (inviteToken) {
    return (
      <Navigate
        to={`/workspaces/${selectedWorkspace.id}/invitations/${inviteToken}`}
        replace
      />
    );
  }

  return (
    <Layout
      key={`${selectedWorkspace.id}_${selectedWorkspace.accounts.length}`}
      navItems={navItems}
      headerTitle={(headerContent && headerContent.title) || pageTitle}
      bannerComponent={<Banners />}
    >
      <Outlet />
      <WebPushPrompt />
      <ToastContainer />
      <CreateCampaignModal
        isOpen={isCampaignModalOpen}
        onClose={() => setIsCampaignModalOpen(false)}
      />
      <AppSumoDialog
        isOpen={isAppSumoDialogOpen}
        code={searchParams.get("code")}
        onClose={() => {
          setIsAppSumoDialogOpen(false);
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.delete("code");
            return newParams;
          });
        }}
      />
    </Layout>
  );
}

export default function WorkspaceLayout() {
  const { workspaceId } = useParams();
  const { workspaces } = useWorkspaces();
  const location = useLocation();

  const selectedWorkspace = workspaces.find(({ id }) => id === workspaceId);

  // Validate the workspaceId in the url
  if (!workspaceId || !selectedWorkspace) {
    return <Navigate to={`/${workspaces[0].id}`} replace />;
  }

  // Check if the current location matches the base workspace URL and redirect to "/home"
  if (location.pathname === `/workspaces/${workspaceId}`) {
    return (
      <Navigate
        to={`/workspaces/${workspaceId}/${workspaceRoutes[0].path}`}
        replace
      />
    );
  }

  return (
    <SelectedWorkspaceProvider value={selectedWorkspace}>
      <Content />
    </SelectedWorkspaceProvider>
  );
}
