import React, { Dispatch, SetStateAction } from "react";
import { Check, PencilSimple, X } from "@phosphor-icons/react";

import RestrictedComponent from "common/components/RestrictedComponent";
import { Button } from "common/components/ui/Button";
import { TimezoneOption } from "common/constants/timezones";
import { Schedule } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useCampaignSchedule from "../../../datahooks/useCampaignSchedule";

interface ScheduleEditProps {
  isEditing: boolean;
  selectedTimezone: TimezoneOption;
  schedule: Schedule;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  resetSchedule: () => void;
}

export default function ScheduleEdit({
  isEditing,
  selectedTimezone,
  schedule,
  setIsEditing,
  resetSchedule,
}: ScheduleEditProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { setCampaignSchedule, isSettingCampaignSchedule } =
    useCampaignSchedule();

  function saveEditedSchedule() {
    const { label, offset } = selectedTimezone;

    let newTimezone = null;
    if (offset) {
      newTimezone = {
        name: label,
        offset,
      };
    }

    setCampaignSchedule({
      schedule: {
        ...schedule,
        timezone: newTimezone,
      },
    }).then(() => setIsEditing(false));
  }

  if (isEditing) {
    return (
      <div className="flex gap-2">
        <Button
          variant="tertiary-black"
          disabled={isSettingCampaignSchedule}
          onClick={resetSchedule}
          intent={isTabletOrDesktop ? "default" : "iconOnly"}
        >
          {isTabletOrDesktop ? "Cancel" : <X />}
        </Button>
        <Button
          variant="secondary-purple"
          onClick={saveEditedSchedule}
          isLoading={isSettingCampaignSchedule}
          intent={isTabletOrDesktop ? "default" : "iconOnly"}
        >
          {isTabletOrDesktop ? "Save" : <Check />}
        </Button>
      </div>
    );
  }

  return (
    <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
      {isTabletOrDesktop ? (
        <Button
          variant="secondary-purple"
          leftIcon={<PencilSimple />}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </Button>
      ) : (
        <Button
          intent="iconOnly"
          variant="secondary-black"
          onClick={() => setIsEditing(true)}
        >
          <PencilSimple />
        </Button>
      )}
    </RestrictedComponent>
  );
}
