import React, { useState, useEffect } from "react";
import { GoogleChromeLogo, Plugs } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LinkedInProfile, WorkspaceAccount } from "common/types";
import useAimfoxExtension from "common/datahooks/useAimfoxExtension";

import { Button } from "../ui/Button";
import ProfileImage from "../ui/ProfileImage";

const chromeExtensionId = import.meta.env.VITE_CHROME_EXTENSION_ID;

interface ExtensionLoginProps {
  account: WorkspaceAccount;
  onBack: () => void;
}

export default function ExtensionLogin({
  account,
  onBack,
}: ExtensionLoginProps) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const [linkedinAccount, setLinkedinAccount] =
    useState<Partial<LinkedInProfile>>(null);
  const [isExtensionAvailable, setIsExtensionAvailable] = useState(false);

  useEffect(() => {
    const checkExtension = () => {
      if (
        typeof window.chrome === "undefined" ||
        !window.chrome?.runtime?.sendMessage
      ) {
        setIsExtensionAvailable(false);
        setLinkedinAccount(null);

        return;
      }

      window.chrome.runtime.sendMessage(
        chromeExtensionId,
        { command: "ping" },
        (response) => {
          const isAvailable = !window.chrome.runtime.lastError && response;
          setIsExtensionAvailable(isAvailable);

          if (!isAvailable) {
            setLinkedinAccount(null);
          }
        },
      );
    };

    // Check initially
    checkExtension();

    // Listen for extension install/uninstall events
    const handleInstall = (info: chrome.management.ExtensionInfo) => {
      if (info.id === chromeExtensionId) {
        checkExtension();
      }
    };

    const handleUninstall = (id: string) => {
      if (id === chromeExtensionId) {
        checkExtension();
      }
    };

    window.chrome?.management?.onInstalled?.addListener(handleInstall);
    window.chrome?.management?.onUninstalled?.addListener(handleUninstall);
    window.addEventListener("focus", checkExtension);

    return () => {
      window.chrome?.management?.onInstalled?.removeListener(handleInstall);
      window.chrome?.management?.onUninstalled?.removeListener(handleUninstall);
      window.removeEventListener("focus", checkExtension);
    };
  }, []);

  const {
    connectLinkedInAccount,
    isConnectingAccount,
    getAccountFromCookies,
    isGettingAccount,
  } = useAimfoxExtension(workspaceId, account?.id);

  function getCookiesAccount(): void {
    if (!chromeExtensionId) {
      // eslint-disable-next-line no-console
      console.error("Chrome extension ID is not defined");
      return;
    }

    window.chrome.runtime.sendMessage(
      chromeExtensionId,
      { command: "getCookies" },
      async ({ response }) => {
        await getAccountFromCookies(response).then((profile) => {
          if (account) {
            onBack();
            return;
          }
          setLinkedinAccount(profile);
        });
      },
    );
  }

  async function handleConnectLinkedInAccount(): Promise<void> {
    await connectLinkedInAccount({
      accountId: String(linkedinAccount.id),
    });
  }

  let mainComponent;

  if (!isExtensionAvailable) {
    mainComponent = (
      <>
        <div className="mb-4 text-center">
          {account ? "Connect" : "Re-connect"} with our Chrome extension instead
        </div>

        <Button
          aria-label="Install Chrome Extension"
          leftIcon={<GoogleChromeLogo />}
          variant="tertiary-black"
          className="mx-auto"
          asChild
        >
          <a
            target="_blank"
            href="https://chromewebstore.google.com/detail/aimfox-linkedin-session-m/lpcfkcolkhgophmdlekfcndfamikglha"
            rel="noreferrer"
          >
            Install Chrome Extension
          </a>
        </Button>
      </>
    );
  } else if (!linkedinAccount) {
    mainComponent = (
      <Button
        className="mx-auto"
        type="button"
        leftIcon={<Plugs />}
        onClick={getCookiesAccount}
        variant="tertiary-black"
        isLoading={isGettingAccount}
      >
        {account ? "Re-connect" : "Connect"} via Aimfox Chrome Extension
      </Button>
    );
  } else {
    mainComponent = (
      <>
        <div className="mb-4 flex w-full items-center gap-3 rounded-20 border border-black-200 p-3">
          <ProfileImage
            src={linkedinAccount?.picture_url}
            alt={linkedinAccount?.full_name}
          />

          <div className="flex flex-col">
            <span className="text-body-16-bold">
              {linkedinAccount?.full_name}
            </span>

            <span className="text-black-500">
              {linkedinAccount?.occupation}
            </span>
          </div>
        </div>

        <Button
          onClick={handleConnectLinkedInAccount}
          isLoading={isConnectingAccount}
          variant="primary-purple"
          type="button"
        >
          Add account
        </Button>
      </>
    );
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="my-4 flex flex-row items-center gap-2">
        <div className="h-px flex-1 bg-black-200" />

        <span className="mx-auto bg-white px-2 text-center text-black-400">
          OR
        </span>

        <div className="h-px flex-1 bg-black-200" />
      </div>

      {mainComponent}
    </div>
  );
}
