import React, { useRef } from "react";
import { CaretRight } from "@phosphor-icons/react";

import { Invitation, Workspace } from "common/types";
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableCell,
} from "common/components/ui/Table";
import { Button } from "common/components/ui/Button";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import WorkspaceImage from "common/components/WorkspaceImage";
import { Tag } from "common/components/ui/Tag";
import { userRoles } from "common/constants";
import useInvitations from "common/datahooks/useInvitations";
import usePromotions from "common/datahooks/usePromotions";

interface WorkspacesTableProps {
  workspaces: Workspace[];
  invitations: Invitation[];
}

function InvitationRow({
  invitation: { workspace_id, workspace_name, role, token },
}: {
  invitation: Invitation;
}) {
  const isAcceptRef = useRef<boolean>();

  const { label, tagVariant } = userRoles[role];

  const { respondToInvitation, isRespondingToInvitation } = useInvitations({
    disableQuery: true,
  });
  const { appSumoPromotion, ltdPromotion } = usePromotions();

  function acceptInvite() {
    isAcceptRef.current = true;
    respondToInvitation({ accept: true, memberToken: token });
  }

  function declineInvite() {
    isAcceptRef.current = false;
    respondToInvitation({ accept: false, memberToken: token });
  }

  return (
    <TableRow className="hover:shadow-none hover:cursor-auto">
      <TableCell>
        <div className="flex items-center gap-3">
          <WorkspaceImage id={workspace_id} />
          <div className="flex flex-col items-start gap-1">
            <span className="text-body-16-bold">{workspace_name}</span>
            <Tag variant={tagVariant}>{label}</Tag>
          </div>
        </div>
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      {(appSumoPromotion || ltdPromotion) && <TableCell>-</TableCell>}
      <TableCell>
        <div className="flex items-center justify-end gap-3">
          <Button
            variant="tertiary-black"
            onClick={declineInvite}
            isLoading={
              isAcceptRef.current === false && isRespondingToInvitation
            }
            disabled={isAcceptRef.current === true && isRespondingToInvitation}
          >
            Decline
          </Button>
          <Button
            variant="secondary-purple"
            onClick={acceptInvite}
            isLoading={isAcceptRef.current === true && isRespondingToInvitation}
            disabled={isAcceptRef.current === false && isRespondingToInvitation}
          >
            Accept
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default function WorkspacesTable({
  workspaces,
  invitations,
}: WorkspacesTableProps) {
  const { goToWorkspace } = useWorkspaceNavigate();
  const { appSumoPromotion, ltdPromotion } = usePromotions();

  return (
    <Table>
      <TableHeader>
        <tr>
          <TableHead className="w-2/6">Name</TableHead>
          <TableHead className="w-1/6">Accounts</TableHead>
          {(appSumoPromotion || ltdPromotion) && (
            <TableHead className="w-1/6">Lifetime Seats</TableHead>
          )}
          <TableHead className="w-1/6">Seats</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody className="mt-4">
        {invitations.map((invitation) => (
          <InvitationRow key={invitation.token} invitation={invitation} />
        ))}
        {workspaces.map(
          ({ id: workspaceId, name, accounts, licenses, role }) => {
            const { label, tagVariant } = userRoles[role];

            return (
              <TableRow
                key={workspaceId}
                onClick={() => goToWorkspace(workspaceId)}
              >
                <TableCell>
                  <div className="flex items-center gap-3">
                    <WorkspaceImage id={workspaceId} />
                    <div className="flex flex-col items-start gap-1">
                      <span className="text-body-16-bold">{name}</span>
                      <Tag variant={tagVariant}>{label}</Tag>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{accounts.length}</TableCell>
                {(appSumoPromotion || ltdPromotion) && (
                  <TableCell>
                    {
                      licenses.filter(({ source }) => source !== "aimfox")
                        .length
                    }
                  </TableCell>
                )}
                <TableCell>{licenses.length}</TableCell>
                <TableCell>
                  <Button intent="labelIcon" variant="quaternary-black">
                    <CaretRight />
                  </Button>
                </TableCell>
              </TableRow>
            );
          },
        )}
      </tbody>
    </Table>
  );
}
