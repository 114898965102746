import React, { useEffect } from "react";
import clsx from "clsx";
import { DotOutline } from "@phosphor-icons/react";

import useNotifications from "common/datahooks/useNotifications";
import { timeSince } from "common/helpers/utils";
import { Notification } from "common/types";
import RenderIf from "common/components/RenderIf";

export default function NotificationItem({
  title,
  body,
  seen,
  created_at,
  id,
}: Notification) {
  const { openNotification } = useNotifications();

  useEffect(() => {
    if (!seen) {
      const timeoutId = setTimeout(
        () => openNotification({ notificationId: id }),
        1000,
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <div
      className={clsx(
        "flex items-center justify-between gap-x-2 rounded-xl p-3 md:gap-x-3 md:p-4",
        !seen && "bg-purple-50",
      )}
    >
      <div className="flex flex-col gap-y-1">
        <h3 className={seen ? "text-body-14-regular" : "text-body-14-bold"}>
          {title}
        </h3>
        <p className="text-caption-12-regular text-black-600">{body}</p>
      </div>
      <div className="flex items-center gap-x-1">
        <RenderIf condition={!seen}>
          <DotOutline weight="fill" size={16} className="fill-purple-500" />
        </RenderIf>
        <span className="text-nowrap text-caption-12-regular text-black-600">
          {timeSince(created_at * 1000)}
        </span>
      </div>
    </div>
  );
}
