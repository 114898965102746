import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import useBilling from "../../datahooks/useBilling";
import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";

import NoInvoicesFound from "assets/images/empty-placeholders/no-invoices-found.png";

export default function Invoices() {
  const { invoices, isLoadingInvoices } = useBilling();
  const isTabletOrDesktop = useTwBreakpoint("md");

  if (!isLoadingInvoices && !invoices?.length) {
    return (
      <EmptyPlaceholder
        title="No invoices found"
        subtitle="There are no invoices to display"
        imageSrc={NoInvoicesFound}
      />
    );
  }

  const Table = isTabletOrDesktop ? DesktopTable : MobileList;
  return <Table invoices={invoices ?? []} isLoading={isLoadingInvoices} />;
}
