import React, { useState } from "react";
import { clsx } from "clsx";

const maxLength = 400;
function truncateString(string: string) {
  if (string.length > maxLength) {
    return `${string.slice(0, maxLength).trim()}...`;
  }
  return string;
}
export default function ExpandableText({
  children,
  className = undefined,
}: {
  children: string;
  className?: string;
}) {
  const [isExpanded, setIsExpanded] = useState(
    children && children.length < maxLength,
  );

  return (
    <div className={clsx("mt-2.5 flex flex-col gap-y-2", className)}>
      <p className="whitespace-pre-wrap break-all text-caption-12-regular text-black-700">
        {isExpanded ? children : truncateString(children)}
      </p>
      {!isExpanded && (
        <button
          className="ml-auto text-body-14-regular text-purple-600"
          type="button"
          onClick={() => setIsExpanded(true)}
        >
          ...see more
        </button>
      )}
    </div>
  );
}
