import React from "react";

import { sequenceDelayOptions } from "../../../../constants";
import DelaySelect from "./DelaySelect";

interface Props {
  isEditing: boolean;
  values: { days: number; hours: number };
  onChange: (values: { days: number; hours: number }) => void;
  messageIndex: number;
  isDisabled: boolean;
}

export default function DesktopLayout({
  isEditing,
  values,
  onChange,
  messageIndex,
  isDisabled,
}: Props) {
  const { days, hours } = values;
  const { dayOptions, hourOptions } = sequenceDelayOptions(true);
  const selectedDay = dayOptions.find(({ value }) => days === value)?.value;
  if (selectedDay === dayOptions[dayOptions.length - 1].value) {
    hourOptions.pop();
  }

  const daysLabel = `day${days === 1 ? "" : "s"}`;
  const hoursLabel = `hour${hours === 1 ? "" : "s"}`;

  return (
    <div className="flex h-[42px] items-center justify-center rounded-2xl border border-black-200 text-black-400">
      {isEditing ? (
        <>
          <span>Delay&nbsp;</span>
          {/* Days Select */}
          <DelaySelect
            options={dayOptions}
            selectedValue={dayOptions.find(({ value }) => days === value).value}
            onSelect={(value) => onChange({ ...values, days: value })}
            isDisabled={isDisabled}
          />
          <span>&nbsp;{daysLabel}&nbsp;</span>
          {/* Hours Select */}
          <DelaySelect
            options={hourOptions}
            selectedValue={
              hourOptions.find(({ value }) => hours === value).value
            }
            onSelect={(value) => onChange({ ...values, hours: value })}
            isDisabled={isDisabled}
          />
          <span>&nbsp;{hoursLabel}&nbsp;</span>
          <span className="text-black-950">
            after
            {messageIndex === 0 ? " connecting" : ` Message ${messageIndex}`}
          </span>
        </>
      ) : (
        <>
          <span>Message {messageIndex + 1} will be sent&nbsp;</span>
          {days + hours !== 0 ? (
            <>
              <span className="text-black-950">
                &nbsp;{days <= 9 ? `0${days}` : days}&nbsp;
              </span>
              <span>{daysLabel}&nbsp;</span>

              <span className="text-black-950">
                &nbsp;{hours <= 9 ? `0${hours}` : hours}&nbsp;
              </span>
              <span>{hoursLabel}&nbsp;</span>
            </>
          ) : (
            <span className="text-black-950">immediately&nbsp;</span>
          )}
          <span>after&nbsp;</span>
          <span className="text-black-950">
            {messageIndex === 0 ? "connecting" : `Message ${messageIndex}`}
          </span>
        </>
      )}
    </div>
  );
}
