import React, { ReactNode } from "react";
import { TextAlignLeft } from "@phosphor-icons/react";

import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "common/components/ui/Accordion";
import { typedObjectEntries } from "common/helpers/utils";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";

import { profileSearchOptions } from "../../../../../../constants";
import Keywords from "../Keywords";
import { useSearchParamsContext } from "../../SearchParamsContext";
import SearchParamSelect from "./SearchParamSelect";

export default function MobileLayout({
  totalCountComponent,
}: {
  totalCountComponent: ReactNode;
}) {
  const { searchParams } = useSearchParamsContext();

  return (
    <>
      <Accordion type="multiple" className="mb-4 w-full">
        <AccordionItem value="keywords">
          <AccordionTrigger>
            <TextAlignLeft size={20} />
            <span>Keywords</span>
            <RenderIf condition={!!searchParams.keywords.length}>
              <Tag>{searchParams.keywords.length}</Tag>
            </RenderIf>
          </AccordionTrigger>
          <AccordionContent>
            <Keywords />
          </AccordionContent>
        </AccordionItem>
        {typedObjectEntries(profileSearchOptions).map(([key]) => (
          <SearchParamSelect key={key} optionsKey={key} />
        ))}
      </Accordion>
      {totalCountComponent}
    </>
  );
}
