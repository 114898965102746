import React from "react";
import { X } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import { Sheet, SheetClose, SheetContent } from "common/components/ui/Sheet";
import { WorkspaceAccount } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import RenderIf from "common/components/RenderIf";

import AccountInfoCard from "./AccountInfoCard";
import AccountDynamics from "./AccountDynamics";
import AccountProxy from "./AccountProxy";

interface AccountDynamicsProps {
  account: WorkspaceAccount;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function AccountSettings({
  account,
  isOpen,
  setIsOpen,
}: AccountDynamicsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Sheet : Drawer;
  const Content = isTabletOrDesktop ? SheetContent : DrawerContent;
  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:flex md:max-w-[568px] md:flex-col md:gap-y-4 md:overflow-y-auto md:rounded-tl-[2rem] md:bg-black-50 md:p-6 md:scrollbar-thin">
        <RenderIf condition={isTabletOrDesktop}>
          <SheetClose asChild>
            <Button
              className="ml-auto"
              variant="quaternary-black"
              intent="iconOnly"
            >
              <X />
            </Button>
          </SheetClose>
        </RenderIf>

        <AccountInfoCard account={account} />
        <AccountDynamics accountId={account.id} />
        <AccountProxy accountId={account.id} />
      </Content>
    </Component>
  );
}
