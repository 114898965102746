import React from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import clsx from "clsx";

import { ScheduleInterval } from "common/types";
import { Button } from "common/components/ui/Button";
import Switch from "common/components/ui/Switch";

import RangesSelect from "../RangesSelect";

interface Props {
  day: string;
  ranges: ScheduleInterval[];
  isDisabled: boolean;
  setRanges: (ranges: ScheduleInterval[]) => void;
  onApplyToAll: (ranges: ScheduleInterval[]) => void;
}

export default function DesktopLayout({
  day,
  ranges,
  isDisabled,
  setRanges,
  onApplyToAll,
}: Props) {
  const [animateParentRef] = useAutoAnimate();
  const isEnabled = !!ranges.length;

  return (
    <div
      ref={animateParentRef}
      className="grid min-h-20 grid-cols-3 items-center border-b border-black-200 py-4 last:border-b-0"
    >
      <h6
        className={clsx(
          "w-20 text-body-14-bold capitalize",
          !isEnabled && "text-black-400",
        )}
      >
        {day}
      </h6>

      {isEnabled ? (
        <RangesSelect
          ranges={ranges}
          setRanges={setRanges}
          isDisabled={isDisabled}
        />
      ) : (
        <span className="mx-auto text-nowrap text-button-14 text-black-400">
          Not active
        </span>
      )}

      <div className="ml-auto flex items-center gap-4">
        <Button
          variant="quaternary-black"
          className={clsx(
            "transition-[visibility,opacity]",
            isEnabled ? "visible opacity-100" : "invisible opacity-0",
          )}
          onClick={() => {
            onApplyToAll(ranges);
          }}
          disabled={isDisabled}
        >
          Apply to all days
        </Button>

        <Switch
          checked={isEnabled}
          disabled={isDisabled}
          onCheckedChange={() =>
            setRanges(isEnabled ? [] : [{ start: 0, end: 2 }])
          }
        />
      </div>
    </div>
  );
}
