import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import debounce from "debounce";
import clsx from "clsx";

import Alert from "common/components/ui/Alert";

export default function TargetingInfo({
  isLimitExceeded,
}: {
  isLimitExceeded: boolean;
}) {
  const [isVisible, setIsVisible] = useState(false);

  const canFitInViewport = document.body.offsetHeight <= window.innerHeight;

  const handleScroll = useCallback(
    debounce(() => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight ||
        canFitInViewport
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 200),
    [],
  );

  useEffect(() => {
    if (canFitInViewport) {
      setIsVisible(true);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const { t } = useTranslation("campaigns", {
    keyPrefix: "targetingSetup",
  });

  return (
    <Alert
      className={clsx(
        "fixed bottom-28 left-1/2 w-10/12 max-w-[600px] -translate-x-1/2 transition-[opacity,visibility] duration-200 md:bottom-24 md:ml-[72px]",
        isVisible ? "visible opacity-100" : "invisible opacity-0",
      )}
      variant={isLimitExceeded ? "warning" : "default"}
      title={t(
        isLimitExceeded ? "limitExceededTitle" : "previewTargetingTitle",
      )}
      description={t(
        isLimitExceeded ? "limitExceededSubtitle" : "previewTargetingSubtitle",
      )}
    />
  );
}
