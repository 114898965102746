import React from "react";

import { formatPrice, isLifetimeDeal } from "common/helpers/utils";
import { PricingPeriodUnit } from "common/types";
import useEstimateCreation from "common/datahooks/useEstimateCreation";
import Skeleton from "common/components/ui/Skeleton";
import useEstimateUpdate from "common/datahooks/useEstimateUpdate";
import useDebounce from "common/hooks/useDebounce";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

interface ToPayNowProps {
  licenseCount: number;
  selectedPeriodUnit: PricingPeriodUnit;
}

export default function ToPayNow({
  selectedPeriodUnit,
  licenseCount,
}: ToPayNowProps) {
  const {
    subscription,
    license_count: workspaceLicenseCount,
    licenses,
  } = useSelectedWorkspaceContext();
  const debouncedLicenseCount = useDebounce(licenseCount);

  const isDowngrade = licenseCount < workspaceLicenseCount;
  const isInTrial = subscription?.status === "in_trial";
  const isValidForTrial = !subscription && !isLifetimeDeal(licenses);

  const isPriceZero =
    selectedPeriodUnit === "month" &&
    (isValidForTrial || isInTrial || isDowngrade);

  const { estimateCreation, isLoadingEstimateCreation } = useEstimateCreation({
    selectedPeriodUnit,
    quantity: debouncedLicenseCount,
  });

  const { estimateUpdate, isLoadingEstimateUpdate } = useEstimateUpdate({
    selectedPeriodUnit,
    quantity: debouncedLicenseCount,
    prorate: true,
    isDisabled: isPriceZero,
  });

  const isLoading = isLoadingEstimateCreation || isLoadingEstimateUpdate;

  let price = 0;
  if (!isPriceZero && !isLoading) {
    price = estimateCreation
      ? estimateCreation.amountDue
      : estimateUpdate.amountDue;
  }

  return (
    <div className="flex items-center justify-between px-2 py-1">
      <span className="text-body-16-bold">To pay now</span>

      {isLoading && !isPriceZero ? (
        <Skeleton className="h-8 w-24" />
      ) : (
        <span className="text-headline-2xl-bold text-purple-600">
          {formatPrice(price / 100)}
        </span>
      )}
    </div>
  );
}
