import React, { Fragment } from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function ConversationLoader({
  itemsCount = 1,
}: {
  itemsCount?: number;
}) {
  const getLoaderList = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, index) => index);

  return (
    <div className="flex flex-col gap-1">
      {getLoaderList(itemsCount).map((item) => (
        <Fragment key={item}>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex flex-row items-center justify-between">
              <div className="flex w-full flex-row gap-2">
                <Skeleton className="size-8 rounded-xl" />

                <div className="flex flex-col gap-0.5">
                  <Skeleton className="h-4 w-28" />
                  <Skeleton className="h-3.5 w-36" />
                </div>

                <Skeleton className="ml-auto size-8 rounded-full" />
              </div>
            </div>

            <Skeleton className="h-4 w-3/4" />

            <div className="flex flex-row items-center justify-between">
              <div className="flex w-full flex-row gap-2">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-4 w-16" />
                <Skeleton className="ml-auto mr-2 size-4 rounded-full" />
              </div>
            </div>
          </div>

          <div className="h-px w-full bg-black-200 last:hidden" />
        </Fragment>
      ))}
    </div>
  );
}
