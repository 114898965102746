import React from "react";

import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

interface IgnoreProfilesModalProps {
  isOpen: boolean;
  invalidUrlsCount: number;
  isSettingTargeting: boolean;
  updateTargeting: () => void;
  onClose: () => void;
}

export default function IgnoreProfilesModal({
  isOpen,
  invalidUrlsCount,
  isSettingTargeting,
  onClose,
  updateTargeting,
}: IgnoreProfilesModalProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const isMoreThanOneUrl = invalidUrlsCount > 1;

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content>
        <h3 className="mb-2 text-center text-headline-xl-bold">
          Ignore invalid profiles?
        </h3>
        <span className="mb-12 text-center text-black-500">
          {invalidUrlsCount} LinkedIn profile URL
          {isMoreThanOneUrl ? "s are" : " is"} invalid. You can skip{" "}
          {isMoreThanOneUrl ? "these" : "this"} URL
          {isMoreThanOneUrl ? "s" : ""} and create the campaign with only the
          valid entries
        </span>
        <div className="flex flex-col gap-4 px-2 md:flex-row-reverse">
          <Button
            size="lg"
            variant="primary-black"
            className="flex-1"
            onClick={updateTargeting}
            isLoading={isSettingTargeting}
          >
            Skip
          </Button>
          <Button
            size="lg"
            variant="secondary-black"
            className="flex-1"
            onClick={onClose}
            disabled={isSettingTargeting}
          >
            Back
          </Button>
        </div>
      </Content>
    </Component>
  );
}
