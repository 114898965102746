import React, { useState } from "react";

import { GenericSearchParam } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import SearchParamOption from "./SearchParamOption";
import { useSearchParamsContext } from "../../SearchParamsContext";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import { profileSearchOptions } from "../../../../../../constants";
import MobileOptions from "./MobileOptions";

export default function Languages({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  const isTabletOrDesktop = useTwBreakpoint("lg");
  const [inputValue, setInputValue] = useState("");
  const { searchParams } = useSearchParamsContext();

  const {
    addSearchParamValue,
    removeSearchParamValue,
    appliedSearchParamValues,
  } = useSearchParams(optionsKey);

  const { suggestions } = useSearchOptions({
    searchParams,
  });

  const { searchParamKey, label } = profileSearchOptions[optionsKey];

  const lowercasedInput = inputValue.toLowerCase();

  const urnsInAppliedSearchParamValues = new Set(
    appliedSearchParamValues.map((option) => option.urn),
  );

  const filteredLanguages = (
    suggestions.languages as GenericSearchParam[]
  )?.filter((language) => {
    const languageNameLower = language.name.toLowerCase();
    return isTabletOrDesktop
      ? languageNameLower.includes(lowercasedInput)
      : languageNameLower.includes(lowercasedInput) &&
          !urnsInAppliedSearchParamValues.has(language.urn);
  });

  function getContent() {
    if (!filteredLanguages?.length)
      return (
        <p className="mx-4 break-words py-8 text-center text-black-500">
          No results found for{" "}
          <span className="text-black-950">{inputValue}</span>
        </p>
      );

    if (isTabletOrDesktop) {
      return (
        <div className="flex max-h-56 flex-col gap-1 overflow-y-auto scrollbar-thin">
          {filteredLanguages.map((language) => {
            const isSelected = !!appliedSearchParamValues.find(
              ({ urn }) => urn === language.urn,
            );
            return (
              <SearchParamOption
                key={language.urn}
                isSelected={isSelected}
                onClick={
                  isSelected
                    ? () => removeSearchParamValue(searchParamKey, language)
                    : () => addSearchParamValue(searchParamKey, language)
                }
                option={language}
              />
            );
          })}
        </div>
      );
    }

    return (
      <MobileOptions
        options={filteredLanguages}
        appliedOptions={appliedSearchParamValues as GenericSearchParam[]}
        removeSearchParam={(option) =>
          removeSearchParamValue(searchParamKey, option)
        }
        addSearchParam={(option) => addSearchParamValue(searchParamKey, option)}
      />
    );
  }

  return (
    <>
      <SearchInput
        variant={isTabletOrDesktop ? "md" : "sm"}
        value={inputValue}
        className="max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder={`Search ${label}`}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />
      {getContent()}
    </>
  );
}
