import React, { useState } from "react";
import { Gear } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Tag } from "common/components/ui/Tag";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { WorkspaceAccount } from "common/types";
import { Button } from "common/components/ui/Button";

import LicenseTag from "./LicenseTag";
import AccountActions from "./AccountActions";
import useWorkspaceAccountActions from "../hooks/useWorkspaceAccountActions";
import AccountSettings from "./AccountSettings";

export default function MobilePreview({
  account,
  isOpen,
  onClose,
}: {
  account: WorkspaceAccount;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { setAction, actionComponent } = useWorkspaceAccountActions({
    account,
    onClose,
    goBack: () => setAction(null),
  });

  const {
    state,
    picture_url: pictureUrl,
    full_name: fullName,
    occupation,
    license,
  } = account;

  const isDisconnected = state !== "LoggedIn";

  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
      onAfterClose={() => {
        setAction(null);
      }}
    >
      <DrawerContent>
        {actionComponent || (
          <>
            <div className="flex gap-x-2 py-4">
              <ProfileImage size="lg" src={pictureUrl} />

              <div className="flex w-full flex-row items-center justify-between gap-2">
                <div className="flex flex-col">
                  <span className="text-headline-xl-bold">{fullName}</span>

                  <span className="line-clamp-1 text-body-14-regular text-black-600">
                    {occupation}
                  </span>
                </div>

                <Button
                  size="lg"
                  intent="iconOnly"
                  variant="secondary-black"
                  onClick={() => setIsSettingsOpen(true)}
                >
                  <Gear size={24} weight="fill" />
                </Button>
              </div>
            </div>

            <div className="mb-8 flex border-t border-t-black-200 py-4">
              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-body-14-regular text-black-600">
                  Seat
                </span>

                <LicenseTag license={license} />
              </div>

              <div className="flex flex-1 flex-col items-start gap-y-1">
                <span className="text-body-14-regular text-black-600">
                  Status
                </span>

                <Tag variant={isDisconnected ? "disabled" : "success"}>
                  {isDisconnected ? "Not connected" : "Connected"}
                </Tag>
              </div>
            </div>

            <AccountActions account={account} setAction={setAction} />

            <AccountSettings
              account={account}
              isOpen={isSettingsOpen}
              setIsOpen={setIsSettingsOpen}
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
