import { post } from "common/helpers/HTTP";

let serviceWorkerRegistration: ServiceWorkerRegistration;
// when this is true it means we already prompted for webpush
const localStorageKey = "webPushPrompted";

function updateLocalStorage(prompted: boolean) {
  localStorage.setItem(localStorageKey, String(prompted));
  window.dispatchEvent(new Event("storage"));
}
// sometimes this doesn't exist, in tests for example
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    serviceWorkerRegistration = registration;
  });
}

export default function usePushService() {
  const webPushPrompted = JSON.parse(localStorage.getItem(localStorageKey));

  async function subscribeWebPush() {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      throw new Error("Notification permission declined");
    } else {
      try {
        const pushSubscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: import.meta.env.VITE_PUSH_SERVICE_KEY,
          });
        await post("webpush", { subscription: pushSubscription.toJSON() });
      } catch (error) {
        throw new Error(`Error subscribing to WebPush ${error}`);
      }
    }
  }
  async function unsubscribeWebPush() {
    const subscription =
      await serviceWorkerRegistration.pushManager.getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
    }
  }

  return {
    webPushPrompted,
    subscribeWebPush,
    unsubscribeWebPush,
    updateLocalStorage,
  };
}
