import { createContext, useContext } from "react";

import { ProfileSearchParams } from "common/types";

const SearchParamsContext = createContext<{
  searchParams: ProfileSearchParams;
  setSearchParams: (searchParams: Partial<ProfileSearchParams>) => void;
}>(null);

export const SearchParamsProvider = SearchParamsContext.Provider;

export function useSearchParamsContext(): {
  searchParams: ProfileSearchParams;
  setSearchParams: (searchParams: Partial<ProfileSearchParams>) => void;
} {
  return useContext(SearchParamsContext);
}
