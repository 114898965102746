import React from "react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface DelaySelectProps {
  selectedValue: number;
  isDisabled: boolean;
  options: { value: number; label: string }[];
  onSelect: (value: number) => void;
}

export default function DelaySelect({
  selectedValue = 30,
  isDisabled,
  options,
  onSelect,
}: DelaySelectProps) {
  const isDesktop = useTwBreakpoint("lg");

  return (
    <Select
      value={selectedValue.toString()}
      onValueChange={(value) => onSelect(Number(value))}
    >
      <SelectTrigger
        size={isDesktop ? "sm" : "lg"}
        disabled={isDisabled}
        className="max-lg:flex-1"
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="max-h-60">
        {options.map(({ value, label }) => (
          <SelectItem value={value.toString()} key={value}>
            <SelectItemText>{label}</SelectItemText>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
