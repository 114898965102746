import React from "react";
import { UseFormReturn } from "react-hook-form";
import clsx from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { TextWithLabels } from "common/components/VariableEditor";

import TemplateEdit from "./TemplateEdit";
import useFlowActions from "../../../../hooks/useFlowActions";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";
import { FlowTemplate } from "../../../../types";
import LanguageDropdown from "./LanguageDropdown";

interface TemplateDetailsProps {
  form: UseFormReturn<{ message: string; subject?: string }>;
  flowTemplate: FlowTemplate;
  isEditing: boolean;
  isDisabled: boolean;
  aiToggleComponent: React.ReactNode;
}

export default function TemplateDetails({
  form,
  flowTemplate,
  isEditing,
  isDisabled,
  aiToggleComponent,
}: TemplateDetailsProps) {
  const isDesktop = useTwBreakpoint("lg");
  const { flow, isFlowError } = useCampaignFlowContext();
  const { setFlowLanguage } = useFlowActions(flow);
  const { canEditFlow } = useCampaignFlowContext();

  const languageDropdownComponent = (
    <LanguageDropdown
      languageCode={flow.source_language}
      onChange={(newLanguageCode) => setFlowLanguage(newLanguageCode, true)}
      isDisabled={!flowTemplate || isDisabled || !canEditFlow}
      isManualLanguage={flow.is_manual_language}
    />
  );

  const templateEditComponent = (
    <TemplateEdit
      form={form}
      isDisabled={isDisabled}
      templateType={flowTemplate.type}
    />
  );

  if (isDesktop) {
    return (
      <section
        className={clsx(
          "flex flex-1 flex-col rounded-2xl border p-3",
          isFlowError ? "border-red-500" : "border-black-200",
        )}
      >
        {isEditing ? (
          templateEditComponent
        ) : (
          <div className="flex-1">
            {flowTemplate.type === "INMAIL_TEMPLATE" &&
              flowTemplate.subject !== "" && (
                <p className="mb-2 text-body-14-bold text-purple-600">
                  {flowTemplate.subject}
                </p>
              )}
            {flowTemplate.message ? (
              <TextWithLabels
                key={flowTemplate.message}
                text={flowTemplate.message}
                isEditable={false}
                isDisabled={isDisabled || !canEditFlow}
              />
            ) : (
              <p className="text-black-400">
                Select a template first. The text will show a preview here when
                selected
              </p>
            )}
          </div>
        )}

        <div className="-mx-3 mt-3 flex items-center justify-between border-t border-t-black-200 px-3 pt-3">
          {languageDropdownComponent}
          {aiToggleComponent}
        </div>
      </section>
    );
  }

  return (
    <>
      <div className="mb-4 flex min-h-64 flex-col rounded-2xl border border-black-200 bg-whiteGray p-3">
        {templateEditComponent}
      </div>
      {languageDropdownComponent}
    </>
  );
}
