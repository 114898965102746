import React, { useEffect, useState } from "react";

import { PaymentSource } from "common/types";
import usePaymentSources from "common/datahooks/usePaymentSources";
import Loader from "common/components/Loader";

import AddPaymentMethod from "./AddPaymentMethod";
import PaymentMethodSelection from "./PaymentMethodSelection";

export interface PaymentMethodProps {
  paymentSource: PaymentSource;
  onBack: () => void;
  onNext?: (paymentSource: PaymentSource) => void;
  onSelect?: (paymentSource: PaymentSource) => void;
}

export default function PaymentMethod({
  paymentSource,
  onBack,
  onNext = null,
  onSelect = null,
}: PaymentMethodProps) {
  const { paymentSources, isLoadingPaymentSources } = usePaymentSources();
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);

  useEffect(() => {
    if (!isLoadingPaymentSources && paymentSources) {
      setIsAddingPaymentMethod(!paymentSources.length);
    }
  }, [paymentSources, isLoadingPaymentSources]);

  if (isLoadingPaymentSources) return <Loader className="my-40" />;

  if (isAddingPaymentMethod)
    return (
      <AddPaymentMethod
        onNext={onNext}
        onBack={
          paymentSources.length ? () => setIsAddingPaymentMethod(false) : onBack
        }
      />
    );

  return (
    <PaymentMethodSelection
      paymentSources={paymentSources}
      selectedPaymentSource={paymentSource}
      addPaymentMethod={() => setIsAddingPaymentMethod(true)}
      onBack={onBack}
      onSelect={onSelect}
    />
  );
}
