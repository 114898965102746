import React from "react";
import { Routes, Route } from "react-router-dom";

import LeadDetails from "./LeadDetails/LeadDetails";
import AllLeads from "./AllLeads/AllLeads";

export default function Leads() {
  return (
    <Routes>
      <Route path="/" element={<AllLeads />} />
      <Route path=":profileId" element={<LeadDetails />} />
    </Routes>
  );
}
