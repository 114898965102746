import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import { LinkedInLoginModal } from "common/components/LinkedInLogin";
import { WorkspaceAccount } from "common/types";

import ReconnectAccountImage from "assets/images/empty-placeholders/reconnect-account.png";

export default function ReconnectAccount({
  account,
}: {
  account: WorkspaceAccount;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <EmptyPlaceholder
        title="Connect your LinkedIn Account"
        subtitle="You need to have a connected LinkedIn account to create a campaign"
        imageSrc={ReconnectAccountImage}
        actionComponent={
          <Button
            onClick={() => setIsModalOpen(true)}
            leftIcon={<PlusCircle weight="fill" />}
          >
            Connect LinkedIn account
          </Button>
        }
      />
      <LinkedInLoginModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        account={account}
      />
    </>
  );
}
