import React from "react";

import { Education as EducationType } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";

import SectionContainer from "./SectionContainer";
import { formatMonthAndYear, formatRange } from "../../utils";
import SectionItem from "./SectionItem";
import ExpandableText from "./ExpandableText";

import EducationDefaultImage from "assets/images/education-fallback.png";

export default function Education({
  education,
  isLoading,
}: {
  education: EducationType[];
  isLoading: boolean;
}) {
  return (
    <SectionContainer title="Education">
      {(isLoading ? new Array(3).fill({}) : education).map(
        (
          { start_date, end_date, school, degree, field_of_study, description },
          index,
        ) => (
          // eslint-disable-next-line react/no-array-index-key
          <SectionItem key={index}>
            <div className="flex flex-col">
              <div className="flex grow items-center gap-x-2">
                {isLoading ? (
                  <Skeleton className="size-10" />
                ) : (
                  <ProfileImage
                    src={
                      school.logo_url ? school.logo_url : EducationDefaultImage
                    }
                  />
                )}
                <div className="flex flex-col gap-y-0.5">
                  {isLoading ? (
                    <Skeleton className="h-4 w-40" />
                  ) : (
                    <span className="break-all text-button-14">
                      {school.name}
                    </span>
                  )}
                  {isLoading ? (
                    <Skeleton className="h-3 w-60" />
                  ) : (
                    (degree || field_of_study) && (
                      <span className="break-all text-caption-12-regular text-black-500">
                        {[degree?.entity.name, field_of_study?.entity.name]
                          .filter((value) => !!value)
                          .join(", ")}
                      </span>
                    )
                  )}
                </div>
                {isLoading ? (
                  <Skeleton className="ml-auto h-3 w-20" />
                ) : (
                  (start_date || end_date) && (
                    <span className="ml-auto text-nowrap text-caption-12-regular">
                      {formatRange(
                        start_date && formatMonthAndYear(start_date),
                        end_date ? formatMonthAndYear(end_date) : "Present",
                      )}
                    </span>
                  )
                )}
              </div>
              {description && (
                <ExpandableText className="ml-12">{description}</ExpandableText>
              )}
            </div>
          </SectionItem>
        ),
      )}
    </SectionContainer>
  );
}
