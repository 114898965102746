import React from "react";
import { LinkedinLogo, Plus } from "@phosphor-icons/react";
import clsx from "clsx";

import { TemplateType } from "common/types";
import { Button } from "common/components/ui/Button";

import { useCampaignFlowContext } from "../../context/CampaignFlowContext";

interface PlaceholderProps {
  onAdd: () => void;
  templateType: TemplateType;
  isDisabled?: boolean;
  shouldShowPremiumBanner?: boolean;
}

const templateTypeTitle: Record<TemplateType, string> = {
  INMAIL_TEMPLATE: "Add a InMail",
  MESSAGE_TEMPLATE: "Add a Follow-Up Message",
  NOTE_TEMPLATE: "Add a Connection Note",
};

export default function Placeholder({
  onAdd,
  templateType,
  isDisabled = false,
  shouldShowPremiumBanner = false,
}: PlaceholderProps) {
  const { canEditFlow } = useCampaignFlowContext();
  const isPlaceholderDisabled = !canEditFlow || isDisabled;

  const commonWrapperClassNames =
    "flex items-center gap-2.5 rounded-2xl border p-4 ";

  if (shouldShowPremiumBanner)
    return (
      <div
        className={clsx(
          "border-purple-200 bg-purple-100",
          commonWrapperClassNames,
          isDisabled && "bg-black-100",
        )}
      >
        <Button intent="labelIcon" size="lg" variant="primary-purple">
          <LinkedinLogo weight="fill" />
        </Button>
        <div>
          <h5 className="mb-0.5 text-left text-button-16">
            Notes not available
          </h5>
          <p className="text-left text-caption-12-regular text-black-500">
            You need LinkedIn Premium to add a note to your connection request
          </p>
        </div>
      </div>
    );

  return (
    <button
      type="button"
      onClick={onAdd}
      disabled={isPlaceholderDisabled}
      className={clsx(
        "group border-dashed border-black-300 bg-white transition-colors hover:bg-whiteGray disabled:cursor-not-allowed disabled:bg-black-100",
        commonWrapperClassNames,
      )}
    >
      <Button
        intent="labelIcon"
        size="lg"
        className="group-disabled:bg-black-200 group-disabled:text-black-400"
      >
        <Plus />
      </Button>

      <h5 className="mb-0.5 text-left text-button-16 group-disabled:text-black-400">
        {templateTypeTitle[templateType]}
      </h5>
    </button>
  );
}
