import {
  addNumberOrdinal,
  getDayName,
  getMonthName,
} from "common/helpers/utils";

import { Conversation } from "./types";

export function formatConversationMessagesDate(date: Date) {
  const dateNow = new Date();

  // Helper function to format time in 12-hour format with AM/PM
  function formatTime(formatDate: Date) {
    let hours = formatDate.getHours();
    const minutes = formatDate.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    return `${hours}:${minutes}${ampm}`;
  }

  // Calculate the difference in days between the current date and the message date
  const diffDays = Math.floor(
    (dateNow.getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  );

  if (
    date.getFullYear() === dateNow.getFullYear() &&
    date.getMonth() === dateNow.getMonth() &&
    date.getDate() === dateNow.getDate()
  ) {
    return `Today, ${formatTime(date)}`;
  }

  if (diffDays === 1) {
    return `Yesterday, ${formatTime(date)}`;
  }

  if (diffDays >= 2 && diffDays <= 7) {
    return `${getDayName(date.getDay())}, ${formatTime(date)}`;
  }

  return `${getMonthName(date.getMonth())} ${addNumberOrdinal(date.getDate())}, ${formatTime(date)}`;
}

export function getGroupParticipantsString(conversation: Conversation) {
  const { participants } = conversation;
  const participantCount = participants.length;

  if (participantCount > 2) {
    const firstTwo = participants
      .slice(0, 2)
      .map(({ full_name }) => `${full_name}`)
      .join(", ");
    const remainingCount = participantCount - 2;
    return `${firstTwo} + ${remainingCount} more`;
  }

  return participantCount > 1
    ? participants.map(({ full_name }) => `${full_name}`).join(", ")
    : null;
}

export function formatChatTimestamp(unixTimestamp: number) {
  const today = new Date();

  // Set to the start of today
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);

  // Set to the start of yesterday
  yesterday.setDate(today.getDate() - 1);

  const date = new Date(unixTimestamp);

  if (unixTimestamp >= today.getTime()) {
    // Get hours and minutes for today's messages
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${ampm}`;
  }

  if (unixTimestamp >= yesterday.getTime()) {
    return "Yesterday";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
