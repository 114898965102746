import React from "react";

import SectionContainer from "./SectionContainer";
import SectionItem from "./SectionItem";

const languageProficiencies: { [key: string]: string } = {
  ELEMENTARY: "Elementary proficiency",
  LIMITED_WORKING: "Limited working proficiency",
  PROFESSIONAL_WORKING: "Professional working proficiency",
  FULL_PROFESSIONAL: "Full professional proficiency",
  NATIVE_OR_BILINGUAL: "Native or bilingual proficiency",
};
export default function Languages({
  languages,
}: {
  languages: { name: string; proficiency: string }[];
}) {
  return (
    <SectionContainer title="Languages">
      {languages.map(({ proficiency, name }) => (
        <SectionItem key={`${name}-${proficiency}`}>
          <div className="flex flex-col gap-y-1">
            <span className="text-button-14">{name}</span>
            <span className="text-caption-12-regular text-black-600">
              {languageProficiencies[proficiency]}
            </span>
          </div>
        </SectionItem>
      ))}
    </SectionContainer>
  );
}
