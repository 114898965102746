import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Lead } from "../types";
import getQueryKeys from "./getQueryKeys";
import { Conversation } from "../../pages/inbox/types";

export default function useUpdateLeads() {
  const queryClient = useQueryClient();

  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { leadsKeys, conversationsKeys } = getQueryKeys(workspaceId);

  function updateAllLeads(
    updateFunction: (lead: Omit<Lead, "location">) => void,
  ) {
    if (queryClient.getQueriesData({ queryKey: leadsKeys.list() })) {
      queryClient.setQueriesData<
        { hits: Lead[] } | { pages: { hits: Lead[] }[] }
      >({ queryKey: leadsKeys.list() }, (prevLeads) => {
        if (prevLeads) {
          if ("pages" in prevLeads) {
            return produce(prevLeads, (draftState) => {
              draftState.pages.forEach(({ hits }) => {
                for (let i = 0; i < hits.length; i += 1) {
                  updateFunction(hits[i]);
                }
              });
            });
          }
          return {
            ...prevLeads,
            hits: produce(prevLeads.hits, (draftState) => {
              for (let i = 0; i < draftState.length; i += 1) {
                updateFunction(draftState[i]);
              }
            }),
          };
        }
        return undefined;
      });
    }
    if (queryClient.getQueriesData({ queryKey: leadsKeys.details() })) {
      queryClient.setQueriesData<Lead>(
        { queryKey: leadsKeys.details() },
        (lead) => {
          if (lead) {
            return produce(lead, updateFunction);
          }
        },
      );
    }
    if (queryClient.getQueriesData({ queryKey: conversationsKeys.list() })) {
      queryClient.setQueriesData<InfiniteData<Conversation[]>>(
        { queryKey: conversationsKeys.list() },
        (prevConversations) =>
          produce(prevConversations, (draftState) => {
            for (let i = 0; i < draftState.pages.length; i += 1) {
              for (let j = 0; j < draftState.pages[i].length; j += 1) {
                for (
                  let k = 0;
                  k < draftState.pages[i][j].participants.length;
                  k += 1
                ) {
                  const lead = draftState.pages[i][j].participants[k];
                  updateFunction(lead);
                }
              }
            }
          }),
      );
    }
  }
  return updateAllLeads;
}
