import React from "react";

import { WorkspaceLicense } from "common/types";
import { Tag } from "common/components/ui/Tag";
import { formatDate } from "common/helpers/utils";

export default function LicenseTag({
  license,
  className = null,
}: {
  license: WorkspaceLicense | undefined;
  className?: string;
}) {
  let label = "No seat assigned";

  if (license) {
    label =
      license.status === "active"
        ? "Assigned"
        : `Assigned until ${formatDate(license.expires_at * 1000)}`;
  }

  return (
    <Tag variant={license ? "gradient" : "tertiary"} className={className}>
      {label}
    </Tag>
  );
}
