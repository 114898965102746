import React, { forwardRef } from "react";

import { Input, type InputProps } from "common/components/ui/Input";

interface MaxLengthInputProps extends InputProps {
  characterLength: number;
  maxLength: number;
}

const MaxLengthInput = forwardRef<HTMLInputElement, MaxLengthInputProps>(
  ({ maxLength, characterLength, rightComponent, ...rest }, ref) => (
    <Input
      {...rest}
      ref={ref}
      rightComponent={
        <>
          <span
            className={characterLength > maxLength ? "text-red-500" : undefined}
          >
            {characterLength}/{maxLength}
          </span>
          {rightComponent}
        </>
      }
    />
  ),
);
MaxLengthInput.displayName = "MaxLengthInput";

export default MaxLengthInput;
