import React, { ReactNode, JSX } from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { NavItemType } from "common/types";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

interface LayoutProps {
  children: ReactNode;
  navItems: NavItemType[];
  headerTitle: string;
  bannerComponent?: JSX.Element;
}

export default function Layout({
  children,
  navItems,
  headerTitle,
  bannerComponent = null,
}: LayoutProps) {
  const isDesktop = useTwBreakpoint("lg");
  const Component = isDesktop ? DesktopLayout : MobileLayout;

  return (
    <Component
      navItems={navItems}
      headerTitle={headerTitle}
      bannerComponent={bannerComponent}
    >
      {children}
    </Component>
  );
}
