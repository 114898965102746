import React from "react";

import useAccountLabel from "common/datahooks/useAccountLabel";
import { Button } from "common/components/ui/Button";

export default function DeleteLabelPrompt({
  labelId,
  onBack,
}: {
  labelId: string;
  onBack: () => void;
}) {
  const { deleteAccountLabel, isDeletingAccountLabel } = useAccountLabel();

  function onDelete() {
    deleteAccountLabel({ labelId }).then(() => {
      onBack();
    });
  }

  return (
    <>
      <h2 className="mb-2 text-center text-headline-2xl-bold">
        Delete this tag?
      </h2>
      <p className="mb-4 text-center text-body-14-regular text-black-500">
        Deleting this tag will remove it from all leads it is currently applied
        to. This cannot be changed later. Are you sure you want to proceed?
      </p>
      <Button
        onClick={onDelete}
        variant="tertiary-danger"
        className="mb-3"
        isLoading={isDeletingAccountLabel}
      >
        Yes
      </Button>
      <Button
        onClick={onBack}
        variant="quaternary-black"
        disabled={isDeletingAccountLabel}
      >
        No
      </Button>
    </>
  );
}
