import React from "react";

import Label from "common/components/ui/Label";
import Switch from "common/components/ui/Switch";

interface CommunicationItemProps {
  id: string;
  title: string;
  subtitle: string;
  isEnabled: boolean;
  onToggle: () => void;
}

export default function CommunicationItem({
  title,
  subtitle,
  id,
  isEnabled,
  onToggle,
}: CommunicationItemProps) {
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <Label htmlFor={id} className="flex flex-col">
        <span className="text-body-14-bold text-black-600">{title}</span>

        <p className="mb-0 text-caption-12-regular text-black-600">
          {subtitle}
        </p>
      </Label>

      <Switch id={id} size="md" checked={isEnabled} onClick={onToggle} />
    </div>
  );
}
