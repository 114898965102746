import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import useWorkspaces from "common/datahooks/useWorkspaces";
import useInvitations from "common/datahooks/useInvitations";
import { CreateWorkspaceModal } from "common/components/CreateWorkspace";
import SearchInput from "common/components/SearchInput";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import usePromotions from "common/datahooks/usePromotions";

import WorkspacesTable from "./WorkspacesTable";
import WorkspacesList from "./WorkspacesList";
import ProfileInfo from "./ProfileInfo";
import ManagePromotion from "./ManagePromotions/ManagePromotion";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function Workspaces() {
  const isDesktop = useTwBreakpoint("lg");
  const [inputValue, setInputValue] = useState("");
  const { appSumoPromotion, ltdPromotion } = usePromotions();
  const { workspaces } = useWorkspaces();
  const { invitations } = useInvitations();

  function clearSearch() {
    setInputValue("");
  }

  const actionComponents = (
    <div
      className={clsx(
        appSumoPromotion && ltdPromotion && "max-md:flex-col",
        "flex gap-x-1 md:gap-x-4",
      )}
    >
      {(appSumoPromotion || ltdPromotion) && (
        <div
          className={clsx(
            "flex gap-1 md:gap-x-4",
            appSumoPromotion && ltdPromotion
              ? "flex max-lg:basis-2/3 max-md:mb-4 max-md:border-b max-md:border-b-black-200 max-md:pb-4"
              : "max-lg:basis-1/2",
          )}
        >
          {appSumoPromotion && <ManagePromotion promotion={appSumoPromotion} />}
          {ltdPromotion && <ManagePromotion promotion={ltdPromotion} />}
        </div>
      )}
      <CreateWorkspaceModal
        triggerButton={
          <Button leftIcon={<PlusCircle weight="fill" />} className="flex-1">
            New workspace
          </Button>
        }
      />
    </div>
  );

  const searchInputComponent = (
    <SearchInput
      value={inputValue}
      onChange={(e) => setInputValue(e.currentTarget.value)}
      onClear={() => setInputValue("")}
      placeholder="Search workspaces"
      className="max-lg:my-4 lg:w-[384px]"
      variant={isDesktop ? "md" : "lg"}
    />
  );

  const lowercasedInput = inputValue.toLowerCase();

  const filteredWorkspaces = workspaces.filter(({ name }) =>
    name.toLowerCase().includes(lowercasedInput),
  );
  const filteredInvitations = invitations
    ? invitations.filter(({ workspace_name }) =>
        workspace_name.toLowerCase().includes(lowercasedInput),
      )
    : [];

  let mainContent;

  if (inputValue && !filteredInvitations.length && !filteredWorkspaces.length) {
    mainContent = (
      <EmptyPlaceholder
        title="No results found"
        subtitle="There were no results that match your criteria"
        imageSrc={NoResultsImage}
        actionComponent={<Button onClick={clearSearch}>Clear Search</Button>}
      />
    );
  } else {
    const Component = isDesktop ? WorkspacesTable : WorkspacesList;

    mainContent = (
      <Component
        workspaces={filteredWorkspaces}
        invitations={filteredInvitations}
      />
    );
  }

  return (
    <>
      <RenderIf condition={isDesktop}>
        <div className="mb-6 flex items-center gap-x-2">
          <h1 className="text-headline-2xl-bold">Workspaces</h1>
          <Tag variant="secondary">{workspaces.length}</Tag>
        </div>
      </RenderIf>

      <ProfileInfo />

      {isDesktop ? (
        <div className="mt-6 flex justify-between gap-4">
          {searchInputComponent}
          {actionComponents}
        </div>
      ) : (
        <>
          <div
            className={clsx(
              "mt-4 flex gap-3 max-sm:flex-col",
              appSumoPromotion || ltdPromotion
                ? "flex-col"
                : "sm:justify-between",
            )}
          >
            <div className="flex items-center gap-1">
              <h1 className="text-headline-lg-bold">Workspaces</h1>
              <Tag variant="secondary">{workspaces.length}</Tag>
            </div>
            {actionComponents}
          </div>
          {searchInputComponent}
        </>
      )}
      {mainContent}
    </>
  );
}
