export default function useInsertText(
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  setValue: (newValue: string) => void,
) {
  function insertText(text: string) {
    const textArea = textAreaRef.current;
    if (!textArea) return;

    const originalValue = textArea.value;
    const newSelection = textArea.selectionStart + text.length;
    setValue(
      originalValue.substring(0, textArea.selectionStart) +
        text +
        originalValue.substring(textArea.selectionEnd, originalValue.length),
    );
    textArea.focus();
    textArea.selectionStart = newSelection;
    textArea.selectionEnd = newSelection;
  }

  return insertText;
}
