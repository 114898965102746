import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function getThisWasMeTokenRequest(
  workspaceId: string,
  email: string,
): Promise<string> {
  return (
    await get(`workspaces/${workspaceId}/accounts/challenge/${email}/token`)
  ).token;
}

export default function useThisWasMeToken(email: string) {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: thisWasMeToken, isPending: isLoadingToken } = useQuery({
    queryKey: ["thisWasMeToken", email],
    queryFn: () => getThisWasMeTokenRequest(workspaceId, email),
  });

  return { thisWasMeToken, isLoadingToken };
}
