import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { PricingPeriodUnit } from "common/types";
import usePeriodUnitPricing from "common/hooks/usePeriodUnitPricing";

import { post } from "../helpers/HTTP";

async function estimateCreationRequest(
  workspaceId: string,
  planId: string,
  licenseId: string,
  quantity: number,
): Promise<{ amountDue: number; nextBillingAt: number }> {
  const { estimate } = await post(
    `workspaces/${workspaceId}/subscriptions/estimates/create`,
    {
      items: [
        {
          id: planId,
        },
        ...(quantity > 0
          ? [
              {
                id: licenseId,
                quantity,
              },
            ]
          : []),
      ],
    },
  );

  const {
    invoice_estimate: invoiceEstimate,
    subscription_estimate: subscriptionEstimate,
    next_invoice_estimate: nextInvoiceEstimate,
  } = estimate;

  return {
    amountDue: invoiceEstimate
      ? invoiceEstimate.amount_due
      : nextInvoiceEstimate.amount_due,
    nextBillingAt: subscriptionEstimate.next_billing_at,
  };
}

export default function useEstimateCreation({
  selectedPeriodUnit,
  quantity,
}: {
  selectedPeriodUnit: PricingPeriodUnit;
  quantity: number;
}) {
  const { id: workspaceId, subscription } = useSelectedWorkspaceContext();
  const { plan, license } = usePeriodUnitPricing(selectedPeriodUnit);

  const { data: estimateCreation, isLoading: isLoadingEstimateCreation } =
    useQuery({
      queryKey: ["estimateCreation", selectedPeriodUnit, quantity],
      queryFn: () =>
        estimateCreationRequest(workspaceId, plan.id, license.id, quantity),
      enabled: !subscription || subscription.status === "non_renewing",
    });

  return { estimateCreation, isLoadingEstimateCreation };
}
