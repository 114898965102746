/* eslint-disable */
// @ts-nocheck

import { isProductionEnvironment } from "common/helpers/utils";

export default function useCustomerIO() {
  function initializeCustomerIO(id: string, email: string) {
    if (isProductionEnvironment()) {
      window._cio = [];
      let c;
      const a = function (f: unknown) {
        return function () {
          window._cio.push(
            [f].concat(Array.prototype.slice.call(arguments, 0)),
          );
        };
      };
      const b = ["load", "identify", "sidentify", "track", "page"];
      for (c = 0; c < b.length; c += 1) {
        window._cio[b[c]] = a(b[c]);
      }
      const t = document.createElement("script");
      const s = document.getElementsByTagName("script")[0];
      t.async = true;
      t.id = "cio-tracker";
      t.setAttribute("data-site-id", import.meta.env.VITE_CUSTOMERIO_SITE_ID);
      t.setAttribute("data-use-array-params", "true");
      t.setAttribute(
        "data-in-app-org-id",
        import.meta.env.VITE_CUSTOMERIO_ORGANIZATION_ID,
      );
      t.setAttribute("data-use-in-app", "true");
      t.src = "https://assets.customer.io/assets/track.js";
      s.parentNode.insertBefore(t, s);
      window._cio.identify({
        id,
        email,
      });
    }
  }
  return { initializeCustomerIO };
}
