import React from "react";
import { Check, X } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

export default function SubmitButtons({
  isUpdating,
  onClose,
}: {
  isUpdating: boolean;
  onClose: () => void;
}) {
  return (
    <div className="flex flex-row gap-2">
      <Button
        variant="tertiary-black"
        intent="iconOnly"
        onClick={onClose}
        disabled={isUpdating}
      >
        <X />
      </Button>

      <Button
        variant="secondary-purple"
        intent="iconOnly"
        type="submit"
        disabled={isUpdating}
      >
        <Check />
      </Button>
    </div>
  );
}
