import { produce } from "immer";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Conversation, ConversationMessage } from "../types";

export default function useUpdateConversation() {
  const queryClient = useQueryClient();

  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { conversationsKeys } = getQueryKeys(workspaceId);

  function updateConversation(
    conversationUrn: string,
    updateFunction: (conversation: Conversation) => void,
    newConversationAccountId?: string,
  ) {
    queryClient.setQueriesData<InfiniteData<Conversation[]>>(
      { queryKey: conversationsKeys.list() },
      (prevConversations) => {
        if (prevConversations) {
          return produce(prevConversations, (draftState) => {
            for (let i = 0; i < draftState.pages.length; i += 1) {
              for (let j = 0; j < draftState.pages[i].length; j += 1) {
                const conversation = draftState.pages[i][j];
                if (conversation.conversation_urn === conversationUrn) {
                  updateFunction(conversation);
                  return;
                }
              }
            }
            // TODO: (hotfix) This is a temporary solution. The logic for new conversation should be revisited
            if (newConversationAccountId) {
              const newConversation: Conversation = {
                conversation_urn: conversationUrn,
                last_activity_at: null,
                last_message: null,
                participants: null,
                unread_count: 0,
                owner: newConversationAccountId,
              };
              updateFunction(newConversation);
              // if conversation wasn't found, add a new one
              draftState.pages[0].unshift(newConversation);
            }
          });
        }
        return undefined;
      },
    );
  }
  function updateConversationMessage(
    conversationUrn: string,
    messageUrn: string,
    updateFunction: (message: ConversationMessage) => void,
  ) {
    queryClient.setQueryData<InfiniteData<ConversationMessage[]>>(
      conversationsKeys.messages(conversationUrn),
      (prevMessages) =>
        produce(prevMessages, (draftState) => {
          for (let i = 0; i < draftState.pages.length; i += 1) {
            for (let j = 0; j < draftState.pages[i].length; j += 1) {
              const message = draftState.pages[i][j];
              if (message.urn === messageUrn) {
                updateFunction(message);
                return;
              }
            }
          }
        }),
    );
  }
  function addMessage(conversationUrn: string, message: ConversationMessage) {
    queryClient.setQueryData<InfiniteData<ConversationMessage[]>>(
      conversationsKeys.messages(conversationUrn),
      (prevMessages) => {
        if (prevMessages) {
          return produce(prevMessages, (draftState) => {
            draftState.pages[0].unshift(message);
          });
        }
        return { pages: [[message]], pageParams: [] };
      },
    );
  }
  return { updateConversation, updateConversationMessage, addMessage };
}
