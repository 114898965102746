import React, { useState } from "react";
import { useForm } from "react-hook-form";

import useCustomer from "common/datahooks/useCustomer";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import Checkbox from "common/components/ui/Checkbox";

import SubmitButtons from "../SubmitButtons";

export default function UpdatePassword({ onClose }: { onClose: () => void }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { updatePassword, isUpdatingPassword } = useCustomer();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    newPassword: string;
    confirmPassword: string;
  }>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  function onSubmit({
    newPassword,
    confirmPassword,
  }: {
    newPassword: string;
    confirmPassword: string;
  }) {
    if (!newPassword) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }

    updatePassword({ newPassword }).then(() => {
      onClose();
    });
  }

  return (
    <form
      className="flex w-full items-end gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Label
            htmlFor="newPassword"
            className="text-caption-12-bold"
            size="md"
          >
            New password
          </Label>

          <Input
            id="newPassword"
            variant="md"
            placeholder="Enter your new password"
            type={isPasswordVisible ? "text" : "password"}
            error={errors.newPassword?.message}
            {...register("newPassword", {
              required: "New password is required",
            })}
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label
            htmlFor="confirmPassword"
            className="text-caption-12-bold"
            size="md"
          >
            Confirm password
          </Label>

          <Input
            id="confirmPassword"
            variant="md"
            placeholder="Confirm your new password"
            type={isPasswordVisible ? "text" : "password"}
            error={errors.confirmPassword?.message}
            {...register("confirmPassword", {
              required: "New password confirmation is required",
            })}
          />
        </div>

        <div className="flex flex-row items-center gap-2">
          <Checkbox
            id="showPassword"
            size="md"
            onChange={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
          />

          <Label htmlFor="showPassword" size="md">
            Show password
          </Label>
        </div>
      </div>

      <SubmitButtons isUpdating={isUpdatingPassword} onClose={onClose} />
    </form>
  );
}
