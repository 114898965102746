import React from "react";
import { GearSix } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import useCustomer from "common/datahooks/useCustomer";
import Avatar from "common/components/ui/Avatar";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

export default function ProfileInfo() {
  const { customerInfo } = useCustomer();
  const isDesktop = useTwBreakpoint("lg");
  const { first_name: firstName, last_name: lastName, email } = customerInfo;

  function getNameDetails() {
    const initials = `${firstName[0]}${lastName[0]}`;
    const fullName = `${firstName} ${lastName}`;

    return { initials, fullName };
  }

  const workspacesButtonComponent = isDesktop ? (
    <Button
      variant="tertiary-black"
      size="sm"
      leftIcon={<GearSix />}
      className="ml-auto"
      asChild
    >
      <Link to="/profile-settings">Profile settings</Link>
    </Button>
  ) : (
    <Button
      variant="tertiary-black"
      className="ml-auto"
      intent="iconOnly"
      asChild
    >
      <Link to="/profile-settings">
        <GearSix />
      </Link>
    </Button>
  );

  return (
    <div className="flex w-full flex-row items-center gap-4 rounded-20 border border-black-200 bg-whiteGray p-3">
      <Avatar size="lg">{getNameDetails().initials}</Avatar>

      <div className="flex flex-col justify-center">
        <span className="w-full max-w-[256px] overflow-hidden truncate text-body-16-bold">
          {getNameDetails().fullName}
        </span>

        <span className="w-full max-w-[256px] overflow-hidden truncate text-caption-12-regular text-black-500">
          {email}
        </span>
      </div>

      {workspacesButtonComponent}
    </div>
  );
}
