import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { LinkedInLoginModal } from "common/components/LinkedInLogin";
import SearchInput from "common/components/SearchInput";

import AccountsTable from "./AccountsTable";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";
import ConnectAccountImage from "assets/images/empty-placeholders/connect-account.png";

export default function LinkedInAccounts() {
  const {
    license_count: licenseCount,
    licenses,
    accounts,
  } = useSelectedWorkspaceContext();
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const lowercasedInput = searchText.trim().toLowerCase();
  const filteredAccounts = accounts.filter(({ full_name }) =>
    full_name.trim().toLowerCase().includes(lowercasedInput),
  );

  const mainComponent =
    searchText.trim() && !filteredAccounts.length ? (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle="There were no results found for your search. Try searching for something else"
        actionComponent={
          <Button onClick={() => setSearchText("")}>Clear Search</Button>
        }
      />
    ) : (
      <AccountsTable accounts={filteredAccounts} />
    );

  const addAccountButton = (
    <Button
      data-intercom-target="add-account-button"
      leftIcon={<PlusCircle weight="fill" />}
      onClick={() => setIsAddAccountOpen(true)}
    >
      Add account
    </Button>
  );

  return (
    <>
      {accounts.length ? (
        <div className="flex grow flex-col">
          <div className="flex flex-col-reverse justify-between gap-y-3 md:flex-row md:items-center">
            <SearchInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onClear={() => setSearchText("")}
              className="md:w-80"
              placeholder="Search accounts"
            />

            <div className="flex flex-col gap-3 md:flex-row md:items-center">
              <span className="text-caption-12-regular md:text-button-16 md:text-black-400">
                No. of seats:{" "}
                <span className="md:text-black-950">
                  {licenses.filter(({ account_id }) => !!account_id).length}/
                  {licenseCount}
                </span>
              </span>

              {addAccountButton}
            </div>
          </div>

          {mainComponent}
        </div>
      ) : (
        <EmptyPlaceholder
          imageSrc={ConnectAccountImage}
          title="No LinkedIn accounts added"
          subtitle="Add a LinkedIn account to start using campaigns, leads and inbox. You can add unlimited accounts"
          actionComponent={addAccountButton}
        />
      )}

      <LinkedInLoginModal
        isOpen={isAddAccountOpen}
        onClose={() => setIsAddAccountOpen(false)}
      />
    </>
  );
}
