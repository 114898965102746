import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { CaretDown, CircleNotch } from "@phosphor-icons/react";

import RenderIf from "common/components/RenderIf";
import { getCountryName } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import SearchInput from "common/components/SearchInput";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import useProxies from "common/datahooks/useProxies";

interface ProxySelectProps {
  countryCode: string;
  onChange: (countryCode: string) => void;
  isLoading?: boolean;
}

// TODO: Add Storybook
export default function ProxySelect({
  countryCode,
  onChange,
  isLoading = false,
}: ProxySelectProps) {
  const { countryCodes, suggestedCountryCode, isLoadingProxies } = useProxies();

  const isSuggestedCountry = countryCode === suggestedCountryCode;

  let selectValue;
  if (isLoadingProxies || isLoading) {
    selectValue = (
      <>
        <CircleNotch size={16} className="animate-spin fill-black-400" />
        <span className="text-black-400">Loading countries...</span>
      </>
    );
  } else if (countryCode) {
    selectValue = (
      <>
        <ReactCountryFlag countryCode={countryCode} className="h-3 w-4" />
        <span className="flex-1 text-left">{getCountryName(countryCode)}</span>
        <RenderIf condition={isSuggestedCountry}>
          <Tag variant="secondary">Recommended</Tag>
        </RenderIf>
      </>
    );
  } else {
    selectValue = <span className="text-black-500">Select location</span>;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const lowercasedInput = inputValue.toLowerCase();
  const filteredOptions =
    countryCodes?.filter((code) =>
      getCountryName(code).toLocaleLowerCase().includes(lowercasedInput),
    ) || [];

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} modal>
      <PopoverTrigger className="group ml-auto flex h-12 w-full items-center justify-between rounded-xl bg-black-100 px-3 text-button-16 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:text-black-400">
        <div className="flex flex-1 items-center gap-x-2">{selectValue}</div>
        <CaretDown
          size={20}
          className="ml-2 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent className="z-aboveDialog w-[--radix-popper-anchor-width] pb-2">
        <SearchInput
          type="text"
          placeholder="Search countries"
          value={inputValue}
          className="p-2"
          onChange={(e) => setInputValue(e.target.value)}
          onClear={() => setInputValue("")}
        />

        {filteredOptions.length ? (
          <div className="max-h-40 overflow-y-auto scrollbar-thin md:max-h-56">
            {filteredOptions.map((code) => (
              <button
                key={code}
                onClick={() => {
                  onChange(code);
                  setIsOpen(false);
                }}
                type="button"
                className="flex w-full px-4 py-2 text-button-14 transition-colors hover:bg-black-50"
              >
                <div>
                  <ReactCountryFlag
                    countryCode={code}
                    className="mr-2 h-3 w-4"
                  />
                  <span>{getCountryName(code)}</span>
                </div>
                <RenderIf condition={code === suggestedCountryCode}>
                  <Tag variant="secondary" className="ml-auto">
                    Recommended
                  </Tag>
                </RenderIf>
              </button>
            ))}
          </div>
        ) : (
          <p className="mx-4 break-words py-4 text-center text-black-500">
            No results for <span className="text-black-950">{inputValue}</span>
          </p>
        )}
      </PopoverContent>
    </Popover>
  );
}
