import React from "react";
import { UsersThree } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";

import useLinkedinGroups from "../../datahooks/useLinkedinGroups";

interface GroupTargetingTagProps {
  groupId: string;
  isLoading: boolean;
}

export default function GroupTargetingTag({
  groupId,
  isLoading,
}: GroupTargetingTagProps) {
  const { linkedinGroup, isLoadingLinkedinGroup } = useLinkedinGroups({
    groupId,
  });

  if (isLoading || isLoadingLinkedinGroup)
    return <Skeleton className="h-7 w-60" />;

  return (
    <Tag
      size="lg"
      variant="primary-black"
      leftIcon={<UsersThree weight="fill" />}
      className="max-w-full"
    >
      <span className="truncate">
        {linkedinGroup ? linkedinGroup.name : "LinkedIn Group"}
      </span>
    </Tag>
  );
}
