import React, { ReactNode } from "react";

export default function SectionContainer({
  title,
  children,
  extraComponent = null,
}: {
  title: string;
  children: ReactNode;
  extraComponent?: ReactNode;
}) {
  return (
    <div className="mb-8 flex flex-col last:mb-0">
      <div className="mb-3.5 flex items-center gap-x-2.5 rounded-xl bg-black-50 px-4 py-2">
        <h2 className="text-button-16">{title}</h2>
        {extraComponent}
      </div>
      <div className="flex flex-col px-2">{children}</div>
    </div>
  );
}
