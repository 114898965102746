import React from "react";
import { Check } from "@phosphor-icons/react";
import clsx from "clsx";

import { GenericSearchParam, SearchParamWithImage } from "common/types";
import RenderIf from "common/components/RenderIf";
import ProfileImage from "common/components/ui/ProfileImage";

interface SearchParamOptionProps {
  option: GenericSearchParam | SearchParamWithImage;
  isSelected: boolean;
  onClick: () => void;
}

export default function SearchParamOption({
  option,
  isSelected,
  onClick,
}: SearchParamOptionProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="mb-1 flex w-full items-center gap-2 p-2 text-left transition-colors last:mb-2 hover:bg-black-50"
    >
      <span
        className={clsx(
          "inline-flex size-4 shrink-0 select-none items-center justify-center rounded",
          isSelected ? "bg-black-950 text-white" : "border border-black-200",
        )}
      >
        <RenderIf condition={isSelected}>
          <Check size={12} />
        </RenderIf>
      </span>
      {"logo_url" in option ? (
        <>
          <ProfileImage src={option.logo_url} size="sm" />
          <div>
            <h5 className="line-clamp-1 text-button-14">{option.name}</h5>
            <p className="text-black-400">{option.description}</p>
          </div>
        </>
      ) : (
        option.name
      )}
    </button>
  );
}
