import React, { useState } from "react";
import { clsx } from "clsx";
import { GearSix, SignOut } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import useCustomer from "common/datahooks/useCustomer";
import Avatar from "common/components/ui/Avatar";

import LogOutModal from "../../LogOutModal";

export default function ProfilePopup({ isExpanded }: { isExpanded: boolean }) {
  const [isOpen, setIsOpen] = useState(false);

  const { customerInfo } = useCustomer();
  const { first_name: firstName, last_name: lastName, email } = customerInfo;

  const menuItemClassname =
    "flex items-center gap-1 pl-2.5 pr-4 py-2 cursor-pointer bg-white hover:bg-black-50 transition-colors text-button-14 [&_svg]:size-5";

  return (
    <>
      <Popover>
        <PopoverTrigger
          className={clsx(
            "mt-auto flex items-center justify-start gap-x-3 rounded-2xl transition-[border,padding] duration-200",
            isExpanded && "border border-black-700 px-3 py-2.5",
          )}
        >
          <Avatar>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
          <div
            className={clsx(
              "flex flex-col items-start gap-y-1 overflow-hidden transition-opacity duration-300",
              isExpanded ? "opacity-100" : "opacity-0",
            )}
          >
            <span className="w-full truncate text-left text-button-14 text-white">
              {firstName} {lastName}
            </span>
            <span className="w-full truncate text-caption-10-semibold text-black-500">
              {email}
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="z-tooltip flex w-48 flex-col py-3"
          side="right"
          align="end"
        >
          <Link
            to="/profile-settings"
            state={{ canGoBack: true }}
            className={menuItemClassname}
          >
            <GearSix />
            Profile settings
          </Link>
          <button
            className={menuItemClassname}
            type="button"
            onClick={() => setIsOpen(true)}
          >
            <SignOut />
            Log Out
          </button>
        </PopoverContent>
      </Popover>
      <LogOutModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
