import React, { useEffect, useState } from "react";

import ProxySelect from "common/components/ProxySelect";
import { Button } from "common/components/ui/Button";

import useAccountProxy from "../../datahooks/useAccountProxy";

export default function AccountProxy({ accountId }: { accountId: string }) {
  const [proxyLocation, setProxyLocation] = useState<string>();

  const { isLoadingProxy, isSettingProxy, proxyCountryCode, setProxy } =
    useAccountProxy(accountId);

  useEffect(() => {
    if (!isLoadingProxy && proxyCountryCode) {
      setProxyLocation(proxyCountryCode);
    }
  }, [isLoadingProxy, proxyCountryCode]);

  return (
    <>
      <div className="flex flex-col gap-y-1 max-md:mb-4">
        <span className="text-black-700">Proxy (login location)</span>
        <ProxySelect
          countryCode={proxyLocation}
          onChange={(countryCode) => setProxyLocation(countryCode)}
          isLoading={isLoadingProxy}
        />
      </div>

      <Button
        variant="secondary-black"
        size="lg"
        onClick={() => setProxy(proxyLocation)}
        isLoading={isSettingProxy}
        disabled={!proxyLocation}
      >
        Save changes
      </Button>
    </>
  );
}
