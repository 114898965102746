import React from "react";
import { NavLink } from "react-router-dom";
import { clsx } from "clsx";

import { Tag } from "common/components/ui/Tag";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { NavItemType } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface BaseNavItemProps extends Omit<NavItemType, "path"> {
  isExpanded?: boolean;
}

export default function NavItem({
  path,
  state,
  onClick,
  label,
  renderIcon,
  notifications,
  isExpanded = false,
}: BaseNavItemProps &
  (
    | { path?: never; onClick: () => void; state?: never }
    | { path: string; onClick?: never; state?: unknown }
  )) {
  const isDesktop = useTwBreakpoint("lg");
  const commonClassName =
    "group w-full flex h-14 items-center justify-start gap-x-2 rounded-lg px-2.5 hover:bg-black-800 lg:h-10 lg:overflow-hidden max-lg:mb-1 max-lg:last:mb-0";

  function getButtonContent(isActive: boolean) {
    return (
      <>
        {renderIcon(
          {
            size: 20,
            className: clsx(
              "fill-black-600",
              isActive
                ? "fill-white"
                : "group-hover:fill-white group-active:fill-white",
            ),
            weight: "fill",
          },
          clsx(
            isActive
              ? "border-black-800"
              : "border-black-950 group-hover:border-black-800",
            !notifications && "hidden",
          ),
        )}
        <span
          className={clsx(
            "text-nowrap text-button-16 text-black-600 transition-opacity duration-500 lg:text-[0.75rem]",
            isActive ? "text-white" : "group-hover:text-white",
            isExpanded ? "lg:opacity-100" : "lg:opacity-0",
          )}
        >
          {label}
        </span>
        {typeof notifications === "number" && !!notifications && (
          <Tag
            variant="primary"
            className={clsx(
              "ml-auto transition-opacity duration-300",
              isExpanded ? "delay-300 lg:opacity-100" : "lg:opacity-0",
            )}
          >
            {notifications}
          </Tag>
        )}
      </>
    );
  }

  const component = path ? (
    <NavLink
      data-intercom-target={`nav-link-${path}`}
      to={path}
      state={state}
      className={clsx(
        commonClassName,
        "hover:bg-black-800 [&.active]:bg-black-800",
      )}
    >
      {({ isActive }) => getButtonContent(isActive)}
    </NavLink>
  ) : (
    <button onClick={onClick} type="button" className={commonClassName}>
      {getButtonContent(false)}
    </button>
  );

  if (isExpanded || !isDesktop) {
    return component;
  }

  return (
    <Tooltip>
      <TooltipTrigger disabled={isExpanded} asChild>
        {component}
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={18} className="w-auto">
        {label}
      </TooltipContent>
    </Tooltip>
  );
}
