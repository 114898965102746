import React, { useMemo, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Member } from "common/types";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import Avatar from "common/components/ui/Avatar";
import { userRoles } from "common/constants";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import useCustomer from "common/datahooks/useCustomer";

import MobileDrawerContent from "./MobileDrawerContent";

import NoDataImage from "assets/images/empty-placeholders/search-campaign.png";

export default function MobileList({
  members,
  isLoading,
}: {
  members: Member[];
  isLoading: boolean;
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>(null);

  const handleDrawerState = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const handleRowClick = (member: Member) => {
    setSelectedMember(member);
    setIsDrawerOpen(true);
  };

  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : members),
    [members, isLoading],
  );

  const { customerInfo } = useCustomer();
  const { email: customerEmail } = customerInfo;

  const matchingMember = members.find(
    (member) => member.email === customerEmail,
  );
  const customerRole = matchingMember
    ? userRoles[matchingMember.role]?.label
    : null;

  if (!isLoading && !tableData.length) {
    return (
      <EmptyPlaceholder
        title="No Members Found"
        subtitle="No members match your search criteria. Try adjusting your search"
        imageSrc={NoDataImage}
      />
    );
  }

  const hasMemberActions =
    (customerRole !== "Member" || customerEmail === selectedMember?.email) &&
    selectedMember?.role !== "owner";

  return (
    <>
      <div className="flex justify-between rounded-xl bg-black-100 px-4 py-2">
        <span className="text-caption-12-regular">Account</span>
        <span className="pr-20 text-caption-12-regular">Role</span>
      </div>

      <div className="mt-2 flex flex-col gap-2">
        {tableData.map((member: Member, index) => {
          const {
            token,
            first_name: firstName,
            last_name: lastName,
            role,
            customer_id: customerId,
            email,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, tagVariant: roleTagVariant } =
            roleData || {};

          const getFullName = () =>
            customerId ? `${firstName} ${lastName}` : "New Member";

          return (
            <button
              key={token || index}
              type="button"
              className="flex items-center justify-between gap-4 rounded-2xl border border-black-200 p-3"
              onClick={() => handleRowClick(member)}
            >
              <div className="flex flex-row items-center gap-2">
                {isLoading ? (
                  <Skeleton className="size-12 rounded-full" />
                ) : (
                  <Avatar variant={customerId ? "primary" : "quaternary"}>
                    {customerId ? `${firstName[0]}${lastName[0]}` : "N/A"}
                  </Avatar>
                )}

                <div className="flex flex-col justify-center gap-0.5">
                  <span className="flex flex-row items-center gap-2 text-body-14-bold">
                    {isLoading ? (
                      <Skeleton className="h-5 w-52" />
                    ) : (
                      <span
                        className={clsx(
                          "max-w-[15ch] truncate text-start text-body-14-bold",
                          !customerId && "text-black-300",
                        )}
                      >
                        {getFullName()}
                      </span>
                    )}
                  </span>

                  {isLoading ? (
                    <Skeleton className="h-4 w-24" />
                  ) : (
                    <span className="max-w-[20ch] truncate text-start text-caption-12-regular text-black-500">
                      {email}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center gap-4">
                {isLoading ? (
                  <Skeleton className="h-4 w-24" />
                ) : (
                  <Tag variant={roleTagVariant} className="capitalize">
                    {roleLabel}
                  </Tag>
                )}

                <Button intent="labelIcon" asChild variant="quaternary-black">
                  <CaretRight />
                </Button>
              </div>
            </button>
          );
        })}
      </div>

      <Drawer open={isDrawerOpen} onOpenChange={handleDrawerState}>
        <DrawerContent>
          <MobileDrawerContent
            member={selectedMember}
            hasMemberActions={hasMemberActions}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}
