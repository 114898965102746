import { useState } from "react";

import { CreatedTemplate, TemplateType } from "common/types";

export default function useTemplatesTable(templates: CreatedTemplate[]) {
  const [searchText, setSearchText] = useState("");
  const [templateType, setTemplateType] = useState<TemplateType>(null);

  const filteredTemplates = templates
    ? templates.filter(
        ({ name, type }) =>
          name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) &&
          (templateType ? type === templateType : true),
      )
    : [];

  function clearFilters() {
    setSearchText("");
    setTemplateType(null);
  }

  return {
    searchText,
    setSearchText,
    templateType,
    setTemplateType,
    filteredTemplates,
    hasFilters: !!searchText || !!templateType,
    clearFilters,
  };
}
