import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import { DotOutline } from "@phosphor-icons/react";

import { timeSince } from "common/helpers/utils";
import useNotifications from "common/datahooks/useNotifications";

export default function NotificationItem({
  title,
  body,
  seen,
  created_at,
  id,
}: {
  title: string;
  body: string;
  seen: boolean;
  created_at: number;
  id: string;
}) {
  const { openNotification } = useNotifications();

  useEffect(() => {
    if (!seen) {
      const timeoutId = setTimeout(
        () => openNotification({ notificationId: id }),
        1000,
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <Link
      className={clsx(
        "flex items-center gap-x-1 rounded-xl p-3",
        !seen && "bg-purple-50",
      )}
      to="account/notifications"
    >
      <div className="flex flex-col gap-y-1">
        <span className={seen ? "text-body-14-regular" : "text-body-14-bold"}>
          {title}
        </span>
        <span className="text-caption-12-regular text-black-600">{body}</span>
      </div>
      <div className="flex items-center gap-x-1">
        {!seen && (
          <DotOutline weight="fill" size={16} className="fill-purple-500" />
        )}
        <span className="text-nowrap text-caption-12-regular text-black-600">
          {timeSince(created_at * 1000)}
        </span>
      </div>
    </Link>
  );
}
