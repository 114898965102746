import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { clsx } from "clsx";
import { CaretUpDown } from "@phosphor-icons/react";

import useInvitations from "common/datahooks/useInvitations";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Tag } from "common/components/ui/Tag";
import SearchInput from "common/components/SearchInput";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useWorkspaces from "common/datahooks/useWorkspaces";
import { CreateWorkspaceModal } from "common/components/CreateWorkspace";
import WorkspaceImage from "common/components/WorkspaceImage";
import { userRoles } from "common/constants";

import InvitationItem from "./InvitationItem";
import WorkspaceItem from "./WorkspaceItem";

const scrollThreshold = 4;
export default function WorkspacesPopup() {
  const {
    id: workspaceId,
    name: workspaceName,
    role,
  } = useSelectedWorkspaceContext();
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const { invitations } = useInvitations();
  const { workspaces } = useWorkspaces();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const lowercasedInput = inputValue.toLowerCase();

  const filteredWorkspaces = workspaces.filter(({ name }) =>
    name.toLowerCase().includes(lowercasedInput),
  );
  const filteredInvitations = invitations
    ? invitations.filter(({ workspace_name }) =>
        workspace_name.toLowerCase().includes(lowercasedInput),
      )
    : [];

  const { label, textColor } = userRoles[role];

  const workspacesCount = workspaces.length;
  const invitationsCount = invitations ? invitations.length : 0;

  const isOverScrollThreshold =
    workspacesCount + invitationsCount > scrollThreshold;

  const isEmptySearch =
    !!inputValue && !filteredWorkspaces.length && !filteredInvitations.length;

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={clsx(
          "flex w-[270px] items-center gap-x-2 rounded-2xl border border-black-200 px-3 py-2 transition-colors",
          isOpen ? "bg-black-100" : "bg-white hover:bg-black-100",
        )}
      >
        <WorkspaceImage id={workspaceId} />
        <div className="flex flex-col gap-y-0.5">
          <span className="line-clamp-1 break-all text-left text-button-16">
            {workspaceName}
          </span>
          <span className={clsx("text-left text-button-12", textColor)}>
            {label}
          </span>
        </div>
        <CaretUpDown size={20} className="ml-auto" />
      </PopoverTrigger>

      <PopoverContent className="flex w-[360px] flex-col p-3" align="end">
        {/* Heading */}
        <RenderIf condition={isOverScrollThreshold}>
          <SearchInput
            placeholder="Search workspaces"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onClear={() => setInputValue("")}
          />
          <div className="-mx-3 my-3 h-px bg-black-200 px-3" />
          <div className="mb-2 flex items-center gap-x-2">
            <span className="text-caption-12-regular text-black-400">
              Workspace list
            </span>

            <Tag variant="secondary">{workspaces.length}</Tag>
          </div>
        </RenderIf>

        <div
          className={clsx(
            "flex flex-col gap-y-2 overflow-y-auto scrollbar-thin",
            isOverScrollThreshold && "h-80",
          )}
        >
          {isEmptySearch ? (
            <p className="mx-4 break-words py-8 text-center text-black-500">
              No results for{" "}
              <span className="text-black-950">{inputValue}</span>
            </p>
          ) : (
            <>
              {filteredInvitations.map((invitation) => (
                <InvitationItem
                  invitation={invitation}
                  key={invitation.token}
                />
              ))}
              {filteredWorkspaces.map((workspace) => (
                <WorkspaceItem
                  key={workspace.id}
                  workspace={workspace}
                  isSelected={workspace.id === workspaceId}
                />
              ))}
            </>
          )}
        </div>

        {/* Separator */}
        <RenderIf condition={!!inputValue || !!filteredWorkspaces.length}>
          <div className="-mx-3 my-3 h-px bg-black-200 px-3" />
        </RenderIf>

        <CreateWorkspaceModal
          triggerButton={
            <Button size="lg" variant="secondary-black" className="mb-3">
              + Add workspace
            </Button>
          }
        />

        <Button size="lg" variant="tertiary-black" asChild>
          <Link to="/workspaces" state={{ canGoBack: true }}>
            See all workspaces
          </Link>
        </Button>
      </PopoverContent>
    </Popover>
  );
}
