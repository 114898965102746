import React from "react";
import { Routes, Route } from "react-router-dom";

import TemplatesList from "./TemplatesList";

export default function Templates() {
  return (
    <Routes>
      <Route path="/" element={<TemplatesList />} />
    </Routes>
  );
}
