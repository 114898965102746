import React from "react";
import { Translate } from "@phosphor-icons/react";

import languages from "common/constants/languages";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
} from "common/components/ui/Select";

import { getLanguageName } from "../../../../utils";

interface LanguageDropdownProps {
  languageCode: string;
  onChange: (languageCode: string) => void;
  isManualLanguage: boolean;
  isDisabled: boolean;
}

export default function LanguageDropdown({
  languageCode,
  onChange,
  isManualLanguage,
  isDisabled,
}: LanguageDropdownProps) {
  const label = languageCode ? getLanguageName(languageCode) : "Language";

  return (
    <Select value={languageCode} onValueChange={onChange} disabled={isDisabled}>
      <SelectTrigger size="sm" variant="simple">
        <Translate weight="fill" size={16} />
        {languageCode && !isManualLanguage && (
          <span className="text-black-400">Detected:</span>
        )}
        {label}
      </SelectTrigger>
      <SelectContent>
        {languages.map(({ code, name }) => (
          <SelectItem value={code} key={code}>
            <SelectItemText>{name}</SelectItemText>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
