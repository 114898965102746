import { useQuery } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";

async function getUrlPreview(url: string, hash: string) {
  return (await post("helpers/preview-link", { url, hash })).preview;
}
export default function useUrlPreview({
  url,
  hash,
}: {
  url: string;
  hash: string;
}) {
  const { data: preview, isLoading: isLoadingPreview } = useQuery<{
    title: string;
    description: string;
    images: string[];
    url: string;
  }>({
    queryKey: ["urlPreview", url],
    queryFn: () => getUrlPreview(url, hash),
  });
  return {
    preview,
    isLoadingPreview,
  };
}
