import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { CommunicationSettings } from "common/types";

import { get, patch } from "../helpers/HTTP";

async function getCommunicationSettings() {
  return (await get("customers/communication")).communication;
}

async function updateCommunicationSettingsRequest(
  updatedSettings: Partial<CommunicationSettings>,
) {
  return (await patch("customers/communication", updatedSettings))
    .communication;
}

const queryKey = ["communicationSettings"];

export default function useCommunicationSettings() {
  const queryClient = useQueryClient();

  const {
    data: communicationSettings,
    isLoading: isLoadingCommunicationSettings,
  } = useQuery<CommunicationSettings>({
    queryKey,
    queryFn: getCommunicationSettings,
    refetchOnMount: false,
  });

  const {
    mutateAsync: updateCommunicationSettings,
    isPending: isUpdatingCommunicationSettings,
  } = useMutation({
    mutationFn: (updatedSettings: Partial<CommunicationSettings>) =>
      updateCommunicationSettingsRequest(updatedSettings),
    onMutate: async (updatedSettings) => {
      // Cancel any refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value of communication settings
      const previousSettings =
        queryClient.getQueryData<CommunicationSettings>(queryKey);

      // Optimistically update communication settings with new value
      queryClient.setQueryData<CommunicationSettings>(queryKey, (old) => ({
        ...old,
        ...updatedSettings,
      }));

      // Return object with the initial value
      return { previousSettings };
    },
    onError: (_, __, { previousSettings }) => {
      // Rollback to the initial value
      queryClient.setQueryData(queryKey, previousSettings);

      // Show toast with error message
      toast.error(
        "Failed to update communication settings. Please try again later.",
      );
    },
  });

  return {
    communicationSettings,
    isLoadingCommunicationSettings,
    updateCommunicationSettings,
    isUpdatingCommunicationSettings,
  };
}
