import React from "react";

import { Tag } from "common/components/ui/Tag";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function PromotionsTag() {
  const { licenses } = useSelectedWorkspaceContext();

  const promotionLicensesCount = licenses.reduce(
    (accumulator, currentValue) => {
      if (currentValue.source !== "aimfox") {
        accumulator[currentValue.source] += 1;
      }
      return accumulator;
    },
    { appsumo: 0, ltd: 0 },
  );

  const totalPromotionLicenses =
    promotionLicensesCount.appsumo + promotionLicensesCount.ltd;

  if (totalPromotionLicenses) {
    const label = `${totalPromotionLicenses} lifetime seats`;
    return (
      <Tag
        variant={promotionLicensesCount.appsumo ? "yellow" : "primary-black"}
        className={
          promotionLicensesCount.appsumo && "bg-appsumoYellow text-black-950"
        }
      >
        {label}
      </Tag>
    );
  }
}
