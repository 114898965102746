import { Check, Info } from "@phosphor-icons/react";
import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";
import useWorkspaces from "common/datahooks/useWorkspaces";
import { TooltipPortal } from "common/components/ui/Tooltip/Tooltip";

export default function WorkspaceSelect({
  selectedWorkspaceId,
  setSelectedWorkspaceId,
}: {
  selectedWorkspaceId: string;
  setSelectedWorkspaceId: (selectedWorkspaceId: string) => void;
}) {
  const { workspaces } = useWorkspaces();

  return (
    <Select value={selectedWorkspaceId} onValueChange={setSelectedWorkspaceId}>
      <SelectTrigger id="workspace-select">
        <SelectValue placeholder="Select a workspace" />
      </SelectTrigger>

      <SelectContent className="z-aboveDialog">
        {workspaces
          .filter(({ role }) => role === "owner")
          .map(
            ({
              subscription,
              id,
              name,
              member_count: memberCount,
              max_members: maxMembers,
            }) => {
              let disabledContent: { text: string; tooltipText: string };

              if (subscription && subscription.status === "in_trial") {
                disabledContent = {
                  text: "In free trial",
                  tooltipText:
                    "You cannot apply lifetime seats to a workspace in trial. Choose a different workspace, or cancel your trial",
                };
              } else if (maxMembers && memberCount > maxMembers) {
                disabledContent = {
                  text: "Too many members",
                  tooltipText:
                    "This workspace has more users than your lifetime deal allows. Choose a different workspace, or remove some users",
                };
              }

              return (
                <SelectItem
                  disabled={!!disabledContent}
                  value={id}
                  key={id}
                  className="group"
                >
                  <SelectItemText>{name}</SelectItemText>

                  <Check className="ml-auto size-5 group-data-[state=unchecked]:hidden" />

                  {disabledContent && (
                    <>
                      <span className="ml-auto mr-1 text-caption-12-bold text-black-400">
                        {disabledContent.text}
                      </span>

                      <Tooltip>
                        <TooltipTrigger>
                          <Info
                            weight="fill"
                            className="size-4 text-black-400"
                          />
                        </TooltipTrigger>

                        <TooltipPortal>
                          <TooltipContent className="z-aboveDialog">
                            {disabledContent.tooltipText}
                          </TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                    </>
                  )}
                </SelectItem>
              );
            },
          )}
      </SelectContent>
    </Select>
  );
}
