import React from "react";
import { Navigate, useParams } from "react-router-dom";

import useInvitation from "common/datahooks/useInvitation";
import Skeleton from "common/components/ui/Skeleton";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import WorkspaceInvitation from "common/components/WorkspaceInvitation";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";

export default function Invitation() {
  const { inviteToken } = useParams();
  const { relativeNavigate } = useWorkspaceNavigate();

  const {
    invitation,
    isLoadingInvitation,
    invitationError,
    refetchInvitation,
  } = useInvitation(inviteToken);

  if (isLoadingInvitation) {
    return (
      <div className="mx-auto flex size-full max-w-[520px] grow flex-col justify-center">
        <Skeleton className="mx-auto mb-6 aspect-[364/254] size-full max-w-[364px]" />
        <Skeleton className="mx-auto mb-2 h-8 w-8/12" />

        <div className="flex w-full flex-col items-center gap-1">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-8/12" />
        </div>

        <div className="mt-12 flex size-full flex-col gap-4 md:flex-row">
          <Skeleton className="h-10 w-full rounded-full" />
          <Skeleton className="h-10 w-full rounded-full" />
        </div>
      </div>
    );
  }

  if (invitationError) {
    return (
      <ErrorPlaceholder
        errorMessage="Error loading invitation"
        onRetry={refetchInvitation}
      />
    );
  }

  if (!invitation || (invitation && invitation.status !== "pending")) {
    return <Navigate to="home" replace />;
  }

  return (
    <WorkspaceInvitation
      invitation={invitation}
      onDecline={() => relativeNavigate("home")}
    />
  );
}
