import React, { useEffect, useState } from "react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import usePushService from "common/hooks/usePushService";
import useCommunicationSettings from "common/datahooks/useCommunicationSettings";

import WebPushNotificationsImage from "assets/images/web-push-notifications.png";

export default function WebPushPrompt() {
  const { webPushPrompted, subscribeWebPush, updateLocalStorage } =
    usePushService();
  const { communicationSettings } = useCommunicationSettings();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (communicationSettings && communicationSettings.webpush) {
      if (!webPushPrompted) {
        navigator.permissions
          .query({ name: "notifications" })
          .then(({ state }) => {
            setIsOpen(state !== "granted");
            updateLocalStorage(true);
          });
      }
      subscribeWebPush().finally(() => {
        setIsOpen(false);
      });
    }
  }, [communicationSettings, webPushPrompted]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-w-screen-sm items-center">
        <img
          src={WebPushNotificationsImage}
          alt="Web Push Notifications"
          height={254}
          width={364}
        />
        <h3 className="mb-2 mt-4 text-center text-headline-2xl-bold">
          Enable Web Push Notifications
        </h3>
        <p className="text-center text-black-500">
          Please enable Web Push notifications to receive the latest updates and
          offers from Aimfox
        </p>
        <span className="mt-4 text-center text-body-14-bold text-purple-500 md:mt-6">
          Press “ALLOW” in the browser prompt
        </span>
      </DialogContent>
    </Dialog>
  );
}
