import React from "react";
import clsx from "clsx";
import { Check } from "@phosphor-icons/react";
import { FieldError } from "react-hook-form";

import { Input } from "common/components/ui/Input";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Question, QuestionnaireResult } from "common/types";

interface QuestionDisplayProps {
  question: Question;
  currentResponse: QuestionnaireResult;
  handleAnswer: (answer: string, isCustomAnswer: boolean) => void;
  handleCustomInputChange: (value: string) => void;
  errorMessage?: FieldError;
}

export default function QuestionDisplay({
  question,
  currentResponse,
  handleAnswer,
  handleCustomInputChange,
  errorMessage = null,
}: QuestionDisplayProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { title, type, answers } = question;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      <h1 className="text-center text-headline-2xl-bold">{title}</h1>

      <div className="flex w-full flex-col flex-wrap items-center justify-center gap-2 md:flex-row md:gap-4">
        {answers.map((answer) => {
          const { id, title: answerTitle, isCustomAnswer } = answer;
          const isChecked = currentResponse?.answers.includes(answerTitle);

          return (
            <button
              key={`${id}-${answerTitle}`}
              className={clsx([
                "flex w-full flex-row items-center gap-2 rounded-2xl px-4 py-3 md:w-auto",
                "transition-shadow duration-300 ease-in-out hover:shadow-sm",
                isChecked
                  ? "bg-purple-500 text-white"
                  : "bg-tertiary-black text-dark ring-1 ring-inset ring-black-200",
              ])}
              type="button"
              onClick={() => handleAnswer(answerTitle, isCustomAnswer)}
            >
              <RenderIf condition={type === "multiple"}>
                {/* Custom checkbox */}
                <span
                  className={clsx([
                    "flex size-3.5 items-center justify-center rounded ring-[1.25px] ring-inset",
                    isChecked
                      ? "bg-white text-purple-600 ring-white"
                      : "ring-black-950",
                  ])}
                >
                  <RenderIf condition={isChecked}>
                    <Check size={10} weight="bold" />
                  </RenderIf>
                </span>
              </RenderIf>

              {answerTitle}
            </button>
          );
        })}
      </div>

      <RenderIf condition={currentResponse?.additional_info !== undefined}>
        <Input
          className="my-2 w-full"
          error={errorMessage?.message}
          variant={isTabletOrDesktop ? "md" : "lg"}
          placeholder="Type here..."
          value={currentResponse?.additional_info}
          onChange={(e) => handleCustomInputChange(e.target.value)}
        />
      </RenderIf>
    </div>
  );
}
