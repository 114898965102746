import React from "react";
import clsx from "clsx";
import { Info } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

interface PlanDetailsProps {
  isOutreachPlan: boolean;
  isSubscriptionNonRenewing: boolean;
}

export default function PlanDetails({
  isOutreachPlan,
  isSubscriptionNonRenewing,
}: PlanDetailsProps) {
  return (
    <div
      className={clsx([
        "flex w-full flex-col items-start rounded-xl p-4 xl:max-w-[288px]",
        isOutreachPlan ? "bg-blackPurple text-white" : "bg-darkGray",
      ])}
    >
      {isSubscriptionNonRenewing ? (
        <Tag size="lg" variant="yellow">
          Non-renewing
        </Tag>
      ) : (
        <Tag size="lg" variant={isOutreachPlan ? "success" : "primary"}>
          {isOutreachPlan ? "Active" : "Free"}
        </Tag>
      )}

      <div className="mb-2 mt-3.5 flex items-center gap-x-2">
        <span className="text-headline-xl-bold">
          LinkedIn {isOutreachPlan ? "Outreach" : "CRM"}
        </span>

        <Tooltip>
          <TooltipTrigger>
            <Info size={20} weight="fill" />
          </TooltipTrigger>

          <TooltipContent>
            <ul className="list-disc pl-3">
              {isOutreachPlan ? (
                <>
                  <li>Bypass LinkedIn connection request limits</li>
                  <li>AI-optimized outreach automation</li>
                  <li>Follow-up direct message sequences</li>
                  <li>Various performance optimization options</li>
                  <li>Auto-translate campaigns and DMs</li>
                </>
              ) : (
                <>
                  <li>Dedicated IP address for each LinkedIn account</li>
                  <li>Fully integrated Inbox</li>
                  <li>Export leads data</li>
                  <li>Leads database and management</li>
                  <li>In-depth analytics dashboard</li>
                  <li>Multi-user support & permission control</li>
                </>
              )}
            </ul>
          </TooltipContent>
        </Tooltip>
      </div>

      <p className={clsx(!isOutreachPlan && "text-black-500")}>
        {isOutreachPlan
          ? "Grow your LinkedIn network with fully automated, personalized campaigns that convert. And a sprinkle of AI"
          : "Boost your team's efficiency and nurture your connections with robust personalization and organization tools"}
      </p>
    </div>
  );
}
