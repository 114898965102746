import React from "react";

import RenderIf from "common/components/RenderIf";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  formatPrice,
  formatPeriodUnitLabel,
  formatShortDate,
  isLifetimeDeal,
} from "common/helpers/utils";
import { PricingPeriodUnit } from "common/types";
import usePeriodUnitPricing from "common/hooks/usePeriodUnitPricing";

import Banner from "./Banner";
import ToPayNow from "./ToPayNow";
import CurrentLicense from "./CurrentLicense";
import NewPrice from "./NewPrice";
import FreeTrialBanner from "./FreeTrialBanner";

interface PricingInfoProps {
  licenseCount: number;
  selectedPeriodUnit: PricingPeriodUnit;
}

export default function PricingInfo({
  licenseCount,
  selectedPeriodUnit,
}: PricingInfoProps) {
  const {
    license_count: workspaceLicenseCount,
    subscription,
    licenses,
  } = useSelectedWorkspaceContext();

  const { license } = usePeriodUnitPricing(selectedPeriodUnit);

  let bannerComponent;

  const isDowngrade = licenseCount < workspaceLicenseCount;

  const isMonthlySubscription =
    subscription && subscription.item_price.period_unit === "month";
  const isUpgradeToYearly =
    isMonthlySubscription && selectedPeriodUnit === "year";

  if (isUpgradeToYearly) {
    bannerComponent = (
      <Banner
        variant="info"
        textNode="Switching your subscription to yearly billing is effective for all current and future seat purchases"
      />
    );
  } else if (!subscription && selectedPeriodUnit === "year") {
    bannerComponent = (
      <Banner
        textNode="Yearly plans are paid up-front and do not offer a free trial"
        variant="info"
      />
    );
  } else if (isDowngrade) {
    bannerComponent = (
      <Banner
        textNode="This is a scheduled change. Your subscription will not change until your next billing date"
        variant="warning"
      />
    );
  } else if (!subscription && !isLifetimeDeal(licenses)) {
    bannerComponent = (
      <FreeTrialBanner
        licenseCount={licenseCount}
        selectedPeriodUnit={selectedPeriodUnit}
      />
    );
  } else if (!isLifetimeDeal(licenses) && subscription.status === "in_trial") {
    const nextBillingDate = formatShortDate(subscription.next_billing * 1000);

    bannerComponent = (
      <Banner
        textNode={`You will not be charged until your  trial expires. Your subscription will automatically upgrade to a paid plan on ${nextBillingDate}`}
        variant="gradient"
      />
    );
  }

  return (
    <section className="flex flex-col gap-y-2.5">
      <div className="flex items-center justify-between rounded-md bg-black-100 px-2 py-1">
        <span className="text-button-14 text-black-600">Price per seat</span>

        <span className="text-body-16-regular text-black-500">
          {formatPrice(license.price / 100)}/
          {formatPeriodUnitLabel(selectedPeriodUnit)}
        </span>
      </div>

      <RenderIf condition={isLifetimeDeal(licenses)}>
        <div className="flex items-center justify-between px-2 py-1">
          <span className="text-button-14 text-black-600">Lifetime seats</span>
          <span className="text-body-16-regular text-black-500">
            {licenses.filter(({ lifetime }) => lifetime).length}
          </span>
        </div>
      </RenderIf>

      {/* Current price & Number of licenses */}
      <RenderIf
        condition={
          subscription &&
          subscription.status !== "cancelled" &&
          subscription.status !== "non_renewing"
        }
      >
        <CurrentLicense />
      </RenderIf>

      <div className="flex items-center justify-between px-2 py-1">
        <span className="text-button-14 text-black-600">New no. of seats</span>
        <span className="text-body-16-bold">{licenseCount}</span>
      </div>

      <NewPrice
        licenseCount={licenseCount}
        selectedPeriodUnit={selectedPeriodUnit}
      />

      {bannerComponent || <span className="h-px w-full bg-black-200" />}

      <ToPayNow
        licenseCount={licenseCount}
        selectedPeriodUnit={selectedPeriodUnit}
      />
    </section>
  );
}
