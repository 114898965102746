import React, { useLayoutEffect, useRef, useState } from "react";

import { typedObjectEntries } from "common/helpers/utils";
import { ProfileSearchParams } from "common/types";
import { Tag, TagVariantType } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import RenderIf from "common/components/RenderIf";

import {
  keywordModifierOptions,
  keywordOptions,
  profileSearchParamsIcons,
} from "../../constants";

interface SearchTargetingTagsProps {
  searchParams: ProfileSearchParams;
  isLoading?: boolean;
}

function getTargetingTags(targeting: ProfileSearchParams) {
  return typedObjectEntries(targeting)
    .filter(([, values]) => !!values)
    .flatMap(([key, values]) => {
      const Icon = profileSearchParamsIcons[key];
      const items = Array.isArray(values) ? values : [values];

      return items.map((value) => {
        let text = "";
        if ("name" in value) {
          text = value.name;
        } else if ("full_name" in value) {
          text = value.full_name;
        } else if ("text" in value) {
          text = value.text;
        }

        const isKeyword = "text" in value;
        const tagVariant: TagVariantType = isKeyword
          ? keywordModifierOptions[value.modifier].tagVariant
          : "primary-black";

        let leftIcon = <Icon weight="fill" />;

        if (isKeyword) {
          const { icon: KeywordIcon } = keywordOptions[value.type];

          leftIcon = (
            <KeywordIcon
              weight={value.type === "general" ? "regular" : "fill"}
            />
          );
        }

        return (
          <Tag
            key={isKeyword ? value.text : value.urn}
            variant={tagVariant}
            size="lg"
            leftIcon={leftIcon}
          >
            {text}
          </Tag>
        );
      });
    });
}

export default function SearchTargetingTags({
  searchParams,
  isLoading = false,
}: SearchTargetingTagsProps) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showMoreIndex, setShowMoreIndex] = useState(0);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
  const containerElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isLoading && containerElement.current) {
      const containerWidth = containerElement.current.clientWidth;
      const { children } = containerElement.current;

      let tagsRowWidth = 0;

      for (let i = 0; i < children.length; i += 1) {
        const childWidth = children[i].clientWidth + 6; // +6 for gap

        if (tagsRowWidth + childWidth > containerWidth) {
          setShowMoreIndex(i);
          setIsShowMoreVisible(true);
          break;
        }

        tagsRowWidth += childWidth;
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="flex flex-wrap gap-1.5">
        <Skeleton className="h-7 w-32" />
        <Skeleton className="h-7 w-32" />
      </div>
    );
  }

  const tags = getTargetingTags(searchParams);

  const visibleTags =
    isShowingMore || !isShowMoreVisible ? tags : tags.slice(0, showMoreIndex);

  return (
    <div ref={containerElement} className="flex w-full flex-wrap gap-1.5">
      {visibleTags}
      <RenderIf condition={isShowMoreVisible}>
        <Tag
          size="lg"
          variant="quaternary"
          className="transition-colors hover:bg-black-200"
          asChild
        >
          <button
            type="button"
            onClick={() => setIsShowingMore((prev) => !prev)}
          >
            Show {isShowingMore ? "less" : "more"}
          </button>
        </Tag>
      </RenderIf>
    </div>
  );
}
