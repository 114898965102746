import { CaretRight } from "@phosphor-icons/react";
import React from "react";

import { Lead } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";

export default function LeadsList({
  leads,
  isLoading,
  openPreview,
}: {
  leads: Lead[];
  isLoading: boolean;
  openPreview: (lead: Lead) => void;
}) {
  return (
    <>
      <div className="mb-2 mt-4 rounded-xl bg-black-100 px-3 py-2 text-caption-12-regular text-black-700">
        Accounts
      </div>
      <div className="mb-4 flex flex-col gap-y-2.5">
        {(isLoading ? new Array<undefined>(8).fill(undefined) : leads).map(
          (lead, index) => (
            <button
              key={lead ? lead.urn : index}
              type="button"
              className="flex flex-col items-stretch rounded-20 border border-black-200 p-3"
              onClick={() => openPreview(lead)}
            >
              <div className="mb-2 flex items-center gap-x-2">
                {lead ? (
                  <ProfileImage size="lg" src={lead.picture_url} />
                ) : (
                  <Skeleton className="size-10" />
                )}
                <div className="flex flex-col items-start gap-y-0.5 overflow-x-hidden">
                  {lead ? (
                    <span className="text-body-16-bold">{lead.full_name}</span>
                  ) : (
                    <Skeleton className="h-5 w-20" />
                  )}
                  {lead ? (
                    <span className="w-full truncate text-left text-caption-12-regular text-black-600">
                      {lead.occupation}
                    </span>
                  ) : (
                    <Skeleton className="h-4 w-40" />
                  )}
                </div>
                {lead ? (
                  <Button
                    intent="labelIcon"
                    variant="quaternary-black"
                    className="ml-auto"
                    disabled={isLoading}
                  >
                    <CaretRight />
                  </Button>
                ) : (
                  <Skeleton className="ml-auto size-10 rounded-full" />
                )}
              </div>
              <div className="flex border-t border-t-black-200 pt-2">
                {lead ? (
                  <LinkedInAccountsTag accountIds={lead.lead_of} />
                ) : (
                  <Skeleton className="h-5 w-32 rounded-full" />
                )}
              </div>
            </button>
          ),
        )}
      </div>
    </>
  );
}
