import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { templateTypes } from "common/constants";
import { InmailTemplateSchema } from "common/schemas";
import useTemplates from "common/datahooks/useTemplates";
import { getSchemaMaxLength } from "common/helpers/utils";
import {
  BaseTemplate,
  CreatedTemplate,
  InMailTemplate,
  Template,
} from "common/types";
import { VariableEditor } from "common/components/VariableEditor";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";
import MaxLengthInput from "common/components/MaxLengthInput";
import Label from "common/components/ui/Label";

import useTemplate from "../../../pages/templates/datahooks/useTemplate";

interface TemplateDetailsFormProps {
  template: Template | CreatedTemplate;
  goBack: () => void;
  onCreated?: (template: CreatedTemplate) => void;
}

export default function TemplateDetailsForm({
  template,
  goBack,
  onCreated = null,
}: TemplateDetailsFormProps) {
  const { validationSchema } = templateTypes[template.type];
  const { t } = useTranslation("enums");

  const {
    createTemplate,
    updateTemplate,
    isUpdatingTemplate,
    isCreatingTemplate,
  } = useTemplate();
  const { allTemplates } = useTemplates();
  // discriminate unions aren't supported
  const form = useForm<Template & { subject?: string }>({
    defaultValues: template,
    resolver: valibotResolver(validationSchema),
  });
  const {
    clearErrors,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = form;

  const isEditing = "id" in template;

  useEffect(() => {
    clearErrors();
  }, [watch("type")]);

  function onSubmit(
    data:
      | (BaseTemplate | Pick<InMailTemplate, "name" | "message" | "subject">)
      | Template,
  ) {
    if (
      data.name !== template.name &&
      allTemplates.find(({ name }) => name === data.name.trim())
    ) {
      setError("name", { message: "Template name already exists" });
    } else if (isEditing) {
      updateTemplate({ templateId: template.id, updates: data }).then(() => {
        goBack();
      });
    } else if ("type" in data) {
      createTemplate({ template: data }).then((createdTemplate) => {
        if (onCreated) {
          onCreated(createdTemplate);
        }
        goBack();
      });
    }
  }

  return (
    <>
      <h5 className="mb-8 text-center text-headline-xl-bold">
        {isEditing ? "Edit" : "Create new"} {t(template.type)} template
      </h5>

      <Label size="sm" htmlFor="templateName" className="mb-1">
        Name
      </Label>
      <MaxLengthInput
        id="templateName"
        error={errors.name?.message}
        placeholder="Name your Template"
        characterLength={watch("name")?.length}
        maxLength={getSchemaMaxLength(validationSchema.entries.name)}
        className="mb-5"
        {...register("name")}
      />

      <RenderIf condition={template.type === "INMAIL_TEMPLATE"}>
        <Label size="sm" htmlFor="templateSubject" className="mb-1">
          Subject
        </Label>
        <MaxLengthInput
          id="templateSubject"
          error={errors.subject?.message}
          placeholder="Subject of InMail message"
          characterLength={watch("subject")?.length}
          maxLength={getSchemaMaxLength(InmailTemplateSchema.entries.subject)}
          className="mb-5"
          {...register("subject")}
        />
      </RenderIf>

      <Label size="sm" htmlFor="templateMessage" className="mb-1">
        Message
      </Label>
      <div
        id="templateMessage"
        className="flex min-h-52 flex-col rounded-2xl border border-black-200 bg-whiteGray p-3"
      >
        <Controller
          control={form.control}
          render={({ field: { value, onBlur, onChange, ref } }) => (
            <VariableEditor
              ref={ref}
              value={value}
              onChange={onChange}
              maxLength={getSchemaMaxLength(validationSchema.entries.message)}
              onBlur={onBlur}
              withScrollWrapper
            />
          )}
          name="message"
        />
      </div>

      <div className="-mx-4 my-6 h-px bg-black-200 px-4" />

      <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          isLoading={isUpdatingTemplate || isCreatingTemplate}
          onClick={handleSubmit(onSubmit)}
          className="flex-1"
        >
          {isEditing ? "Apply Changes" : "Create Template"}
        </Button>

        <Button variant="tertiary-black" className="flex-1" onClick={goBack}>
          {isEditing ? "Discard" : "Cancel"}
        </Button>
      </div>
    </>
  );
}
