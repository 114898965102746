import React from "react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { clsx } from "clsx";

import usePromotions from "common/datahooks/usePromotions";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

export default function Promotions() {
  const { appSumoPromotion } = usePromotions();
  const { licenses } = useSelectedWorkspaceContext();

  const commonClassName =
    "w-full lg:w-32 xl:w-48 flex gap-0.5 flex-col rounded-2xl p-4";

  const promotionLicensesCount = licenses.reduce(
    (accumulator, currentValue) => {
      if (currentValue.source !== "aimfox") {
        accumulator[currentValue.source] += 1;
      }
      return accumulator;
    },
    { appsumo: 0, ltd: 0 },
  );

  return (
    <div className="flex gap-x-2 max-lg:w-full">
      {!!promotionLicensesCount.appsumo && appSumoPromotion && (
        <div className={clsx(commonClassName, "bg-appsumoYellow")}>
          <div className="flex justify-between">
            <span>AppSumo</span>
            <a
              href="https://appsumo.com/account/products/"
              aria-label="go to app sumo dashboard"
              target="_blank"
              rel="noreferrer"
            >
              <ArrowSquareOut size={20} />
            </a>
          </div>
          <span className="text-body-14-bold">
            Tier {appSumoPromotion.tier}
          </span>
        </div>
      )}
      {!!promotionLicensesCount.ltd && (
        <div className={clsx(commonClassName, "bg-black-950 text-white")}>
          <span className="">Lifetime deal</span>
          <span className="text-body-14-bold">
            {promotionLicensesCount.ltd} seats
          </span>
        </div>
      )}
    </div>
  );
}
