import React from "react";
import { CaretDown, FunnelSimple } from "@phosphor-icons/react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";
import Checkbox from "common/components/ui/Checkbox";

import { AudienceProfile, AudienceProfileState } from "../../../types";
import { audienceProfileStates } from "../../../constants";

interface FiltersPopoverProps {
  selectedStates: AudienceProfileState[];
  setSelectedStates: (selectedGroups: AudienceProfileState[]) => void;
  audience: AudienceProfile[];
  isLoadingAudience: boolean;
}

export default function FiltersPopover({
  selectedStates,
  setSelectedStates,
  audience,
  isLoadingAudience,
}: FiltersPopoverProps) {
  function addStateFilters(states: AudienceProfileState[]) {
    setSelectedStates([...selectedStates, ...states]);
  }
  function removeStateFilters(states: AudienceProfileState[]) {
    setSelectedStates(
      selectedStates.filter((state) => !states.includes(state)),
    );
  }

  return (
    <Popover>
      <PopoverTrigger className="group" asChild>
        <Button
          variant="secondary-black"
          leftIcon={<FunnelSimple />}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="py-2">
        <label
          htmlFor="targets-filter-all"
          className="flex cursor-pointer items-center gap-1 px-4 py-2 transition-colors hover:bg-black-50"
        >
          <Checkbox
            id="targets-filter-all"
            checked={!selectedStates.length}
            onChange={() => setSelectedStates([])}
          />

          <span className="text-button-14">All</span>
          {isLoadingAudience ? (
            <Skeleton className="ml-auto h-4 w-6" />
          ) : (
            <span className="ml-auto text-black-400">{audience.length}</span>
          )}
        </label>

        <>
          {audienceProfileStates.map(({ label, states }) => (
            <label
              key={label}
              htmlFor={`targets-filter-${label}`}
              className="flex cursor-pointer items-center gap-1 px-4 py-2 transition-colors hover:bg-black-50"
            >
              <Checkbox
                id={`targets-filter-${label}`}
                checked={selectedStates.some((selectedState) =>
                  states.some((state) => selectedState === state),
                )}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    addStateFilters(states);
                  } else {
                    removeStateFilters(states);
                  }
                }}
              />

              <span className="text-button-14">{label}</span>
              {isLoadingAudience ? (
                <Skeleton className="ml-auto h-4 w-6" />
              ) : (
                <span className="ml-auto text-black-400">
                  {
                    audience.filter(({ state }) => states.includes(state))
                      .length
                  }
                </span>
              )}
            </label>
          ))}
        </>
      </PopoverContent>
    </Popover>
  );
}
