import { createContext, useContext } from "react";

const CampaignContext = createContext<{
  campaignId: string;
  accountId: string;
}>(null);

export const CampaignProvider = CampaignContext.Provider;

export function useCampaignContext() {
  return useContext(CampaignContext);
}
