import React, { useMemo } from "react";
import { ClockCounterClockwise } from "@phosphor-icons/react";
import clsx from "clsx";

import RenderIf from "common/components/RenderIf";
import { Member } from "common/types";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import { userRoles } from "common/constants";
import Avatar from "common/components/ui/Avatar";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import useCustomer from "common/datahooks/useCustomer";

import { INVITATION_STATUSES } from "../../constants";
import MemberActions from "./MemberActions";
import { formatBillingDate } from "../../utils";

import NoDataImage from "assets/images/empty-placeholders/search-campaign.png";

export default function DesktopTable({
  members,
  isLoading,
}: {
  members: Member[];
  isLoading: boolean;
}) {
  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : members),
    [members, isLoading],
  );

  const { customerInfo } = useCustomer();
  const { email: customerEmail } = customerInfo;

  const matchingMember = members.find(
    (member) => member.email === customerEmail,
  );
  const customerRole = matchingMember
    ? userRoles[matchingMember.role]?.label
    : null;

  if (!isLoading && !tableData.length) {
    return (
      <EmptyPlaceholder
        title="No Members Found"
        subtitle="No members match your search criteria. Try adjusting your search"
        imageSrc={NoDataImage}
      />
    );
  }

  return (
    <Table variant="list">
      <TableHeader>
        <tr>
          <TableHead>Name</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Date Added</TableHead>
          <TableHead>Status</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody>
        {tableData.map((member: Member, index) => {
          const {
            token,
            first_name: firstName = "New",
            last_name: lastName = "Member",
            role,
            customer_id: memberId,
            email,
            status,
            created_at: createdAt,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, tagVariant: roleTagVariant } =
            roleData || {};

          const hasMemberActions =
            (customerRole !== "Member" || customerEmail === email) &&
            role !== "owner";

          const renderStatusTag = () => {
            if (role === "owner") {
              return "-";
            }

            const { label } = INVITATION_STATUSES[status];

            return (
              <div
                className={clsx([
                  "flex flex-row items-center justify-center gap-1",
                  status === "pending" && "text-black-500",
                ])}
              >
                <span className="text-caption-12-regular ">{label}</span>

                <RenderIf condition={status === "pending"}>
                  <ClockCounterClockwise />
                </RenderIf>
              </div>
            );
          };

          function getNameInitials() {
            return `${firstName[0]}${lastName[0]}`;
          }

          return (
            <TableRow
              key={token || index}
              className="hover:shadow-none hover:cursor-auto"
            >
              <TableCell className="2xl:max-w-24">
                {isLoading ? (
                  <div className="flex flex-row items-center gap-2">
                    <Skeleton className="size-10 rounded-full" />

                    <div className="flex flex-col gap-1">
                      <Skeleton className="h-4 w-44 rounded-xl" />
                      <Skeleton className="h-4 w-32 rounded-xl" />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    <Avatar
                      variant={status === "pending" ? "quaternary" : "primary"}
                    >
                      {getNameInitials()}
                    </Avatar>

                    <div className="flex flex-col items-start gap-1">
                      <span
                        className={clsx(
                          "text-body-14-bold",
                          memberId ? "text-black-700" : "text-black-300",
                        )}
                      >
                        {memberId ? `${firstName} ${lastName}` : "New Member"}
                      </span>

                      <span
                        className={clsx(
                          "inline-block max-w-[40ch] overflow-hidden text-ellipsis",
                          memberId ? "text-black-700" : "text-black-300",
                        )}
                      >
                        {email}
                      </span>
                    </div>
                  </div>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-16 rounded-xl" />
                ) : (
                  <Tag
                    variant={roleTagVariant}
                    className="capitalize"
                    size="md"
                  >
                    {roleLabel}
                  </Tag>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-24 rounded-xl" />
                ) : (
                  <span
                    className={clsx(
                      "text-caption-12-regular",
                      memberId ? "text-black-700" : "text-black-300",
                    )}
                  >
                    {role === "owner" ? "-" : formatBillingDate(createdAt)}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-16 rounded-xl" />
                ) : (
                  renderStatusTag()
                )}
              </TableCell>

              <TableCell>
                <RenderIf condition={hasMemberActions}>
                  {isLoading ? (
                    <Skeleton className="ml-auto size-10 rounded-full" />
                  ) : (
                    <MemberActions member={member} />
                  )}
                </RenderIf>
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}
