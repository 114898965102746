import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { toast } from "react-toastify";
import { PlusCircle } from "@phosphor-icons/react";

import { EmailSchema } from "common/schemas";
import { HTTPError } from "common/helpers/HTTP";
import { UserRole } from "common/types";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Input } from "common/components/ui/Input";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { typedObjectEntries } from "common/helpers/utils";
import { userRoles } from "common/constants";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import Label from "common/components/ui/Label";

import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import RoleOption from "./RoleOption";

export default function InviteMember() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const {
    members,
    inviteMember,
    isInvitingMember,
    inviteMemberError,
    resetInviteMember,
  } = useWorkspaceMembers();

  const { max_members: maxMembers } = useSelectedWorkspaceContext();

  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: valibotResolver(EmailSchema),
  });

  const [selectedRole, setSelectedRole] = useState<UserRole>("admin");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const filteredRolesArray = typedObjectEntries(userRoles).filter(
    ([role]) => role !== "owner",
  );

  const roleOptions = filteredRolesArray.map(
    ([role, { label, description }]) => ({
      label,
      value: role,
      description,
    }),
  );

  function resetStates() {
    resetForm();
    resetInviteMember();
  }

  function onSubmit({ email }: { email: string }): void {
    inviteMember({
      email,
      role: selectedRole,
    }).then(() => {
      setIsDialogOpen(false);
      toast.success("Invite successfully sent");
      resetStates();
    });
  }

  let errorMessage: string;

  if (inviteMemberError) {
    if (
      inviteMemberError instanceof HTTPError &&
      inviteMemberError.type === "CustomerIsAlreadyMember"
    ) {
      errorMessage = "User is already a member of this workspace";
    } else {
      errorMessage = "Error inviting member, please try again later";
    }
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  const content = (
    <div className="flex flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-2">
        <h3 className="text-headline-xl-bold">Invite a member</h3>

        <span className="mx-auto w-full max-w-[492px] text-center text-black-500">
          Invite someone to this workspace. They will get an email invite with a
          link to join you
        </span>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-4"
      >
        <div className="flex flex-col gap-2">
          <Label className="text-button-14" htmlFor="email">
            Email
          </Label>

          <Input
            {...register("email", { onChange: resetInviteMember })}
            error={errorMessage || errors.email?.message}
            placeholder="Teammate email "
            aria-label="Teammate email"
            className="w-full"
            variant="lg"
            id="email"
          />
        </div>

        <div className="h-px w-full bg-black-200" />

        <div className="flex flex-col gap-2">
          <span className="text-button-14">Choose role:</span>

          {roleOptions.map((option) => {
            const { label, value, description } = option;

            return (
              <RoleOption
                key={value}
                option={value}
                selectedRole={selectedRole}
                onRoleChange={setSelectedRole}
                label={label}
                description={description}
              />
            );
          })}
        </div>

        <div className="grid gap-2 md:grid-cols-2 md:gap-4">
          <Close asChild>
            <Button
              variant="secondary-black"
              type="button"
              size="lg"
              className="order-2 md:order-1"
            >
              Cancel
            </Button>
          </Close>

          <Button
            className="order-1 md:order-2"
            type="submit"
            size="lg"
            isLoading={isInvitingMember}
          >
            Invite
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <Component
      open={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      onAfterClose={resetStates}
    >
      <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
        {(isDisabled) => (
          <Trigger asChild>
            <Button
              leftIcon={<PlusCircle weight="fill" />}
              disabled={
                isDisabled || (maxMembers && members.length >= maxMembers)
              }
              size="md"
              className={maxMembers ? "w-auto" : "w-full md:w-auto"}
            >
              Invite teammate
            </Button>
          </Trigger>
        )}
      </RestrictedComponent>

      <Content>{content}</Content>
    </Component>
  );
}
