import React from "react";
import { PencilSimple, Plus } from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { cardIcons, paymentMethods } from "common/constants";
import { PaymentSource } from "common/types";
import { Button } from "common/components/ui/Button";

interface PrimaryPaymentSourceProps {
  isLoading: boolean;
  paymentSource: PaymentSource;
  editPaymentSource: () => void;
}

export default function PrimaryPaymentSource({
  isLoading,
  paymentSource,
  editPaymentSource,
}: PrimaryPaymentSourceProps) {
  let Icon = null;
  if (paymentSource) {
    Icon =
      paymentSource.type === "card"
        ? cardIcons[paymentSource.card.brand]
        : paymentMethods[paymentSource.type].icon;
  }

  if (isLoading)
    return (
      <div className="flex items-center gap-x-2.5 rounded-2xl border border-black-200 bg-black-100 px-4 py-2">
        <Skeleton className="h-6 w-10" />
        <Skeleton className="h-[19px] w-36" />
        <Skeleton className="ml-auto size-10 rounded-full" />
      </div>
    );

  if (paymentSource)
    return (
      <div className="flex items-center gap-x-2.5 rounded-2xl bg-purple-50 px-4 py-2 ring ring-purple-200">
        <Icon />
        <span className="flex-1 text-nowrap text-left text-body-14-bold">
          {paymentSource.type === "card"
            ? `Card ending with ${paymentSource.card.last4}`
            : paymentSource.paypal.email}
        </span>
        <Button
          variant="tertiary-black"
          intent="iconOnly"
          onClick={editPaymentSource}
        >
          <PencilSimple />
        </Button>
      </div>
    );

  return (
    <button
      type="button"
      className="flex items-center gap-x-2 rounded-2xl border border-dashed border-black-300 bg-white px-4 py-2 text-button-14 transition-colors hover:bg-black-50"
      onClick={editPaymentSource}
    >
      <Button intent="labelIcon">
        <Plus weight="bold" />
      </Button>
      Add payment method
    </button>
  );
}
