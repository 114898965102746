import React from "react";
import { Link } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Workspace } from "common/types";
import WorkspaceImage from "common/components/WorkspaceImage";
import { Button } from "common/components/ui/Button";
import { userRoles } from "common/constants";

export default function WorkspaceItem({ workspace }: { workspace: Workspace }) {
  const { id, name, role } = workspace;
  const { label, textColor } = userRoles[role];

  return (
    <Link
      to={`/workspaces/${id}`}
      className="mb-2 flex items-center gap-2 rounded-2xl border border-black-200 bg-white p-3 transition-colors last:mb-0 hover:bg-black-50"
    >
      <WorkspaceImage id={id} />
      <div className="flex flex-col items-start gap-y-0.5">
        <span className="text-body-16-bold">{name}</span>
        <span className={clsx(textColor, "text-button-12")}>{label}</span>
      </div>

      <Button variant="quaternary-black" intent="labelIcon" className="ml-auto">
        <CaretRight />
      </Button>
    </Link>
  );
}
