import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { Button } from "common/components/ui/Button";

import PostStats from "./PostStats";
import useLinkedinPost from "../../../../datahooks/useLinkedinPosts";
import { PostStatus } from "../../../../types";
import PostPreview from "./PostPreview";

interface PostDetailsProps {
  postStatus: PostStatus;
  postId: string;
  resetPostUrl: () => void;
  updatePostStatus: (status: keyof PostStatus, checked: boolean) => void;
}

export default function PostDetails({
  postStatus,
  postId,
  resetPostUrl,
  updatePostStatus,
}: PostDetailsProps) {
  const { linkedinPost, isLoadingLinkedinPost, isLinkedinPostError } =
    useLinkedinPost({ postId });

  useEffect(() => {
    if (isLinkedinPostError) {
      resetPostUrl();
      toast.error(
        "We weren't able to load this preview, please choose a different post",
      );
    }
  }, [isLinkedinPostError]);

  if (isLinkedinPostError) return <span>Couldn&apos;t load preview</span>;

  const { likes, comments, shares } = linkedinPost ?? {};

  return (
    <article className="mx-auto flex w-full max-w-5xl flex-col gap-4 md:flex-row md:items-start md:gap-8">
      <PostPreview post={linkedinPost} isLoading={isLoadingLinkedinPost} />

      <div className="flex flex-1 flex-col gap-4">
        <PostStats
          interactions={{ likes, comments, shares }}
          isLoading={isLoadingLinkedinPost}
          postStatus={postStatus}
          updatePostStatus={updatePostStatus}
        />

        <Button
          size="lg"
          variant="secondary-black"
          onClick={resetPostUrl}
          className="ml-auto"
        >
          Change post
        </Button>
      </div>
    </article>
  );
}
