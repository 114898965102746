import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { getCountryName } from "common/helpers/utils";

interface ProxiesResponse {
  countries: string[];
  suggestion: {
    country_code: string;
  };
}

function getProxiesRequest(workspaceId: string): Promise<ProxiesResponse> {
  return get(`workspaces/${workspaceId}/proxies`);
}

export default function useProxies() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data, isLoading } = useQuery({
    queryKey: ["proxies", workspaceId],
    queryFn: () => getProxiesRequest(workspaceId),
  });

  // Sort country codes alphabetically by country name
  const countryCodes = data?.countries.sort((a, b) => {
    const nameA = getCountryName(a);
    const nameB = getCountryName(b);
    return nameA.localeCompare(nameB, "en", { ignorePunctuation: true });
  });

  return {
    countryCodes,
    suggestedCountryCode: data?.suggestion.country_code,
    isLoadingProxies: isLoading,
  };
}
