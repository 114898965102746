import React, { useRef } from "react";
import { Check, X } from "@phosphor-icons/react";
import clsx from "clsx";

import { Invitation } from "common/types";
import useInvitations from "common/datahooks/useInvitations";
import { userRoles } from "common/constants";
import { Button } from "common/components/ui/Button";
import WorkspaceImage from "common/components/WorkspaceImage";

export default function InvitationItem({
  invitation,
}: {
  invitation: Invitation;
}) {
  const {
    workspace_id: workspaceId,
    workspace_name: workspaceName,
    token,
    role,
  } = invitation;
  const isAcceptRef = useRef<boolean>();

  const { label, textColor } = userRoles[role];

  const { respondToInvitation, isRespondingToInvitation } = useInvitations({
    disableQuery: true,
  });

  function acceptInvite() {
    isAcceptRef.current = true;
    respondToInvitation({ accept: true, memberToken: token });
  }

  function declineInvite() {
    isAcceptRef.current = false;
    respondToInvitation({ accept: false, memberToken: token });
  }

  return (
    <div className="flex items-center gap-2 rounded-2xl border border-black-200 p-3">
      <WorkspaceImage id={workspaceId} />
      <div className="flex flex-col items-start gap-y-0.5">
        <span className="line-clamp-1 break-all text-left text-body-14-bold">
          {workspaceName}
        </span>

        <span className={clsx(textColor, "text-button-12")}>{label}</span>
      </div>

      <div className="ml-auto flex gap-2">
        <Button
          intent="iconOnly"
          size="sm"
          onClick={declineInvite}
          variant="tertiary-black"
          isLoading={isAcceptRef.current === false && isRespondingToInvitation}
          disabled={isAcceptRef.current === true && isRespondingToInvitation}
        >
          <X />
        </Button>
        <Button
          intent="iconOnly"
          size="sm"
          variant="secondary-purple"
          onClick={acceptInvite}
          isLoading={isAcceptRef.current === true && isRespondingToInvitation}
          disabled={isAcceptRef.current === false && isRespondingToInvitation}
        >
          <Check />
        </Button>
      </div>
    </div>
  );
}
