import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogClose, DialogFooter } from "common/components/ui/Dialog";
import { DrawerClose, DrawerFooter } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import SeatPurchaseImage from "assets/images/seat-purchase.png";

export default function SeatPurchase({ onNext }: { onNext: () => void }) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { subscription } = useSelectedWorkspaceContext();

  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  const isFreeTrial = subscription?.status === "in_trial";

  return (
    <>
      <img
        src={SeatPurchaseImage}
        width={364}
        height={254}
        className="mx-auto mb-4"
        alt="Seat purchase illustration"
      />
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        {isFreeTrial
          ? "Add a Trial seat to use LinkedIn Outreach"
          : "You need to purchase a seat to use LinkedIn Outreach"}
      </h3>
      <p className="mb-14 text-center text-black-500 md:mb-8">
        {isFreeTrial
          ? "This feature requires a LinkedIn Outreach seat. You will not be charged for any seats until your trial expires"
          : "You do not have any available seats at this time. You will need to purchase a seat to use this feature"}
      </p>

      <Footer className="shadow-none flex-col-reverse gap-4 border-none md:-mx-16 md:flex-row">
        <Close asChild>
          <Button className="flex-1" variant="secondary-black" size="lg">
            Cancel
          </Button>
        </Close>
        <Button
          className="flex-1"
          size="lg"
          variant="gradient"
          onClick={onNext}
        >
          Purchase a seat
        </Button>
      </Footer>
    </>
  );
}
