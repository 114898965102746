import { jwtDecode } from "jwt-decode";

import { redirectToAuth } from "common/helpers/auth";

export default async function checkAuth() {
  const token = localStorage.getItem("auth");
  let shouldRedirectToAuth = false;

  if (token) {
    try {
      const { exp } = jwtDecode(token);
      const expirationTime = exp * 1000 - 60000;
      if (expirationTime && Date.now() >= expirationTime) {
        shouldRedirectToAuth = true;
      }
    } catch (err) {
      shouldRedirectToAuth = true;
    }
  } else {
    shouldRedirectToAuth = true;
  }
  if (shouldRedirectToAuth) {
    await redirectToAuth(true);
    return Promise.reject();
  }
  return Promise.resolve();
}
