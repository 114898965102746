/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { FunnelSimple } from "@phosphor-icons/react";

import { LinkedInProfile, SearchSuggestion } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import { Button } from "common/components/ui/Button";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import { useSearchParamsContext } from "../SearchParamsContext";
import useSearchSuggestions from "../../../../../datahooks/useSearchSuggestions";
import ProfileItem from "./ProfileItem";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/search-campaign.png";

interface PreviewResultsProps {
  profiles: LinkedInProfile[];
  isLoading: boolean;
}

const AUDIENCE_LIMIT = 18;

export default function PreviewResults({
  profiles,
  isLoading,
}: PreviewResultsProps) {
  const { setSearchParams } = useSearchParamsContext();
  const { t } = useTranslation("campaigns", { keyPrefix: "targetingSetup" });

  const { searchSuggestions, isLoadingSearchSuggestions } =
    useSearchSuggestions();

  function setDefaultSearchParams({
    keyword_text,
    industries,
    schools,
    locations,
    current_companies,
  }: Omit<SearchSuggestion, "label">) {
    setSearchParams({
      keywords: [{ text: keyword_text, type: "general", modifier: "optional" }],
      industries: industries ?? [],
      locations: locations ?? [],
      schools: schools ?? [],
      current_companies: current_companies ?? [],
    });
  }

  const audience = profiles
    ? profiles.slice(0, AUDIENCE_LIMIT)
    : new Array(AUDIENCE_LIMIT).fill({});

  // Search Suggestions
  if ((!profiles || !profiles.length) && !isLoading) {
    return (
      <EmptyPlaceholder
        imageSrc={EmptyPlaceholderImage}
        title={t(!profiles ? "noResultsTitle" : "noProfilesFoundTitle")}
        subtitle={t(
          !profiles ? "noResultsSubtitle" : "noProfilesFoundSubtitle",
        )}
        actionComponent={
          <div className="flex flex-wrap items-center justify-center gap-2">
            {isLoadingSearchSuggestions ? (
              <>
                {new Array(5).fill({}).map((_, index) => (
                  <Skeleton key={index} className="h-8 w-44 rounded-full" />
                ))}
              </>
            ) : (
              searchSuggestions.map(({ label, ...searchOptions }) => (
                <Button
                  key={label}
                  variant="tertiary-black"
                  size="sm"
                  leftIcon={<FunnelSimple />}
                  onClick={() => setDefaultSearchParams(searchOptions)}
                >
                  {label}
                </Button>
              ))
            )}
          </div>
        }
      />
    );
  }

  return (
    <section className="grid gap-3 max-lg:mt-4 md:grid-cols-2 lg:grid-cols-3">
      {audience.map((profile, index) => (
        <ProfileItem key={index} profile={profile} isLoading={isLoading} />
      ))}
    </section>
  );
}
