import React from "react";
import { CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Button } from "common/components/ui/Button";

import { profileSearchOptions } from "../../../../../../constants";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import GenericOptions from "../Options/GenericOptions";
import Followers from "../Options/Followers";
import Languages from "../Options/Languages";
import Companies from "../Options/Companies";

export default function SearchParamSelect({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  // this helps load suggestions earlier
  useSearchOptions();
  const { appliedSearchParamValues } = useSearchParams(optionsKey);
  const { icon: Icon, label } = profileSearchOptions[optionsKey];

  let content = <GenericOptions optionsKey={optionsKey} />;
  if (optionsKey === "followers") {
    content = <Followers optionsKey={optionsKey} />;
  } else if (optionsKey === "companies") {
    content = <Companies optionsKey={optionsKey} />;
  } else if (optionsKey === "languages") {
    content = <Languages optionsKey={optionsKey} />;
  }

  const isEnabled = !!appliedSearchParamValues.length;

  const firstSelection = appliedSearchParamValues[0];

  let selectLabel = label;
  if (appliedSearchParamValues.length === 1) {
    selectLabel =
      "full_name" in firstSelection
        ? firstSelection.full_name
        : firstSelection.name;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={isEnabled ? "primary-black" : "tertiary-black"}
          leftIcon={<Icon weight="fill" />}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
          className={clsx(
            "group",
            isEnabled
              ? "data-[state=open]:bg-black-700"
              : "data-[state=open]:bg-black-50",
          )}
        >
          <span className="max-w-32 overflow-hidden text-ellipsis text-nowrap">
            {selectLabel}
          </span>
          {appliedSearchParamValues.length > 1 && (
            <span className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-black-950">
              {appliedSearchParamValues.length}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-1">{content}</PopoverContent>
    </Popover>
  );
}
